import { RouteRecordRaw } from 'vue-router';
import { requireAuth } from '@/common/router/navigationGuard';
import ConfigViewport from '@/common/components/templates/ConfigViewport.vue';
import { Master, RoleId, Supervisors } from '@/common/auth/auth.defines';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { VIEW_MODE } from '@/common/define/common.define';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
// import { isDevServer } from '@/common/router/guard';

const PREFIX_CONFIG = 'config_';

const configMenu: RouteRecordRaw[] = [
  {
    path: '/',
    name: `${PREFIX_CONFIG}Account`,
    component: ConfigViewport,
    beforeEnter: requireAuth,
    children: [
      {
        path: 'myInfo',
        name: `${PREFIX_CONFIG}My Info`,
        component: () => import(/* webpackChunkName: "my-info" */ '../views/myInfo/MyInfo.vue'),
      },
      {
        path: 'userGroup',
        name: `${PREFIX_CONFIG}User & Group`,
        component: () =>
          import(/* webpackChunkName: "user-group" */ '../views/userGroup/UserGroup.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_ACCOUNT_USER_USER_GROUP,
          roles: Supervisors,
        },
      },
      {
        path: 'policy',
        name: `${PREFIX_CONFIG}Policy`,
        component: () => import(/* webpackChunkName: "policy" */ '../views/policy/PolicyView.vue'),
        meta: {
          roles: Supervisors,
        },
      },
      {
        path: 'permission',
        name: `${PREFIX_CONFIG}Permission`,
        component: () =>
          import(/* webpackChunkName: "permission" */ '../views/permission/PermissionView.vue'),
        meta: {
          roles: Supervisors,
        },
      },
    ],
  },
  {
    path: '/platform/',
    name: `${PREFIX_CONFIG}Platform`,
    component: ConfigViewport,
    beforeEnter: requireAuth,
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_PLATFORM,
    },
    children: [
      {
        path: 'infrastructure',
        name: `${PREFIX_CONFIG}Infrastructure`,
        component: () =>
          import(
            /* webpackChunkName: "infrastructure" */ '../views/platform/infrastructure/ConfigPlatform.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_PLATFORM_INFRASTRUCTURE,
        },
      },
      {
        path: 'cloud',
        name: `${PREFIX_CONFIG}Cloud`,
        component: () =>
          import(/* webpackChunkName: "cloud" */ '../views/platform/cloud/SettingCloud.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_PLATFORM_CLOUD,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
      {
        path: 'kubernetes',
        name: `${PREFIX_CONFIG}Kubernetes`,
        component: () =>
          import(
            /* webpackChunkName: "kubernetes" */ '../views/platform/kubernetes/ConfigPlatform.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_PLATFORM_KUBERNETES,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
      {
        path: 'application',
        name: `${PREFIX_CONFIG}Application`,
        component: () =>
          import(
            /* webpackChunkName: "application" */ '../views/platform/application/ConfigPlatform.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_PLATFORM_APPLICATION,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
      {
        path: 'database',
        name: `${PREFIX_CONFIG}Database`,
        component: () =>
          import(
            /* webpackChunkName: "database" */ '../views/platform/database/ConfigPlatform.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_PLATFORM_DATABASE,
        },
      },
    ],
  },
  {
    path: '/monitoring/',
    name: `${PREFIX_CONFIG}Monitoring`,
    component: ConfigViewport,
    children: [
      {
        path: 'business',
        name: `${PREFIX_CONFIG}Business`,
        component: () =>
          import(
            /* webpackChunkName: "business" */ '../views/management/service/ConfigService.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MONITORING_BUSINESS,
        },
      },
      {
        path: 'log',
        name: `${PREFIX_CONFIG}Log`,
        component: () =>
          import(/* webpackChunkName: "log" */ '../views/management/log/LogMonitoringView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MONITORING_LOG,
        },
      },
      {
        path: 'report',
        name: `${PREFIX_CONFIG}Report`,
        component: () =>
          import(/* webpackChunkName: "report" */ '../views/management/report/ConfigReport.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MONITORING_REPORT,
        },
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MONITORING,
    },
  },
  {
    path: '/alert/',
    name: `${PREFIX_CONFIG}Alert`,
    component: ConfigViewport,
    children: [
      {
        path: 'userAlert',
        name: `${PREFIX_CONFIG}User Alert`,
        component: () =>
          import(/* webpackChunkName: "user-alert" */ '../views/alertGroup/AlertGroup.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_ALERT_USER_ALERT,
        },
      },
      {
        path: 'systemAlert',
        name: `${PREFIX_CONFIG}System Alert`,
        component: () =>
          import(/* webpackChunkName: "system-alert" */ '../views/systemAlert/SystemAlert.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_ALERT_SYSTEM_ALERT,
        },
      },
      {
        path: 'notification',
        name: `${PREFIX_CONFIG}Notification`,
        component: () =>
          import(
            /* webpackChunkName: "notification" */ '../views/notification/NotificationSetting.vue'
          ),
        // beforeEnter: startLoading,
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_ALERT_NOTIFICATION,
        },
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_ALERT,
    },
  },
  {
    path: '/management/',
    name: `${PREFIX_CONFIG}Management`,
    component: ConfigViewport,
    children: [
      {
        path: 'notice',
        name: `${PREFIX_CONFIG}Notice`,
        component: () => import('../views/management/notice/NoticeListView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_NOTICE,
        },
      },
      {
        path: 'agent',
        name: `${PREFIX_CONFIG}Agent`,
        component: () => import('../views/management/agent/AgentView.vue'),
        meta: {
          roles: Master,
        },
      },
      {
        path: 'license',
        name: `${PREFIX_CONFIG}License`,
        component: () => import('../views/management/license/LicenseView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_LICENSE,
        },
      },
      {
        path: 'tag',
        name: `${PREFIX_CONFIG}Tag`,
        component: () =>
          import(/* webpackChunkName: "database" */ '../views/management/tag/SettingTag.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_TAG,
        },
      },
      {
        path: 'data-retention',
        name: `${PREFIX_CONFIG}Data Retention`,
        component: () => import('../views/management/dataRetention/DataRetentionView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_DATA_RETENTION,
        },
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.SETTING.SETTING_PLATFORM,
    },
  },
];

const getConfigNaviMenu = (roleId: RoleId) => {
  return extractAccessibleRoutes(configMenu, roleId);
};

export { configMenu, getConfigNaviMenu };
