import { useInternational } from '@/common/locale';
import type { GroupItem } from './myInfoPopup.types';

export interface Props {
  groupList: GroupItem[];
  selectedGroup: GroupItem | null;
}

export interface Emit {
  (e: 'click-group-item', group: GroupItem): void;
}

export const setup = (emit: Emit) => {
  const { t } = useInternational();

  const onClickGroupItem = (group: GroupItem) => {
    emit('click-group-item', group);
  };

  return {
    t,

    onClickGroupItem,
  };
};
