import { RouteRecordRaw } from 'vue-router';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { VIEW_MODE } from '@/common/define/common.define';

const PREFIX_KUBERNETES = 'kubernetes_';

const kubernetesMenu: RouteRecordRaw[] = [
  {
    path: '/kubernetes/',
    name: `${PREFIX_KUBERNETES}Kubernetes`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES,
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
    },
    children: [
      {
        path: 'clusterTopology',
        name: `${PREFIX_KUBERNETES}Cluster Topology`,
        component: () =>
          import(
            /* webpackChunkName: "kubernetes-cluster-topology" */ '@/kubernetes/views/clusterTopologyView/ClusterTopologyView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CLUSTER_TOPOLOGY,
        },
      },
      {
        path: 'objectTopology',
        name: `${PREFIX_KUBERNETES}Object Topology`,
        component: () =>
          import(
            /* webpackChunkName: "kubernetes-object-topology" */ '@/kubernetes/views/objectTopologyView/ObjectTopologyView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_OBJECT_TOPOLOGY,
        },
      },
      {
        path: 'cluster',
        name: `${PREFIX_KUBERNETES}Cluster`,
        component: () => import('../views/clusterView/ClusterView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CLUSTER,
        },
      },
      {
        path: 'pod',
        name: `${PREFIX_KUBERNETES}Pod`,
        component: () =>
          import(
            /* webpackChunkName: "kubernetes-pods-list-view" */ '../views/podsListView/PodsListView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_POD,
        },
      },
      {
        path: 'namespace',
        name: `${PREFIX_KUBERNETES}Namespace`,
        component: () =>
          import(
            /* webpackChunkName: "kubernetes-namespace-view" */ '../views/namespaceView/NamespaceView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NAMESPACE,
        },
      },
      {
        path: 'node',
        name: `${PREFIX_KUBERNETES}Node`,
        component: () =>
          import(/* webpackChunkName: "kubernetes-node-view" */ '../views/nodeView/NodeView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NODE,
        },
      },
    ],
  },
  {
    path: '/kubernetes/workloads/',
    name: `${PREFIX_KUBERNETES}Workload`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
    },
    children: [
      {
        path: 'deployments',
        name: `${PREFIX_KUBERNETES}Deployment`,
        component: () => import('../views/workloads/workloadsView/WorkloadsView.vue'),
      },
      {
        path: 'statefulSets',
        name: `${PREFIX_KUBERNETES}StatefulSet`,
        component: () => import('../views/workloads/workloadsView/WorkloadsView.vue'),
      },
      {
        path: 'daemonSets',
        name: `${PREFIX_KUBERNETES}DaemonSet`,
        component: () => import('../views/workloads/workloadsView/WorkloadsView.vue'),
      },
      {
        path: 'replicaSets',
        name: `${PREFIX_KUBERNETES}ReplicaSet`,
        component: () => import('../views/workloads/workloadsView/WorkloadsView.vue'),
      },
      {
        path: 'hpa',
        name: `${PREFIX_KUBERNETES}HPA`,
        component: () => import('../views/workloads/hpaView/HpaView.vue'),
      },
      {
        path: 'jobs',
        name: `${PREFIX_KUBERNETES}Job`,
        component: () => import('../views/workloads/jobsView/JobsView.vue'),
      },
      {
        path: 'cronJobs',
        name: `${PREFIX_KUBERNETES}CronJob`,
        component: () => import('../views/workloads/cronJobsView/CronJobsView.vue'),
      },
    ],
  },
  {
    path: '/kubernetes/network/',
    name: `${PREFIX_KUBERNETES}Network`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NETWORK,
    },
    children: [
      {
        path: 'service',
        name: `${PREFIX_KUBERNETES}Service`,
        component: () => import('@/kubernetes/views/network/serviceView/ServiceView.vue'),
      },
      {
        path: 'ingress',
        name: `${PREFIX_KUBERNETES}Ingress`,
        component: () => import('@/kubernetes/views/network/ingressView/IngressView.vue'),
      },
    ],
  },
  {
    path: '/kubernetes/configStorage/',
    name: `${PREFIX_KUBERNETES}Config & Storage`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
    },
    children: [
      {
        path: 'configMaps',
        name: `${PREFIX_KUBERNETES}Configmap`,
        component: () => import('../views/configStorage/configMapsView/ConfigMapsView.vue'),
      },
      {
        path: 'persistentVolumesClaims',
        name: `${PREFIX_KUBERNETES}Persistent Volume Claim`,
        component: () =>
          import(
            '@/kubernetes/views/configStorage/persistentVolumesClaimsView/PersistentVolumesClaimsView.vue'
          ),
      },
      {
        path: 'persistentVolumes',
        name: `${PREFIX_KUBERNETES}Persistent Volume`,
        component: () =>
          import('../views/configStorage/persistentVolumesView/PersistentVolumesView.vue'),
      },
      {
        path: 'storageClasses',
        name: `${PREFIX_KUBERNETES}Storage Classes`,
        component: () => import('../views/configStorage/storageClassesView/StorageClassesView.vue'),
      },
    ],
  },
  {
    path: '/kubernetes/accessControl/',
    name: `${PREFIX_KUBERNETES}Access Control`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_ACCESS_CONTROL,
    },
    children: [
      {
        path: 'roles',
        name: `${PREFIX_KUBERNETES}Role`,
        component: () => import('../views/accessControl/rolesView/RolesView.vue'),
      },
      {
        path: 'rolesBindings',
        name: `${PREFIX_KUBERNETES}Role Binding`,
        component: () => import('../views/accessControl/rolesBindingsView/RolesBindingsView.vue'),
      },
      {
        path: 'clusterRoles',
        name: `${PREFIX_KUBERNETES}Cluster Role`,
        component: () => import('../views/accessControl/clusterRolesView/ClusterRolesView.vue'),
      },
      {
        path: 'clusterRolesBindings',
        name: `${PREFIX_KUBERNETES}Cluster Role Binding`,
        component: () =>
          import('../views/accessControl/clusterRolesBindingsView/ClusterRolesBindingsView.vue'),
      },
      {
        path: 'serviceAccounts',
        name: `${PREFIX_KUBERNETES}ServiceAccount`,
        component: () =>
          import('../views/accessControl/serviceAccountsView/ServiceAccountsView.vue'),
      },
    ],
  },
];

const subMenuMappingKubernetes = {
  workloads: `${PREFIX_KUBERNETES}Workload`,
  network: `${PREFIX_KUBERNETES}Network`,
  configStorage: `${PREFIX_KUBERNETES}Config & Storage`,
  accessControl: `${PREFIX_KUBERNETES}Access Control`,
};

const getKubernetesNaviMenu = (
  subMenu?: 'workloads' | 'network' | 'configStorage' | 'accessControl',
) => {
  const filteredKubernetesMenu = extractAccessibleRoutes(kubernetesMenu);

  if (subMenu && subMenuMappingKubernetes[subMenu]) {
    return filteredKubernetesMenu.filter((menu) => menu.name === subMenuMappingKubernetes[subMenu]);
  }

  return filteredKubernetesMenu;
};

export { kubernetesMenu, getKubernetesNaviMenu };
