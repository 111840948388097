<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  modelValue: string | undefined;
  options: {
    label: string;
    disabled?: boolean;
  }[];
  type?: 'default' | 'round';
}
interface Emits {
  (event: 'update:modelValue', newValue: string | undefined): void;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'default',
});
const emit = defineEmits<Emits>();

const selectedValue = computed({
  get: () => props.modelValue ?? props.options[0]?.label,
  set: (newSelectedValue) => emit('update:modelValue', newSelectedValue),
});
</script>

<template>
  <ev-radio-group
    v-model="selectedValue"
    :class="['toggle-button-group', { round: type === 'round' }]"
    type="button"
  >
    <ev-radio
      v-for="({ label, disabled }, index) in options"
      :key="`${label}__${index}`"
      :label="label"
      :disabled="disabled"
    />
  </ev-radio-group>
</template>

<style lang="scss">
.toggle-button-group.type-button[role='group'] .ev-radio {
  span {
    padding: 0 !important;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 12px;
    color: var(--color-gray-00-12) !important;
  }

  &.checked {
    background-color: var(--color-blue-05-05) !important;

    span {
      color: var(--color-gray-00-00) !important;
    }
  }

  &:hover:not(.checked) span {
    background-color: transparent !important;
  }
}
</style>

<style scoped lang="scss">
.ev-radio-group {
  background-color: var(--color-blue-09-01);
}
.ev-radio {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 50%;
  height: 100%;
}
:deep(.ev-radio-label) {
  width: fit-content !important;
  border-radius: inherit !important;
}
.round {
  &.ev-radio-group {
    border-radius: 50px !important;
  }
  .ev-radio {
    border-radius: inherit !important;
  }
}
</style>
