<script
  lang="ts"
  setup
  generic="ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>, RowData"
>
import { baseGridOption } from '@/common/utils/define';
import BaseGridRatioBarCell from '@/common/components/molecules/BaseGridRatioBarCell.vue';
import BaseGridChipsCell from '@/common/components/molecules/baseGridChipsCell/BaseGridChipsCell.vue';
import BaseCustomTooltip from '@/common/components/molecules/baseCustomTooltip/BaseCustomTooltip.vue';
import ChipCellTooltipContent from '@/common/components/molecules/grid/ChipCellTooltipContent.vue';
import { type Emit, type Props, setup } from '@/common/components/molecules/grid/baseGrid.setup';
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import BasePagination from '@/common/components/molecules/basePagination/BasePagination.vue';
import { convertMsToSec, formatNumWithCommasAndDecimal } from '@/common/utils/commonUtils';
import { ClickedRowInfo, CustomColumn, DblClickedRowInfo, RowData } from '@/common/utils';
import { capitalize, isArray, upperCase } from 'lodash-es';
import FilterSearch from '@/common/components/molecules/filterSearch/FilterSearch.vue';
import { COLUMN_EVENT_TYPE } from '@/common/components/molecules/grid/baseGrid.define';
import StatusTooltipTag from '@/common/components/molecules/statusTooltipTag/StatusTooltipTag.vue';
import TagItem from '../tag/TagItem.vue';

const props = withDefaults(defineProps<Props<ColumnData, RowData>>(), {
  type: 'page',
  disabledRows: () => [],
  selectedRows: () => [],
  checkedRows: () => [],
  uncheckable: () => [],
  searchWord: undefined,
  option: () => structuredClone(baseGridOption),
  searchBarOption: () => ({
    use: false,
    mode: 'full',
    placeholder: '',
    useClientFilter: false,
    useMergedToolbar: false,
    filterSearch: {
      use: false,
    },
  }),
  tooltipOption: 'list',
  pagination: () => ({
    use: false,
    useClient: false,
    align: 'right',
    showSetting: false,
    showPageInfo: false,
    perData: 20,
    items: [20, 30, 40, 50],
    total: 0,
  }),
  exportExcelOption: () => ({}),
  hasDynamicColumns: false,
  customClass: () => ({}),
});
const emit = defineEmits<Emit<ColumnData, RowData>>();

const {
  gridRef,
  rows,
  selectedRows,
  checkedRows,
  refObj,
  slotNamesOfCustomCell,
  filterSearchResultMV,
  searchWord,
  searchBarOption,
  computedColumns,
  computedOption,
  customColumnFields,
  optionClassName,
  tooltipItems,
  customTooltipRef,
  hideTooltip,
  clickCell,
  clickChip,
  clickCount,
  getDisplayRows,
  onChangeColumnInfo,
  currentPageNum,
  perDataCnt,
  totalOfRows,
  expanded,
  currentTooltipFieldName,
  currentTooltipRowData,
  currentTooltipColumnIndex,
  isChipCellClickable,
  onClickTooltipItem,
  clickCountWithFieldData,
} = setup(props, emit);
</script>

<template>
  <div
    ref="gridRef"
    :class="['base-grid', props.type, optionClassName]"
  >
    <div
      :class="[
        'base-grid__content',
        {
          'use-pagination': props?.pagination?.use,
        },
      ]"
    >
      <ev-grid
        ref="refObj"
        v-model:expanded="expanded"
        v-model:selected="selectedRows"
        v-model:checked="checkedRows"
        :disabled-rows="props.disabledRows"
        :uncheckable="props.uncheckable"
        :columns="computedColumns"
        :option="computedOption"
        :rows="getDisplayRows(rows)"
        @check-row="
          (isCheck: boolean, rowIndex: number, row: RowData) => {
            emit('check-row', isCheck, rowIndex, row);
          }
        "
        @check-all="
          (isCheck: boolean, rows: RowData[]) => {
            emit('check-all', isCheck, rows);
          }
        "
        @click-row="
          (row: ClickedRowInfo<RowData>) => {
            emit('click-row', row);
          }
        "
        @dblclick-row="
          (rows: DblClickedRowInfo<RowData>) => {
            emit('dblclick-row', rows);
          }
        "
        @change-column-status="(v) => onChangeColumnInfo(COLUMN_EVENT_TYPE.DISPLAY, v)"
        @resize-column="(v) => onChangeColumnInfo(COLUMN_EVENT_TYPE.RESIZE, v)"
        @sort-column="(v) => onChangeColumnInfo(COLUMN_EVENT_TYPE.SORT, v)"
        @change-column-order="(v) => onChangeColumnInfo(COLUMN_EVENT_TYPE.ORDER, v)"
      >
        <!-- toolbar -->
        <template
          v-if="$slots.toolbar || props?.searchBarOption?.use"
          #toolbar
        >
          <div
            :class="[
              'grid-toolbar',
              {
                'merged-toolbar': props.searchBarOption?.useMergedToolbar,
                'with-grid-setting': props?.option?.useGridSetting?.use,
              },
            ]"
          >
            <!-- toolbar - search -->
            <div
              v-if="props?.searchBarOption?.use"
              :class="[
                'grid-toolbar__row',
                'search',
                props.searchBarOption.mode,
                {
                  'with-grid-setting':
                    props?.option?.useGridSetting?.use && props?.searchBarOption?.useMergedToolbar,
                },
              ]"
            >
              <template v-if="props.searchBarOption.filterSearch?.use">
                <filter-search
                  v-model:selected-values="filterSearchResultMV"
                  :filter-items="props.searchBarOption?.filterSearch?.filterItems ?? []"
                  :storage-key="props.searchBarOption.filterSearch?.storageKey ?? 'base-grid'"
                  :placeholder="props.searchBarOption.filterSearch?.placeholder"
                />
              </template>
              <template v-else>
                <ev-text-field
                  v-model.trim="searchWord"
                  :placeholder="searchBarOption.placeholder"
                  :max-length="50"
                >
                  <template #icon-prefix>
                    <ev-icon icon="ev-icon-search2" />
                  </template>
                </ev-text-field>
              </template>
            </div>

            <!-- toolbar - custom slot -->
            <div
              v-if="$slots.toolbar"
              :class="[
                'grid-toolbar__row',
                'custom',
                { 'with-grid-setting': props?.option?.useGridSetting?.use },
              ]"
            >
              <slot name="toolbar" />
            </div>
            <slot name="tableHeader" />
          </div>
        </template>

        <!-- cell renders by rendererType -->
        <template
          v-for="(fieldOption, fieldName) in customColumnFields"
          :key="fieldName"
          #[fieldName]="{ item }"
        >
          <!-- cell-render by rendererType-->
          <template v-if="fieldOption?.rendererType === 'ratio'">
            <base-grid-ratio-bar-cell
              v-if="item.row[2][item.column.index] != null"
              :ratio="item.row[2][item.column.index]"
              :digit="fieldOption?.decimal ?? 3"
            />
          </template>
          <template v-else-if="fieldOption?.rendererType === 'chip-cell'">
            <base-grid-chips-cell
              v-if="
                isArray(item.row[2][item.column.index]) && item.row[2][item.column.index].length > 0
              "
              :row-index="item.row[0]"
              :chips="item.row[2][item.column.index]"
              :shown-chip-count="props?.shownChipCount"
              @click-count="clickCount"
              @resize="hideTooltip"
            />
          </template>
          <template v-else-if="fieldOption?.rendererType === 'chip-cell-clickable'">
            <base-grid-chips-cell
              v-if="
                isArray(item.row[2][item.column.index]) && item.row[2][item.column.index].length > 0
              "
              :row-index="item.row[0]"
              :chips="item.row[2][item.column.index]"
              :shown-chip-count="props?.shownChipCount"
              :field-name="fieldName"
              :row-data="item.row[2]"
              :column-index="item.column.index"
              :is-clickable="true"
              @click-count-clickable="clickCountWithFieldData"
              @resize="hideTooltip"
              @click="
                (event) =>
                  clickCell(
                    event,
                    fieldName,
                    item.row[2][item.column.index],
                    item.row[2],
                    item.column.index,
                  )
              "
            />
          </template>
          <template v-else-if="fieldOption?.rendererType === 'multi-chip-cell'">
            <base-grid-chips-cell
              :row-index="item.row[0]"
              :chips="item.row[2][item.column.index]"
              is-multiple-value-chip
              @click-chip="clickChip"
              @click-count="clickCount"
              @resize="hideTooltip"
            />
          </template>
          <template v-else-if="fieldOption?.rendererType === 'label'">
            <div
              v-if="item.row[2][item.column.index]"
              class="label-cell-wrapper"
            >
              <status-tag
                v-for="(status, index) in item.row[2][item.column.index].split(', ').sort()"
                :key="index"
                :type="status"
              >
                {{ status }}
              </status-tag>
            </div>
          </template>
          <template v-else-if="fieldOption?.rendererType === 'capitalize-label'">
            <div
              v-if="item.row[2][item.column.index]"
              class="label-cell-wrapper"
            >
              <status-tag
                v-for="(status, index) in item.row[2][item.column.index].split(', ').sort()"
                :key="index"
                :type="status"
              >
                {{ capitalize(status) }}
              </status-tag>
            </div>
          </template>
          <template v-else-if="fieldOption?.rendererType === 'upper-case-label'">
            <div
              v-if="item.row[2][item.column.index]"
              class="label-cell-wrapper"
            >
              <status-tag
                v-for="(status, index) in item.row[2][item.column.index].split(', ').sort()"
                :key="index"
                :type="status"
              >
                {{ upperCase(status) }}
              </status-tag>
            </div>
          </template>
          <template v-else-if="fieldOption?.rendererType === 'tooltip-label'">
            <div
              v-if="item.row[2][item.column.index]"
              class="label-cell-wrapper"
            >
              <status-tooltip-tag
                :status-info="
                  typeof item.row[2][item.column.index] === 'string'
                    ? { status: item.row[2][item.column.index], type: '' }
                    : item.row[2][item.column.index]
                "
              />
            </div>
          </template>

          <template v-else-if="fieldOption?.rendererType === 'sec'">
            <div :title="String(formatNumWithCommasAndDecimal(item.row[2][item.column.index]))">
              {{ convertMsToSec(item.row[2][item.column.index]) }}
            </div>
          </template>
          <template v-else-if="fieldOption?.clickable">
            <div
              class="base-grid__cell--link"
              :title="item.row[2][item.column.index]"
              @click="
                (event) =>
                  clickCell(
                    event,
                    fieldName,
                    item.row[2][item.column.index],
                    item.row[2],
                    item.column.index,
                  )
              "
            >
              <span
                v-if="!!(item.row[2][item.column.index] || item.row[2][item.column.index] === 0)"
                class="cell-text"
                :title="item.row[2][item.column.index]"
              >
                {{ item.row[2][item.column.index] }}
              </span>
            </div>
          </template>
        </template>

        <!-- custom-renderer -->
        <template
          v-for="(slotName, index) in slotNamesOfCustomCell"
          :key="`custom-cell_${slotName}_${index}`"
          #[slotName]="{ item }"
        >
          <slot
            :name="slotName"
            :item="item"
          />
        </template>
      </ev-grid>
    </div>
    <div
      v-if="props?.pagination?.use"
      class="base-grid__pagination"
    >
      <base-pagination
        v-model:current="currentPageNum"
        v-model:per-data="perDataCnt"
        :items="props.pagination.items"
        :per-data="props.pagination.perData"
        :show-setting="props.pagination.showSetting"
        :show-page-info="props.pagination.showPageInfo"
        :page-per-jump="10"
        :total="totalOfRows"
      />
    </div>

    <!-- tooltip for chip-cell -->
    <base-custom-tooltip
      v-if="tooltipOption === 'list'"
      ref="customTooltipRef"
      :use-deselect="true"
    >
      <slot name="tooltip-content">
        <chip-cell-tooltip-content
          :items="tooltipItems"
          :field-name="currentTooltipFieldName"
          :row-data="currentTooltipRowData"
          :column-index="currentTooltipColumnIndex"
          :is-clickable="isChipCellClickable"
          @click-item="onClickTooltipItem"
        />
      </slot>
    </base-custom-tooltip>

    <base-custom-tooltip
      v-else-if="tooltipOption === 'chip'"
      ref="customTooltipRef"
      :use-deselect="true"
      :custom-class="`base-grid__cell-tooltip base-grid__cell-tooltip__chip ${(
        props.customClass?.chipCellTooltip || []
      ).join(' ')}`"
    >
      <slot name="tooltip-content">
        <tag-item
          v-for="(item, idx) in tooltipItems"
          :key="`tooltip-${idx}`"
          :title="item"
          size="xsmall"
        >
          {{ item }}
        </tag-item>
      </slot>
    </base-custom-tooltip>
  </div>
</template>

<style lang="scss">
.base-grid {
  --base-grid-pagination-height: 32px;
  --option-height-h1: var(--grid-toolbar-height);
  --option-height-h2: var(--grid-toolbar-height) * 2;

  &__content {
    height: 100%;

    &.use-pagination {
      height: calc(100% - var(--base-grid-pagination-height));
    }
  }

  &__pagination {
    height: var(--base-grid-pagination-height);
    padding: 4px 0;
  }

  .base-grid__cell--link {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: var(--color-blue-05) !important;

    &:hover {
      text-decoration: underline !important;
    }

    .cell-text {
      @include shortening(inline-block, auto);

      &--icon-right {
        max-width: calc(100% - 4px - 20px);
        margin-right: 4px;
      }
    }
  }
  .selected .base-grid__cell--link {
    color: var(--grid-selected-link-color) !important;
  }
  .base-grid__cell--link:empty {
    cursor: default;
    pointer-events: none;
  }

  .grid-toolbar {
    display: flex;
    flex-direction: column;

    &.merged-toolbar {
      flex-direction: row;
      align-items: center;
      height: var(--grid-toolbar-height);

      .grid-toolbar__row.search {
        flex: 1;
        height: auto;
      }
      .grid-toolbar__row.custom {
        flex-basis: max-content;
        height: auto;
        margin-left: 8px;
      }

      &.with-grid-setting {
        width: calc(100% - var(--grid-setting-width) - var(--grid-setting-margin));
      }
    }

    &__row {
      height: var(--grid-toolbar-height);
    }
    &__row.search.full {
      .ev-text-field {
        width: 100% !important;
      }
      .ev-input {
        height: var(--grid-toolbar-height) !important;
      }
    }
    &__row.search.right {
      margin-left: auto;
    }
    &__row.search.left {
      margin-right: auto;
    }
    &__row.custom.with-grid-setting {
      width: calc(100% - var(--grid-setting-width) - var(--grid-setting-margin));
    }
  }

  &.has-option-h1 {
    .grid-wrapper {
      height: calc(100% - var(--option-height-h1)) !important;
    }
  }
  &.has-option-h2 {
    .grid-wrapper {
      height: calc(100% - var(--option-height-h2)) !important;
    }
  }

  // for renderer-type ratio
  &__ratio {
    justify-content: center;
  }

  .label-cell-wrapper,
  .cell[data-name='no'] {
    text-align: center;
  }

  .cell .tag-item {
    max-width: 100%;
    height: 16px;
    margin-right: 4px;
    line-height: 16px;

    /* stylelint-disable */
    > span {
      @include shortening();
    }
    /* stylelint-enable */
  }

  /* stylelint-disable */
  &__cell-tooltip {
    width: 180px;

    .detail-information {
      padding: 0 0 4px;
      margin: 0;
      border: none;
      background-color: var(--custom-tooltip-bg-color);

      @include set-scrollbar();

      &__header {
        padding: 8px 8px 4px;
        border: none;
        background-color: transparent;
      }
      &__content {
        max-height: 120px;
        padding: 0 8px;
        overflow-y: auto;

        .information-row {
          align-items: center;
          height: 24px;
          margin: 0;

          &__label {
            width: calc(100% - 80px) !important;
            min-width: calc(100% - 80px);
            padding-right: 4px;
            font-size: 11px;
            line-height: 24px;

            @include shortening(inline-block, 88px);
          }

          &__value {
            color: #ffffff;
          }
          .ratio-wrapper {
            width: 80px;
          }
        }
      }
    }

    &__chip {
      max-height: 88px;
      padding: 10px 12px;
      overflow-y: auto;
      background-color: var(--menu-list-item-bg-color);
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .tag-item {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  /* stylelint-enable */
}
</style>
