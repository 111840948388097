import { STATUS_SET } from '@/common/components/molecules/statusTag/statusTag.define';

export const convertStandardStatus = (type: string) => {
  if (type.includes(' ')) {
    return type.split(' ').join('-').toLocaleLowerCase();
  }
  return type.toLocaleLowerCase();
};

export const statusToColor = (status: string): string => {
  const colorKeys = Object.keys(STATUS_SET);
  for (let ix = 0; ix < colorKeys.length; ix++) {
    const statuses = STATUS_SET[colorKeys[ix]];
    const color = colorKeys[ix];
    if (statuses.includes(status)) {
      return color.toLowerCase();
    }
  }
  return 'gray';
};
