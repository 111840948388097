import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { checkOneDayCompareChartData } from '@/dashboard/components/widgets/checkChartDataStatus/metric/checkOneDayCompareChartData';
import { MetricChartType } from '../../widgets.types';
import { defaultCheckChartDataStatus } from '../checkChartDataStatus';

export const checkMetricChartTypeDataStatus =
  () =>
  (
    chartType: MetricChartType,
  ): ((
    rawData: MetricRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ) => WidgetChartDataStatusInfo[]) => {
    switch (chartType) {
      case 'oneDayCompare':
        return checkOneDayCompareChartData(chartType);
      case 'timeseries':
      case 'exclusive':
        return defaultCheckChartDataStatus<MetricRawData>(chartType);
      default:
        throw new Error(`Unsupported table chartDataType: ${chartType}`);
    }
  };
