import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { WidgetChartDataStatusInfo } from '@/common/utils';
import { i18n } from '@/common/locale';
import { convertChartTypeToWidgetChartType } from '@/dashboard/utils/dashboardUtils';
import { getLimitSeriesCountByChartType } from '@/dashboard/components/widgets/widgets.defines';
import { ChartType } from '../../widgets.types';

export const checkOneDayCompareChartData =
  (chartType: ChartType) =>
  (
    rawData: MetricRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ): WidgetChartDataStatusInfo[] => {
    const chartWidgetType = convertChartTypeToWidgetChartType(chartType);
    const seriesCountLimit = getLimitSeriesCountByChartType(chartWidgetType[0], chartWidgetType[1]);

    return chartDataStatus.map((status, idx) => {
      if (seriesCountLimit != null && seriesCountLimit <= idx) {
        return {
          ...status,
          status: 'fail',
          type: 'custom',
          reason: i18n.global.t('MESSAGE.SERIES_LIMIT', {
            limit: seriesCountLimit,
          }),
          errorCode: 'CE002',
        };
      }
      return status;
    });
  };
