import { checkValid } from '@/common/utils/commonUtils';
import {
  getOtpHyundaiSsoControllerAxios,
  getResponseFidoHyundaiSsoControllerAxios,
} from '@/openapi/metaV6/api/hyundai-sso-controller-api';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import router from '@/common/router';
import { postV1RefreshTokenAxios } from '@/openapi/meta/api/user-controller-api';
import { encrypt, webStorageController } from '@/common/utils/webStorage.util';
import { REG_EXP, STORAGE_KEY } from '@/common/utils/define';
import { setHeaderConfig } from '@/worker/commands/config/apiInstance';
import dayjs from 'dayjs';
import { loginUserV6ControllerAxios } from '@/openapi/metaV6/api/user-v6-controller-api';
import axios from 'axios';
import { getEncryptString, getPublicRsaKey } from '@/common/utils/rsa';
import { logoutAxios } from '@/openapi/gateway/api/gateway-controller-api';
import { useApiRsa } from '@/common/utils/rsa/apiRsa';

type TokenType = 'refreshToken' | 'accessToken';
type OptionalTokenType = 'sessionToken';
type Token = Record<TokenType, string> & Partial<Record<OptionalTokenType, string>>;

interface LoginResultInfo {
  resultCode: string;
  resultText: string;
}

type ReturnTypeContainsToken<
  T extends (...args: any) => any,
  K extends keyof Awaited<ReturnType<T>>,
> = Awaited<ReturnType<T>>[K] & Token;

type LoginData = ReturnTypeContainsToken<typeof loginUserV6ControllerAxios, 'data'> & {
  isPasswordExpired: boolean;
  passwordExpireDay: number;
};

const TOKEN_CHECK_CYCLE_SEC = 10 * 1_000;
const ACCESS_TOKEN_EXPIRATION_MIN = 15; // Access Token 유효 기간 (15분)
const getAccessTokenExpiredTime = () => {
  const now = dayjs();
  return +dayjs(now).add(ACCESS_TOKEN_EXPIRATION_MIN - 1, 'minute');
};
const setTokenInHeader = (accessToken: string) => {
  const tokenConfig = { key: 'Authorization', value: `Bearer ${accessToken}` };
  setHeaderConfig([tokenConfig]);
};

const initTokenInHeader = () => {
  const tokenConfig = { key: 'Authorization', value: null };
  setHeaderConfig([tokenConfig]);
};

const useToken = () => {
  const tokenInfo = ref<Token>({
    refreshToken: '',
    accessToken: '',
    sessionToken: '',
  });
  const accessTokenExpiredTime = ref(-1);

  const setTokenInfo = ({ accessToken, refreshToken, sessionToken }: Token) => {
    tokenInfo.value.accessToken = accessToken;
    tokenInfo.value.refreshToken = refreshToken;
    tokenInfo.value.sessionToken = sessionToken;
  };

  const setAccessTokenExpiredTime = () => {
    accessTokenExpiredTime.value = getAccessTokenExpiredTime();
  };

  return {
    tokenInfo,
    accessTokenExpiredTime,
    setTokenInfo,
    setAccessTokenExpiredTime,
  };
};

export const useAuthStore = defineStore('authStore', () => {
  const isVisible = ref(true);
  const expiredTimeInfo = ref<{ timer: ReturnType<typeof setTimeout> | null; isPending: boolean }>({
    timer: null,
    isPending: false,
  });
  const isCallable = computed(() => isVisible.value && !expiredTimeInfo.value.isPending);
  const { tokenInfo, accessTokenExpiredTime, setTokenInfo, setAccessTokenExpiredTime } = useToken();
  const isPasswordChange = ref(false);
  const isPasswordExpired = ref(false);
  const passwordExpireDay = ref(0);
  const loginResult = ref<LoginResultInfo>({
    resultCode: '',
    resultText: '',
  });

  const initAuth = () => {
    tokenInfo.value = {
      refreshToken: '',
      accessToken: '',
      sessionToken: '',
    };
    isPasswordChange.value = false;
    isPasswordExpired.value = false;
    passwordExpireDay.value = 0;
    initTokenInHeader();
  };

  const setStorage = () => {
    const { accessToken, refreshToken, sessionToken } = tokenInfo.value;

    webStorageController.setItem({
      type: 'session',
      key: STORAGE_KEY.TOKEN,
      value: encrypt.encode(
        JSON.stringify({
          accessToken,
          refreshToken,
          sessionToken,
          expiredTime: accessTokenExpiredTime.value,
        }),
      ),
    });
  };

  const clear = () => {
    initAuth();
    webStorageController.clear('session');
  };
  const setTokenLogout = async () => {
    if (!tokenInfo.value.refreshToken) {
      return;
    }
    try {
      await logoutAxios({
        request: {
          refreshToken: tokenInfo.value.refreshToken,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const login = async (loginData: { id: string; password: string }) => {
    const { rsaKey: publicRsaKey, rsaId } = await getPublicRsaKey();
    const loginDataIdKey = checkValid(REG_EXP.EMAIL, loginData.id) ? 'email' : 'activeId';
    const encryptedLoginData = {
      [loginDataIdKey]: loginData.id,
      password: getEncryptString(loginData.password, publicRsaKey) as string,
    };
    const { data } = await useApiRsa().handleApiRsaId({
      fn: loginUserV6ControllerAxios,
      params: {
        request: encryptedLoginData,
      },
      rsaId,
    });
    // TODO api 응답 모델하고 전혀 다른 데이터가 응답되고 있음, BE팀 사정으로 model update 보류 요청, 임시로 강제 형변환으로 사용
    const loginResponse = data as LoginData;
    if (!loginResponse) return;
    const {
      accessToken,
      refreshToken,
      sessionToken = '',
      passwordChange,
      isPasswordExpired: pwExpired,
      passwordExpireDay: pwExpireDay,
    } = loginResponse;
    setTokenInfo({ accessToken, refreshToken, sessionToken });
    setTokenInHeader(accessToken);
    if (passwordChange === false && pwExpired === false) {
      setAccessTokenExpiredTime();
      setStorage();
    }
    isPasswordChange.value = !!passwordChange;
    isPasswordExpired.value = pwExpired;
    passwordExpireDay.value = pwExpireDay;
  };

  const ssoLogin = async (id?: string) => {
    const response = await axios.get(`/api/v6/sso/${id}`, {
      responseType: 'json',
    });
    const loginResponse = response.data;
    if (!loginResponse) return;
    const { accessToken, refreshToken } = loginResponse;
    setTokenInfo({ accessToken, refreshToken });
    setAccessTokenExpiredTime();
    setTokenInHeader(accessToken);
    setStorage();
  };

  const otpLogin = async (loginData: { id: string; otp: string }) => {
    initTokenInHeader();
    const { rsaKey: publicRsaKey, rsaId } = await getPublicRsaKey();
    const encryptedLoginData = {
      id: loginData.id,
      otp: getEncryptString(loginData.otp, publicRsaKey) as string,
    };
    const { data } = await useApiRsa().handleApiRsaId({
      fn: getOtpHyundaiSsoControllerAxios,
      params: {
        request: encryptedLoginData,
      },
      rsaId,
    });
    const responseData = data as ReturnTypeContainsToken<
      typeof getOtpHyundaiSsoControllerAxios,
      'data'
    >;
    const {
      accessToken = '',
      refreshToken = '',
      sessionToken = '',
      resultCode = '',
      resultText = '',
    } = responseData || {};
    setTokenInfo({ accessToken, refreshToken, sessionToken });
    setAccessTokenExpiredTime();
    loginResult.value.resultCode = resultCode;
    loginResult.value.resultText = resultText;
    setTokenInHeader(accessToken);
    setStorage();
  };

  const fidoLogin = async (loginData: { uid: string; fid: string }) => {
    initTokenInHeader();
    const { rsaKey: publicRsaKey, rsaId } = await getPublicRsaKey();
    const { data } = await useApiRsa().handleApiRsaId({
      fn: getResponseFidoHyundaiSsoControllerAxios,
      params: {
        request: {
          uid: loginData.uid,
          fid: getEncryptString(loginData.fid ?? '', publicRsaKey) as string,
        },
      },
      rsaId,
    });
    const responseData = data as ReturnTypeContainsToken<
      typeof getResponseFidoHyundaiSsoControllerAxios,
      'data'
    >;
    const {
      accessToken = '',
      refreshToken = '',
      sessionToken = '',
      resultCode = '',
      resultText = '',
    } = responseData || {};
    setTokenInfo({ accessToken, refreshToken, sessionToken });
    setAccessTokenExpiredTime();
    loginResult.value.resultCode = resultCode;
    loginResult.value.resultText = resultText;
    setTokenInHeader(accessToken);
    setStorage();
  };

  const updateToken = async () => {
    if (!tokenInfo.value.refreshToken) {
      return;
    }
    try {
      const { data } = await postV1RefreshTokenAxios(tokenInfo.value);

      tokenInfo.value.accessToken = data.accessToken;
      if (data.refreshToken) {
        tokenInfo.value.refreshToken = data.refreshToken;
      }
      accessTokenExpiredTime.value = getAccessTokenExpiredTime();

      setStorage();
    } catch (e) {
      initAuth();
      console.log(e);
    }
  };

  const clearCheckTime = () => {
    if (expiredTimeInfo.value.timer) {
      clearTimeout(expiredTimeInfo.value.timer);
    }
  };
  const checkTimeForRefreshToken = async () => {
    const now = +dayjs();
    if (!accessTokenExpiredTime.value || dayjs(now).diff(accessTokenExpiredTime.value) > 0) {
      expiredTimeInfo.value.isPending = true;
      await updateToken();
      expiredTimeInfo.value.isPending = false;
    }
    expiredTimeInfo.value.timer = setTimeout(checkTimeForRefreshToken, TOKEN_CHECK_CYCLE_SEC);
  };

  const getToken = (type: TokenType | OptionalTokenType) => tokenInfo.value[type];

  const setInitialInfo = async () => {
    clearCheckTime();

    if (tokenInfo.value.accessToken) {
      await checkTimeForRefreshToken();
      return;
    }

    const storedVal = webStorageController.getItem({ type: 'session', key: STORAGE_KEY.TOKEN });
    const tokenObj: (Token & { expiredTime?: number }) | null = storedVal
      ? encrypt.decode(storedVal, true)
      : null;

    if (tokenObj) {
      const { accessToken, refreshToken, expiredTime, sessionToken } = tokenObj;
      tokenInfo.value = {
        ...tokenInfo.value,
        accessToken,
        refreshToken,
        sessionToken,
      };

      if (expiredTime) {
        accessTokenExpiredTime.value = expiredTime;
      }
      setTokenInHeader(accessToken);
      await checkTimeForRefreshToken();
    }
  };
  document.addEventListener('visibilitychange', async () => {
    const { visibilityState } = document;
    const currentPath = router.currentRoute.value?.path;
    if (visibilityState === 'visible' && currentPath !== '/') {
      isVisible.value = true;
      clearCheckTime();
      await checkTimeForRefreshToken();
    } else if (visibilityState === 'hidden') {
      isVisible.value = false;
      clearCheckTime();
    }
  });

  return {
    isCallable,
    tokenInfo,
    loginResult,
    passwordExpireDay,
    isPasswordChange,
    isPasswordExpired,
    clear,
    setTokenLogout,
    login,
    getToken,
    updateToken,
    setInitialInfo,
    checkTimeForRefreshToken,
    ssoLogin,
    otpLogin,
    fidoLogin,
  };
});
