import { I18nTranslation } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { TableRawData } from '@/worker/commands/dashboard/table';
import { TableChartType } from '../../widgets.types';
import { defaultCheckChartDataStatus } from '../checkChartDataStatus';
import { checkEqualizerChartData } from './checkEqualizerChartData';

export const checkTableChartTypeDataStatus =
  (t: I18nTranslation) =>
  (
    chartType: TableChartType,
  ): ((
    rawData: TableRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ) => WidgetChartDataStatusInfo[]) => {
    switch (chartType) {
      case 'equalizer':
        return checkEqualizerChartData({
          notSupportStat: t('MESSAGE.UI.DASHBOARD.EQUALIZER_NOT_SUPPORT'),
          notNumberError: t('MESSAGE.UI.DASHBOARD.EQUALIZER_NOT_NUMBER'),
          chartType: 'equalizer',
        });
      case 'actionView':
      case 'pie':
      case 'serviceCard':
      case 'statusSummary':
      case 'table':
      case 'topList':
      case 'treeGrid':
      case 'statusHexa':
      case 'server':
        return defaultCheckChartDataStatus<TableRawData>(chartType);
      default:
        throw new Error(`Unsupported table chartDataType: ${chartType}`);
    }
  };
