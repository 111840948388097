import { type EChartsOption } from 'echarts';
import {
  CircleCountOption,
  FetchCycleFrame,
  FetchInfo,
  GridColumn,
  GridOption,
  TreeGridOption,
  WindowSize,
} from '@/common/utils/types';
import { StatChartOption } from '@/postgresql/store/singleView/singleViewEnv';
import { i18n } from '@/common/locale';
import { RoleIdMap, Supervisors } from '../auth/auth.defines';

export const SUPER_USER_ROLE_ID = Supervisors.map((role) => RoleIdMap[role]);
export const SUPER_USER_ID = 'master';

export const MAX_SEARCH_INPUT_NUMBER_VALUE = 2147483647;

export const STORAGE_KEY = {
  ENTRY_TOKEN: 'entryToken',
  TOKEN: 'token',
  LAST_LOGIN_ID: 'LastLoginId',
  SINGLE_VIEW: 'singleView',
  MULTI_VIEW: 'multiView',
  FOLDERS: 'folders',
};

export const DB_TYPE = {
  NONE: 'NONE', // BE에 추가됨 (참고: @/openapi/database/model/instance-agent-item.ts)
  JAVA: 'java', // BE에 추가됨 (참고: @/openapi/database/model/instance-agent-item.ts)
  POSTGRESQL: 'postgresql',
  ORACLE: 'oracle',
  SQLSERVER: 'sqlserver',
  MYSQL: 'mysql',
  MONGODB: 'mongodb',
  REDIS: 'redis',
  CUBRID: 'cubrid',
} as const;

export const OS_TYPE = {
  WINDOWS: 'windows',
  Linux: 'linux',
  AIX: 'aix',
  HP_UX: 'hpux',
  CENTOS: 'centos',
  REDHAT: 'redhat',
  ROCKY: 'rocky',
  UBUNTU: 'ubuntu',
  DEBIAN: 'debian',
  SUSE: 'suse',
  FEDORA: 'fedora',
  UNKNOWN: 'unknown',
};

export const QUERY_TYPE = {
  SLOW_QUERY: 'slowQuery',
} as const;

export const DECIMAL = {
  RATIO: 1,
  ORACLE: 3,
  SQLSERVER: 3,
  MYSQL: 3,
  POSTGRESQL: 3,
};

// single view - top5 grid
export const top5GridOptions: GridOption = {
  adjust: true,
  useFilter: false,
  rowMinHeight: 20,
  scrollWidth: 8,
  showHeader: false,
  hiddenColumnMenuItem: {
    filter: true,
  },
  style: {
    border: 'none',
    highlight: 0,
  },
};

export const top5GridColumns: GridColumn[] = [
  {
    caption: 'Rank',
    field: 'rank',
    type: 'number',
    width: 40,
  },
  {
    caption: 'Name',
    field: 'name',
    type: 'string',
  },
  {
    caption: 'Value',
    field: 'value',
    type: 'number',
  },
  {
    caption: 'Diff',
    field: 'diff',
    type: 'number',
  },
  {
    caption: 'sqlId',
    field: 'sqlId',
    type: 'string',
    hide: true,
  },
];

export const top5EventGridColumns: GridColumn[] = [
  {
    caption: 'Rank',
    field: 'rank',
    type: 'number',
    width: 40,
  },
  {
    caption: 'Name',
    field: 'name',
    type: 'string',
  },
  {
    caption: 'Type',
    field: 'type',
    type: 'string',
  },
  {
    caption: 'Value',
    field: 'value',
    type: 'number',
  },
  {
    caption: 'Diff',
    field: 'diff',
    type: 'number',
  },
];

export const top5DiffStatisticsGridColumns: GridColumn[] = [
  {
    caption: 'Rank',
    field: 'rank',
    type: 'number',
    width: 40,
  },
  {
    caption: 'Name',
    field: 'name',
    type: 'string',
  },
  {
    caption: 'DiffRatio',
    field: 'diffRatio',
    type: 'number',
  },
  {
    caption: 'DiffValue',
    field: 'diffValue',
    type: 'number',
  },
  {
    caption: 'DataID',
    field: 'dataId',
    type: 'string',
    hide: true,
  },
];

export const baseGridOption: GridOption = {
  adjust: true,
  useFilter: false,
  rowMinHeight: 32,
  rowHeight: 32,
  scrollWidth: 8,
  useCheckbox: {
    use: false,
  },
  hiddenColumnMenuItem: {
    filter: true,
  },
  style: {
    border: 'rows',
  },
};

export const configGridOption: GridOption = {
  ...baseGridOption,
  rowHeight: 32,
  useSelection: {
    use: false,
  },
  useGridSetting: {
    use: true,
  },
};

export const configCheckboxGridOption: GridOption = {
  ...configGridOption,
  useCheckbox: {
    use: true,
    mode: 'multi',
    headerCheck: true,
  },
};

export const configDetailGridOption: GridOption = {
  ...baseGridOption,
  rowHeight: 32,
  useSelection: {
    use: false,
  },
  useCheckbox: {
    use: true,
    mode: 'multi',
    headerCheck: true,
  },
};

export const paGridOption: GridOption = {
  ...baseGridOption,
  columnMenuText: {
    ascending: i18n.global.t('WORD.ASCENDING'),
    descending: i18n.global.t('WORD.DESCENDING'),
    filter: i18n.global.t('WORD.FILTER'),
    hide: i18n.global.t('WORD.HIDE'),
  },
  emptyText: i18n.global.t('MESSAGE.NO_DATA'),
  useGridSetting: {
    use: true,
    columnMenuText: i18n.global.t('WORD.COLUMN_LIST'),
    searchText: i18n.global.t('WORD.SEARCH'),
    emptyText: i18n.global.t('MESSAGE.NO_DATA'),
    okBtnText: i18n.global.t('WORD.OK'),
  },
};

export const paTablespaceOption: GridOption = {
  ...paGridOption,
  useSummary: true,
  adjust: true,
};

export const treeGridOption: TreeGridOption = {
  adjust: true,
  useFilter: false,
  rowHeight: 28,
  scrollWidth: 8,
  useCheckbox: {
    use: false,
  },
  style: {
    border: 'rows',
  },
  parentIcon: 'none',
  childIcon: 'none',
  hiddenColumnMenuItem: {
    ascending: true,
    descending: true,
  },
};

export const lockTreeGridOption = {
  ...treeGridOption,
  useSelection: {
    use: false,
  },
};

export const VIEW_TYPE = {
  VIEW: 'view',
  EDIT: 'edit',
  REGISTER: 'register',
  CHANGE: 'change',
} as const;

export const OR_MORE = '>=';

export const SLIDER_INFO = {
  FORWARD_COLOR: ['#198FFF', '#F8A30D', '#FF1F3F'],
  REVERSE_COLOR: ['#FF1F3F', '#F8A30D', '#198FFF'],
  ONE_THIRD_RATIO: [33, 66],
  ALERT_RATIO: [45, 90],
};

export const SLIDER_MARK = {
  33: 'Warning',
  66: 'Critical',
};

const lowerRegex = /(?=.*?[a-z])/; // 소문자 포함
const upperRegex = /(?=.*?[A-Z])/; // 대문자 포함
const digitRegex = /(?=.*?[0-9])/; // 숫자 포함
const specialRegex = /(?=.*?[!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?])/; // 특수문자 포함

export const REG_EXP = {
  ACTIVE_ID: /^[a-zA-Z0-9]*$/,
  BUTTON_NAME: /^[a-zA-Z0-9\s]*$/,
  EMAIL: /[\w](-_\.)?[\w]*@[\w]([-_.]?[\w])*\.[a-zA-Z]{2,3}/,
  PASSWORD: new RegExp(
    `^(
    ${lowerRegex.source}${upperRegex.source}|
    ${lowerRegex.source}${digitRegex.source}|
    ${lowerRegex.source}${specialRegex.source}|
    ${upperRegex.source}${digitRegex.source}|
    ${upperRegex.source}${specialRegex.source}|
    ${digitRegex.source}${specialRegex.source}
    )(.{10,})`.replace(/\s/g, ''),
  ),
  IS_COMMA: /,/,
  IP: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  DOMAIN: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
};

export const ONE_SECOND = 1_000;
export const ONE_MINUTE = 60_000;
export const ONE_HOUR = 3_600_000;
export const ONE_HOUR_IN_SEC = 3_600;

export const ONE_DAY = 86_400_000;
export const ONE_DAY_IN_SECOND = 86_400;

export const ONE_WEEK = 604_800_000;
export const ONE_MONTH = 2_628_000_000;
export const ONE_YEAR = 31_536_000_000;

export const TIME_SHORT_UNITS = ['ms', 's', 'm', 'h', 'd', 'w', 'M', 'y'];

export const FETCH_CYCLE = {
  DASHBOARD: 1000 * 5,
  RTM: 1000 * 5, // Real Time Monitor
  RTM_SUMMARY: 1000 * 60 * 5, // Grouping Summary
  DB_METRIC: 1000 * 60, // Multi View - DB Metric
  MULTIVIEW_SQL_SUMMARY: 1000 * 60 * 15, // Multi View - Session Tab - Sql Summary

  MYSQL_SINGLEVIEW_1HOUR: ONE_HOUR, // 1시간 주기 MySQL - SingleView - Parameter
};

export const TOP5_RANK_STR = ['1st', '2nd', '3rd', '4th', '5th'];

export const PG_CHART_COLORS = [
  '#198FFF',
  '#15EFB4',
  '#E97FD7',
  '#FFB960',
  '#8C4EE6',
  '#E2E2E2',
  '#FBFF60',
  '#C66984',
  '#58B632',
  '#5F52DC',
  '#105393',
  '#0F6B52',
  '#842073',
  '#9C76FF',
  '#6C34FF',
];

export const MULTIVIEW_CHART_COLORS = [
  '#A5CAD6',
  '#3A639B',
  '#25556B',
  '#AAD0DD',
  '#82A3B2',
  '#4C7867',
  '#97AB9F',
  '#A6CFFF',
  '#9C76FF',
  '#6C34FF',
  '#FFCF5F',
  '#58B632',
  '#4346D3',
  '#30A6FC',
  '#7BAAC7',
];

export const ORACLE_PA_CHART_COLORS_OBJ: { [key: string]: string } = {
  C1: '#D46C4F',
  C2: '#FAAC6A',
  C3: '#F9E181',
  C4: '#4FD4B6',
  C5: '#6DB9E3',
  C6: '#2967AD',
  C7: '#5A4BE1',
  C8: '#F2B896',
  C9: '#982F27',
  C10: '#FDD7A5',
  C11: '#B36135',
  C12: '#FDF1B3',
  C13: '#B39741',
  C14: '#96F2CC',
  C15: '#279894',
  C16: '#A9E4F6',
  C17: '#366FA3',
  C18: '#7CB8E6',
  C19: '#143B7C',
  C20: '#9F93F6',
  C21: '#2F25A2',
  C22: '#7A1919',
  C23: '#904221',
  C24: '#907529',
  C25: '#19747A',
  C26: '#224F83',
  C27: '#0D2964',
  C28: '#1F1782',
};
export const SQLSERVER_PA_CHART_COLORS_OBJ: { [key: string]: string } = {
  C1: '#D46C4F',
  C2: '#FAAC6A',
  C3: '#F9E181',
  C4: '#4FD4B6',
  C5: '#6DB9E3',
  C6: '#2967AD',
  C7: '#5A4BE1',
  C8: '#F2B896',
  C9: '#982F27',
  C10: '#FDD7A5',
  C11: '#B36135',
  C12: '#FDF1B3',
  C13: '#B39741',
  C14: '#96F2CC',
  C15: '#279894',
  C16: '#A9E4F6',
  C17: '#366FA3',
  C18: '#7CB8E6',
  C19: '#143B7C',
  C20: '#9F93F6',
  C21: '#2F25A2',
  C22: '#7A1919',
  C23: '#904221',
  C24: '#907529',
  C25: '#19747A',
  C26: '#224F83',
  C27: '#0D2964',
  C28: '#1F1782',
};

export const ORACLE_DB_METRIC_CHART_COLORS = [
  ORACLE_PA_CHART_COLORS_OBJ.C5,
  ORACLE_PA_CHART_COLORS_OBJ.C4,
  ORACLE_PA_CHART_COLORS_OBJ.C2,
  ORACLE_PA_CHART_COLORS_OBJ.C3,
  ORACLE_PA_CHART_COLORS_OBJ.C6,
  ORACLE_PA_CHART_COLORS_OBJ.C7,
  ORACLE_PA_CHART_COLORS_OBJ.C1,
  ORACLE_PA_CHART_COLORS_OBJ.C9,
  ORACLE_PA_CHART_COLORS_OBJ.C8,
  ORACLE_PA_CHART_COLORS_OBJ.C10,
  ORACLE_PA_CHART_COLORS_OBJ.C11,
  ORACLE_PA_CHART_COLORS_OBJ.C12,
  ORACLE_PA_CHART_COLORS_OBJ.C13,
  ORACLE_PA_CHART_COLORS_OBJ.C14,
  ORACLE_PA_CHART_COLORS_OBJ.C15,
];
export const SQLSERVER_DB_METRIC_CHART_COLORS = [
  ORACLE_PA_CHART_COLORS_OBJ.C5,
  ORACLE_PA_CHART_COLORS_OBJ.C4,
  ORACLE_PA_CHART_COLORS_OBJ.C2,
  ORACLE_PA_CHART_COLORS_OBJ.C3,
  ORACLE_PA_CHART_COLORS_OBJ.C6,
  ORACLE_PA_CHART_COLORS_OBJ.C7,
  ORACLE_PA_CHART_COLORS_OBJ.C1,
  ORACLE_PA_CHART_COLORS_OBJ.C9,
  ORACLE_PA_CHART_COLORS_OBJ.C8,
  ORACLE_PA_CHART_COLORS_OBJ.C10,
  ORACLE_PA_CHART_COLORS_OBJ.C11,
  ORACLE_PA_CHART_COLORS_OBJ.C12,
  ORACLE_PA_CHART_COLORS_OBJ.C13,
  ORACLE_PA_CHART_COLORS_OBJ.C14,
  ORACLE_PA_CHART_COLORS_OBJ.C15,
];

export const MYSQL_MULTIVIEW_CHART_COLORS = [
  '#4FD4B6',
  '#F9E181',
  '#4E99D1',
  '#2967AD',
  '#D46C4F',
  '#6DB9E3',
  '#0F6B52',
  '#5F52DC',
  '#E97FD7',
  '#FBFF60',
  '#C66984',
  '#FAAC6A',
  '#58B632',
  '#198FFF',
  '#5A4BE1',
];

export const RTM_RANK_BOX_COLOR_INFO = {
  orange: [
    [0, 'var(--rank-box-content-bg-orange-start)'],
    [1, 'var(--rank-box-content-bg-orange-end)'],
  ],
  green: [
    [0, 'var(--rank-box-content-bg-green-start)'],
    [1, 'var(--rank-box-content-bg-green-end)'],
  ],
  blue: [
    [0, 'var(--rank-box-content-bg-blue-start)'],
    [1, 'var(--rank-box-content-bg-blue-end)'],
  ],
  purple: [
    [0, 'var(--rank-box-content-bg-purple-start)'],
    [1, 'var(--rank-box-content-bg-purple-end)'],
  ],
  lightgreen: [
    [0, 'var(--rank-box-content-bg-lightgreen-start)'],
    [1, 'var(--rank-box-content-bg-lightgreen-end)'],
  ],
  lightblue: [
    [0, 'var(--rank-box-content-bg-lightblue-start)'],
    [1, 'var(--rank-box-content-bg-lightblue-end)'],
  ],
};

// EVUI Chart는 css var 변수 사용 불가
export const RTM_RANK_RATIO_CHART_COLOR_BY_THEME = {
  dark: {
    orange: [
      [0, 'rgba(180, 68, 32, 0.28)'],
      [1, '#ac630d'],
    ],
    green: [
      [0, 'rgba(97, 140, 70, 0.28)'],
      [1, '#317227'],
    ],
    blue: [
      [0, '#1b2e44'],
      [1, '#0d60ac'],
    ],
    purple: [
      [0, 'rgba(106, 32, 180, 0.28)'],
      [1, '#aa71b8'],
    ],
    lightgreen: [
      [0, 'rgba(142, 180, 32, 0.28)'],
      [1, '#a9ac0d'],
    ],
    lightblue: [
      [0, 'rgba(47, 125, 198, 0.28)'],
      [1, '#88c1f7'],
    ],
  },
  light: {
    orange: '#fdd7a5',
    green: '#b4f2bb',
    blue: '#a9e4f6',
    purple: '#c5a9ff',
    lightgreen: '#a4b3ff',
    lightblue: '#88c1f7',
  },
};

export const TIMEZONE_ITEMS = [
  {
    name: '(UTC+08:00) 베이징, 충칭, 홍콩, 우루무치, 쿠알라룸프르, 싱가포르, 타이베이',
    value: 'Asia/Shanghai',
    utcTimeOffset: '+08:00',
    utcOffsetMin: 480,
  },
  {
    name: '(UTC+09:00) 서울, 오사카, 삿포로, 도쿄, 야쿠츠쿠',
    value: 'Asia/Seoul',
    utcTimeOffset: '+09:00',
    utcOffsetMin: 540,
  },
  {
    name: '(UTC+09:30) 다윈',
    value: 'Australia/Darwin',
    utcTimeOffset: '+09:30',
    utcOffsetMin: 570,
  },
  {
    name: '(UTC+10:00) 브리즈번, 괌, 포트 모르즈비, 블라디보스토크, 캔버라, 멜버른, 시드니, 호바트',
    value: 'Australia/Brisbane',
    utcTimeOffset: '+10:00',
    utcOffsetMin: 600,
  },
  {
    name: '(UTC+11:00) 뉴칼레도니아',
    value: 'Pacific/Noumea',
    utcTimeOffset: '+11:00',
    utcOffsetMin: 660,
  },
  {
    name: '(UTC+12:00) 아나디리, 페트로파블로프스크-캄차스키, 오클랜드, 웰링턴, 피지 제도, 캄차카',
    value: 'Asia/Anadyr',
    utcTimeOffset: '+12:00',
    utcOffsetMin: 720,
  },
  {
    name: '(UTC+13:00) 사모아',
    value: 'Pacific/Apia',
    utcTimeOffset: '+13:00',
    utcOffsetMin: 780,
  },
  {
    name: '(UTC-11:00) 미드웨이 제도',
    value: 'Pacific/Midway',
    utcTimeOffset: '-11:00',
    utcOffsetMin: -660,
  },
  {
    name: '(UTC-10:00) 하와이',
    value: 'Pacific/Honolulu',
    utcTimeOffset: '-10:00',
    utcOffsetMin: -600,
  },
  {
    name: '(UTC-09:00) 알래스카',
    value: 'America/Nome',
    utcTimeOffset: '-09:00',
    utcOffsetMin: -540,
  },
  {
    name: '(UTC-08:00) 태평양 표준시(미국, 캐나다), 바하 캘리포니아',
    value: 'America/Tijuana',
    utcTimeOffset: '-08:00',
    utcOffsetMin: -480,
  },
  {
    name: '(UTC-07:00) 산지표준시(미국, 캐나다), 애리조나, 치와와',
    value: 'America/Phoenix',
    utcTimeOffset: '-07:00',
    utcOffsetMin: -420,
  },
  {
    name: '(UTC-06:00) 중부 표준시(미국, 캐나다), 중앙 아메리카, 멕시코 시티',
    value: 'America/Chicago',
    utcTimeOffset: '-06:00',
    utcOffsetMin: -360,
  },
  {
    name: '(UTC-05:00) 동부 표준시(미국, 캐나다), 보고타, 리마, 킹스턴',
    value: 'America/New_York',
    utcTimeOffset: '-05:00',
    utcOffsetMin: -300,
  },
  {
    name: '(UTC-04:00) 대서양 표준시(캐나다), 라파즈, 산후안, 산티아고',
    value: 'America/Glace_Bay',
    utcTimeOffset: '-04:00',
    utcOffsetMin: -240,
  },
  {
    name: '(UTC-03:30) 뉴펀들랜드, 래브라',
    value: 'America/St_Johns',
    utcTimeOffset: '-03:30',
    utcOffsetMin: -210,
  },
  {
    name: '(UTC-03:00) 부에노스아이레스, 몬테비디오, 그린란드, 포트탈레자',
    value: 'America/Argentina/Buenos_Aires',
    utcTimeOffset: '-03:00',
    utcOffsetMin: -180,
  },
  {
    name: '(UTC-02:00) 중부-대서양',
    value: 'America/Noronha',
    utcTimeOffset: '-02:00',
    utcOffsetMin: -120,
  },
  {
    name: '(UTC-01:00) 아조레스, 카보베르데 제도',
    value: 'Atlantic/Azores',
    utcTimeOffset: '-01:00',
    utcOffsetMin: -60,
  },
  {
    name: '(UTC+00:00) 리스본, 런던, 협정 시계시, 카사블랑카, 몬로비아, 레이캬비크',
    value: 'Europe/London',
    utcTimeOffset: '+00:00',
    utcOffsetMin: 0,
  },
  {
    name: '(UTC+01:00) 암스테르담, 베를린, 로마, 빈, 서중앙 아프리카',
    value: 'Europe/Berlin',
    utcTimeOffset: '+01:00',
    utcOffsetMin: 60,
  },
  {
    name: '(UTC+02:00) 동부 유럽, 카이로, 헬싱키, 예루살렘',
    value: 'Africa/Cairo',
    utcTimeOffset: '+02:00',
    utcOffsetMin: 120,
  },
  {
    name: '(UTC+03:00) 모스크바, 나이로비, 바그다드, 쿠웨이트, 이스탄불',
    value: 'Europe/Moscow',
    utcTimeOffset: '+03:00',
    utcOffsetMin: 180,
  },
  {
    name: '(UTC+04:00) 아부다비, 예레반, 이젭스크, 사마라, 포트루이스',
    value: 'Asia/Yerevan',
    utcTimeOffset: '+04:00',
    utcOffsetMin: 240,
  },
  {
    name: '(UTC+04:30) 카불',
    value: 'Asia/Kabul',
    utcTimeOffset: '+04:30',
    utcOffsetMin: 270,
  },
  {
    name: '(UTC+05:00) 타슈켄트, 아시가바트, 예카테린부르크, 이슬라마바드, 카라치',
    value: 'Asia/Tashkent',
    utcTimeOffset: '+05:00',
    utcOffsetMin: 300,
  },
  {
    name: '(UTC+05:30) 첸나이, 콜카타, 뭄바이, 뉴델리, 스리자야와르데네푸라',
    value: 'Asia/Kolkata',
    utcTimeOffset: '+05:30',
    utcOffsetMin: 330,
  },
  {
    name: '(UTC+05:45) 카트만두',
    value: 'Asia/Kathmandu',
    utcTimeOffset: '+05:45',
    utcOffsetMin: 345,
  },
  {
    name: '(UTC+06:00) 아스타나, 다카',
    value: 'Asia/Dhaka',
    utcTimeOffset: '+06:00',
    utcOffsetMin: 360,
  },
  {
    name: '(UTC+06:30) 양곤',
    value: 'Asia/Rangoon',
    utcTimeOffset: '+06:30',
    utcOffsetMin: 390,
  },
  {
    name: '(UTC+07:00) 방콕, 하노이, 자카르타, 크라스노야르스크',
    value: 'Asia/Bangkok',
    utcTimeOffset: '+07:00',
    utcOffsetMin: 420,
  },
];

export const ALERT_LEVEL = ['normal', 'warning', 'critical'];

export const WINDOW_SIZE: WindowSize = {
  FULL: {
    width: '100%',
    height: '100%',
    minWidth: '100%',
    minHeight: '100%',
  },
  LARGE: {
    width: '1040px',
    height: '416px',
    minWidth: '500px',
    minHeight: '240px',
  },
  LARGE_EXPAND: {
    width: '1040px',
    height: '688px',
    minWidth: '580px',
    minHeight: '560px',
  },
  EXTRA_LARGE_EXPAND: {
    width: '1272px',
    height: '880px',
    minWidth: '640px',
    minHeight: '850px',
  },
  MEDIUM: {
    width: '720px',
    height: '416px',
    minWidth: '320px',
    minHeight: '240px',
  },
  MEDIUM_EXPAND: {
    width: '720px',
    height: '688px',
    minWidth: '320px',
    minHeight: '416px',
  },
  SMALL: {
    width: '480px',
    height: '540px',
    minWidth: '300px',
    minHeight: '300px',
  },
  EXTRA_SMALL: {
    width: '430px',
    height: '380px',
    minWidth: '300px',
    minHeight: '300px',
  },
};

export const USER_PROFILE_IMAGE_DEFAULT = 'user-profile-image-01';

export const USER_PROFILE_IMAGE = [
  'user-profile-image-01',
  'user-profile-image-02',
  'user-profile-image-03',
  'user-profile-image-04',
  'user-profile-image-05',
];

export const PA_TOGGLE_DATA_TYPE = {
  SUM: 'sum',
  AVG: 'avg',
  MAX: 'max',
} as const;

export const PA_BOX_TYPE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const SESSION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  KILLED: 'killed',
};

export const DEFAULT_STAT_CHART_OPTION: StatChartOption = {
  common: {
    type: 'line',
    showLegend: false,
    tooltipSorting: false,
    stack: false,
    fill: false,
  },
  line: {
    lineWidth: 2,
    point: false,
  },
  bar: {
    thickness: 1,
    borderRadius: 0,
  },
};

export const ALERT_CLEAR_MENU_TYPE = {
  DASHBOARD: 'dashboard',
  POSTGRESQL_SINGLE: 'postgresqlSingleView',
  POSTGRESQL_MULTI: 'postgresqlMultiView',
  MYSQL_SINGLE: 'mysqlSingleView',
  MYSQL_MULTI: 'mysqlMultiView',
};

export const ALERT_VIEW_MODE = {
  CARD: 'card',
  GRID: 'grid',
};

export const CIRCLE_COUNT_DEFAULT_OPTION: CircleCountOption = {
  size: 20,
  color: 'default',
  hover: false,
  border: { style: 'none' },
  tooltip: false,
};

export const ORACLE_DEFAULT_FETCH_CYCLE: Record<FetchCycleFrame['oracle'], FetchInfo> = {
  overview: [5_000, 'I5s'],
  dbSessionType: [5_000, 'I5s'],
  dbWaitClass: [5_000, 'I5s'],
  dbTimeModel: [5_000, 'I5s'],
  redo: [5_000, 'I5s'],
  undoTemp: [60_000 * 60, 'I1h'],
  dbMetric: [5_000, 'I5s'],
  sessions: [5_000, 'I5s'],
  waitChains: [5_000, 'I5s'],
  sqlSummary: [60_000, 'I1m'],
  event: [5_000, 'I5s'],
};

export const CHART_COLORS_SPARROW = [
  '#50BC5E',
  '#7C90F6',
  '#AABF26',
  '#8A5DEB',
  '#4FD4B6',
  '#FAAC6A',
  '#6AA9F2',
  '#F4D867',
  '#3589B8',
  '#AB9D8D',
  '#FCA3E4',
  '#FF7171',
  '#BCC5D3',
  '#EA6394',
  '#7EDE8B',
  '#6065E2',
  '#CDDE6A',
  '#AD88FA',
  '#279894',
  '#D66F35',
  '#3F77CA',
  '#B39741',
  '#6DB9E3',
  '#CBBFB2',
  '#E961DC',
  '#FFA693',
  '#8F9CB3',
  '#FBA0C1',
  '#B4F2BB',
  '#4340C8',
  '#ECFA9B',
  '#C5A9FF',
  '#19747A',
  '#B24213',
  '#224EA5',
  '#907529',
  '#A9E4F6',
  '#E4DAD0',
  '#C036C0',
  '#FFD7C9',
  '#606D86',
  '#FFCEE0',
  '#2C903B',
  '#A4B3FF',
  '#8A9600',
  '#7248CA',
  '#96F2CC',
  '#FDD7A5',
  '#97D2FF',
  '#FDF1B3',
  '#166997',
  '#807266',
  '#FFCEED',
  '#E1404D',
  '#DAE0EA',
  '#CF336B',
  '#107C20',
  '#7C90F6',
  '#747E00',
  '#6130CA',
  '#4FD4B6',
  '#FAAC6A',
  '#6AA9F2',
  '#F4D867',
  '#005889',
  '#6E5E53',
  '#FCA3E4',
  '#CD2020',
  '#BCC5D3',
  '#BE0C4C',
  '#B4F2BB',
  '#3428B7',
  '#ECFA9B',
  '#C5A9FF',
  '#00656B',
  '#963107',
  '#0D3D9B',
  '#806006',
  '#A9E4F6',
  '#E4DAD0',
  '#AC0CAC',
  '#FFD7C9',
  '#4C5668',
  '#FFCEE0',
];
export const getSparrowChartColorByIndex = (
  index: number,
  colors: string[] = CHART_COLORS_SPARROW,
): string => colors[index % colors.length];

export const WAIT_CLASS_COLOR_INFO = {
  CPU: CHART_COLORS_SPARROW[0],
  Scheduler: CHART_COLORS_SPARROW[1],
  'User I/O': CHART_COLORS_SPARROW[2],
  'System I/O': CHART_COLORS_SPARROW[3],
  Concurrency: CHART_COLORS_SPARROW[4],
  Application: CHART_COLORS_SPARROW[5],
  Commit: CHART_COLORS_SPARROW[6],
  Configuration: CHART_COLORS_SPARROW[7],
  Administrative: CHART_COLORS_SPARROW[8],
  Network: CHART_COLORS_SPARROW[9],
  Queueing: CHART_COLORS_SPARROW[10],
  Cluster: CHART_COLORS_SPARROW[11],
  Other: CHART_COLORS_SPARROW[12],
  'Disk I/O': CHART_COLORS_SPARROW[13],
  // '': '#2967AD',
};

export const ELAPSED_TIME_RANGE_INFOS = (min = 3, max = 10) => ({
  null: {
    name: 'Null',
    value: 'NULL',
  },
  less: {
    name: `Less than ${min} seconds`,
    value: `LESS_THAN_${min}_SECONDS`,
  },
  default: {
    name: `${min} ~ ${max} seconds`,
    value: `BETWEEN_${min}_AND_${max}_SECONDS`,
  },
  more: {
    name: `More than ${max} seconds`,
    value: `MORE_THAN_${max}_SECONDS`,
  },
});

export const USER_ROLE = Object.entries(RoleIdMap).map(([name, value]) => ({ name, value }));
export const USER_ROLE_EXCEPT_MASTER = USER_ROLE.filter((role) => role.value !== 0);

export const PRODUCT_THEMES = ['dark', 'light'] as const;
export const DEFAULT_PRODUCT_THEME = 'light' as const;
export const USER_GENERAL_SETTING_ITEMS = {
  lang: [
    {
      name: 'KOR',
      value: 'KO',
    },
    {
      name: 'ENG',
      value: 'EN',
    },
  ],
  theme: [
    {
      name: 'Light',
      value: 'light',
    },
    {
      name: 'Dark',
      value: 'dark',
    },
  ],
};

export const CHART_PASSING_VALUE: number = -9999999.820105;

export const DATE_SYMBOL = {
  s: 0,
  m: 1,
  h: 2,
  d: 3,
  M: 4,
  y: 5,
} as const;

export const DATE_UNIT = {
  s: 1,
  m: 60,
  h: 3600,
  d: 3600 * 24,
  M: 3600 * 24 * 30,
  y: 3600 * 24 * 365,
} as const;

export const TIME_RANGE_BAR_OPTION: EChartsOption = {
  grid: {
    bottom: 23,
    left: 10,
    right: 0,
    top: 0,
    height: 12,
  },
  toolbox: {
    show: false,
  },
  brush: {
    toolbox: ['lineX'],
    xAxisIndex: 0,
    brushType: 'lineX',
    brushStyle: {
      borderWidth: 1,
      color: 'transparent',
      borderColor: '#9BD9FF',
    },
    outOfBrush: {
      colorAlpha: 0.4,
    },
    removeOnClick: false,
  },
};

export const TIME_UNIT = {
  month: 'month',
  day: 'd',
  hour: 'h',
  minute: 'm',
  second: 's',
} as const;

export const DEFAULT_PAST_INPUT_TIME = {
  value: '5',
  unit: TIME_UNIT.minute,
} as const;
