import { computed, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import { useInternational } from '@/common/locale';
import { secondsToPeriod } from '@/common/utils/time.utils';
import { UTC_TIME_FORMAT } from '../timePeriodIndicator/timePeriodIndicator.define';

export interface Props {
  // [fromTime, toTime]
  dateTimePeriod: [string, string];
  // max range time limit (seconds)
  maxRangeInSec?: number;
  // min range time limit (seconds)
  minRangeInSec?: number;
  // false 일 경우, 캘린더 열 때마다 시간은 00:00:00 ~ 23:59:59로 초기화 (dsp-15331)
  allowTime?: boolean;
  // 최소 선택 interval (ex. 1시간 일 경우, 1시간으로 딱 나눠 떨어지는 기간만 선택 가능)
  minimumIntervalInSec?: number;
  readonlyTimes?: {
    hour: boolean;
    minute: boolean;
    second: boolean;
  };
  // 해당 날짜 이전 날짜는 disable
  disabledSelectedBeforeDay?: string;
}

export interface Emit {
  (e: 'update:isShow', value: boolean): void;
  (e: 'clickCancel'): void;
  (e: 'clickOk', value: string[]): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const FULL_DAY_RANGE = ['00:00:00', '00:00:00'];
  const rangeCalendarRef = ref();

  let from = dayjs(props.dateTimePeriod?.[0], UTC_TIME_FORMAT);
  if (!from.isValid()) from = dayjs().startOf('day');

  let to = dayjs(props.dateTimePeriod?.[1], UTC_TIME_FORMAT);
  if (!to.isValid()) to = dayjs().endOf('day');

  const startDate = ref(from.toDate());
  const endDate = ref(to.toDate());
  const startDateString = computed(() => dayjs(startDate.value).format('YYYY.MM.DD'));
  const endDateString = computed(() =>
    endDate.value ? dayjs(endDate.value).format('YYYY.MM.DD') : '',
  );

  const fromTime = from.format('HH:mm:ss');
  const toTime = to.format('HH:mm:ss');

  const timePickerValues = ref(props.allowTime ? [fromTime, toTime] : [...FULL_DAY_RANGE]);

  const startDateTime = computed(() =>
    dayjs(`${startDateString.value} ${timePickerValues.value[0]}`),
  );
  const endDateTime = computed(() =>
    dayjs(endDate.value ? `${endDateString.value} ${timePickerValues.value[1]}` : undefined),
  );

  const diffFromTo = computed(() => endDateTime.value.diff(startDateTime.value, 'second'));
  const isMoreThanMaxRange = computed(() => {
    if (!props.maxRangeInSec || props.maxRangeInSec < 0) return false;
    return diffFromTo.value > props.maxRangeInSec;
  });

  const isLessThanMinRange = computed(() => {
    if (!props.minRangeInSec || props.minRangeInSec < 0) return false;
    return diffFromTo.value < props.minRangeInSec;
  });

  const isNotTimeDivisibleByInterval = computed(() => {
    if (!props.minimumIntervalInSec || props.minimumIntervalInSec < 0) return false;
    return (diffFromTo.value + 1) % props.minimumIntervalInSec !== 0;
  });

  const isRangeInToday = computed(() => {
    const todayStart = dayjs().tz().startOf('day');
    const todayEnd = dayjs().tz().endOf('day');

    return (
      startDateTime.value.isSame(todayStart, 'seconds') &&
      endDateTime.value.isSame(todayEnd, 'seconds')
    );
  });

  const validTimeFormatExp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
  const timeValidationInfo = reactive({
    fromTime: computed(() => {
      if (startDateTime.value > endDateTime.value) {
        return t('NOTI.VALIDATION.TIME_RANGE');
      }
      if (!validTimeFormatExp.test(timePickerValues.value[0])) {
        return t('WORD.ERROR');
      }
      if (isMoreThanMaxRange.value) {
        const period = secondsToPeriod(props.maxRangeInSec!);
        return t('MESSAGE.VALIDATION_GUIDE', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }
      if (isLessThanMinRange.value) {
        const period = secondsToPeriod(props.minRangeInSec!);
        return t('MESSAGE.VALIDATION_GUIDE_MIN', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }
      if (isNotTimeDivisibleByInterval.value) {
        const period = secondsToPeriod(props.minimumIntervalInSec!);
        return t('MESSAGE.VALIDATION_INTERVAL', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }

      return '';
    }),
    toTime: computed(() => {
      if (startDateTime.value > endDateTime.value) {
        return t('NOTI.VALIDATION.TIME_RANGE');
      }
      if (!validTimeFormatExp.test(timePickerValues.value[1])) {
        return t('WORD.ERROR');
      }
      if (isMoreThanMaxRange.value) {
        const period = secondsToPeriod(props.maxRangeInSec!);
        return t('MESSAGE.VALIDATION_GUIDE', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }
      if (isLessThanMinRange.value) {
        const period = secondsToPeriod(props.minRangeInSec!);
        return t('MESSAGE.VALIDATION_GUIDE_MIN', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }
      if (isNotTimeDivisibleByInterval.value) {
        const period = secondsToPeriod(props.minimumIntervalInSec!);
        return t('MESSAGE.VALIDATION_INTERVAL', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }

      return '';
    }),
  });

  const onClickResetDateTimePicker = () => {
    const today = dayjs.tz();
    const tomorrow = today.add(1, 'day');

    startDate.value = today.toDate();
    endDate.value = tomorrow.toDate();
    timePickerValues.value = [...FULL_DAY_RANGE];

    rangeCalendarRef.value?.setCalendarMonthYear({
      month: today.month(),
      year: today.year(),
    });
  };

  const onClickCancel = () => {
    emit('clickCancel');
  };

  const onClickOk = () => {
    emit('clickOk', [
      startDateTime.value.format('YYYY.MM.DD HH:mm:ss'),
      endDateTime.value.format('YYYY.MM.DD HH:mm:ss'),
    ]);
  };

  return {
    t,
    rangeCalendarRef,
    startDate,
    endDate,
    startDateString,
    endDateString,
    timePickerValues,
    timeValidationInfo,
    isRangeInToday,
    onClickResetDateTimePicker,
    onClickCancel,
    onClickOk,
  };
};
