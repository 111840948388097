<script setup lang="ts">
import { BASE_MENU_VALUE } from '@/common/components/organisms/baseMenu/baseMenu.define';
import FloatingPopupLayout from '../FloatingPopupLayout.vue';
import DashboardElement from './DashboardElement.vue';
import { type Props, setup } from './floatingMenuPopup.setup';
import MidCategory from './MidCategory.vue';
import MenuElement from './MenuElement.vue';

const props = defineProps<Props>();
const {
  mainMenuRef,
  subMenuRef,
  subMenuPopupStyle,

  subMenuInfo,
  clickedMenu,
  computedDashboardMenu,
  computedAnalysisBoardMenu,
  computedSubMenuList,

  getMidCategoryTitle,

  onHoverSubMenu,
  onClickSubMenu,
  clearSubMenu,
} = setup(props);
</script>

<template>
  <floating-popup-layout
    ref="mainMenuRef"
    class="floating-main-menu"
    :title="props.menuInfo?.text"
  >
    <template #list>
      <dashboard-element
        v-if="props.menuInfo?.dashboardList"
        :dashboard-menu="computedDashboardMenu!"
        :root-menu="props.menuInfo.value"
        menu-type="dashboard"
        :clicked="clickedMenu === BASE_MENU_VALUE.DASHBOARD"
        @hover="onHoverSubMenu"
        @click="onClickSubMenu"
      />
      <dashboard-element
        v-if="
          props.menuInfo?.analysisBoardList &&
          props.analysisBoardMenuList &&
          props.analysisBoardMenuList.length > 0
        "
        :dashboard-menu="computedAnalysisBoardMenu!"
        :root-menu="props.menuInfo.value"
        :clicked="clickedMenu === BASE_MENU_VALUE.ANALYSISBOARD"
        menu-type="analysisDashboard"
        @hover="onHoverSubMenu"
        @click="onClickSubMenu"
      />

      <template v-for="(menu, menuIdx) in props.menuInfo?.children">
        <menu-element
          v-if="!menu?.children?.length && menu.text"
          :key="`${menu.value}_${menuIdx}`"
          :menu-info="menu"
          :clicked="clickedMenu === menu.value"
          @hover="onHoverSubMenu"
          @click="onClickSubMenu"
        />
        <mid-category
          v-else-if="menu?.children?.length"
          :key="`category_${menu.value}_${menuIdx}`"
          :title="menu.text"
        >
          <menu-element
            v-for="(info, childMenuIdx) in menu.children"
            :key="`${info.value}_${childMenuIdx}`"
            :menu-info="info"
            :clicked="clickedMenu === info.value"
            @hover="onHoverSubMenu"
            @click="onClickSubMenu"
          />
        </mid-category>
      </template>
    </template>
    <template #addition>
      <floating-popup-layout
        v-if="computedSubMenuList.length > 0"
        ref="subMenuRef"
        class="floating-sub-menu"
        :title="subMenuInfo!.text"
        :style="subMenuPopupStyle"
        @mouseleave="() => clearSubMenu()"
      >
        <template #list>
          <template v-for="(subMenu, subMenuIdx) in computedSubMenuList">
            <menu-element
              v-if="!subMenu.children?.length && subMenu.text"
              :key="`${subMenu.value}_${subMenuIdx}`"
              :menu-info="subMenu"
            />
            <mid-category
              v-else-if="subMenu.children?.length"
              :key="`category_${subMenu.value}_${subMenuIdx}`"
              :title="getMidCategoryTitle(subMenu.text)"
            >
              <menu-element
                v-for="(info, childMenuIdx) in subMenu.children"
                :key="`${info.value}_${childMenuIdx}`"
                :menu-info="info"
              />
            </mid-category>
          </template>
        </template>
      </floating-popup-layout>
    </template>
  </floating-popup-layout>
</template>

<style scoped lang="scss">
.floating-main-menu {
  --sub-menu-initial-position: calc(var(--gnb-menu-width) - var(--gnb-sub-menu-width) - 4px);

  left: var(--sub-menu-initial-position);
  transition: left $animate-gnb;

  &.shown {
    left: (var(--gnb-menu-width)) !important;
  }
}
.floating-sub-menu {
  left: 100%;
  padding: 0;
}
</style>
