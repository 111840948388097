// export const STATUS = {
//   GREEN: ['AVAILABLE'],
//   BLUE: ['ACTIVE', 'RUNNING', 'OK'],
//   RED: [
//     'TERMINATED',
//     'FAILED',
//     'HARDWARE_FAILURE',
//     'INCOMPATIBLE_HSM',
//     'PAUSED',
//     'STORAGE_FULL',
//     'INACCESSIBLE_ENCRYPTION_CREDENTIALS_RECOVERABLE',
//     'INACCESSIBLE_ENCRYPTION_CREDENTIALS',
//     'INCOMPATIBLE_NETWORK',
//     'INCOMPATIBLE_OPTION_GROUP',
//     'INCOMPATIBLE_RESTORE',
//     'INSUFFICIENT_CAPACITY',
//     'RESTORE_ERROR',
//     'STOPPED',
//     'DELETED',
//     'RESTORE_FAILED',
//     'ALARM',
//     'CREATE_FAILED',
//     'INCOMPATIBLE_PARAMETERS',
//   ],
//   YELLOW: [
//     'PENDING',
//     'SHUTTING_DOWN',
//     'BACKING_UP',
//     'CREATING',
//     'DELETING',
//     'FAILING_OVER',
//     'MIGRATING',
//     'MIGRATION_FAILED',
//     'MODIFYING',
//     'RENAMING',
//     'RESETTING_MASTER_CREDENTIALS',
//     'UPGRADING',
//     'CONFIGURING_LOG_EXPORTS',
//     'REBOOTING',
//     'STARTING',
//     'STOPPING',
//     'ARCHIVING',
//     'ARCHIVED',
//     'AVAILABLE_PREP_FOR_RESIZE', // NOTE: 분리하지 않고 한 라벨로 표현, input: available, prep-for-resize
//     'AVAILABLE_RESIZE_CLEANUP', // NOTE: 분리하지 않고 한 라벨로 표현, input: available, resize-cleanup
//     'CANCELLING_RESIZE',
//     'FINAL_SNAPSHOT',
//     'RESIZING',
//     'ROTATING_KEYS',
//     'UPDATING',
//     'UPDATING_HSM',
//     'CONFIGURING_ENHANCED_MONITORING',
//     'CONFIGURING_IAM_DATABASE_AUTH',
//     'CONVERTING_TO_VPC',
//     'DELETE_PRECHECK',
//     'MOVING_TO_VPC',
//     'STORAGE_CONFIG_UPGRADE',
//     'STORAGE_OPTIMIZATION',
//     'REBOOTING_CLUSTER_NODES',
//     'SNAPSHOTTING',
//     'REBOOTING_CACHE_CLUSTER_NODES',
//     'MAINTENANCE',
//   ],
// };

export const STATUS = {
  CLOUD_WATCH_ALARM: {
    BLUE: ['OK'],
    RED: ['ALARM'],
  },
  DOCUMENT_DB: {
    CLUSTER: {
      BLUE: ['ACTIVE'],
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'BACKING_UP',
        'CREATING',
        'DELETING',
        'FAILING_OVER',
        'MAINTENANCE',
        'MIGRATING',
        'MIGRATION_FAILED',
        'MODIFYING',
        'RENAMING',
        'RESETTING_MASTER_CREDENTIALS',
        'UPGRADING',
      ],
      RED: ['INACCESSIBLE_ENCRYPTION_CREDENTIALS', 'STOPPED'],
    },
    INSTANCE: {
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'BACKING_UP',
        'CONFIGURING_LOG_EXPORTS',
        'CREATING',
        'DELETING',
        'MAINTENANCE',
        'MODIFYING',
        'REBOOTING',
        'RENAMING',
        'RESETTING_MASTER_CREDENTIALS',
        'STARTING',
        'STOPPING',
      ],
      RED: [
        'FAILED',
        'INCOMPATIBLE_NETWORK',
        'INACCESSIBLE_ENCRYPTION_CREDENTIALS',
        'RESTORE_ERROR',
        'STOPPED',
        'STORAGE_FULL',
      ],
    },
  },
  DYNAMO_DB: {
    BLUE: ['ACTIVE'],
    YELLOW: ['CREATING', 'UPDATING', 'DELETING', 'ARCHIVING', 'ARCHIVED'],
    RED: ['INACCESSIBLE_ENCRYPTION_CREDENTIALS'],
  },
  REDSHIFT: {
    GREEN: ['AVAILABLE'],
    YELLOW: [
      'AVAILABLE_PREP_FOR_RESIZE',
      'AVAILABLE_RESIZE_CLEANUP',
      'CANCELLING_RESIZE',
      'CREATING',
      'DELETING',
      'FINAL_SNAPSHOT',
      'MODIFYING',
      'REBOOTING',
      'RENAMING',
      'RESIZING',
      'ROTATING_KEYS',
      'UPDATING_HSM',
    ],
    RED: [
      'HARDWARE_FAILURE',
      'INCOMPATIBLE_HSM',
      'INCOMPATIBLE_NETWORK',
      'INCOMPATIBLE_PARAMETERS',
      'INCOMPATIBLE_RESTORE',
      'PAUSED',
      'STORAGE_FULL',
    ],
  },
  ELASTI_CACHE: {
    CLUSTER: {
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'CREATING',
        'DELETING',
        'MODIFYING',
        'REBOOTING_CLUSTER_NODES',
        'RESTORE_FAILED',
        'SNAPSHOTTING',
      ],
      RED: ['DELETED', 'INCOMPATIBLE_NETWORK', 'RESTORE_FAILED'],
    },
    SHARD: {
      GREEN: ['AVAILABLE'],
      YELLOW: ['CREATING', 'MODIFYING', 'DELETING'],
    },
    NODE: {
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'CREATING',
        'DELETING',
        'MODIFYING',
        'UPDATING',
        'REBOOTING_CACHE_CLUSTER_NODES',
        'SNAPSHOTTING',
      ],
      RED: ['RESTORE_FAILED', 'INCOMPATIBLE_NETWORK', 'INCOMPATIBLE_PARAMETERS'],
    },
  },
  SERVERLESS: {
    GREEN: ['AVAILABLE'],
    YELLOW: ['CREATING', 'DELETING', 'MODIFYING'],
    RED: ['CREATE_FAILED'],
  },
};
