<script setup lang="ts">
import BaseGridChipsCell from '@/common/components/molecules/baseGridChipsCell/BaseGridChipsCell.vue';
import {
  Props,
  setup,
} from '@/common/components/molecules/baseChipsCellWithTooltip/baseChipsCellWithTooltip.setup';
import BaseCustomTooltip from '@/common/components/molecules/baseCustomTooltip/BaseCustomTooltip.vue';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';

const props = withDefaults(defineProps<Props>(), {
  useWheelEvent: true,
});
const { tooltipRef, allChips, onClickCount } = setup(props);
</script>
<template>
  <base-grid-chips-cell
    :chips="props.chips ?? []"
    :shown-chip-count="props.shownChipCount"
    :row-index="props.rowIndex"
    :is-multiple-value-chip="props.isMultipleValueChip"
    @click-count="({ evt }) => onClickCount(evt)"
  />
  <base-custom-tooltip
    ref="tooltipRef"
    use-deselect
    custom-class="base-chips-cell-tooltip"
  >
    <ul>
      <li
        v-for="(chip, index) in allChips"
        :key="`chip${index}`"
        class="base-chips-cell-tooltip__row"
      >
        <tag-item
          :title="chip"
          size="xsmall"
        >
          {{ chip }}
        </tag-item>
      </li>
    </ul>
  </base-custom-tooltip>
</template>

<style lang="scss">
.base-chips-cell-tooltip {
  min-width: 150px;
  max-width: 200px;
  max-height: 190px;
  padding: 8px 8px 6px;
  overflow-y: auto;
  background-color: var(--chip-cell-tooltip-bg-color);
  &__row {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  .tag-item {
    width: auto !important;
    max-width: 100%;

    @include shortening();
  }
}
</style>
