import { computed, ref, watch, getCurrentInstance } from 'vue';
import {
  DEFAULT_PERIOD_ITEMS_ALL,
  getSafeDateTime,
  getTimePeriodByRange,
  parsePeriod,
  stringifyDateString,
} from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { useInternational } from '@/common/locale';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { DEFAULT_PAST_INPUT_TIME, MAX_SEARCH_INPUT_NUMBER_VALUE, TIME_UNIT } from '@/common/utils';
import { getOnlyNumber } from '@/common/utils/validation.utils';
import { webStorageController } from '@/common/utils/webStorage.util';
import type { SelectOption, TimePeriodData, ValueOf } from '@/types/common.types';
import dayjs, { ManipulateType } from 'dayjs';
import { isEqual } from 'lodash-es';
import { useRoute } from 'vue-router';
import { GLOBAL_TIME_RANGE_FORMAT } from '../timePeriodIndicator/timePeriodIndicator.define';

export interface Props {
  modelValue: string;
  periods?: TimePeriodData[];
  fromTime?: string;
  toTime?: string;
  disabled?: boolean;
  timeRangeSecLimit?: number;
  minTimeRangeSecLimit?: number;
  useCalendar?: boolean;
  useInput?: boolean;
}

export interface Emit {
  (e: 'update:modelValue', periodValue: string): void;
  (e: 'change-period', periodValue: string | string[]): void;
  (
    e: 'change-period-by-calendar',
    value: {
      fromTime: string;
      toTime: string;
      period: string;
    },
  ): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;

  const isShowDropdown = ref(false);
  const isShowDatePicker = ref(false);
  const dateTimePeriod = ref<[string, string]>(['', '']);

  const timeUnitItems: SelectOption[] = [
    {
      name: t('WORD.TIME_PERIOD_ITEMS.PAST_DYNAMIC_UNIT', {
        unit: t('DESC.UI.MONTH'),
      }),
      value: TIME_UNIT.month,
    },
    {
      name: t('WORD.TIME_PERIOD_ITEMS.PAST_DYNAMIC_UNIT', { unit: t('DESC.UI.DAY') }),
      value: TIME_UNIT.day,
    },
    {
      name: t('WORD.TIME_PERIOD_ITEMS.PAST_DYNAMIC_UNIT', { unit: t('DESC.UI.HOUR') }),
      value: TIME_UNIT.hour,
    },
    {
      name: t('WORD.TIME_PERIOD_ITEMS.PAST_DYNAMIC_UNIT', { unit: t('DESC.UI.MINUTE') }),
      value: TIME_UNIT.minute,
    },
    {
      name: t('WORD.TIME_PERIOD_ITEMS.PAST_DYNAMIC_UNIT', { unit: t('DESC.UI.SECOND') }),
      value: TIME_UNIT.second,
    },
  ];
  const pastInputTime = ref<string>(DEFAULT_PAST_INPUT_TIME.value);
  const pastInputUnit = ref<ValueOf<typeof TIME_UNIT>>(DEFAULT_PAST_INPUT_TIME.unit);
  const pastInputError = ref<string>('');

  const initPastInputInfo = () => {
    pastInputTime.value = DEFAULT_PAST_INPUT_TIME.value;
    pastInputUnit.value = DEFAULT_PAST_INPUT_TIME.unit;
    pastInputError.value = '';
  };

  const openDatePicker = () => {
    if (props.fromTime && props.toTime && props.modelValue.includes('custom')) {
      dateTimePeriod.value = [props.fromTime, props.toTime];
    } else {
      dateTimePeriod.value = [
        dayjs().startOf('day').format(GLOBAL_TIME_RANGE_FORMAT),
        dayjs().endOf('day').format(GLOBAL_TIME_RANGE_FORMAT),
      ];
    }

    isShowDatePicker.value = !isShowDatePicker.value;
  };

  const closeDatePicker = () => {
    isShowDatePicker.value = false;
  };

  const toggleDropdown = () => {
    if (props.disabled) {
      return;
    }
    isShowDropdown.value = !isShowDropdown.value;
  };

  const closeDropdown = () => {
    isShowDropdown.value = false;
  };

  const closeAll = () => {
    closeDatePicker();
    closeDropdown();
  };

  const usePropsPeriods = !!(props?.periods && props.periods.length);
  const periods = computed<TimePeriodData[]>(() => {
    return (usePropsPeriods ? props.periods : DEFAULT_PERIOD_ITEMS_ALL) as TimePeriodData[];
  });

  const quickPeriods = computed<TimePeriodData[]>(() => {
    return periods.value.filter(({ value }) => {
      if (props.timeRangeSecLimit) {
        return parsePeriod(value).offset <= props.timeRangeSecLimit && !value.includes('custom');
      }
      return !value.includes('custom');
    });
  });

  const recentlySearches = ref<
    {
      id: number;
      value: TimePeriodData;
    }[]
  >([]);

  const route = useRoute();
  const storageKey = ref<string>('');

  watch(
    () => route,
    () => {
      storageKey.value = `timePeriodV2_recently_searches_${route.path}`;

      recentlySearches.value = JSON.parse(
        webStorageController.getItem({
          type: 'local',
          key: storageKey.value,
        }) ?? '[]',
      );
    },
    {
      immediate: true,
      deep: true,
    },
  );

  const MAXIMUM_HISTORY_SIZE = 5;
  const saveRecentlySearch = (param: TimePeriodData) => {
    const duplicatedItemIndex = recentlySearches.value.findIndex(({ value }) =>
      isEqual(value, param),
    );
    if (duplicatedItemIndex > -1) {
      recentlySearches.value.splice(duplicatedItemIndex, 1);
    }

    recentlySearches.value.unshift({
      id: Date.now(),
      value: param,
    });

    if (recentlySearches.value.length > MAXIMUM_HISTORY_SIZE) {
      recentlySearches.value.pop();
    }

    webStorageController.setItem({
      type: 'local',
      key: storageKey.value,
      value: JSON.stringify(recentlySearches.value),
    });
  };

  const deleteRecentlySearch = (index: number) => {
    recentlySearches.value.splice(index, 1);

    webStorageController.setItem({
      type: 'local',
      key: storageKey.value,
      value: JSON.stringify(recentlySearches.value),
    });
  };

  const selectedPeriod = computed({
    get: () => props.modelValue,
    set: (val: string) => {
      if (props.disabled) {
        return;
      }
      emit('update:modelValue', val);
      emit('change-period', val);
    },
  });

  const periodShortName = computed(
    () => periods.value.find(({ value }) => value === selectedPeriod.value)?.shortName,
  );

  const periodName = computed(
    () => periods.value.find(({ value }) => value === selectedPeriod.value)?.name,
  );

  const onClickQuickPeriod = (period: TimePeriodData) => {
    selectedPeriod.value = period.value;
    saveRecentlySearch(period);
    closeAll();
  };

  const onSelectByCalendar = (value: string[]) => {
    const fromTime = dayjs(value[0]).format(GLOBAL_TIME_RANGE_FORMAT);
    const toTime = dayjs(value[1]).format(GLOBAL_TIME_RANGE_FORMAT);
    const period = getTimePeriodByRange(value[0], value[1]);

    emit('change-period-by-calendar', {
      fromTime,
      toTime,
      period,
    });

    saveRecentlySearch({
      name: `${fromTime} ~ ${toTime}`,
      value: `${fromTime} ~ ${toTime}`,
      shortName: period,
    });

    closeAll();
  };

  const onClickRecentlySearch = (selectedValue: TimePeriodData) => {
    if (
      props.timeRangeSecLimit &&
      parsePeriod(selectedValue.shortName).offset > props.timeRangeSecLimit
    ) {
      showErrorMsg(
        ctx,
        t('MESSAGE.VALIDATION_GUIDE', { value: stringifyDateString(props.timeRangeSecLimit) }),
      );
      return;
    }

    if (selectedValue.name.includes('~')) {
      const [fromTime, toTime] = selectedValue.name.split(' ~ ');

      emit('change-period-by-calendar', {
        fromTime,
        toTime,
        period: selectedValue.shortName,
      });
    } else {
      selectedPeriod.value = selectedValue.value;
    }

    closeAll();
  };

  const getDisplayName = (inputTime: string, inputUnit: ValueOf<typeof TIME_UNIT>) => {
    const singularUnit = {
      [TIME_UNIT.month]: t('DESC.UI.MONTH'),
      [TIME_UNIT.day]: t('DESC.UI.DAY'),
      [TIME_UNIT.hour]: t('DESC.UI.HOUR'),
      [TIME_UNIT.minute]: t('DESC.UI.MINUTE'),
      [TIME_UNIT.second]: t('DESC.UI.SECOND'),
    };
    const pluralUnit = {
      [TIME_UNIT.month]: t('WORD.PLURAL_ELAPSED_TIME_UNIT', { value: t('DESC.UI.MONTH') }),
      [TIME_UNIT.day]: t('WORD.PLURAL_ELAPSED_TIME_UNIT', { value: t('DESC.UI.DAY') }),
      [TIME_UNIT.hour]: t('WORD.PLURAL_ELAPSED_TIME_UNIT', { value: t('DESC.UI.HOUR') }),
      [TIME_UNIT.minute]: t('WORD.PLURAL_ELAPSED_TIME_UNIT', { value: t('DESC.UI.MINUTE') }),
      [TIME_UNIT.second]: t('WORD.PLURAL_ELAPSED_TIME_UNIT', { value: t('DESC.UI.SECOND') }),
    };

    return t('WORD.TIME_PERIOD_ITEMS.PAST_DYNAMIC_TIME', {
      value: inputTime,
      unit: `${+inputTime > 1 ? pluralUnit[inputUnit] : singularUnit[inputUnit]}`,
    });
  };

  const getCustomPeriod = ({
    inputTime,
    inputUnit,
    diffDates,
  }: {
    inputTime: string;
    inputUnit: ValueOf<typeof TIME_UNIT>;
    diffDates: number;
  }) => {
    const displayName = getDisplayName(inputTime, inputUnit);
    const searchUnit = inputUnit === 'month' ? TIME_UNIT.day : inputUnit;
    const searchTime = inputUnit === 'month' ? diffDates : inputTime;
    return {
      name: displayName,
      value: `p${searchTime}${searchUnit}`,
      shortName: `${searchTime}${inputUnit === 'month' ? TIME_UNIT.day : inputUnit}`,
    };
  };

  const getDiffDates = (
    curTime: string | undefined,
    option: { value: number; unit: ManipulateType },
  ) => {
    if (!curTime) {
      return 0;
    }
    const formattedCurTime = dayjs(curTime, GLOBAL_TIME_RANGE_FORMAT);
    const prevTime = getSafeDateTime(formattedCurTime.subtract(option.value, option.unit));
    return formattedCurTime.diff(prevTime, 'day');
  };

  const onInputValidation = (inputTime) => {
    if (!inputTime) {
      pastInputError.value = t('MESSAGE.ENTER_THE_TIME');
      return;
    }
    pastInputError.value = '';
    pastInputTime.value = `${getOnlyNumber(inputTime, {
      min: 1,
      max: MAX_SEARCH_INPUT_NUMBER_VALUE,
    })}`;
  };

  const onClickInputTimeOk = (inputTime: string, inputUnit: ValueOf<typeof TIME_UNIT>) => {
    const customPeriod = getCustomPeriod({
      inputTime,
      inputUnit,
      diffDates:
        inputUnit === 'month'
          ? getDiffDates(props.toTime, { value: +inputTime, unit: 'month' })
          : -1,
    });
    selectedPeriod.value = `p${inputTime}${inputUnit}`;
    selectedPeriod.value = customPeriod.value;
    saveRecentlySearch(customPeriod);
    closeAll();
  };

  watch(
    () => isShowDropdown.value,
    (isShow) => {
      if (isShow) {
        initPastInputInfo();
      }
    },
  );

  return {
    t,

    isShowDropdown,
    toggleDropdown,
    closeAll,

    periodName,
    periodShortName,

    quickPeriods,
    onClickQuickPeriod,

    recentlySearches,
    onClickRecentlySearch,
    deleteRecentlySearch,

    isShowDatePicker,
    dateTimePeriod,
    openDatePicker,
    closeDatePicker,
    onSelectByCalendar,

    timeUnitItems,
    pastInputTime,
    pastInputUnit,
    pastInputError,
    onInputValidation,
    onClickInputTimeOk,
  };
};
