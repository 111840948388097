const MAIN_FRAMES = {
  TAG: 'Tag',
} as const;

export const COMMON_FRAME_NAMES = {
  SIMPLE_WINDOW: {
    ACTIVE_BACKEND: 'Active Backend Window/Active Backend',
    FILE_SYSTEM_USAGE: 'File System Usage Window/File System Usage',
    METRIC: 'Metric Window/Metric',
    GLOBAL_TEMP_LIST: 'Global Temp List Window/Global Temp List',
    LOCK_TREE: 'Lock Tree Window/Lock Tree',
    META_LOCK_TREE: 'Lock Tree Window/Meta Lock Tree',
    LOADED_CLASS: 'Loaded Class Window/Loaded Class',
    OPEN_TABLES: 'Open Tables Window/Open Tables',
    PARAMETER: 'Parameter Window/Parameter',
    THREAD: 'Thread Window/Thread',
    TRANSACTION_DETAIL: 'Transaction Detail Window/Transaction Detail',
    TOP_10_SESSION_TEMP_LIST: "Top 10 Sessions Temp List Window/Top 10 Session's Temp List",
    TOP_10_TABLESPACE_LIST: 'Top 10 Tablespace List Window/Top 10 Tablespace List',
    TOP_10_SORTING_LIST: 'Top 10 Sorting List Window/Top 10 Sorting List',
    TOP_10_OLD_TRANSACTION: 'Top 10 Old Transaction Window/Top 10 Old Transaction',
    TOP_10_OBJECT_LIST_ORDER_BY: 'Top 10 Object List Order By Window/Top 10 Object List Order By',
    TOP_10_EVENT_LIST: 'Top 10 Event List Window/Top 10 Event List',
    TOP_10_SQL_LIST_ORDER_BY: 'Top 10 SQL List Order By Window/Top 10 List Order By',
    TOP_10_SQL_FULL_SCAN_LIST: 'Top 10 SQL Full Scan List Window/Top 10 SQL Full Scan List',
    TOP_SQL: 'Top SQL Window/Top SQL',
    STAT_CHART_OPTION_METRIC: 'Stat Chart Option Window/Metric',
    SQL_STAT_LIST: 'SQL Stat List Window/SQL Stat List',
    STAT_METRIC: 'Stat Metric Window/Stat Metric',
    SESSION_LIST: 'Session List Window/Session List',
    SYSLOG_MESSAGE: 'Syslog Window/System Message Log',
    SNMP_TRAP_MESSAGE_WINDOW: 'SNMP Message Window/SNMP Message',
    SQL_TEXT_WINDOW: 'SQL Text Window/SQL Text',
    VACUUMING_BACKENDS_WINDOW: 'Vacuuming Backends Window/Vacuuming Backends',
  },

  TAG: {
    TARGET_TAGS: `${MAIN_FRAMES.TAG}/Target Tags`,
    TAG_ID_LIST: `${MAIN_FRAMES.TAG}/Tag ID List`,
    TARGET_TAGS_CATEGORY_BY_VALUE: (value: string) =>
      `${MAIN_FRAMES.TAG}/Target Tags Category ${value}`,
  },
} as const;
