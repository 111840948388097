export const CONFIG = {
  ACCOUNT: {
    GRIDCOLUMNS_config_myInfo_Tokens: 'GRIDCOLUMNS_config_myInfo_Tokens',
    GRIDCOLUMNS_config_userUserGroup_userList: 'GRIDCOLUMNS_config_userUserGroup_userList',
    GRIDCOLUMNS_config_permission_targetList: 'GRIDCOLUMNS_config_permission_targetList',
    GRIDCOLUMNS_config_permission_targetUserGroup: 'GRIDCOLUMNS_config_permission_targetUserGroup',
    GRIDCOLUMNS_config_permission_rolePermissionList:
      'GRIDCOLUMNS_config_permission_rolePermissionList',
    FILTERSEARCH_config_userUserGroup_userList: 'FILTERSEARCH_config_userUserGroup_userList',
  },
  PLATFORM: {
    GRIDCOLUMNS_config_infrastructure_hostList: 'GRIDCOLUMNS_config_infrastructure_hostList',
    GRIDCOLUMNS_config_infrastructure_hostListDetail_configList:
      'GRIDCOLUMNS_config_infrastructure_hostListDetail_configList',
    GRIDCOLUMNS_config_infrastructure_hostListDetail_configSetting:
      'GRIDCOLUMNS_config_infrastructure_hostListDetail_configSetting',
    GRIDCOLUMNS_config_infrastructure_networkObjectList:
      'GRIDCOLUMNS_config_infrastructure_networkObjectList',
    GRIDCOLUMNS_config_infrastructure_networkDevice_deviceList:
      'GRIDCOLUMNS_config_infrastructure_networkDevice_deviceList',
    GRIDCOLUMNS_config_infrastructure_oidPackManager_oidList:
      'GRIDCOLUMNS_config_infrastructure_oidPackManager_oidList',
    GRIDCOLUMNS_config_infrastructure_oidPackManagerAddGroup_oidList:
      'GRIDCOLUMNS_config_infrastructure_oidPackManagerAddGroup_oidList',
    GRIDCOLUMNS_config_infrastructure_messageQueue_messageQueueList:
      'GRIDCOLUMNS_config_infrastructure_messageQueue_messageQueueList',
    GRIDCOLUMNS_config_cloud_instanceList: 'GRIDCOLUMNS_config_cloud_instanceList',
    GRIDCOLUMNS_config_cloud_collectorList: 'GRIDCOLUMNS_config_cloud_collectorList',
    GRIDCOLUMNS_config_cloud_collectorDetail_configList:
      'GRIDCOLUMNS_config_cloud_collectorDetail_configList',
    GRIDCOLUMNS_config_kubernetes_namespaceList: 'GRIDCOLUMNS_config_kubernetes_namespaceList',
    GRIDCOLUMNS_config_kubernetes_exportFile_configList:
      'GRIDCOLUMNS_config_kubernetes_exportFile_configList',
    GRIDCOLUMNS_config_application_wasList: 'GRIDCOLUMNS_config_application_wasList',
    GRIDCOLUMNS_config_application_wasListDetail_configList:
      'GRIDCOLUMNS_config_application_wasListDetail_configList',
    GRIDCOLUMNS_config_application_wasListDetail_configSetting:
      'GRIDCOLUMNS_config_application_wasListDetail_configSetting',
    GRIDCOLUMNS_config_application_url_monitoring_urlList:
      'GRIDCOLUMNS_config_application_url_monitoring_urlList',
    GRIDCOLUMNS_config_application_rumList: 'GRIDCOLUMNS_config_application_rumList',
    GRIDCOLUMNS_config_database_instance_instanceList:
      'GRIDCOLUMNS_config_database_instance_instanceList',
    GRIDCOLUMNS_config_database_agentManagementListDetail_instanceList:
      'GRIDCOLUMNS_config_database_agentManagementListDetail_instanceList',
    GRIDCOLUMNS_config_database_instanceListDetail_customConfiguration:
      'GRIDCOLUMNS_config_database_instanceListDetail_customConfiguration',
    FILTERSEARCH_config_infrastructure_hostList: 'FILTERSEARCH_config_infrastructure_hostList',
    FILTERSEARCH_config_infrastructure_deviceList: 'FILTERSEARCH_config_infrastructure_deviceList',
    FILTERSEARCH_config_infrastructure_oidList: 'FILTERSEARCH_config_infrastructure_oidList',
    FILTERSEARCH_config_infrastructure_messageQueueList:
      'FILTERSEARCH_config_infrastructure_messageQueueList',
    FILTERSEARCH_config_cloud_resourceList: 'FILTERSEARCH_config_cloud_resourceList',
    FILTERSEARCH_config_cloud_collectorList: 'FILTERSEARCH_config_cloud_collectorList',
    FILTERSEARCH_config_cloud_metricSettingList: 'FILTERSEARCH_config_cloud_metricSettingList',
    FILTERSEARCH_config_kubernetes_namespaceList: 'FILTERSEARCH_config_kubernetes_namespaceList',
    FILTERSEARCH_config_application_wasList: 'FILTERSEARCH_config_application_wasList',
    FILTERSEARCH_config_database_instance_instanceList:
      'FILTERSEARCH_config_database_instance_instanceList',
    FILTERSEARCH_config_database_agentManagement_instanceList:
      'FILTERSEARCH_config_database_agentManagement_instanceList',
  },
  MONITORING: {
    GRIDCOLUMNS_config_business_businessList: 'GRIDCOLUMNS_config_business_businessList',
    GRIDCOLUMNS_config_log_loggingList: 'GRIDCOLUMNS_config_log_loggingList',
    GRIDCOLUMNS_config_log_loggingListDetail_accessLog:
      'GRIDCOLUMNS_config_log_loggingListDetail_accessLog',
    GRIDCOLUMNS_config_log_loggingListDetail_pathList:
      'GRIDCOLUMNS_config_log_loggingListDetail_pathList',
    GRIDCOLUMNS_config_log_pathList: 'GRIDCOLUMNS_config_log_pathList',
    GRIDCOLUMNS_config_log_pathListDetail_filePathGuide:
      'GRIDCOLUMNS_config_log_pathListDetail_filePathGuide',
    GRIDCOLUMNS_config_log_pathListDetail_config: 'GRIDCOLUMNS_config_log_pathListDetail_config',
    GRIDCOLUMNS_config_report_dashboardList: 'GRIDCOLUMNS_config_report_dashboardList',
    GRIDCOLUMNS_config_report_scheduleList: 'GRIDCOLUMNS_config_report_scheduleList',
    GRIDCOLUMNS_config_report_scheduleListDetail_sendInformation:
      'GRIDCOLUMNS_config_report_scheduleListDetail_sendInformation',
    GRIDCOLUMNS_config_license_licenseList: 'GRIDCOLUMNS_config_license_licenseList',
    GRIDCOLUMNS_config_license_licenseTarget_List: 'GRIDCOLUMNS_config_license_licenseTarget_List',
    GRIDCOLUMNS_config_license_hostList: 'GRIDCOLUMNS_config_license_hostList',
    GRIDCOLUMNS_config_license_ndmList: 'GRIDCOLUMNS_config_license_ndmList',
    GRIDCOLUMNS_config_license_kafkaList: 'GRIDCOLUMNS_config_license_kafkaList',
    GRIDCOLUMNS_config_license_containerList: 'GRIDCOLUMNS_config_license_containerList',
    GRIDCOLUMNS_config_license_appContainerList: 'GRIDCOLUMNS_config_license_appContainerList',
    GRIDCOLUMNS_config_license_apmList: 'GRIDCOLUMNS_config_license_apmList',
    GRIDCOLUMNS_config_license_redisList: 'GRIDCOLUMNS_config_license_redisList',
    GRIDCOLUMNS_config_license_postgresqlList: 'GRIDCOLUMNS_config_license_postgresqlList',
    GRIDCOLUMNS_config_license_mysqlList: 'GRIDCOLUMNS_config_license_mysqlList',
    GRIDCOLUMNS_config_license_oracleList: 'GRIDCOLUMNS_config_license_oracleList',
    GRIDCOLUMNS_config_license_sqlServerList: 'GRIDCOLUMNS_config_license_sqlServerList',
    FILTERSEARCH_config_business_businessList: 'FILTERSEARCH_config_business_businessList',
    FILTERSEARCH_config_log_loggingList: 'FILTERSEARCH_config_log_loggingList',
    FILTERSEARCH_config_log_pathList: 'FILTERSEARCH_config_log_pathList',
    FILTERSEARCH_config_log_addLoggingWindow: 'FILTERSEARCH_config_log_addLoggingWindow',
    FILTERSEARCH_config_report_dashboardList: 'FILTERSEARCH_config_report_dashboardList',
    FILTERSEARCH_config_report_scheduleList: 'FILTERSEARCH_config_report_scheduleList',
  },
  ALERT: {
    GRIDCOLUMNS_config_userAlert_ruleList: 'GRIDCOLUMNS_config_userAlert_ruleList',
    GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventPreview:
      'GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventPreview',
    GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventLogging:
      'GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventLogging',
    GRIDCOLUMNS_config_systemAlert_hostList: 'GRIDCOLUMNS_config_systemAlert_hostList',
    GRIDCOLUMNS_config_systemAlert_deviceList: 'GRIDCOLUMNS_config_systemAlert_deviceList',
    GRIDCOLUMNS_config_systemAlert_nodeList: 'GRIDCOLUMNS_config_systemAlert_nodeList',
    GRIDCOLUMNS_config_systemAlert_wasList: 'GRIDCOLUMNS_config_systemAlert_wasList',
    GRIDCOLUMNS_config_systemAlert_instanceList: 'GRIDCOLUMNS_config_systemAlert_instanceList',
    GRIDCOLUMNS_config_notification_slackNotificationList:
      'GRIDCOLUMNS_config_notification_slackNotificationList',
    GRIDCOLUMNS_config_notification_telegramNotificationList:
      'GRIDCOLUMNS_config_notification_telegramNotificationList',
    GRIDCOLUMNS_config_notification_mailSMTPNotificationList:
      'GRIDCOLUMNS_config_notification_mailSMTPNotificationList',
    GRIDCOLUMNS_config_notification_mailNCPNotificationList:
      'GRIDCOLUMNS_config_notification_mailNCPNotificationList',
    GRIDCOLUMNS_config_notification_smsDbNotificationList:
      'GRIDCOLUMNS_config_notification_smsDbNotificationList',
    GRIDCOLUMNS_config_notification_smsDbNotificationProvisionVariable:
      'GRIDCOLUMNS_config_notification_smsDbNotificationProvisionVariable',
    GRIDCOLUMNS_config_notification_smsNcpNotificationList:
      'GRIDCOLUMNS_config_notification_smsNcpNotificationList',
    GRIDCOLUMNS_config_notification_webhookNotificationList:
      'GRIDCOLUMNS_config_notification_webhookNotificationList',
    FILTERSEARCH_config_userAlert_ruleList: 'FILTERSEARCH_config_userAlert_ruleList',
    FILTERSEARCH_config_systemAlert_infra: 'FILTERSEARCH_config_systemAlert_infra',
    FILTERSEARCH_config_systemAlert_kube: 'FILTERSEARCH_config_systemAlert_kube',
    FILTERSEARCH_config_systemAlert_app: 'FILTERSEARCH_config_systemAlert_app',
    FILTERSEARCH_config_systemAlert_db: 'FILTERSEARCH_config_systemAlert_db',
    FILTERSEARCH_config_systemAlert_network: 'FILTERSEARCH_config_systemAlert_network',
    FILTERSEARCH_config_notification_slackNotificationList:
      'FILTERSEARCH_config_notification_slackNotificationList',
    FILTERSEARCH_config_notification_telegramNotificationList:
      'FILTERSEARCH_config_notification_telegramNotificationList',
    FILTERSEARCH_config_notification_mailSmtpNotificationList:
      'FILTERSEARCH_config_notification_mailSmtpNotificationList',
    FILTERSEARCH_config_notification_mailNcpNotificationList:
      'FILTERSEARCH_config_notification_mailNcpNotificationList',
    FILTERSEARCH_config_notification_smsDbNotificationList:
      'FILTERSEARCH_config_notification_smsDbNotificationList',
    FILTERSEARCH_config_notification_smsNcpNotificationList:
      'FILTERSEARCH_config_notification_smsNcpNotificationList',
    FILTERSEARCH_config_notification_webhookNotificationList:
      'FILTERSEARCH_config_notification_webhookNotificationList',
  },
  MANAGEMENT: {
    GRIDCOLUMNS_config_notice_noticeList: 'GRIDCOLUMNS_config_notice_noticeList',
    GRIDCOLUMNS_config_agent_agentList: 'GRIDCOLUMNS_config_agent_agentList',
    GRIDCOLUMNS_config_agent_agentListDetail_agentTarget:
      'GRIDCOLUMNS_config_agent_agentListDetail_agentTarget',
    GRIDCOLUMNS_config_agent_agentListDetail_agentPatchHistory:
      'GRIDCOLUMNS_config_agent_agentListDetail_agentPatchHistory',
    GRIDCOLUMNS_config_agent_patchFileList: 'GRIDCOLUMNS_config_agent_patchFileList',
    GRIDCOLUMNS_config_agent_patchAgentList: 'GRIDCOLUMNS_config_agent_patchAgentList',
    GRIDCOLUMNS_config_agent_patchResultList: 'GRIDCOLUMNS_config_agent_patchResultList',
    GRIDCOLUMNS_config_agent_patchHistoryList: 'GRIDCOLUMNS_config_agent_patchHistoryList',
    GRIDCOLUMNS_config_agent_uploadFileList: 'GRIDCOLUMNS_config_agent_uploadFileList',
    GRIDCOLUMNS_config_agent_uploadFileResultList: 'GRIDCOLUMNS_config_agent_uploadFileResultList',
    GRIDCOLUMNS_config_tag_valueList: 'GRIDCOLUMNS_config_tag_valueList',
    GRIDCOLUMNS_config_dataRetention_tableList: 'GRIDCOLUMNS_config_dataRetention_tableList',
    GRIDCOLUMNS_config_dataRetentionUsageTrend_tableList:
      'GRIDCOLUMNS_config_dataRetentionUsageTrend_tableList',
    GRIDCOLUMNS_config_dataRetentionUsageTrend_retentionSetting:
      'GRIDCOLUMNS_config_dataRetentionUsageTrend_retentionSetting',
    FILTERSEARCH_config_notice_list: 'FILTERSEARCH_config_notice_list',
    FILTERSEARCH_config_notice_selectUserList: 'FILTERSEARCH_config_notice_selectUserList',
    FILTERSEARCH_config_agent_agentList: 'FILTERSEARCH_config_agent_agentList',
    FILTERSEARCH_config_agent_agentListDetail_agentTarget:
      'FILTERSEARCH_config.agent_agentListDetail_agentTarget',
    FILTERSEARCH_config_license_keyList: 'FILTERSEARCH_config_license_keyList',
    FILTERSEARCH_config_license_targetList: 'FILTERSEARCH_config_license_targetList',
    FILTERSEARCH_config_license_targetList_host: 'FILTERSEARCH_config_license_targetList_host',
    FILTERSEARCH_config_license_targetList_ndm: 'FILTERSEARCH_config_license_targetList_ndm',
    FILTERSEARCH_config_license_targetList_kafka: 'FILTERSEARCH_config_license_targetList_kafka',
    FILTERSEARCH_config_license_targetList_container:
      'FILTERSEARCH_config_license_targetList_container',
    FILTERSEARCH_config_license_targetList_appContainer:
      'FILTERSEARCH_config_license_targetList_appContainer',
    FILTERSEARCH_config_license_targetList_apm: 'FILTERSEARCH_config_license_targetList_apm',
    FILTERSEARCH_config_license_targetList_redis: 'FILTERSEARCH_config_license_targetList_redis',
    FILTERSEARCH_config_license_targetList_postgresql:
      'FILTERSEARCH_config_license_targetList_postgresql',
    FILTERSEARCH_config_license_targetList_mysql: 'FILTERSEARCH_config_license_targetList_mysql',
    FILTERSEARCH_config_license_targetList_sqlServer:
      'FILTERSEARCH_config_license_targetList_sqlServer',
    FILTERSEARCH_config_license_targetList_oracle: 'FILTERSEARCH_config_license_targetList_oracle',
    FILTERSEARCH_config_tag_tagValueList: 'FILTERSEARCH_config_tag_tagValueList',
    FILTERSEARCH_config_dataRetention_platformTableList:
      'FILTERSEARCH_config_dataRetention_platformTableList',

    TOPBANNER_config_agent_warning: 'TOPBANNER_config_agent_warning',
  },
  COMMON: {
    GRIDCOLUMNS_config_tagInformation: 'GRIDCOLUMNS_config_tagInformation',
    GRIDCOLUMNS_config_alertNotification: 'GRIDCOLUMNS_config_alertNotification',
  },
};
