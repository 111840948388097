<script setup lang="ts">
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import PopupListElement from '../popupListElement/PopupListElement.vue';
import { type Props, type Emit, setup } from './dashboardElement.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  isCurrentPath,
  childLength,

  onHover,
  onClick,
} = setup(props, emit);
</script>

<template>
  <popup-list-element
    :selected="isCurrentPath"
    :disabled="childLength === 0"
    :class="['floating-menu-element', { clicked: props.clicked }]"
    @hover="onHover"
    @click="onClick"
  >
    <template #left-side>
      <span>{{ props.dashboardMenu.text }}</span>
      <badge-item
        size="xsmall"
        :class="['dashboard-length-badge', { circular: childLength < 10 }]"
      >
        <span>
          {{ childLength }}
        </span>
      </badge-item>
    </template>
    <template #default>
      <ev-icon
        v-if="childLength > 0"
        icon="icon-arrow-right"
        class="floating-menu-element__chevron"
      />
    </template>
  </popup-list-element>
</template>

<style scoped lang="scss">
.dashboard-length-badge {
  justify-content: center;
  padding: 0 6px;
  background-color: var(--color-blue-05-05);
  color: var(--color-gray-00-00);

  &:hover {
    background-color: var(--color-blue-05-05) !important;
  }

  &.circular {
    width: 16px;
    min-width: 16px;
    padding: 0;
  }
}
</style>
