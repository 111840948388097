import { useInternational } from '@/common/locale';
import { computed, ref, watch } from 'vue';
import { getLogAlertMessageLoggingViewV8ControllerAxios } from '@/openapi/log/api/logging-view-v8-controller-api';
import { useLogsKeywordSettingStore } from '@/logs/stores/useKeywordSettingModal';
import { storeToRefs } from 'pinia';
import { FilteredKeyword } from '@/common/components/molecules/keywordSettingModal/keywordSettingModal.types';
import { LogAlertInfo } from '@/alert/components/logAlertViewerWindow/logAlertViewerWindow.types';
import { getAddedKeysHighlightInfo } from '@/logs/components/logMessage/logMessage.setup';
import { highlightLog } from '@/logs/components/logMessage/logMessage.utils';
import { getFilteredKeysHighlightInfo } from '@/alert/components/logAlertViewerWindow/logAlertViewerWindow.uses';

export interface Props {
  isShow: boolean;
  targetId: string;
  ruleId: string;
  resultId?: string;
}
export interface Emit {
  (e: 'update:isShow', visible: boolean): void;
}

const INIT_LOG_INFO = {
  alertRuleId: '',
  filteredKeys: [],
  logType: '',
  path: '',
  pathId: '',
  pathName: '',
  targetId: '',
  targetKind: '',
  targetName: '',
};

const useApiData = (props) => {
  const { configKeywordList, userKeywordList } = storeToRefs(useLogsKeywordSettingStore());

  const isLoading = ref<boolean>(false);
  const logAlertInfo = ref<LogAlertInfo>({ ...INIT_LOG_INFO });
  const logAlertMessageInfo = ref();

  const initData = () => {
    logAlertInfo.value = { ...INIT_LOG_INFO };
  };
  const getData = async () => {
    isLoading.value = true;
    try {
      const {
        data: { data = {} },
      } = await getLogAlertMessageLoggingViewV8ControllerAxios({
        alertRuleId: props.ruleId,
        targetId: props.targetId,
        alertResultId: props.resultId ?? undefined,
      });

      const filteredKeys = getFilteredKeysHighlightInfo(
        data.filteredKeys ?? [],
        configKeywordList.value,
      );
      logAlertInfo.value = {
        ...data,
        filteredKeys,
      };
      logAlertMessageInfo.value = highlightLog(data?.message?.message ?? '', {
        configKeywordList: filteredKeys,
        userKeywordList: getAddedKeysHighlightInfo(
          data?.message?.message ?? '',
          userKeywordList.value,
        ),
      });
    } catch (e) {
      console.log(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    logAlertInfo,
    logAlertMessageInfo,
    getData,
    initData,
  };
};

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const isShow = computed({
    get: () => props.isShow,
    set: (value) => emit('update:isShow', value),
  });

  const { isLoading, logAlertInfo, logAlertMessageInfo, getData, initData } = useApiData(props);

  watch(
    isShow,
    async (_isShow) => {
      if (_isShow) {
        await getData();
      } else {
        initData();
      }
    },
    { immediate: true },
  );

  return {
    t,
    isShow,
    isLoading,
    logAlertInfo,
    logAlertMessageInfo,
  };
};
