import { computed, ref, onMounted, onUnmounted, ComponentPublicInstance } from 'vue';
import { useRouter } from 'vue-router';
import { useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { storeToRefs } from 'pinia';
import { store } from '@/common/store';
import dayjs from 'dayjs';
import { DetailInfoData } from '@/common/utils/types';

export interface DashboardKey {
  target: string;
  targetId: string;
  namespace?: string;
}
export const useDashboardJump = () => {
  const isOpenDashboardSettingPopup = ref(false);
  const isOpenDashboardListPopup = ref(false);
  const router = useRouter();

  const { dashboardName, setupDashBoardData } = storeToRefs(useDashboardPopupEnvStore());

  const dashboardPopupListCnt = computed(
    () => setupDashBoardData.value?.dashboardList?.length ?? 0,
  );
  const dashboardPopupList = computed(() => setupDashBoardData.value?.dashboardList ?? []);

  const moveDashboard = (id: number, keyInfo?: DashboardKey) => {
    const tagString = keyInfo?.namespace
      ? `${keyInfo?.target}+${keyInfo?.targetId}+${keyInfo?.namespace}`
      : `${keyInfo?.target}+${keyInfo?.targetId}`;
    router.push({
      name: 'dashboard_Dashboard View',
      params: {
        id,
      },
      query: {
        rootMenu: 'dashboard',
        tag: tagString,
      },
    });
  };

  const viewDashboardList = (
    event: Event,
    infoDataList: DetailInfoData[],
    keyInfo: DashboardKey,
  ) => {
    event.stopPropagation();
    const popupList = dashboardPopupList.value;
    const popupCount = dashboardPopupListCnt.value;
    const accountInfo = store.getters['myInfo/getAccountInfo'];
    const containerKey = infoDataList?.filter((data) => data.field === 'containerKey')[0]
      ?.values[0];

    if (popupCount > 1) {
      isOpenDashboardListPopup.value = !isOpenDashboardListPopup.value;
      if (isOpenDashboardSettingPopup.value) {
        isOpenDashboardSettingPopup.value = false;
      }
    } else if (popupCount === 1 && popupList?.[0]) {
      const popup = popupList[0];
      isOpenDashboardListPopup.value = false;

      if (popup.dashboardId) {
        moveDashboard(popup.dashboardId, keyInfo);
      } else if (popup.url) {
        const fromTime = +dayjs().tz().subtract(10, 'minutes');
        const toTime = +dayjs().tz();
        const url = popup.url
          .replace('$intermaxFromTime', fromTime.toString())
          .replace('$intermaxToTime', toTime.toString())
          .replace('$timeZone', accountInfo.timezone ?? '')
          .replace('$containerId', containerKey ?? '');

        if (popup.isNewWindow) {
          window.open(url, '_blank');
        } else {
          location.replace(url);
        }
      }
    }
  };

  const dashboardTooltipRef = ref();
  const onShowDashboardTooltip = (e) => {
    if (dashboardPopupListCnt.value === 0) {
      dashboardTooltipRef.value.show(e);
    }
  };
  const onHideDashboardTooltip = (e) => {
    const { relatedTarget } = e;
    if (!relatedTarget?.classList.contains('dashboard-msg-tooltip')) {
      dashboardTooltipRef.value.hide();
    }
  };

  const triggerButtonRef = ref<ComponentPublicInstance | null>(null);
  const popupAreaRef = ref<ComponentPublicInstance | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTriggerButton = triggerButtonRef.value?.$el?.contains(target);
    const isPopupArea = popupAreaRef.value?.$el?.contains(target);

    if (!isTriggerButton && !isPopupArea && isOpenDashboardListPopup.value) {
      isOpenDashboardListPopup.value = false;
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });

  return {
    isOpenDashboardSettingPopup,
    isOpenDashboardListPopup,
    dashboardPopupListCnt,
    dashboardPopupList,
    dashboardName,
    moveDashboard,
    viewDashboardList,

    dashboardTooltipRef,
    onShowDashboardTooltip,
    onHideDashboardTooltip,
    triggerButtonRef,
    popupAreaRef,
  };
};
