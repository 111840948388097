import { RouteRecordRaw } from 'vue-router';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { VIEW_MODE } from '@/common/define/common.define';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';

const PREFIX_CLOUD = 'cloud_';
const SUB_MENU_PREFIX = {
  aws: `${PREFIX_CLOUD}AWS_`,
};

const SUB_MENU_COMP_NAME = {
  ec2: `${SUB_MENU_PREFIX.aws}EC2`,
  lambda: `${SUB_MENU_PREFIX.aws}Lambda`,
  s3: `${SUB_MENU_PREFIX.aws}S3`,
  dynamoDb: `${SUB_MENU_PREFIX.aws}DynamoDB`,
  documentDb: `${SUB_MENU_PREFIX.aws}DocumentDB`,
  elastiCache: `${SUB_MENU_PREFIX.aws}ElastiCache`,
  rds: `${SUB_MENU_PREFIX.aws}RDS`,
  redshift: `${SUB_MENU_PREFIX.aws}Redshift`,
  costExplorer: `${SUB_MENU_PREFIX.aws}CostExplorer`,
};

const cloudMenu: RouteRecordRaw[] = [
  {
    path: '/cloud/',
    name: `${PREFIX_CLOUD}Cloud`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD,
    },
    children: [],
  },
  {
    path: '/cloud/aws/',
    name: SUB_MENU_PREFIX.aws,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
    },
    children: [
      // NOTE: 포함X
      // {
      //   path: 'ec2',
      //   name: SUB_MENU_COMP_NAME.ec2,
      //   component: () => import('@/cloud/views/aws/ec2View/Ec2View.vue'),
      //   meta: {
      //     category: 'computing',
      //     rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_EC2,
      //     prefixIcon: 'icon-aws-ec2',
      //   },
      // },
      // {
      //   path: 'lambda',
      //   name: SUB_MENU_COMP_NAME.lambda,
      //   component: () => import('@/cloud/views/aws/lambdaView/LambdaView.vue'),
      //   meta: {
      //     category: 'computing',
      //     rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_LAMBDA,
      //     prefixIcon: 'icon-aws-lambda',
      //   },
      // },
      // NOTE: 기획 변경이 필요한 부분이라 숨김 처리
      // {
      //   path: 's3',
      //   name: SUB_MENU_COMP_NAME.s3,
      //   component: () => import('@/cloud/views/aws/s3View/S3View.vue'),
      //   meta: {
      //     category: 'storage',
      //     rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_S3,
      //     prefixIcon: 'icon-aws-s3',
      //   },
      // },
      {
        path: 'document-db',
        name: SUB_MENU_COMP_NAME.documentDb,
        component: () => import('@/cloud/views/aws/documentDbView/DocumentDbView.vue'),
        meta: {
          category: 'database',
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_DOCUMENT_DB,
          prefixIcon: 'icon-aws-documentdb',
        },
      },
      {
        path: 'dynamo-db',
        name: SUB_MENU_COMP_NAME.dynamoDb,
        component: () => import('@/cloud/views/aws/dynamoDbView/DynamoDbView.vue'),
        meta: {
          category: 'database',
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_DYNAMO_DB,
          prefixIcon: 'icon-aws-dynamodb',
        },
      },
      {
        path: 'elasti-cache',
        name: SUB_MENU_COMP_NAME.elastiCache,
        component: () => import('@/cloud/views/aws/elastiCacheView/ElastiCacheView.vue'),
        meta: {
          category: 'database',
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_ELASTI_CACHE,
          prefixIcon: 'icon-aws-elasticache',
        },
      },
      // NOTE: 포함X
      // {
      //   path: 'rds',
      //   name: SUB_MENU_COMP_NAME.rds,
      //   component: () => import('@/cloud/views/aws/rdsView/RdsView.vue'),
      //   meta: {
      //     category: 'database',
      //     rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_RDS,
      //     prefixIcon: 'icon-aws-rds',
      //   },
      // },
      {
        path: 'redshift',
        name: SUB_MENU_COMP_NAME.redshift,
        component: () => import('@/cloud/views/aws/redshiftView/RedshiftView.vue'),
        meta: {
          category: 'database',
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_REDSHIFT,
          prefixIcon: 'icon-aws-redshift',
        },
      },
      {
        path: 'cost-explorer',
        name: SUB_MENU_COMP_NAME.costExplorer,
        component: () => import('@/cloud/views/aws/costExplorerView/CostExplorerView.vue'),
        meta: {
          category: 'management',
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS_COST_EXPLORER,
          prefixIcon: 'icon-aws-costexplorer',
        },
      },
    ],
  },
];

const getCloudNaviMenu = (
  subMenu?: 'aws',
  category?: 'computing' | 'storage' | 'database' | 'management',
) => {
  const filteredMenu = extractAccessibleRoutes(cloudMenu);

  if (subMenu && SUB_MENU_PREFIX[subMenu] && category) {
    return filteredMenu
      .filter((menu) => menu.name === SUB_MENU_PREFIX[subMenu])
      .map((menu) => {
        return {
          ...menu,
          children:
            menu.children?.filter((child) => {
              return (child?.meta?.category ?? '') === category;
            }) ?? [],
        };
      });
  }

  return filteredMenu;
};

export { cloudMenu, getCloudNaviMenu };
