import {
  AddedKeyword,
  FilteredKeyword,
} from '@/common/components/molecules/keywordSettingModal/keywordSettingModal.types';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { useRtmApi } from '@/common/utils/apiUtils';
import { scrollToBottom } from '@/common/utils/commonUtils';
import { useLogsKeywordSettingStore } from '@/logs/stores/useKeywordSettingModal';
import { getPreviousLoggingLoggingViewV8ControllerAxios } from '@/openapi/log/api/logging-view-v8-controller-api';
import { LoggingFilterDto } from '@/openapi/log/model';
import { PreviousLoggingV8Request } from '@/openapi/metav8Over/model';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { highlightLog } from './logMessage.utils';

export interface LoggingFilter {
  filterDetail?: string;
  filterType?: string;
}
export interface LogMessageProps {
  visible: boolean;
  filteredList: LoggingFilter[];
  request: Required<PreviousLoggingV8Request> & { target: string; pathName: string[] };
}

interface LogMessageEmit {
  (e: 'update:visible', visible: boolean): void;
}

export const isK8sTarget = (target: string) => {
  return ['deployment', 'statefulset', 'cronjob', 'daemonset'].includes(target);
};

export const getFilteredKeysHighlightInfo = (
  filteredKeys: LoggingFilterDto[],
  highlightInfo: FilteredKeyword[] = [],
): FilteredKeyword[] => {
  const nameList = filteredKeys?.map((keyword) => keyword.filterDetail);
  return (
    nameList?.map((name) => {
      return (
        highlightInfo?.find((keyword) => keyword.keyword === name) ?? {
          keyword: name ?? '',
          type: 'string' as const,
          lineColor: 'var(--keyword-list-item-default-color)',
          bgColor: 'var(--keyword-list-item-default-bg-color)',
        }
      );
    }) ?? []
  );
};

export const getAddedKeysHighlightInfo = (
  logContent: string,
  highlightInfo: AddedKeyword[] = [],
) => {
  return highlightInfo?.filter((keyword) => new RegExp(keyword.keyword, 'i').test(logContent));
};

const useHighlight = (props: LogMessageProps) => {
  const store = useLogsKeywordSettingStore();
  const { highlightInfo, configKeywordList, userKeywordList } = storeToRefs(store);

  const filteredKeys = computed(() =>
    getFilteredKeysHighlightInfo(props?.filteredList ?? [], configKeywordList.value),
  );
  const addedKeys = computed(() =>
    getAddedKeysHighlightInfo(props.request?.logContent ?? '', userKeywordList.value),
  );

  const highlight = (log: string) =>
    highlightLog(log, { configKeywordList: filteredKeys.value, userKeywordList: addedKeys.value });

  const rawBody = computed(() => highlight(props.request?.logContent ?? ''));
  return {
    rawBody,
    filteredKeys,
    addedKeys,
    highlight,
    highlightInfo,
  };
};

interface DetailInfo {
  clusterName: string;
  namespace: string;
  workloadName: string;
  podName: string;
  containerName: string;
}

const useFetchPreviousLogging = () => {
  const isLoading = ref(true);
  const { callApi } = useRtmApi();
  const store = useLogsKeywordSettingStore();
  const { configKeywordList } = storeToRefs(store);

  const call = async (request: Required<LogMessageProps['request']>) => {
    try {
      if (!request.target) return {};

      isLoading.value = true;

      const { data = [], error } = await callApi({
        fn: getPreviousLoggingLoggingViewV8ControllerAxios,
        params: {
          request,
        },
        frameName: FRAME_NAMES.LOG_LOGGING.LOGGING_PREVIOUS,
      });

      if (error) {
        return {};
      }
      return {
        detail: {
          clusterName: data[0]?.targetInfo?.clusterName ?? '',
          namespace: data[0]?.targetInfo?.namespace ?? '',
          workloadName: data[0]?.targetInfo?.workloadName ?? '',
          podName: data[0]?.targetInfo?.podName ?? '',
          containerName: data[0]?.targetInfo?.containerName ?? '',
        },
        logInfoList:
          data[0]?.logInfoList?.map((logInfo) => ({
            ...logInfo,
            filteredList: getFilteredKeysHighlightInfo(
              Array.from(logInfo.filteredList ?? []),
              configKeywordList.value,
            ),
          })) ?? [],
        // @ts-expect-error logs is not defined but it is used
        rawMoreContext: data[0]?.logContent?.logs ?? [],
      };
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    call,
  };
};

const defaultDetail: DetailInfo = {
  clusterName: '',
  namespace: '',
  workloadName: '',
  podName: '',
  containerName: '',
};

const setup = (props: LogMessageProps, emit: LogMessageEmit) => {
  const visible = computed({
    get: () => props.visible,
    set: (value) => emit('update:visible', value),
  });

  const highlight = useHighlight(props);
  const detail = ref<DetailInfo>(structuredClone(defaultDetail));
  const rawMoreContext = ref('');
  const logInfoList = ref<
    {
      logGroupId?: string;
      logGroupName?: string;
      loggingId?: string;
      loggingName?: string;
      filteredList: FilteredKeyword[];
    }[]
  >([]);

  const isFolded = ref(true);
  const unfoldMoreContext = () => {
    isFolded.value = false;
    scrollToBottom('.ev-window-content');
  };

  const fetcher = useFetchPreviousLogging();

  const getDetailInfo = async () => {
    const { request } = props;

    if (!request) return;

    const {
      detail: rawDetail,
      rawMoreContext: rawMore,
      logInfoList: logInfo,
    } = await fetcher.call(request);

    if (rawDetail) {
      detail.value = rawDetail;
    }

    if (logInfo) {
      logInfoList.value = logInfo;
    }

    if (rawMore) {
      rawMoreContext.value =
        rawMore.map((item) => highlight.highlight(item ?? '')).join('<br/>') ?? ''; // TODO
      isFolded.value = true;
    } else {
      isFolded.value = false;
    }
  };

  watch(visible, async (_visible) => {
    if (_visible) {
      await getDetailInfo();
    } else {
      detail.value = structuredClone(defaultDetail);
      rawMoreContext.value = '';
      logInfoList.value = [];
      isFolded.value = false;
    }
  });

  return {
    visible,
    detail,
    rawMoreContext,
    isFolded,
    unfoldMoreContext,
    fetcher,
    logInfoList,

    highlight,
  };
};

export { type LogMessageEmit, setup };
