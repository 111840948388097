import { defineStore } from 'pinia';
import { getGroupsInfoUserV7ControllerAxios } from '@/openapi/metaV6/api/user-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { ref } from 'vue';
import { USER_PROFILE_IMAGE, USER_PROFILE_IMAGE_DEFAULT } from '@/common/utils';
import { DashboardShareUserGroupResponseItem, UserOpenInfoResponse } from '@/openapi/metaV6/model';
import { getAllGroupByUsersUserV8ControllerAxios } from '@/openapi/metaV6/api/user-v8-controller-api';

export type UserInfo = UserOpenInfoResponse;
export type GroupInfo = DashboardShareUserGroupResponseItem;

/*
 * 권한 무관하게 조회 가능한 사용자 정보 store 입니다
 * 사용자 정보의 경우 userId / activeId / name / email / profileImage 공개됩니다.
 */

const useUserGroups = () => {
  const userGroups = ref<GroupInfo[]>([]);

  const dispatchUserGroups = async ({ frameName }: { frameName?: string }) => {
    try {
      const {
        data: { data = [] },
      } = await getGroupsInfoUserV7ControllerAxios({
        frameName: frameName ?? FRAME_NAMES.CONFIG_USER_GROUP.USER_GROUP_LIST,
      });
      userGroups.value = data;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    userGroups,
    dispatchUserGroups,
  };
};

const useUsersByGroupId = () => {
  const usersByGroupId = ref<Map<number, UserInfo[]>>(new Map());

  const getUsersByGroupId = (groupId: number): UserInfo[] => {
    return groupId ? usersByGroupId.value.get(groupId) ?? [] : [];
  };
  const setUsersByGroupId = (groupId: number, userData: UserOpenInfoResponse[]) => {
    const convertedData = userData.map((info) => {
      if (!info.profileImage || !USER_PROFILE_IMAGE.includes(info.profileImage)) {
        return {
          ...info,
          profileImage: USER_PROFILE_IMAGE_DEFAULT,
        };
      }
      return info;
    });
    usersByGroupId.value.set(groupId, convertedData);
  };

  const dispatchUsersByGroupId = async ({
    groupId,
    frameName,
  }: {
    groupId: number;
    frameName?: string;
  }) => {
    try {
      const {
        data: { data = [] },
      } = await getAllGroupByUsersUserV8ControllerAxios({
        groupId,
        frameName: frameName ?? FRAME_NAMES.CONFIG_USER_GROUP.USER_LIST,
      });

      setUsersByGroupId(groupId, data[0]?.users ?? []);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    usersByGroupId,
    getUsersByGroupId,
    dispatchUsersByGroupId,
  };
};

export const usePublicUserGroupStore = defineStore('publicUserGroup', () => {
  return {
    ...useUserGroups(),
    ...useUsersByGroupId(),
  };
});
