<script setup lang="ts">
import { ALERT_RULE_NAME_ICON } from '@/alert/utils/tagsAndTargets.uses';
import { isEventType } from '@/infrastructure/views/networkDeviceView/networkDeviceDetail/networkDeviceAlert/networkDeviceAlert.setup';
import SetupDashBoardPopup from '@/common/components/organisms/setupDashBoardPopup/SetupDashBoardPopup.vue';
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import BaseCustomTooltip from '@/common/components/molecules/baseCustomTooltip/BaseCustomTooltip.vue';
import { Emit, Props, PROPS_DEFAULTS, setup } from '@/alert/components/alertGrid/alertGrid.setup';
import BaseTreeGrid from '@/common/components/molecules/grid/BaseTreeGrid.vue';
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import AlertTargetList from '@/alert/components/alertTargetList/AlertTargetList.vue';
import AlertStatusButtonRow from '@/alert/components/alertStatusButtonRow/AlertStatusButtonRow.vue';
import { checkModalLinked } from '@/alert/components/alertGrid/alertGrid.utils';
import TextViewerWindow from '@/common/components/organisms/textViewerWindow/TextViewerWindow.vue';
import { formatNumberToLocale } from '@/common/utils/commonUtils';
import { isNil } from 'lodash-es';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import DashBoardListPopup from '@/common/components/organisms/dashBoardListPopup/DashBoardListPopup.vue';
import LogAlertViewerWindow from '@/alert/components/logAlertViewerWindow/LogAlertViewerWindow.vue';

const props = withDefaults(defineProps<Props>(), {
  ...PROPS_DEFAULTS,
});
const emit = defineEmits<Emit>();

const {
  gridOption,
  searchBarOption,
  checkedRows,
  searchWord,
  exportExcelOption,
  columnIdx,
  t,
  formatThresholdStr,

  criteriaTooltipMouseEvent,
  criteriaTooltipText,
  onCriteriaMouse,

  isVisibleSetupDashBoard,
  dashboardListRef,
  dashboardEnvKey,
  setupDashboardPopupClose,
  viewDashboardList,
  moveDashboard,
  dashboardName,
  dashboardPopupListCnt,
  dashboardPopupList,
  dashboardTooltipRef,
  onShowDashboardTooltip,
  onHideDashboardTooltip,

  valueModalInfo,
  onClickLinkedValue,
  getAlertValue,
  getLoggingAlertValue,
} = setup(props, emit);
</script>

<template>
  <base-tree-grid
    v-bind="$attrs"
    v-model:checkedRows="checkedRows"
    v-model:searchWord="searchWord"
    :type="props.type === 'main' ? 'page' : 'widget'"
    :columns="props.gridColumns"
    :option="gridOption"
    :search-bar-option="searchBarOption"
    :rows="props.gridData"
    class="alert-grid"
    :export-excel-option="exportExcelOption"
    :column-env-key="`${props.envKey}_treeGrid`"
    @click-cell="(e) => emit('on-click-cell', e)"
    @export-to-excel-from-server="emit('export-to-excel-from-server', exportExcelOption)"
  >
    <template #ruleName="{ item }">
      <div
        v-if="item.data.ruleName === ALERT_RULE_NAME_ICON"
        class="alert-grid__child-icon"
      >
        <ev-icon :icon="ALERT_RULE_NAME_ICON" />
      </div>
      <div
        v-else
        :class="[
          'alert-grid__rule-name',
          props.gridColumns[columnIdx.ruleName].clickable ? 'base-grid__cell--link' : '',
        ]"
        @click.stop="
          () => {
            if (props.gridColumns[columnIdx.ruleName].clickable) {
              emit('on-click-cell', {
                field: 'ruleName',
                value: item.data.value,
                row: item.data,
              });
            }
          }
        "
      >
        <template v-if="item.data.ruleNameIcon">
          <ev-icon
            :icon="item.data.ruleNameIcon.iconClass"
            @mouseenter="(e) => onCriteriaMouse({ text: item.data.ruleNameIcon.tooltip, e })"
            @mouseleave="(e) => onCriteriaMouse({ text: '', e })"
          />
        </template>
        <span :title="item.data.ruleName">{{ item.data.ruleName }}</span>
      </div>
    </template>
    <template #lastAlert="{ item }">
      <div class="label-cell-wrapper">
        <span v-if="item.data.isChild">
          {{ item.data.lastAlert }}
        </span>
        <status-tag
          v-else
          :type="item.data.lastAlert ?? ''"
        >
          {{ item.data.lastAlert }}
        </status-tag>
      </div>
    </template>
    <template #tags="{ item }">
      <alert-target-list
        v-if="item.data.tags?.length"
        :target-list="item.data.tags"
      />
    </template>
    <template #targets="{ item }">
      <alert-target-list
        v-if="item.data.targets?.length"
        :target-list="item.data.targets"
        :is-clickable="props.isTargetClickable"
      />
    </template>
    <template #threshold="{ item }">
      <div
        v-if="item.data.threshold"
        :title="
          formatThresholdStr({ ...(item.data.threshold || {}), statUnit: item.data.statUnit })
        "
        class="threshold"
      >
        <span>
          <strong>{{ item.data.threshold.evaluateValue }}</strong>
        </span>
        <span
          v-if="item.data.threshold.duringTimeInterval"
          class="alert-grid__support-word"
          >{{ t('WORD.DURING_THE') }}</span
        >
        <span v-if="item.data.threshold.duringTimeInterval">
          <strong>{{ item.data.threshold.duringTimeInterval }}</strong>
        </span>
        <span>
          <strong>{{ item.data.threshold.comparison }}</strong>
        </span>
        <template v-if="!isNil(item.data.threshold.warning)">
          <status-tag
            type="warning"
            class="auto-width"
          >
            Warning
          </status-tag>
          <span>
            <strong>{{ formatNumberToLocale(item.data.threshold.warning) }}</strong>
            {{ item.data.statUnit }}
          </span>
        </template>
        <template v-if="!isNil(item.data.threshold.critical)">
          <status-tag
            type="critical"
            class="auto-width"
          >
            Critical
          </status-tag>
          <span>
            <strong>{{ formatNumberToLocale(item.data.threshold.critical) }}</strong>
            {{ item.data.statUnit }}
          </span>
        </template>
      </div>
    </template>
    <template #value="{ item }">
      <template v-if="isEventType(item.data.value)">
        <div
          class="base-grid__cell--link"
          @click.stop="
            () =>
              emit('on-click-cell', {
                field: 'value',
                value: item.data.value,
                row: item.data,
              })
          "
        >
          <span
            v-if="item.data.value"
            class="cell-text"
          >
            {{ item.data.value }}
          </span>
        </div>
      </template>

      <template v-else-if="checkModalLinked(item.data, 'text')">
        <span
          :title="getAlertValue(item.data.value, item.data)"
          class="base-grid__cell--link"
          @click.stop="() => onClickLinkedValue(item.data)"
        >
          {{ getAlertValue(item.data.value, item.data) }}
        </span>
      </template>

      <template v-else-if="checkModalLinked(item.data, 'logging')">
        <p
          class="alert-grid__log-content"
          @click.stop="() => onClickLinkedValue(item.data)"
          v-html="getLoggingAlertValue(item.data.value)"
        />
      </template>

      <template v-else>
        <span :title="getAlertValue(item.data.value, item.data, item.data.statUnit)">
          {{ getAlertValue(item.data.value, item.data, item.data.statUnit) }}
        </span>
      </template>
    </template>
    <template #detail="{ item }">
      <tag-item
        v-if="!item.data.isChild"
        html-type="button"
        icon="icon-arrow-right"
        size="small"
        class="border-button"
        :disabled="dashboardPopupListCnt === 0"
        @click.stop="(e) => viewDashboardList(e, item.data)"
        @mouseenter="(e) => onShowDashboardTooltip(e)"
        @mouseleave="(e) => onHideDashboardTooltip(e)"
      >
        {{ dashboardName === '' ? t('WORD.VIEW_DETAIL') : dashboardName }}
      </tag-item>
    </template>
    <template #notiStatus="{ item }">
      <alert-status-button-row
        :status-list="item.data[item.fieldName]"
        :row-index="item.data.index"
      />
    </template>
  </base-tree-grid>
  <simple-text-tooltip
    :mouse-event="criteriaTooltipMouseEvent"
    :text="criteriaTooltipText"
    base-pos="bottom"
  />
  <base-custom-tooltip
    ref="dashboardTooltipRef"
    custom-class="dashboard-msg-tooltip"
    use-deselect
    use-hover-event
  >
    <p>{{ t('MESSAGE.NO_PAGE_DASHBOARD') }}</p>
  </base-custom-tooltip>
  <setup-dash-board-popup
    v-if="props.type === 'detail' && isVisibleSetupDashBoard"
    v-model:is-show="isVisibleSetupDashBoard"
    :env-key="dashboardEnvKey"
    :is-alert="true"
    @close="setupDashboardPopupClose"
  />
  <base-custom-tooltip
    ref="dashboardListRef"
    custom-class="dashboard-list-tooltip"
    use-deselect
  >
    <dash-board-list-popup
      :move-dashboard="moveDashboard"
      :dashboard-list="dashboardPopupList"
    />
  </base-custom-tooltip>
  <text-viewer-window
    v-if="valueModalInfo.windowType === 'text'"
    v-model:is-show="valueModalInfo.isShow"
    title="Alert Value"
    :type="valueModalInfo.type"
    :text="valueModalInfo.text"
    :show-message-title="false"
    :line-number="false"
  />
  <log-alert-viewer-window
    v-if="valueModalInfo.windowType === 'logging'"
    v-model:is-show="valueModalInfo.isShow"
    :target-id="valueModalInfo.targetId"
    :rule-id="valueModalInfo.ruleId"
    :result-id="valueModalInfo.resultId"
  />
</template>
