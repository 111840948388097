<script setup lang="ts">
import { type Props, type Emit, setup } from './userMenuElement.setup';

defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  t,
  userInfo,

  onHoverButton,
  onClickButton,
} = setup(emit);
</script>

<template>
  <li :class="['menu-element', 'user-menu', { selected }]">
    <button
      class="menu-element__title user-menu__title"
      :data-menu="info.value"
      @mouseenter="onHoverButton"
      @click="onClickButton"
    >
      <div class="menu-element__title-inner user-menu__title-inner">
        <div class="menu-element__item-icon">
          <span
            class="user-menu__item-icon"
            :class="userInfo.profileImage"
          />
        </div>
        <h3>{{ t('WORD.PROFILE') }}</h3>
        <div class="menu-element__arrow-icon big right" />
      </div>
    </button>
  </li>
</template>

<style scoped lang="scss">
.user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;

  &__title {
    width: 100%;
    height: 100%;
  }

  &__title-inner {
    padding-right: 11.5px;
    padding-left: 6px;
  }

  &__item-icon {
    display: block;
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 20px;
    background-size: 28px 28px;

    &::before {
      position: absolute;
      bottom: -2px;
      left: -2px;
      width: 12px;
      height: 12px;
      border-radius: 8px;
      background-color: var(--color-gray-00-00);
      box-shadow: 0.6px 0.6px 1.5px 0 #0000008f;
      content: '';
    }

    &::after {
      display: inline-block;
      position: absolute;
      bottom: -2px;
      left: -2px;
      width: 12px;
      height: 12px;
      background-color: var(--color-gray-07-11);
      content: '';
      mask-image: url('@/common/assets/icon-setting-fill.svg');
      mask-size: 10px 10px;
      mask-position: center;
      mask-repeat: no-repeat;
    }
  }

  &.selected,
  &.selected > .user-menu__title:hover {
    background-color: var(--color-blue-06-05) !important;
  }
}

@include max-screen() {
  .user-menu {
    height: 42px;

    &__item-icon {
      width: 22px;
      height: 22px;
      background-size: 22px 22px;

      &::before {
        bottom: -1px;
        left: -1px;
        width: 9px;
        height: 9px;
        border-radius: 6px;
      }

      &::after {
        bottom: -1px;
        left: -1px;
        width: 9px;
        height: 9px;
        mask-size: 8px 8px;
      }
    }
  }
}
</style>
