<template>
  <ev-button
    v-bind="$attrs"
    :type="props.type"
    :shape="props.shape"
    :size="props.size"
    :html-type="props.htmlType"
    :disabled="props.disabled || props.isLoading"
    class="one-button"
  >
    <template v-if="props.isLoading">
      <i
        v-if="props.spinnerPlacement !== 'right'"
        class="loading-spinner ev-icon-refresh2"
      />
      <span v-if="props.loadingText">{{ props.loadingText }}</span>
      <i
        v-if="props.spinnerPlacement === 'right'"
        class="loading-spinner ev-icon-refresh2"
      />
    </template>
    <template v-else>
      <ev-icon
        v-if="props.leftIcon"
        :icon="props.leftIcon"
        :size="props.size"
        :style="$slots.default && { marginRight: '4px' }"
      />
      <slot />
      <ev-icon
        v-if="props.rightIcon"
        :icon="props.rightIcon"
        :size="props.size"
        :style="$slots.default && { marginLeft: '4px' }"
      />
    </template>
  </ev-button>
</template>

<script setup lang="ts">
import { Size } from '@/types/common.types';
import { IconType } from '@/types/icon.types';

interface Props {
  type?:
    | 'default'
    | 'primary'
    | 'info'
    | 'warning'
    | 'error'
    | 'ghost'
    | 'dashed'
    | 'text'
    | 'icon';
  shape?: 'square' | 'radius' | 'circle' | 'border';
  size?: Size;
  htmlType?: 'button' | 'submit' | 'reset';
  leftIcon?: IconType;
  rightIcon?: IconType;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  spinnerPlacement?: 'left' | 'right';
}

const props = defineProps<Props>();
</script>
<style lang="scss" scoped>
.loading-spinner {
  display: inline-block;
  width: 13px;
  height: 13px;
  animation: rotating 2s linear infinite;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.one-button {
  display: flex;
  align-items: center;
  width: fit-content;
}
</style>
