import { FilteredKeyword } from '@/common/components/molecules/keywordSettingModal/keywordSettingModal.types';

export const getFilteredKeysHighlightInfo = (
  filteredKeys: string[],
  highlightInfo: FilteredKeyword[] = [],
): FilteredKeyword[] => {
  return (
    filteredKeys?.map((name) => {
      return (
        highlightInfo?.find((keyword) => keyword.keyword === name) ?? {
          keyword: name ?? '',
          type: 'string' as const,
          lineColor: '#6699ff',
          bgColor: '#0044cc',
        }
      );
    }) ?? []
  );
};
