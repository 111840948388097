import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { StatItem } from '@/openapi/data/model';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';
import { DB_TYPE } from '@/common/utils';
import { multiInstanceOverviewPostPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { OverviewItemV7 } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

interface OverviewState {
  metricNameInfo: Partial<StatItem>[];
  metricNamesInChartUsed: string[];
  displayNamesInChartUsed: string[];
  metricInfo: OverviewItemV7[];
  errorStatusText: string;
}

const OVERVIEW_METRIC_NAMES_KEY = 'PG_OVERVIEW_METRIC_NAMES';
const FRAME_NAME = 'overview';
const DEFAULT_METRIC_NAMES = [
  'db_postgresql_os.cpu_usage',
  'db_postgresql_os.memory_percent',
  'db_postgresql_tps',
  'db_postgresql_rowshitratio',
];
const DEFAULT_DISPLAY_NAMES = ['CPU', 'Memory', 'Tps', 'Rows Hit Ratio'];

export const overview: Module<OverviewState, RootState> = {
  namespaced: true,
  state: {
    metricNameInfo: DEFAULT_METRIC_NAMES.map((metricName) => ({
      dataId: metricName,
    })),
    metricNamesInChartUsed: [],
    displayNamesInChartUsed: [],
    metricInfo: [],
    errorStatusText: '',
  },
  mutations: {
    setErrorStatusText: (state: OverviewState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
    setMetricInfo: (state: OverviewState, data: OverviewItemV7[]) => {
      state.metricInfo = data;
    },
    setMetricNameInfo: (state: OverviewState, data: Partial<StatItem>[]) => {
      state.metricNameInfo = data.map((metricInfo) => ({
        category: metricInfo.category,
        dataId: metricInfo.dataId,
        displayName: metricInfo.displayName,
        unit: metricInfo.unit,
      }));
    },
    setMetricNamesInChartUsed: (state: OverviewState, metricNames: string[]) => {
      state.metricNamesInChartUsed = metricNames;
    },
    setDisplayNamesInChartUsed: (state: OverviewState, displayNames: string[]) => {
      state.displayNamesInChartUsed = displayNames;
    },
    changeMetricNamesInChartUsed: (
      state: OverviewState,
      { idx, metricName }: { idx: number; metricName: string },
    ) => {
      state.metricNamesInChartUsed[idx] = metricName;
    },
    changeDisplayNamesInChartUsed: (
      state: OverviewState,
      { idx, displayName }: { idx: number; displayName: string },
    ) => {
      state.displayNamesInChartUsed[idx] = displayName;
    },
  },
  actions: {
    initMetricNamesInChartUsed: async ({ commit, rootGetters }) => {
      const metricNamesInUserEnv =
        rootGetters['userEnv/getUserEnvMap'].get(OVERVIEW_METRIC_NAMES_KEY) ?? '{}';
      const parseMetricNamesInUserEnv = JSON.parse(metricNamesInUserEnv);
      if (parseMetricNamesInUserEnv?.metricNames) {
        commit(
          'setMetricNamesInChartUsed',
          mapMetricNamesToDataIds({
            dbType: DB_TYPE.POSTGRESQL,
            metricNames: parseMetricNamesInUserEnv.metricNames,
          }),
        );
      } else {
        commit('setMetricNamesInChartUsed', DEFAULT_METRIC_NAMES);
      }

      if (parseMetricNamesInUserEnv?.displayNames) {
        commit('setDisplayNamesInChartUsed', parseMetricNamesInUserEnv.displayNames);
      } else {
        commit('setDisplayNamesInChartUsed', DEFAULT_DISPLAY_NAMES);
      }
    },
    async saveMetricNamesInUserEnv(
      { commit },
      { metricNames, displayNames }: { metricNames: string[]; displayNames: string[] },
    ) {
      const metricInfo = {
        metricNames,
        displayNames,
      };
      await addXmUserEnvControllerAxios({
        request: [
          {
            key: OVERVIEW_METRIC_NAMES_KEY,
            value: JSON.stringify(metricInfo),
          },
        ],
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: OVERVIEW_METRIC_NAMES_KEY, value: JSON.stringify(metricInfo) },
        { root: true },
      );
    },
    fetchMetricInfo: async (
      { commit },
      { metricNames, instanceIds = [] }: { metricNames: string[]; instanceIds: string[] },
    ) => {
      try {
        if (!instanceIds.length) {
          return;
        }
        const { data } = await multiInstanceOverviewPostPostgresqlV7ControllerAxios({
          metricNames: [...new Set(metricNames)],
          request: {
            instanceIds: instanceIds as unknown as Set<string>,
          },
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.OVERVIEW,
        });

        commit('setMetricInfo', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlMultiViewEnv/deleteFramesByFailedApi', FRAME_NAME, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getErrorStatusText: (state: OverviewState) => state.errorStatusText,
    getMetricInfo: (state: OverviewState) => state.metricInfo,
    getMetricNameInfo: (state: OverviewState) => state.metricNameInfo,
    getMetricNamesInChartUsed: (state: OverviewState) => state.metricNamesInChartUsed,
    getDisplayNamesInChartUsed: (state: OverviewState) => state.displayNamesInChartUsed,
  },
};
