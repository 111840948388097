import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useInternational } from '@/common/locale';
import { useViewModeStore } from '@/common/stores/view-mode';
import { getVersion } from '@/common/utils/commonUtils';

interface ModuleInfo {
  title: string;
  version: string;
}

interface FileInfo {
  label: string;
  path: string;
  name: string;
}

export const setup = () => {
  const { t } = useInternational();
  const { viewMode } = storeToRefs(useViewModeStore());

  const moduleInfoList = computed<ModuleInfo[]>(() => {
    const version = getVersion();
    const currentViewMode = t(`WORD.${viewMode.value.toUpperCase()}`);
    return [
      {
        title: t('WORD.GNB.HELP.SYSTEM_MODULE_VERSION', { version: '', viewMode: currentViewMode }),
        version,
      },
      {
        title: t('WORD.GNB.HELP.K8S_MODULE_VERSION', { version: '' }),
        version,
      },
      {
        title: t('WORD.GNB.HELP.APPLICATION_MODULE_VERSION', { version: '' }),
        version,
      },
      {
        title: t('WORD.GNB.HELP.DATABASE_MODULE_VERSION', {
          version: '',
          viewMode: currentViewMode,
        }),
        version,
      },
    ];
  });

  const fileInfoList: FileInfo[] = [
    // {
    //   label: t('WORD.GNB.HELP.PRODUCT_INTRODUCTION_DOWNLOAD'),
    //   path: `/support/${locale.value}/product_introduction.pdf`,
    //   name: `exemONE_${t('WORD.PRODUCT_INTRODUCTION')}.pdf`,
    // },
    // {
    //   label: t('WORD.GNB.HELP.USER_GUIDE_DOWNLOAD'),
    //   path: `/support/${locale.value}/user_guide.pdf`,
    //   name: `exemONE_${t('WORD.USER_GUIDE')}.pdf`,
    // },
    {
      label: t('WORD.GNB.HELP.OPENAPI_GUIDE_DOWNLOAD'),
      path: '/support/exemOne_openapi_guide.pdf',
      name: `exemONE_${t('WORD.OPENAPI_GUIDE')}.pdf`,
    },
  ];

  return {
    t,

    moduleInfoList,
    fileInfoList,
  };
};
