/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  AlertBarChartResponse,
  AlertCurrentResponse,
  AlertHistoryResponse,
  ClearRequest,
  InfoItemListResponse,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getCurrentSystemRuleControllerAxios = ({
  systemEvent,
  systemKind,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  systemEvent: string;
  systemKind: 'application' | 'database' | 'infrastructure' | 'kubernetes' | 'network';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertCurrentResponse> => {
  const localVarPath = '/api/v6/system-alert/alert/{systemKind}/{systemEvent}/current'
    .replace(`{${'systemEvent'}}`, encodeURIComponent(String(systemEvent)))
    .replace(`{${'systemKind'}}`, encodeURIComponent(String(systemKind)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHistoryBarChartSystemRuleControllerAxios = ({
  systemEvent,
  systemKind,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  systemEvent: string;
  systemKind: 'application' | 'database' | 'infrastructure' | 'kubernetes' | 'network';
  fromTime?: string;
  period?: 'p10m' | 'p12h' | 'p1d' | 'p1h' | 'p30m' | 'p3h' | 'p5m' | 'p6h';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertBarChartResponse> => {
  const localVarPath = '/api/v6/system-alert/alert/{systemKind}/{systemEvent}/historyBarChart'
    .replace(`{${'systemEvent'}}`, encodeURIComponent(String(systemEvent)))
    .replace(`{${'systemKind'}}`, encodeURIComponent(String(systemKind)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHistorySystemRuleControllerAxios = ({
  systemEvent,
  systemKind,
  fromTime,
  isReport,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  systemEvent: string;
  systemKind: 'application' | 'database' | 'infrastructure' | 'kubernetes' | 'network';
  fromTime?: string;
  isReport?: boolean;
  period?: 'p10m' | 'p12h' | 'p1d' | 'p1h' | 'p30m' | 'p3h' | 'p5m' | 'p6h';
  timezone?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertHistoryResponse> => {
  const localVarPath = '/api/v6/system-alert/alert/{systemKind}/{systemEvent}/history'
    .replace(`{${'systemEvent'}}`, encodeURIComponent(String(systemEvent)))
    .replace(`{${'systemKind'}}`, encodeURIComponent(String(systemKind)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isReport,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getInfoSystemRuleControllerAxios = ({
  systemKind,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  systemKind: 'application' | 'database' | 'infrastructure' | 'kubernetes' | 'network';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfoItemListResponse> => {
  const localVarPath = '/api/v6/system-alert/alert/{systemKind}/info'.replace(
    `{${'systemKind'}}`,
    encodeURIComponent(String(systemKind)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const clearSystemRuleControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: ClearRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/system-alert/alert/clear';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getCurrentSystemRuleControllerAxios,
  getHistoryBarChartSystemRuleControllerAxios,
  getHistorySystemRuleControllerAxios,
  getInfoSystemRuleControllerAxios,
  clearSystemRuleControllerAxios,
};
