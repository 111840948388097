import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { paActiveBackendListSessionPostgresV7ControllerAxios } from '@/openapi/postgresqlV7/api/session-postgres-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface ActiveBackends {
  databaseName: string;
  pid: number;
  userName: string;
  applicationName: string;
  clientAddr: string;
  clientHostName: string;
  clientPort: number;
  waitEvent: string;
  waitEventType: string;
  xactStart: string;
  backendStart: string;
  backendXid: number;
  backendXmin: number;
  queryStart: string;
  stateChange: string;
  state: string;
  sqlId: string;
  queryText: string;
}
export type LastApiParams = Parameters<
  typeof paActiveBackendListSessionPostgresV7ControllerAxios
>[0];

export interface ActiveBackendsState {
  activeBackends: ActiveBackends[];
  lastApiParams: LastApiParams;
  errorStatusText: string;
}

export const activeBackends: Module<ActiveBackendsState, RootState> = {
  namespaced: true,
  state: {
    activeBackends: [],
    lastApiParams: {} as LastApiParams,
    errorStatusText: '',
  },
  mutations: {
    setActiveBackends(state: ActiveBackendsState, data: ActiveBackends[]) {
      state.activeBackends = data;
    },
    setLastApiParams(state: ActiveBackendsState, data: LastApiParams) {
      state.lastApiParams = data;
    },
    setErrorStatusText(state: ActiveBackendsState, errorStatusText: string) {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchActiveBackends({ commit }, instanceId) {
      if (!instanceId) {
        return;
      }
      const frameName = FRAME_NAMES.SESSION_TAB.ACTIVE_BACKENDS;
      try {
        const limitSize = Number(localStorage.getItem('LIMIT_SIZE'));
        if (!limitSize || limitSize < 0) {
          localStorage.setItem('LIMIT_SIZE', '100');
        }
        const params: LastApiParams = {
          instanceId,
          frameName,
          size: Math.round(Number(localStorage.getItem('LIMIT_SIZE'))),
          sort: 'elapsedTime',
          filter: 'Active',
          period: 'p5s',
        };
        const { data } = await paActiveBackendListSessionPostgresV7ControllerAxios(params);
        commit('setLastApiParams', params);
        commit('setActiveBackends', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getActiveBackends: (state: ActiveBackendsState) => state.activeBackends,
    getLastApiParams: (state: ActiveBackendsState) => state.lastApiParams,
    getErrorStatusText: (state: ActiveBackendsState) => state.errorStatusText,
  },
};
