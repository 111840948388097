import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useApiTraceStore } from '@/common/stores/api-trace';
import { useRoute } from 'vue-router';
import { Props as BaseWidgetProps } from '@/dashboard/components/baseWidgetFrame/baseWidgetFrame.setup';

export const useApiTraceEvent = () => {
  const route = useRoute();
  const apiTraceStore = useApiTraceStore();
  const isShow = ref<boolean>(false);
  const keyUpSet: Set<string> = new Set();
  const keyDownSet: Set<string> = new Set();

  const checkOpenablePath = () => {
    const containsApiTrace = /apitrace/i.test(route.fullPath);
    const containsPopup = /popup/i.test(route.fullPath);

    return !(
      !route.name ||
      route.name === 'PageNotFound' ||
      route.fullPath === '/' ||
      (containsApiTrace && containsPopup)
    );
  };

  const initKeySet = () => {
    if (keyUpSet.size) {
      keyUpSet.clear();
    }

    if (keyDownSet.size) {
      keyDownSet.clear();
    }
  };

  const onKeyUpWindow = (e: KeyboardEvent): void => {
    if (['ControlLeft', 'ShiftLeft', 'KeyS'].includes(e.code)) {
      keyUpSet.add(e.code);
    } else {
      initKeySet();
    }

    if (keyUpSet.has('ControlLeft') && keyUpSet.has('ShiftLeft') && keyUpSet.has('KeyS')) {
      initKeySet();
    }
  };

  const onKeyDownWindow = (e: KeyboardEvent) => {
    if (['ControlLeft', 'ShiftLeft', 'KeyS'].includes(e.code)) {
      keyDownSet.add(e.code);
    } else {
      initKeySet();
    }

    if (keyDownSet.has('ControlLeft') && keyDownSet.has('ShiftLeft') && keyDownSet.has('KeyS')) {
      isShow.value = checkOpenablePath();
    }
  };

  watch(
    () => route,
    (v) => {
      apiTraceStore.changePath(`${window.origin}${v.path}`);
      if (isShow.value && !checkOpenablePath()) {
        isShow.value = false;
      }
    },
    {
      immediate: true,
      deep: true,
    },
  );

  onMounted(() => {
    apiTraceStore.init();
    window.addEventListener('keyup', onKeyUpWindow);
    window.addEventListener('keydown', onKeyDownWindow);
  });

  onBeforeUnmount(() => {
    initKeySet();
    window.removeEventListener('keyup', onKeyUpWindow);
    window.removeEventListener('keydown', onKeyDownWindow);
  });

  return {
    isShow,
  };
};

export const useApiTraceTargetEvent = () => {
  const apiTraceStore = useApiTraceStore();

  const onClickGetWidgetInfo = (value: Required<BaseWidgetProps>) => {
    apiTraceStore.setTargetFrameInfo({
      type: 'widget',
      id: value.widgetId ?? '',
    });
  };

  return {
    onClickGetWidgetInfo,
  };
};
