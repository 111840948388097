/* eslint-disable no-redeclare */
import { i18n } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { convertChartTypeToWidgetChartType } from '@/dashboard/utils/dashboardUtils';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { WidgetScatterRawData } from '@/worker/commands/dashboard/scatter';
import { TableRawData } from '@/worker/commands/dashboard/table';
import { getLimitSeriesCountByChartType } from '@/dashboard/components/widgets/widgets.defines';
import { ChartType } from '../widgets.types';

export const defaultCheckChartDataStatus =
  <RawData extends MetricRawData | CurrentRawData | TableRawData | WidgetScatterRawData>(
    chartType: ChartType,
  ) =>
  (rawData: RawData, chartDataStatus: WidgetChartDataStatusInfo[]): WidgetChartDataStatusInfo[] => {
    const chartWidgetType = convertChartTypeToWidgetChartType(chartType);
    const seriesCountLimit = getLimitSeriesCountByChartType(
      chartWidgetType?.[0],
      chartWidgetType?.[1],
    );

    return chartDataStatus.map((status, idx) => {
      if (seriesCountLimit != null && seriesCountLimit <= idx) {
        return {
          ...status,
          status: 'fail',
          type: 'custom',
          reason: i18n.global.t('ERROR.CHART_DATA.CE002', {
            value: seriesCountLimit,
          }),
          errorCode: 'CE002',
        };
      }
      return status;
    });
  };
