<script setup lang="ts">
import FeaturedIcon from '@/common/components/molecules/featuredIcon/FeaturedIcon.vue';
import PopupListElement from '../popupListElement/PopupListElement.vue';
import { type Props, type Emit, setup } from './menuElement.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  favoriteRef,

  isCurrentPath,
  isFavoriteIconVisible,
  isFavorited,
  isAnalysisFavorited,

  childLength,

  onHover,
  onClick,
} = setup(props, emit);
</script>

<template>
  <popup-list-element
    :selected="isCurrentPath"
    :class="['floating-menu-element', { clicked: props.clicked }]"
    html-type="button"
    @hover="onHover"
    @click="onClick"
  >
    <template #left-side>
      <featured-icon
        v-if="props.menuInfo.featuredIcon"
        :icon-code="props.menuInfo.featuredIcon"
      />
      <span>{{ props.menuInfo.text }}</span>
    </template>
    <template #default>
      <button
        v-if="isFavoriteIconVisible"
        ref="favoriteRef"
        :class="['favorite-button', { active: isFavorited || isAnalysisFavorited }]"
      >
        <ev-icon :icon="isFavorited || isAnalysisFavorited ? 'icon-fill-star' : 'icon-star'" />
      </button>
      <ev-icon
        v-else-if="childLength > 0"
        class="floating-menu-element__chevron"
        icon="icon-arrow-right"
      />
    </template>
  </popup-list-element>
</template>

<style scoped lang="scss">
.favorite-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 2px;
  background-color: transparent;

  i {
    width: 16px;
    max-width: 16px;
    height: 16px;
    max-height: 16px;
    background-color: var(--color-gray-03-09);
    mask-size: 18px;
  }

  &.active i {
    background-color: var(--color-yellow-05-05);
  }

  &:hover {
    background-color: var(--color-gray-05-03);
  }
}
</style>
