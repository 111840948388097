<template>
  <ev-window
    v-model:visible="mv"
    :title="props.title"
    :window-class="windowClass"
    :width="windowSize.width"
    :height="windowSize.height"
    :esc-close="true"
    :style="{ width: '100px', height: '200px' }"
  >
    <ev-loading v-model="isLoading" />
    <template
      v-if="!props.title && slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <div :class="['window-content', { 'window-content--has-tail': slots.tail }]">
      <slot />
    </div>
    <template v-if="slots.tail">
      <slot name="tail">
        <div class="btn-field">
          <ev-button @click="closeWindow"> {{ $t('WORD.CANCEL') }} </ev-button>
          <ev-button
            type="primary"
            @click="clickSave"
          >
            {{ $t('WORD.SAVE') }}
          </ev-button>
        </div>
      </slot>
    </template>
    <template
      v-if="slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </ev-window>
</template>

<script setup lang="ts">
import { computed, ref, useSlots, watch } from 'vue';
import { WindowSizeType, WINDOW_SIZE } from '@/common/utils';

interface Props {
  loading?: boolean;
  visible?: boolean;
  title?: string;
  sizeType?: WindowSizeType | 'CUSTOM';
  size?: { width: string; height: string };
  className?: string;
}

interface Emit {
  (e: 'update:visible', value: boolean): void;
  (e: 'update:loading', value: boolean): void;
  (e: 'click-save'): void;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  visible: false,
  title: '',
  sizeType: 'MEDIUM',
  size: () => ({ width: 'auto', height: 'auto' }),
  className: '',
});

const emit = defineEmits<Emit>();

const slots = useSlots();

const mv = computed({
  get: () => props.visible,
  set: () => {
    const isFocusedFilterSearch = !!document.querySelector(
      '.ev-window .filter-search__filter-main.focus.expand',
    );

    if (!isFocusedFilterSearch) {
      emit('update:visible', false);
    }
  },
});

const isLoading = computed({
  get: () => props.loading,
  set: (v) => emit('update:loading', v),
});

const screenSize = ref({ width: window.innerWidth });

const windowSize = computed(() => {
  if (props.sizeType === 'CUSTOM' && props.size) {
    return {
      width: props.size.width,
      height: props.size.height,
    };
  }
  return WINDOW_SIZE[props.sizeType] ?? 'MEDIUM';
});

const windowClass = computed(() => {
  const baseClass = `base-modal-window window--type-${props.sizeType
    .toLowerCase()
    .replace('_', '-')} ${props.className}`;

  const customFitClass =
    props.sizeType === 'CUSTOM' &&
    parseInt(windowSize.value.width, 10) > screenSize.value.width - 48
      ? ' window--type-custom-fit-window'
      : '';

  return `${baseClass}${customFitClass}`;
});

const updateScreenSize = () => {
  screenSize.value = { width: window.innerWidth };
};

const closeWindow = () => {
  mv.value = false;
};

const clickSave = () => emit('click-save');

watch(
  () => props.visible,
  (newVal) => {
    if (newVal && props.sizeType === 'CUSTOM') {
      window.addEventListener('resize', updateScreenSize);
      updateScreenSize();
    } else {
      window.removeEventListener('resize', updateScreenSize);
    }
  },
  { immediate: true },
);
</script>

<style lang="scss">
.base-modal-window {
  border-radius: 2px;
  &.ev-window {
    &.window--type-custom-fit-window {
      --horizontal-padding: 24px;

      width: calc(100% - (var(--horizontal-padding) * 2)) !important;
    }

    &.window--type-full {
      --vertical-padding: 30px;
      --horizontal-padding: 40px;

      top: var(--vertical-padding) !important;
      left: var(--horizontal-padding) !important;
      width: calc(100% - (var(--horizontal-padding) * 2)) !important;
      height: calc(100% - (var(--vertical-padding) * 2)) !important;
      padding: 56px 4px 42px;
      transform: none !important;

      .ev-window-content {
        padding: 0 !important;
      }
      .config-content__wrapper {
        width: $config-window-wrapper-width;
      }
      .ev-window-close {
        top: 16px !important;
      }
      .config-section {
        margin-bottom: 0;
      }
    }
    .window-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto !important;
      &--has-tail {
        height: calc(100% - 35px - 10px);
        margin-bottom: 10px;
        overflow-y: auto;
      }
    }
    .ev-window-footer {
      padding: 8px 12px;
    }
  }
}
.ev-window-header {
  border-radius: 2px 2px 0 0;
}
</style>
