/*
 * NOTE: draggable window 사용처가 데이터베이스 PA / RTM, API Trace 뿐
 * 이중 데이터베이스 쪽은 추후 2단 슬라이드 디테일로 변경될 여지 있음 (2024년에 논의됨)
 * 스펙 변경될 경우 본 파일 삭제 필요
 */

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { isNil } from 'lodash-es';

export const useDraggableWindowIndexStore = defineStore('draggableWindowZIndex', () => {
  const DRAGGABLE_WINDOW_MIN_INDEX = 700;
  const draggableWindowSequenceMap = ref<Map<string, string | number>>(new Map());

  const checkIsBehindWindowMinIndex = (windowRef: HTMLDivElement): boolean => {
    const computedZIndex = Number(window.getComputedStyle(windowRef).zIndex);

    return computedZIndex < DRAGGABLE_WINDOW_MIN_INDEX;
  };

  const addDraggableWindowSequence = (windowRef: HTMLDivElement): string | null => {
    const { dataset = { sequence: null } } = windowRef;
    const computedZIndex = Number(window.getComputedStyle(windowRef).zIndex);

    if (!dataset.sequence) {
      return null;
    }

    if (!checkIsBehindWindowMinIndex(windowRef)) {
      draggableWindowSequenceMap.value.set(dataset.sequence, computedZIndex);
    }
    return dataset.sequence;
  };

  const removeDraggableWindowSequence = (sequence: string) => {
    draggableWindowSequenceMap.value.delete(sequence);
  };

  const setDraggableWindowZIndexBySequence = (sequence: string, zIndex: string | number) => {
    const windowRef = document.querySelector<HTMLDivElement>(
      `div.ev-window.draggable-window[data-sequence="${sequence}"]`,
    );

    if (!windowRef) {
      return;
    }

    windowRef.style.setProperty('z-index', `${zIndex}`);
  };

  const resetDraggableWindowZIndexBySequence = (sequence: string) => {
    const initialZIndex = draggableWindowSequenceMap.value.get(sequence);

    if (isNil(initialZIndex)) {
      return;
    }

    setDraggableWindowZIndexBySequence(sequence, initialZIndex);
  };

  const setDraggableWindowZIndexByIterate = (zIndex: number, maintainOrder?: boolean) => {
    draggableWindowSequenceMap.value.forEach((initialZIndex, sequence) => {
      let newZIndex = zIndex;
      if (maintainOrder) {
        // 여러 개의 윈도우 열려있을 경우 순서 맞추기
        newZIndex = +initialZIndex - DRAGGABLE_WINDOW_MIN_INDEX + zIndex;
      }

      setDraggableWindowZIndexBySequence(sequence, newZIndex);
    });
  };

  const resetDraggableWindowZIndexByIterate = () => {
    draggableWindowSequenceMap.value.forEach((initialZIndex, sequence) =>
      setDraggableWindowZIndexBySequence(sequence, initialZIndex),
    );
  };

  return {
    checkIsBehindWindowMinIndex,

    addDraggableWindowSequence,
    removeDraggableWindowSequence,
    setDraggableWindowZIndexBySequence,
    setDraggableWindowZIndexByIterate,
    resetDraggableWindowZIndexBySequence,
    resetDraggableWindowZIndexByIterate,
  };
});
