/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseGroupListResponseItem,
  CheckPasswordRequest,
  DashboardShareUserGroupResponse,
  GroupWithUsersAndAlertsResponse,
  ResultResponse,
  RoleUserPermissionResponse,
  UpdateUserV7Request,
  UserResponse,
  UserV7Request,
  UserV7Response,
  UserWithGroupsAndRoleV7Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const deleteV7UserUserV7ControllerAxios = ({
  userIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  userIds: Array<number>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/users';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      userIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAllByTenantUsersUserV7ControllerAxios = ({
  includeAll,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  includeAll?: boolean;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseGroupListResponseItem> => {
  const localVarPath = '/api/v7/groups';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      includeAll,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getGroupByUsersUserV7ControllerAxios = ({
  groupId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  groupId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<GroupWithUsersAndAlertsResponse> => {
  const localVarPath = '/api/v7/groups/{groupId}'.replace(
    `{${'groupId'}}`,
    encodeURIComponent(String(groupId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getMyInfoUserV7ControllerAxios = ({
  tenantId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  tenantId?: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserWithGroupsAndRoleV7Response> => {
  const localVarPath = '/api/v7/me';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      tenantId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getGroupsInfoUserV7ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DashboardShareUserGroupResponse> => {
  const localVarPath = '/api/v7/open-info/groups-info';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const passwordChangeTypesUserV7ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<Array<string>> => {
  const localVarPath = '/api/v7/password-change-types';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPermissionUserRolesUserV7ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<RoleUserPermissionResponse> => {
  const localVarPath = '/api/v7/user-roles';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchMyInfoUserV7ControllerAxios = ({
  request,
  patchUserId,
  tenantId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: UpdateUserV7Request;
  patchUserId?: number;
  tenantId?: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserResponse> => {
  const localVarPath = '/api/v7/me';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      patchUserId,
      tenantId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateV7UserUserV7ControllerAxios = ({
  request,
  patchUserId,
  tenantId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: UserV7Request;
  patchUserId?: number;
  tenantId?: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV7Response> => {
  const localVarPath = '/api/v7/users';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      patchUserId,
      tenantId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateUserLockUserV7ControllerAxios = ({
  locked,
  patchUserId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  locked: boolean;
  patchUserId?: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV7Response> => {
  const localVarPath = '/api/v7/users/lock';
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      locked,
      patchUserId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const changePasswordUserV7ControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: CheckPasswordRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/password-check';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addV7UserUserV7ControllerAxios = ({
  request,
  tenantId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: UserV7Request;
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV7Response> => {
  const localVarPath = '/api/v7/users';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      tenantId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  deleteV7UserUserV7ControllerAxios,
  getAllByTenantUsersUserV7ControllerAxios,
  getGroupByUsersUserV7ControllerAxios,
  getMyInfoUserV7ControllerAxios,
  getGroupsInfoUserV7ControllerAxios,
  passwordChangeTypesUserV7ControllerAxios,
  getPermissionUserRolesUserV7ControllerAxios,
  patchMyInfoUserV7ControllerAxios,
  updateV7UserUserV7ControllerAxios,
  updateUserLockUserV7ControllerAxios,
  changePasswordUserV7ControllerAxios,
  addV7UserUserV7ControllerAxios,
};
