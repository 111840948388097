import { ref, watch } from 'vue';
import { useSlideDetailApi } from '@/alert/components/alertDetail/alertDetail.use';
import { getDetailAlertRuleControllerAxios } from '@/openapi/alert/api/alert-rule-controller-api';
import { AlertRuleDetail, ResponseEventCondition } from '@/openapi/alert/model';
import {
  ALERT_TYPE_VALUE,
  EVENT_VARIABLE_CONDITION_ITEMS,
  getAggregationItems,
  getAlertTypeItems,
  getCheckHoldTimeItems,
  getCheckIntervalItems,
  getDuringTimeItems,
  getEvaluateValueItems,
  getEventTypeItems,
  getWeeklyScheduleItems,
  RULE_CRITERIA_ITEMS,
} from '@/alert/utils/define';
import {
  getConditionsNameByUserAlertType,
  getDisplayNameFromSelectboxItem,
} from '@/alert/utils/utils';
import { SelectboxItem } from '@/common/utils/types';
import {
  convertWeeklySchedule,
  getMetricSubset,
} from '@/config/views/alertGroup/alertRuleWindow.uses';
import { getTimezoneItemsByLocale, numberWithComma } from '@/common/utils/commonUtils';
import { AlertDetailTabProps } from '@/alert/components/alertDetail/alertDetail.types';
import { InfoItem } from '@/openapi/systemAlert/model';
import { getInfoSystemRuleControllerAxios } from '@/openapi/systemAlert/api/system-rule-controller-api';
import { TARGET_TYPE, useAlertTagsAndTargets } from '@/alert/utils/tagsAndTargets.uses';
import { getSlideDetailStore } from '@/common/stores/slide-detail';
import { AlertTargetItem } from '@/alert/components/alertTargetList/alertTargetList.types';
import { get, isEmpty, upperFirst, words } from 'lodash-es';
import { useInternational } from '@/common/locale';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getPathPathControllerAxios } from '@/openapi/metaV6/api/path-controller-api';
import { makeRows } from '@/common/utils/gridUtils';
import { parsePostPeriod } from '@/config/views/alertGroup/alertRuleDataConditions/alertRuleDataConditionEventLogging.setup';
import { UserAlertType } from '@/config/views/alertGroup/alertRuleWindow.types';
import { getPathLogPathV8ControllerAxios } from '@/openapi/metav8Over/api/log-path-v8-controller-api';
import { useReasonData } from '@/config/components/reasonSelect/reasonSelect.uses';
import { useAlertDetailStore } from '@/alert/stores/alert-detail';
import { storeToRefs } from 'pinia';

export interface RuleItem {
  label: string;
  value: string | number | string[] | AlertTargetItem[];
  type?: string;
  renderer?: string;
  selectItems?: SelectboxItem[];
}

export type RuleInfo = Record<string, RuleItem>;

const useCommonRule = (t) => {
  const getInitData = () => ({
    type: {
      label: t('WORD.TYPE'),
      type: 'selectbox',
      value: '',
      selectItems: getAlertTypeItems(),
    },
    ruleCriteria: {
      label: t('WORD.RULE_CRITERIA'),
      type: 'selectbox',
      renderer: 'label',
      value: '',
      selectItems: RULE_CRITERIA_ITEMS,
    },
    checkInterval: {
      label: t('WORD.CHECK_INTERVAL'),
      type: 'selectbox',
      value: 0,
      selectItems: getCheckIntervalItems(),
    },
    checkRepeat: {
      label: t('WORD.CHECK_REPEAT'),
      type: 'number',
      value: 0,
    },
    maximumOccurrences: {
      label: t('WORD.MAXIMUM_OCCURRENCES'),
      type: 'number',
      value: '',
    },
    checkHoldTime: {
      label: t('WORD.CHECK_HOLD_TIME'),
      type: 'selectbox',
      value: '',
      selectItems: getCheckHoldTimeItems(),
    },
    weeklySchedule: {
      label: t('WORD.WEEKLY_SCHEDULE'),
      type: 'weeklySchedule',
      renderer: 'list',
      value: [],
    },
    time: {
      label: t('WORD.TIME'),
      type: 'time',
      renderer: 'list',
      value: [],
    },
  });
  const commonRuleData = ref<RuleInfo>(getInitData());

  const valueFormatter = {
    selectbox: (resVal, refInfo): string => {
      if (refInfo?.selectItems) {
        return getDisplayNameFromSelectboxItem(refInfo.selectItems, resVal);
      }
      return `${resVal}`;
    },
    weeklySchedule: (resVal): string[] => {
      const formattedSchedule: string[] = [];
      const uiData = convertWeeklySchedule.forUi(resVal);
      const weekDays = getWeeklyScheduleItems().map((item) => item.name);
      uiData.dayWeek.forEach((v) => {
        formattedSchedule.push(getDisplayNameFromSelectboxItem(getWeeklyScheduleItems(), v));
      });
      return formattedSchedule.sort((a, b) => weekDays.indexOf(a) - weekDays.indexOf(b));
    },
    time: (resVal): string[] => {
      const strArr: string[] = [];
      const uiData = convertWeeklySchedule.forUi(resVal);
      if (uiData.timeZone) {
        strArr.push(getDisplayNameFromSelectboxItem(getTimezoneItemsByLocale(), uiData.timeZone));
      }
      strArr.push(`${uiData.range[0] ?? '00:00'} ~ ${uiData.range[1] ?? '00:00'}`);
      return strArr;
    },
    number: (resVal): string => numberWithComma(resVal),
  };

  const initData = () => {
    commonRuleData.value = getInitData();
  };

  const setCommonRuleData = (
    val: AlertRuleDetail,
    userAlertType: Capitalize<UserAlertType> = ALERT_TYPE_VALUE.METRIC,
  ) => {
    initData();
    if (isEmpty(val)) {
      return;
    }

    if (userAlertType === ALERT_TYPE_VALUE.EVENT) {
      delete commonRuleData.value.checkInterval;
      delete commonRuleData.value.weeklySchedule;
      delete commonRuleData.value.time;
    }
    Object.keys(commonRuleData.value).forEach((key) => {
      const refInfo = commonRuleData.value[key];
      const resKey = key === 'time' ? 'weeklySchedule' : key;
      const resVal = val![resKey];
      commonRuleData.value[key].value =
        refInfo.type && valueFormatter[refInfo.type]
          ? valueFormatter[refInfo.type](resVal, refInfo)
          : resVal;
    });
  };

  return {
    commonRuleData,
    setCommonRuleData,
  };
};

type PathRow = [
  pathName: string,
  targetType: string,
  filePath: string,
  filterTypeValues: string[],
  logParserItems: string[],
];
const useLoggingConditions = () => {
  const { t } = useInternational();

  const PATH_COLUMNS = [
    {
      caption: 'Path Name',
      field: 'name',
      type: 'string',
      width: 175,
    },
    {
      caption: 'Log Type',
      field: 'targetType',
      type: 'string',
      width: 175,
    },
    {
      caption: 'Path',
      field: 'filePath',
      type: 'string',
      width: 175,
    },
    {
      caption: 'Filter',
      field: 'filterTypeValues',
      rendererType: 'chip-cell',
      type: 'string',
    },
    {
      caption: 'Parser',
      field: 'logParserItems',
      type: 'string',
      rendererType: 'chip-cell',
    },
  ] as const;
  const pathRows = ref<PathRow[]>([]);

  const { callApi } = useSlideDetailApi();
  const fetchPath = async (pathId: number | string) => {
    if (!pathId) {
      pathRows.value = [];
      return;
    }

    let data;
    const isUuid = Number.isNaN(Number(pathId));
    if (isUuid) {
      ({ data } = await callApi({
        fn: getPathLogPathV8ControllerAxios,
        params: {
          pathId: String(pathId),
        },
        hasIndicator: false,
        frameName: FRAME_NAMES.ALERT_DETAIL.PATH,
      }));
    } else {
      ({ data } = await callApi({
        fn: getPathPathControllerAxios,
        params: {
          pathId: +pathId,
        },
        hasIndicator: false,
        frameName: FRAME_NAMES.ALERT_DETAIL.PATH,
      }));
    }

    pathRows.value = makeRows(data || [], PATH_COLUMNS, {
      filterTypeValues: (filterTypeValues) =>
        filterTypeValues?.map(({ filterDetail }) => filterDetail),
      logParserItems: (logParserItems) => logParserItems?.map(({ parserPattern }) => parserPattern),
    });
  };

  const formatPostPeriod = (period: null | string) => {
    if (period == null) {
      return t('WORD.NOT_SET');
    }

    const unitLang = {
      m: 'WORD.MINUTES',
      h: 'WORD.HOURS',
      d: 'WORD.DAYS',
    } as const;
    const [value, unit] = parsePostPeriod(period);

    if (!value || !unit) {
      return '';
    }
    return t(unitLang[unit], { value });
  };

  const getLoggingConditionsData = (condition: ResponseEventCondition) => {
    const { level, postPeriod, variableConditionList } = condition || {};
    const { pathId, loggingName } = variableConditionList?.[0] || {};

    fetchPath(pathId?.[0]);

    const formalPostPeriod = formatPostPeriod(postPeriod ?? null);

    return {
      loggingName: {
        label: t('WORD.LOGGING'),
        value: loggingName[0] ? [loggingName[0]] : [],
      },
      path: {
        label: 'Path',
        value: {
          columns: PATH_COLUMNS,
          rows: pathRows,
        },
      },
      level: {
        label: 'Level',
        value: level ?? '',
      },
      postPeriod: {
        label: t('WORD.ALERT_POSTING_PERIOD'),
        value: formalPostPeriod ? [formalPostPeriod] : [],
      },
    };
  };

  return {
    getLoggingConditionsData,
  };
};

const useConditions = () => {
  const { t } = useInternational();

  const conditionsData = ref<RuleInfo[]>([]);
  const conditionsDataByEvent = ref<Record<string, { label: string; value: any }>>({});
  const { tagsAndTargetsController } = useAlertTagsAndTargets();

  const eventType = ref<RuleItem>();
  const eventTypeTargets = ref<RuleInfo>({});

  const { getLoggingConditionsData } = useLoggingConditions();
  const { fetchReason, getReasons } = useReasonData();

  const setConditionsData = (
    val: AlertRuleDetail['eventConditions'] | AlertRuleDetail['metricConditions'],
    userAlertType: Capitalize<UserAlertType> = ALERT_TYPE_VALUE.METRIC,
  ) => {
    if (!Array.isArray(val)) {
      return;
    }
    if (userAlertType === ALERT_TYPE_VALUE.METRIC) {
      const conditions = val as AlertRuleDetail['metricConditions'];
      conditionsData.value =
        conditions?.map((item) => {
          const { tags, targets } = tagsAndTargetsController[TARGET_TYPE.ALL](item.targets ?? []);

          let subsetInfo = {};
          if (getMetricSubset({ category: item.category }) === 'process') {
            subsetInfo = {
              processName: {
                label: t('WORD.PROCESS_NAME'),
                value: item.processName ?? '',
              },
            };
          }

          return {
            name: {
              label: t('WORD.ALERT_STAT_NAME'),
              value: [item.category ?? '', item.dataId ?? ''],
            },
            ...subsetInfo,
            aggregation: {
              label: t('WORD.AGGREGATION'),
              value: getDisplayNameFromSelectboxItem(getAggregationItems(), item.aggregation),
            },
            critical: {
              label: 'Critical',
              value: item.critical ?? '',
            },
            warning: {
              label: 'Warning',
              value: item.warning ?? '',
            },
            comparison: {
              label: '',
              value: item.criticalComparison ?? '>',
            },
            evaluateValue: {
              label: '',
              value: [
                getDisplayNameFromSelectboxItem(getEvaluateValueItems(), item.evaluateValue),
                t('WORD.DURING_THE'),
                getDisplayNameFromSelectboxItem(getDuringTimeItems(), item.duringTimeInterval),
              ],
            },
            tags: {
              label: t('WORD.TAG'),
              value: tags,
            },
            targets: {
              label: t('WORD.TARGET'),
              value: targets,
            },
          };
        }) ?? [];
    } else if (userAlertType === ALERT_TYPE_VALUE.EVENT) {
      const conditions = val as AlertRuleDetail['eventConditions'];
      const { tags, targets } = tagsAndTargetsController[TARGET_TYPE.ALL](
        conditions?.[0]?.targets ?? [],
        {
          type: userAlertType,
        },
      );

      eventType.value = {
        label: t('WORD.EVENT_TYPE'),
        value: getDisplayNameFromSelectboxItem(getEventTypeItems(), conditions?.[0]?.eventType),
      };
      eventTypeTargets.value = {
        tags: {
          label: t('WORD.TAG'),
          value: tags,
        },
        targets: {
          label: t('WORD.TARGET'),
          value: targets,
        },
      };
      conditionsDataByEvent.value = {};

      const eventTypeVal = conditions?.[0]?.eventType;

      if (eventTypeVal === 'Logging') {
        const { loggingName, path, level, postPeriod } = getLoggingConditionsData(
          conditions?.[0] || {},
        );
        eventTypeTargets.value.loggingName = loggingName;
        conditionsDataByEvent.value = {
          path,
        };
        conditionsData.value = [
          {
            level,
            postPeriod,
          },
        ];
        return;
      }

      if (eventTypeVal === 'Kubernetes') {
        eventTypeTargets.value.kind = {
          label: 'Kind',
          value: [
            getDisplayNameFromSelectboxItem(
              EVENT_VARIABLE_CONDITION_ITEMS.kind,
              get(conditions, 'conditions[0].variableConditionList[0].kind[0]', ''),
            ),
          ],
        };

        conditionsData.value =
          conditions?.map((item) => {
            const [variableCondition] = item?.variableConditionList ?? [{}];

            return {
              level: {
                label: 'Level',
                value: item.level ?? '',
              },
              eventId: {
                label: 'Reason',
                value: getReasons(variableCondition.eventId.map((v) => +v)),
                type: 'multi-chip-cell',
              },
            };
          }) ?? [];

        return;
      }

      const getVariableConditions = (data): RuleInfo =>
        Object.keys(data).reduce((acc, key) => {
          acc[`${key}`] = {
            label: words(key)
              .map((v) => upperFirst(v))
              .join(' '),
            value: EVENT_VARIABLE_CONDITION_ITEMS[key]
              ? data[key].map((v) =>
                  getDisplayNameFromSelectboxItem(EVENT_VARIABLE_CONDITION_ITEMS[key], v),
                )
              : data[key],
          };
          return acc;
        }, {} as RuleInfo);

      conditionsData.value =
        conditions?.map((item) => {
          const [variableCondition] = item?.variableConditionList ?? [{}];

          return {
            messageText: {
              label: 'Message Text',
              value: item.messageText ?? '',
            },
            level: {
              label: 'Level',
              value: item.level ?? '',
            },
            ...getVariableConditions(variableCondition),
          };
        }) ?? [];
    }
  };

  return {
    conditionsData,
    conditionsDataByEvent,
    eventType,
    eventTypeTargets,
    setConditionsData,
    fetchReason,
  };
};

const useNotification = () => {
  const notificationData = ref<InfoItem[]>([]);

  const setNotificationData = (val: InfoItem[]) => {
    notificationData.value = Array.isArray(val) ? val : [];
  };

  return {
    notificationData,
    setNotificationData,
  };
};

export const setup = (props: AlertDetailTabProps) => {
  const { t } = useInternational();
  const { callApi, abortApi } = useSlideDetailApi();
  const slideDetailStore = getSlideDetailStore();
  const { selectedRuleInfo: ruleInfo, userAlertInformationData } =
    storeToRefs(useAlertDetailStore());

  const { commonRuleData, setCommonRuleData } = useCommonRule(t);
  const {
    conditionsData,
    conditionsDataByEvent,
    eventType,
    eventTypeTargets,
    setConditionsData,
    fetchReason,
  } = useConditions();
  const { notificationData, setNotificationData } = useNotification();

  const userAlertType = ref<Capitalize<UserAlertType>>(ALERT_TYPE_VALUE.METRIC);

  const initData = () => {
    setCommonRuleData({});
    setConditionsData([]);
    setNotificationData([]);
  };
  const dataSettingController = {
    user: async () => {
      if (!ruleInfo.value.ruleId) {
        initData();
        return;
      }

      const setData = async (rowData: AlertRuleDetail) => {
        const type = (rowData?.type as Capitalize<UserAlertType>) ?? ALERT_TYPE_VALUE.METRIC;
        const conditions = rowData[getConditionsNameByUserAlertType(type)];

        if (conditions[0]?.eventType === 'Kubernetes') {
          const kind = conditions[0]?.variableConditionList[0]?.kind[0];
          await fetchReason(kind);
        }
        userAlertType.value = type;
        setCommonRuleData(rowData, type);
        setConditionsData(conditions, type);
        setNotificationData([rowData.notification!]);
      };

      if (userAlertInformationData.value) {
        await setData(userAlertInformationData.value);
        return;
      }

      const { data, error } = await callApi({
        fn: getDetailAlertRuleControllerAxios,
        params: {
          alertRuleId: ruleInfo.value.ruleId,
        },
        frameName: FRAME_NAMES.ALERT_DETAIL.INFORMATION,
      });

      if (!error && data?.[0]) {
        await setData(data[0]);
      }
    },
    system: async () => {
      if (!ruleInfo.value.platform) {
        setNotificationData([]);
        return;
      }
      const { data, error } = await callApi({
        fn: getInfoSystemRuleControllerAxios,
        params: {
          systemKind: ruleInfo.value.platform,
        },
        frameName: FRAME_NAMES.ALERT_DETAIL.INFORMATION,
      });
      if (!error && data) {
        setNotificationData(data);
      }
    },
  };

  const setScrollTop = () => {
    const el = document.querySelector('.alert-detail .ev-tabs-body');
    if (el) {
      el.scrollTop = 0;
    }
  };

  const getListColumnTitle = (val: RuleItem['value']): string => {
    if (Array.isArray(val)) {
      return val.join(' ');
    }
    return `${val}`;
  };

  watch(
    [() => props.isShow, ruleInfo],
    async ([isShow, currRuleInfo], [, prevRuleInfo]) => {
      if (!isShow) {
        abortApi();
        initData();
        return;
      }

      if (currRuleInfo.type && prevRuleInfo) {
        slideDetailStore.setIsDetailLoading(true);
        await dataSettingController[currRuleInfo.type]?.();
        setScrollTop();
        slideDetailStore.setIsDetailLoading(false);
      }
    },
    { immediate: true, deep: true },
  );

  return {
    ruleInfo,
    commonRuleData,
    conditionsData,
    conditionsDataByEvent,
    notificationData,
    userAlertType,
    eventType,
    eventTypeTargets,
    getListColumnTitle,
    t,
  };
};
