/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  AlertCommonListResponse,
  AlertCountResponse,
  AlertGroupListResponse,
  AlertHistoryTargetResponse,
  AlertLogsResponse,
  LicenseOnSiteAlertResponse,
  OnSiteAlertResponse,
  TagsRequest,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getCommonAlertCountAlertCommonControllerAxios = ({
  subTargetKinds,
  targetKind,
  targetIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  subTargetKinds: Array<string>;
  targetKind: 'business_service_group' | 'database' | 'network_object';
  targetIds?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertCountResponse> => {
  const localVarPath = '/api/v6/{targetKind}/alert-count'.replace(
    `{${'targetKind'}}`,
    encodeURIComponent(String(targetKind)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      subTargetKinds,
      targetIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getCommonAlertListAlertCommonControllerAxios = ({
  subTargetKinds,
  targetKind,
  targetIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  subTargetKinds: Array<string>;
  targetKind: 'business_service_group' | 'database' | 'network_object';
  targetIds?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertCommonListResponse> => {
  const localVarPath = '/api/v6/{targetKind}/alert-groups/alert-rules'.replace(
    `{${'targetKind'}}`,
    encodeURIComponent(String(targetKind)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      subTargetKinds,
      targetIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAlertHistoriesForDashboardAlertCommonControllerAxios = ({
  alertResultId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertResultId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertHistoryTargetResponse> => {
  const localVarPath = '/api/v6/{alertResultId}/alert-history'.replace(
    `{${'alertResultId'}}`,
    encodeURIComponent(String(alertResultId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getCurrentByServiceAlertCommonControllerAxios = ({
  targetIds,
  targetKind,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  targetIds: Array<string>;
  targetKind: 'application' | 'cloud' | 'database' | 'infrastructure' | 'kubernetes' | 'network';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertLogsResponse> => {
  const localVarPath = '/api/v6/alert-rules/current';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      targetIds,
      targetKind,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getK8sAlertCountAlertCommonControllerAxios = ({
  clusterId,
  resourceKinds,
  namespaces,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  clusterId: string;
  resourceKinds: Array<string>;
  namespaces?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertCountResponse> => {
  const localVarPath = '/api/v6/kubernetes/alert-count';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      clusterId,
      namespaces,
      resourceKinds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getK8sAlertListAlertCommonControllerAxios = ({
  clusterId,
  resourceKinds,
  namespaces,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  clusterId: string;
  resourceKinds: Array<string>;
  namespaces?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertGroupListResponse> => {
  const localVarPath = '/api/v6/kubernetes/alert-groups/alert-rules';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      clusterId,
      namespaces,
      resourceKinds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getClusterTopologyAlertCountAlertCommonControllerAxios = ({
  clusterIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  clusterIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertCountResponse> => {
  const localVarPath = '/api/v6/kubernetes/cluster-topology/alert-count';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      clusterIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getClusterTopologyAlertListAlertCommonControllerAxios = ({
  clusterIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  clusterIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertGroupListResponse> => {
  const localVarPath = '/api/v6/kubernetes/cluster-topology/alert-groups/alert-rules';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      clusterIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getLicenseOnSiteAlertAlertCommonControllerAxios = ({
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LicenseOnSiteAlertResponse> => {
  const localVarPath = '/api/v6/onSite/alert/license-notification';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getOnSiteAlertAlertCommonControllerAxios = ({
  fromTime,
  period,
  toTime,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<OnSiteAlertResponse> => {
  const localVarPath = '/api/v6/onSite/alert/notification';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getCountAlertCommonControllerAxios = ({
  dashboardId,
  userTags,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
  hashedRequest,
}: {
  dashboardId: number;
  userTags?: Array<TagsRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
  hashedRequest?: string;
}): PromiseAxiosResponse<AlertCountResponse> => {
  const localVarPath = `/api/v6/dashboard/{dashboardId}/alert-count${
    hashedRequest ? `?${hashedRequest}` : ''
  }`.replace(`{${'dashboardId'}}`, encodeURIComponent(String(dashboardId)));
  const httpMethod = 'post';

  const data: any = userTags;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getGroupAndRuleListAlertCommonControllerAxios = ({
  dashboardId,
  userTags,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  userTags?: Array<TagsRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertGroupListResponse> => {
  const localVarPath = '/api/v6/dashboard/{dashboardId}/alert-groups/alert-rules'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'post';

  const data: any = userTags;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getCommonAlertCountAlertCommonControllerAxios,
  getCommonAlertListAlertCommonControllerAxios,
  getAlertHistoriesForDashboardAlertCommonControllerAxios,
  getCurrentByServiceAlertCommonControllerAxios,
  getK8sAlertCountAlertCommonControllerAxios,
  getK8sAlertListAlertCommonControllerAxios,
  getClusterTopologyAlertCountAlertCommonControllerAxios,
  getClusterTopologyAlertListAlertCommonControllerAxios,
  getLicenseOnSiteAlertAlertCommonControllerAxios,
  getOnSiteAlertAlertCommonControllerAxios,
  getCountAlertCommonControllerAxios,
  getGroupAndRuleListAlertCommonControllerAxios,
};
