import { AlertType } from '@/alert/components/alertDetail/alertDetail.types';
import { useTargetDetailProvide } from '@/alert/components/alertTargetList/alertTargetList.uses';
import { TARGET_TYPE, useAlertTagsAndTargets } from '@/alert/utils/tagsAndTargets.uses';
import { getAlertType, getSystemAlertInfo } from '@/alert/utils/utils';
import type {
  DetailItemInfo,
  ItemInfo,
  RouteInfo,
  SlideViewType,
  StoredAlertParams,
} from '@/common/components/organisms/alertListDetail/alertListDetail.types';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { useInternational } from '@/common/locale';
import { useRolePermission } from '@/common/permission/permission.utils';
import { useRtmApi } from '@/common/utils/apiUtils';
import { handleResize } from '@/common/utils/browserPopupUtils';
import {
  confirmMsg,
  showErrorMsg,
  showSuccessMsg,
  utcZeroTimeToStandardTime,
} from '@/common/utils/commonUtils';
import { webStorageController } from '@/common/utils/webStorage.util';
import {
  useClusterTopologyAlertStore,
  useObjectTopologyAlertStore,
} from '@/config/stores/kubernetes';
import { useOverviewAlertStore } from '@/database/stores/overview-alert';
import { clearAlertRuleControllerAxios } from '@/openapi/alert/api/alert-rule-controller-api';
import { DashboardAlertItems } from '@/openapi/alert/model';
import { clearSystemRuleControllerAxios } from '@/openapi/systemAlert/api/system-rule-controller-api';
import { useRepeat } from '@/worker/composables/useRepeat';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, getCurrentInstance, onBeforeUnmount, ref, Ref, watch } from 'vue';
import {
  ALERT_API_INFO,
  FRAME_KEY,
  POPUP_MIN_SIZE,
  SYSTEM_ALERT_GROUP_ID,
  USE_SLIDE_VIEW,
} from './alertListDetail.define';

export interface Props {
  type?: SlideViewType;
  isShow?: boolean;
  viewName?: string;
}
export interface Emit {
  (e: 'expand-slide', value: boolean): void;
  (e: 'openDetail'): void;
}

const useClearAlert = (routeInfo: Ref<RouteInfo | undefined>) => {
  const type = computed(() => routeInfo.value?.type ?? USE_SLIDE_VIEW.DASHBOARD);
  const clearController = {
    user: async (items: DetailItemInfo[]) => {
      if (!items?.length) {
        return;
      }
      const clearRequests = items.map((item) => {
        const targetsRequest = item.targetList?.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id ?? '',
        }));
        return {
          alertRuleId: item.id,
          targetsRequest,
        };
      });
      await clearAlertRuleControllerAxios({
        clearRequests,
        frameName: FRAME_NAMES[FRAME_KEY[type.value]].CLEAR_ALERT_RULE,
      });
    },
    system: async (items: DetailItemInfo[]) => {
      if (!items?.length) {
        return;
      }
      const clearRequests = items.map((item) => {
        const { event, platform } = getSystemAlertInfo(item.name);
        const targetsRequest = item.targetList?.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id ?? '',
        }));
        return {
          ruleId: item.id,
          systemEvent: event,
          systemKind: platform,
          targetsRequest,
        };
      });
      await clearSystemRuleControllerAxios({
        request: { clearRequests },
        frameName: FRAME_NAMES[FRAME_KEY[type.value]].CLEAR_SYSTEM_RULE,
      });
    },
  };

  return {
    clearController,
  };
};

const useAlertRule = (routeInfo: Ref<RouteInfo | undefined>) => {
  const { t } = useInternational();

  const selectedGroupId = ref(SYSTEM_ALERT_GROUP_ID);
  const selectedRuleCardId = ref('');

  const allGroupRuleData = ref<Map<string, DetailItemInfo[]>>(new Map());
  const isFetchingGroupList = ref(false);
  const groupList = ref<ItemInfo[] | undefined>();
  const ruleList = computed<DetailItemInfo[]>(
    () => allGroupRuleData.value.get(selectedGroupId.value) ?? [],
  );

  const { callApi } = useRtmApi();
  const { tagsAndTargetsController } = useAlertTagsAndTargets();

  const getConvertRuleList = (list): DetailItemInfo[] => {
    return list.map((item) => {
      const collectTime = item.lastTriggered
        ? dayjs(utcZeroTimeToStandardTime(item.lastTriggered)).format('MM-DD HH:mm:ss')
        : '';
      const type = getAlertType(item.type);
      const getIdByTagId = () => item.targets.map((v) => `${v.targetId}`).join('//');
      return {
        cardId: `${item.ruleId}//${getIdByTagId()}`,
        id: item.ruleId,
        name: item.ruleName,
        collectTime,
        targetList: tagsAndTargetsController[TARGET_TYPE.TARGETS](item.targets)?.targets,
        level: item.lastAlert.toLowerCase(),
        type,
        ruleCriteria: item.ruleCriteria,
      };
    });
  };
  const setData = (data: DashboardAlertItems[]) => {
    /**
     * NOTE
     * @field {DashboardAlertRuleItem[]} ruleList
     * @field {CommonAlertRuleItem[]} alertList
     */
    const alertListPropertyName = [
      USE_SLIDE_VIEW.DATABASE,
      USE_SLIDE_VIEW.BUSINESS,
      USE_SLIDE_VIEW.NETWORK_OBJECT,
    ].includes(routeInfo.value?.type)
      ? 'alertList'
      : 'ruleList';

    data?.forEach((item) => {
      allGroupRuleData.value.set(
        item.alertGroupId ?? SYSTEM_ALERT_GROUP_ID,
        getConvertRuleList(item[alertListPropertyName]),
      );
    });
    groupList.value =
      data?.reduce<ItemInfo[]>((acc, curr) => {
        let { name } = curr;
        if (name === 'System Alert') {
          name = t('WORD.SYSTEM_ALERT');
        }
        acc.push({
          id: curr.alertGroupId ?? SYSTEM_ALERT_GROUP_ID,
          name: name ?? '',
          critical: curr.criticalCount ?? 0,
          warning: curr.warningCount ?? 0,
        });
        return acc;
      }, []) ?? [];

    const hasPrevId =
      selectedGroupId.value && data?.find((item) => item.alertGroupId === selectedGroupId.value);
    const firstId = data?.[0]?.alertGroupId ?? SYSTEM_ALERT_GROUP_ID;
    selectedGroupId.value = hasPrevId ? selectedGroupId.value : firstId;
  };

  const getData = async (_routeInfo?: RouteInfo) => {
    if (groupList.value === undefined) {
      isFetchingGroupList.value = true;
    }

    try {
      if (!_routeInfo) {
        throw new Error('fail');
      }
      const params = _routeInfo[_routeInfo.type];
      const frameName = FRAME_NAMES[FRAME_KEY[_routeInfo.type]].ALERT_GROUP_AND_RULES;

      const apiInfo = ALERT_API_INFO[_routeInfo.type](params, frameName);

      const { data, error } = await callApi(apiInfo);
      if (!error) {
        setData(data ?? []);
      }
    } finally {
      isFetchingGroupList.value = false;
    }
  };

  const initData = () => {
    allGroupRuleData.value.clear();
    groupList.value = undefined;
  };

  return {
    selectedGroupId,
    isFetchingGroupList,
    groupList,
    selectedRuleCardId,
    ruleList,
    getData,
    initData,
  };
};

const useAlertParamStorage = (props: Props) => {
  const getRouteInfo = () => {
    let storedDataString = webStorageController.getItem({
      type: 'session',
      key: 'alertDetailParams',
    });

    // fixme: 글로벌 필터에 의한 변경 이슈 해결을 위한 코드 수정 필요
    if (props.type === 'objectTopology') {
      storedDataString = JSON.stringify({
        objectTopology: useObjectTopologyAlertStore().apiParams,
      });
    }
    if (props.type === 'clusterTopology') {
      storedDataString = JSON.stringify({
        clusterTopology: useClusterTopologyAlertStore().apiParams,
      });
    }

    const storedData: StoredAlertParams = JSON.parse(storedDataString ?? '{}');

    if (isEmpty(storedData)) return undefined;

    return {
      type: props.type ?? USE_SLIDE_VIEW.DASHBOARD,
      ...storedData,
    } as RouteInfo;
  };
  return {
    getRouteInfo,
  };
};

const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const overviewAlertStore = useOverviewAlertStore();
  const objectTopologyAlertStore = useObjectTopologyAlertStore();
  const clusterTopologyAlertStore = useClusterTopologyAlertStore();

  useTargetDetailProvide(emit);

  const routeInfo = ref<RouteInfo>();

  const { clearController } = useClearAlert(routeInfo);
  const {
    selectedGroupId,
    isFetchingGroupList,
    groupList,
    selectedRuleCardId,
    ruleList,
    getData,
    initData,
  } = useAlertRule(routeInfo);

  const { isPermissionDenied } = useRolePermission();

  const isBrowserPopup = computed(() => !!props.viewName);
  const isShowAlertDetail = ref(false);

  const showDetail = (isShow) => {
    isShowAlertDetail.value = isShow;
    emit('expand-slide', isShow);
    if (!isShow) {
      selectedRuleCardId.value = '';
    }
  };
  const onClickGroup = () => {
    showDetail(false);
  };
  const onClickRule = (item: DetailItemInfo | null) => {
    showDetail(!!item);
  };
  const onClickClear = ({ items, type }: { items: DetailItemInfo[]; type: AlertType }) => {
    const isDenied = isPermissionDenied({
      type: 'action',
      rolePermissionKey: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_LIST_CLEAR,
    });
    if (isDenied) {
      return;
    }
    confirmMsg(ctx, {
      msgStr: t('MESSAGE.CLEAR_ALERT'),
      okCallback: async () => {
        try {
          await clearController[type](items);

          switch (props.type) {
            case USE_SLIDE_VIEW.DATABASE:
              overviewAlertStore.resetFetch();
              break;
            case USE_SLIDE_VIEW.OBJECT_TOPOLOGY:
              objectTopologyAlertStore.resetFetch();
              break;
            case USE_SLIDE_VIEW.CLUSTER_TOPOLOGY:
              clusterTopologyAlertStore.resetFetch();
              break;
            case USE_SLIDE_VIEW.DASHBOARD:
            case USE_SLIDE_VIEW.BUSINESS:
            case USE_SLIDE_VIEW.NETWORK_OBJECT:
            default:
              await getData(routeInfo.value);
              break;
          }

          showDetail(false);
          showSuccessMsg(ctx, t('MESSAGE.ALERT_DELETED'));
        } catch (e: any) {
          const { status } = e?.response ?? {};
          if (status !== 406) {
            showErrorMsg(ctx, t('MESSAGE.ALERT_DELETED_FAIL'));
          }
        }
      },
    });
  };

  const onReFetch = () => {
    getData(routeInfo.value);
  };

  if (isBrowserPopup.value) {
    const popupResize = () => {
      handleResize(POPUP_MIN_SIZE.WIDTH, POPUP_MIN_SIZE.HEIGHT);
    };
    window.addEventListener('resize', popupResize);

    onBeforeUnmount(() => {
      window.removeEventListener('resize', popupResize);
    });
  }

  const paramStorage = useAlertParamStorage(props);

  const getAlertDataByStorage = async () => {
    routeInfo.value = paramStorage.getRouteInfo();
    await getData(routeInfo.value);
  };

  const { resetFetch: repeatGetAlertDataByStorage, clearFetch: clearGetAlertDataByStorage } =
    useRepeat(getAlertDataByStorage, 5_000, {
      isSetup: [
        USE_SLIDE_VIEW.DASHBOARD,
        USE_SLIDE_VIEW.BUSINESS,
        USE_SLIDE_VIEW.NETWORK_OBJECT,
      ].includes(props.type),
      isImmediate: false,
    });

  // database > alert slide
  const { updatedFetchFlag } = storeToRefs(overviewAlertStore);
  watch(
    () => [updatedFetchFlag.value, props.isShow],
    async () => {
      if (props.type === USE_SLIDE_VIEW.DATABASE && props.isShow) {
        getAlertDataByStorage();
      }
    },
    { immediate: true },
  );

  // object topology  > alert slide
  const { fetchFlag: objectTopologyFlag } = storeToRefs(objectTopologyAlertStore);
  watch(
    () => [objectTopologyFlag.value, props.isShow],
    async () => {
      if (props.type === USE_SLIDE_VIEW.OBJECT_TOPOLOGY && props.isShow) {
        getAlertDataByStorage();
      }
    },
    { immediate: true },
  );

  // cluster topology  > alert slide
  const { fetchFlag: clusterTopologyFlag } = storeToRefs(clusterTopologyAlertStore);
  watch(
    () => [clusterTopologyFlag.value, props.isShow],
    async () => {
      if (props.type === USE_SLIDE_VIEW.CLUSTER_TOPOLOGY && props.isShow) {
        getAlertDataByStorage();
      }
    },
    { immediate: true },
  );

  watch(
    () => props.isShow,
    async (isShow) => {
      if (!isShow) {
        selectedRuleCardId.value = '';
        initData();
        onClickRule(null);
      }
      if (
        [USE_SLIDE_VIEW.DASHBOARD, USE_SLIDE_VIEW.BUSINESS, USE_SLIDE_VIEW.NETWORK_OBJECT].includes(
          props.type,
        )
      ) {
        if (isShow) {
          repeatGetAlertDataByStorage();
        } else {
          clearGetAlertDataByStorage();
        }
      }
    },
    { immediate: true },
  );
  watch(
    (): [string, DetailItemInfo[]] => [selectedRuleCardId.value, ruleList.value],
    ([cardId, list]) => {
      if (!list?.length || !list?.find((item) => item.cardId === cardId)) {
        showDetail(false);
      }
    },
  );
  return {
    isShowAlertDetail,
    selectedGroupId,
    isFetchingGroupList,
    groupList,
    selectedRuleCardId,
    ruleList,
    onClickGroup,
    onClickRule,
    onClickClear,
    onReFetch,
    t,
  };
};

export { POPUP_MIN_SIZE, setup };
