<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import { DetailInfoData, DetailInfoRenderType } from '@/common/utils/types';
import BaseGridRatioBarCell from '@/common/components/molecules/BaseGridRatioBarCell.vue';
import { useInternational } from '@/common/locale';
import SetupDashBoardPopup from '@/common/components/organisms/setupDashBoardPopup/SetupDashBoardPopup.vue';
import DashBoardListPopup from '@/common/components/organisms/dashBoardListPopup/DashBoardListPopup.vue';
import { useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { DashboardKey, useDashboardJump } from '@/common/uses/useDashboardJump';
import lightImg from '@/common/assets/icon-setting-fill-dark.svg';
import darkImg from '@/common/assets/icon-setting-fill.svg';
import { getCurrentTheme } from '@/common/utils/commonUtils';
import BaseCustomTooltip from '@/common/components/molecules/baseCustomTooltip/BaseCustomTooltip.vue';

interface Props {
  envKey?: string;
  title?: string;
  infoDataList?: DetailInfoData[];
  useTooltip?: {
    label: boolean;
  };
  keyInfo?: DashboardKey;
  isShow?: boolean;
  isNamespace?: boolean;
  useExpand?: boolean;
  useHeader?: boolean;
  useDashboardSetting?: boolean;
}
interface Emit {
  (
    e: 'click-link',
    value: { field: string; value: string; namespace: string; lists: DetailInfoData[] },
  ): void;
}

const props = withDefaults(defineProps<Props>(), {
  envKey: '',
  title: '',
  infoDataList: () => [],
  useTooltip: () => ({
    label: false,
  }),
  keyInfo: () => ({
    target: '',
    targetId: '',
    namespace: '',
  }),
  useHeader: true,
  useDashboardSetting: true,
});

const emit = defineEmits<Emit>();

const isExpand = ref<boolean>(true);

const toggleExpand = () => {
  isExpand.value = !isExpand.value;
};

const getValueContainerClass = (renderType: DetailInfoRenderType): string => {
  const defaultClass = 'information-row__value';
  const isFullType = renderType === 'custom' || renderType === 'grid';
  const fullTypeClass = isFullType ? ` ${defaultClass}--full` : '';
  const renderTypeClass =
    renderType && renderType !== 'link' ? ` ${defaultClass}--${renderType}` : '';

  return `${defaultClass}${fullTypeClass}${renderTypeClass}`;
};

const getNamespaceValue = computed<string>(
  () => props.infoDataList.find(({ field }) => field === 'namespace')?.values[0] ?? '',
);

const isValid = (value: string) => value && value !== '-';

const infoDataListExceptHide = computed(() =>
  (props?.infoDataList ?? []).filter((info) => !info.hide),
);

const theme = getCurrentTheme();
const imgSrc = computed(() => (theme === 'light' ? lightImg : darkImg));

const { fetchDashboardPopupEnvInfoForKey } = useDashboardPopupEnvStore();
const { t } = useInternational();

const {
  isOpenDashboardSettingPopup,
  isOpenDashboardListPopup,
  dashboardPopupListCnt,
  dashboardName,
  dashboardPopupList,
  moveDashboard,
  viewDashboardList,
  dashboardTooltipRef,
  onShowDashboardTooltip,
  onHideDashboardTooltip,
  triggerButtonRef,
  popupAreaRef,
} = useDashboardJump();
const onClickSetting = () => {
  isOpenDashboardSettingPopup.value = true;
  if (isOpenDashboardListPopup.value) {
    isOpenDashboardListPopup.value = false;
  }
};
const setupDashboardPopupClose = async () => {
  isOpenDashboardListPopup.value = false;
  await fetchDashboardPopupEnvInfoForKey(props.envKey);
};

watch(
  () => props.isShow,
  (val) => {
    if (!val) {
      isOpenDashboardSettingPopup.value = false;
    }
  },
);
</script>

<template>
  <div :class="['detail-information', { expanded: isExpand }]">
    <div
      v-if="props.useHeader"
      class="detail-information__header"
    >
      <ev-icon
        v-if="useExpand"
        :icon="isExpand ? 'icon-arrow-up' : 'icon-arrow-down'"
        class="detail-information__header-expand-icon"
        size="medium"
        @click="toggleExpand"
      />
      <h3
        v-if="props.title"
        class="detail-information__header-title"
      >
        {{ props.title }}

        <slot name="header-icon-suffix" />
      </h3>
      <div
        v-if="$slots['header-action']"
        class="detail-information__header-action"
      >
        <slot name="header-action" />
      </div>
      <div
        v-if="$slots['header-help-tooltip']"
        class="detail-information__header-help-tooltip"
      >
        <slot name="header-help-tooltip" />
      </div>
      <div
        v-if="$slots['header-text']"
        class="detail-information__header-text"
      >
        <slot name="header-text" />
      </div>
    </div>

    <div
      v-if="isExpand"
      class="detail-information__content"
    >
      <slot name="content" />
      <template
        v-for="data in infoDataListExceptHide"
        :key="data.field"
      >
        <dl
          v-if="data.renderType !== 'customRow'"
          class="information-row"
        >
          <dt
            :title="props.useTooltip.label ? data.label : ''"
            class="information-row__label"
          >
            <slot
              v-if="data.useIconInLabel"
              :name="data.field"
              :data="data"
              :label="data.label"
            />
            <span v-else> {{ data.label }}</span>
          </dt>
          <dd :class="getValueContainerClass(data.renderType!)">
            <slot
              v-if="data.renderType === 'grid' || data.renderType === 'grid-fill'"
              :name="data.field"
              :data="data"
            />
            <template v-else>
              <div
                v-for="(value, index) in data.values"
                :key="`${data.field}-val-${index}`"
                class="information-row__value--value"
              >
                <span
                  v-if="data.renderType === 'link' && isValid(value)"
                  class="information-row__value--link"
                  @click="
                    () =>
                      emit('click-link', {
                        field: data.field,
                        value,
                        namespace: getNamespaceValue,
                        lists: props.infoDataList,
                      })
                  "
                >
                  {{ value }}
                  <slot
                    v-if="value"
                    name="link-suffix"
                  />
                </span>
                <div
                  v-else-if="
                    (data.field === 'name' ||
                      data.field === 'instanceName' ||
                      (data.field === 'namespace' && props.isNamespace)) &&
                    data.values[0] &&
                    props.keyInfo.targetId
                  "
                  class="detail-name"
                >
                  <slot
                    v-if="data.field === 'instanceName'"
                    :name="data.field"
                    :data="data"
                  />
                  <span
                    v-else
                    :title="value"
                  >
                    {{ value }}
                  </span>
                  <div class="detail-name__btn-area">
                    <tag-item
                      ref="triggerButtonRef"
                      html-type="button"
                      icon="icon-arrow-right"
                      size="small"
                      :class="['border-button', { primary: dashboardPopupListCnt > 1 }]"
                      :disabled="dashboardPopupListCnt === 0"
                      @click="(event) => viewDashboardList(event, infoDataList, props.keyInfo)"
                      @mouseenter="(e) => onShowDashboardTooltip(e)"
                      @mouseleave="(e) => onHideDashboardTooltip(e)"
                    >
                      {{ dashboardName === '' ? t('WORD.VIEW_DETAIL') : dashboardName }}
                    </tag-item>
                    <div class="dashboard-list-popup-wrapper">
                      <dash-board-list-popup
                        v-if="isOpenDashboardListPopup"
                        ref="popupAreaRef"
                        :dashboard-list="dashboardPopupList"
                        :info-data-list="props.infoDataList"
                        :move-dashboard="moveDashboard"
                        :key-info="props.keyInfo"
                      />
                    </div>
                  </div>
                  <div class="detail-name__setting-button">
                    <img
                      :src="imgSrc"
                      width="16"
                      height="16"
                      :title="t('WORD.SETUP_DETAIL')"
                      alt="clickIcon"
                      @click="onClickSetting"
                    />
                  </div>
                </div>
                <status-tag
                  v-else-if="data.renderType === 'status' && value"
                  :type="value"
                >
                  {{ value }}
                </status-tag>
                <tag-item
                  v-else-if="data.renderType === 'label' && value"
                  size="xsmall"
                >
                  {{ value }}
                </tag-item>
                <base-grid-ratio-bar-cell
                  v-else-if="data.renderType === 'ratio'"
                  :ratio="+value"
                  has-unit
                />
                <slot
                  v-else-if="data.renderType === 'customValue'"
                  :name="data.field"
                  :data="data"
                  :value="value"
                />
                <span v-else>
                  {{ value }}
                </span>
              </div>
            </template>
          </dd>
        </dl>
        <slot
          v-else
          :name="data.field"
          :data="data"
        />
      </template>
    </div>
    <setup-dash-board-popup
      v-if="props.useDashboardSetting"
      v-model:is-show="isOpenDashboardSettingPopup"
      :env-key="envKey"
      @close="setupDashboardPopupClose"
    />
    <base-custom-tooltip
      ref="dashboardTooltipRef"
      custom-class="dashboard-msg-tooltip"
      use-deselect
      use-hover-event
    >
      <p>{{ t('MESSAGE.NO_PAGE_DASHBOARD') }}</p>
    </base-custom-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.detail-information {
  &__header {
    display: flex;
    position: relative;
    gap: 6px;
    align-items: center;

    &-action {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    &-expand-icon {
      cursor: pointer;
    }

    &-text {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  &:not(.expanded) {
    border-bottom: none;
  }
}
.information-row__value--value {
  width: 100% !important;
  .detail-name {
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 100%;
    &__btn-area {
      position: relative;
      height: 20px !important;
      cursor: pointer;
      white-space: nowrap;
    }
    .dashboard-list-popup-wrapper {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
    }
    &__button {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      justify-content: center;
      height: 20px !important;
      cursor: pointer;
    }
    &__setting-button {
      height: 16px;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.information-row__value {
  .detail-name {
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
