import { Props as BaseChipsCellProps } from '@/common/components/molecules/baseGridChipsCell/baseGridChipsCell.setup';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

export interface Props extends BaseChipsCellProps {
  useWheelEvent?: boolean;
}

export const setup = (props: Props) => {
  const allChips = computed<string[]>(() =>
    props.isMultipleValueChip ? Object.keys(props.chips) : (props.chips as string[]),
  );

  const tooltipRef = ref();
  const onClickCount = (e) => {
    tooltipRef.value.show(e);
  };

  const wheelEventHandler = () => {
    tooltipRef.value.hide();
  };
  onMounted(() => {
    if (props.useWheelEvent) {
      window.addEventListener('wheel', wheelEventHandler);
    }
  });

  onBeforeUnmount(() => {
    if (props.useWheelEvent) {
      window.removeEventListener('wheel', wheelEventHandler);
    }
  });

  return {
    allChips,
    tooltipRef,
    onClickCount,
  };
};
