export const RSA_ID_HEADER = 'RSA-ID';

export const useApiRsa = (() => {
  const apiRsaId: Record<string, string> = {};

  const setRsaId = ({ key, rsaId }: { key: string; rsaId: string }) => {
    apiRsaId[key] = rsaId;
  };

  const getRsaId = ({ key }: { key: string }) => {
    return apiRsaId[key];
  };

  // POST, PUT, PATCH만 지원
  const handleApiRsaId = <Fn extends (p: any) => any>({
    fn,
    params,
    rsaId,
    key,
  }: {
    fn: Fn;
    params: Parameters<Fn>[0];
    rsaId?: string;
    key?: string;
  }): ReturnType<Fn> => {
    if (rsaId) {
      const fnKey = key ?? fn.name; // unique
      setRsaId({
        key: fnKey,
        rsaId,
      });

      params.request = {
        ...params.request,
        [RSA_ID_HEADER]: fnKey,
      };
    }

    return fn(params);
  };

  return () => ({
    setRsaId,
    getRsaId,
    handleApiRsaId,
  });
})();
