const MAIN_FRAMES = {
  QUERY_MANAGER: 'Query Manager',
  QUERY_MANAGER_HISTORY: 'Query Manager History',

  PA_ALERT_HISTORY: 'PA - Alert History',
  PA_DB_INFO: 'PA - DB Info',
  PA_EXCEPTION_SUMMARY: 'PA - Exception Summary',
  PA_PARAMETER_HISTORY: 'PA - Parameter History',
  PA_PLAN_ANALYSIS: 'PA - Plan Analysis',
  PA_SEARCH_SESSION: 'PA - Search Session',
  PA_SEARCH_SQL: 'PA - Search SQL',
  PA_SQL_SUMMARY: 'PA - SQL Summary',
  PA_TOP_N_ANALYSIS: 'PA - Top N Analysis',
  PA_TABLESPACE: 'PA - Tablespace',
  PA_TRANSACTION_TREND: 'PA - Transaction Trend',
  PA_TREND_ANALYSIS: 'PA - Trend Analysis',
  PA_UNDO_TEMP: 'PA - Undo & Temp',
  PA_WAIT_SUMMARY: 'PA - Wait Summary',
} as const;

export const PA_FRAME_NAMES = {
  QUERY_MANAGER: {
    TABLE_REFERENCE: `${MAIN_FRAMES.QUERY_MANAGER}/Table Reference`,
    CREATOR_INFO: `${MAIN_FRAMES.QUERY_MANAGER}/Creator Info`,
    MODIFIER_INFO: `${MAIN_FRAMES.QUERY_MANAGER}/Modifier Info`,
    RUN_QUERY: `${MAIN_FRAMES.QUERY_MANAGER}/Run Query`,
    VIEW_PLAN: `${MAIN_FRAMES.QUERY_MANAGER}/View Plan`,
  },
  QUERY_MANAGER_HISTORY: {
    HISTORY_LIST: `${MAIN_FRAMES.QUERY_MANAGER_HISTORY}/History List`,
    HISTORY_DETAIL: `${MAIN_FRAMES.QUERY_MANAGER_HISTORY}/History Detail`,
  },

  PA_DB_INFO: {
    DATABASE_SIZE_INFO: `${MAIN_FRAMES.PA_DB_INFO}/Database Size Info`,
    DATABASE_FILE_INFO: `${MAIN_FRAMES.PA_DB_INFO}/Database File Info`,
    DATABASE_INO: `${MAIN_FRAMES.PA_DB_INFO}/DATABASE Info`,
    FILE_TREND_DAY: `${MAIN_FRAMES.PA_DB_INFO}/File Trend - Day`,
    FILE_TREND_TIME: `${MAIN_FRAMES.PA_DB_INFO}/File Trend - Time`,
    TOP_TABLE: `${MAIN_FRAMES.PA_DB_INFO}/Top Table`,
  },
  PA_EXCEPTION_SUMMARY: {
    TOTAL_COUNT_CHART: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/Total Exception/Transaction Count - Chart`,
    TOTAL_COUNT_LIST: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/Total Exception/Transaction Count - List`,
    RATIO: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/ Exception/Transaction Ratio`,
    EXCEPTION_LIST: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/Exception List`,
  },
  PA_SQL_SUMMARY: {
    STAT_RATIO: `${MAIN_FRAMES.PA_SQL_SUMMARY}/Stat Ratio - Summary`,
    TOP_SQL: `${MAIN_FRAMES.PA_SQL_SUMMARY}/Top SQL`,
  },
  PA_TOP_N_ANALYSIS: {
    VALUE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Value - Trend`,
    VALUE_TOP: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Value - Top`,
    SCHEME: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Scheme`,
    MODULE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Module`,
    TOP_SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top SQL`,
    TOP_SCHEMA: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top Schema`,
    TOP_USER: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top User`,
    TOP_PROCEDURE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top Procedure`,
    TREND_TOTAL_SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - Total SQL`,
    TREND_SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - SQL`,
    TREND_TOTAL_PROCEDURE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - Total Procedure`,
    TREND_PROCEDURE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - Procedure`,
    SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/SQL`,
    FULL_TEXT: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Full Text`,
  },
  PA_PLAN_ANALYSIS: {
    PLAN_ANALYSIS_LIST: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Plan Analysis List`,
    TREND: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Trend`,
    SQL_LIST: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/SQL List`,
    PLAN: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Plan`,
    PLAN_HASH_LIST: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Plan Hash List`,
    REASON: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Reason`,
    DOWNLOAD: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/[Download] Plan XML`,
  },
  PA_SEARCH_SQL: {
    SEARCH_SQL_LIST: `${MAIN_FRAMES.PA_SEARCH_SQL}/Search SQL List`,
    TREND: `${MAIN_FRAMES.PA_SEARCH_SQL}/Trend`,
    FULL_TEXT: `${MAIN_FRAMES.PA_SEARCH_SQL}/Full Text`,
    HISTORY: `${MAIN_FRAMES.PA_SEARCH_SQL}/History`,
    PLAN: `${MAIN_FRAMES.PA_SEARCH_SQL}/Plan`,
  },
  PA_SEARCH_SESSION: {
    SEARCH_SESSION_LIST: `${MAIN_FRAMES.PA_SEARCH_SESSION}/Search Session List`,
  },
  PA_TABLESPACE: {
    ASM_DAY: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Day`,
    ASM_DAY_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Day,Partial`,
    ASM_TIME: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Time`,
    ASM_TIME_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Time,Partial`,
    ASM_GROUP: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Group`,
    ASM_SIZE_FORECAST: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Size Forecast`,
    TABLESPACE_DAY: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - Day`,
    TABLESPACE_DAY_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - Day,Partial`,
    TABLESPACE_TIME: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - Time`,
    TABLESPACE_TIME_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - TIme,Partial`,
    TOP_100_TABLESPACE: `${MAIN_FRAMES.PA_TABLESPACE}/Top 100 Tablespace`,
    TOP_100_SEGMENT: `${MAIN_FRAMES.PA_TABLESPACE}/Top 100 Segment`,
  },
  PA_TRANSACTION_TREND: {
    RESPONSE_TIME_CHART: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Response Time Chart`,
    TRANSACTION_SUMMARY: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Transaction Summary`,
    TRANSACTION_LIST: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Transaction List`,
    TRANSACTION_LIST_COUNT: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Transaction List - Count`,
  },
  PA_WAIT_SUMMARY: {
    WAIT_CLASS: `${MAIN_FRAMES.PA_WAIT_SUMMARY}/Wait Class`,
    WAIT_CLASS_EVENT: `${MAIN_FRAMES.PA_WAIT_SUMMARY}/Wait Class & Event`,
    WAIT_SQL_LIST: `${MAIN_FRAMES.PA_WAIT_SUMMARY}/Wait SQL List`,
  },
  PA_UNDO_TEMP: {
    UNDO_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Undo > Used Size`,
    UNDO_AVG_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Undo > Avg Used Size`,
    UNDO_SESSION_LIST: `${MAIN_FRAMES.PA_UNDO_TEMP}/Undo > Session List`,
    TEMP_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Temp > Used Size`,
    TEMP_AVG_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Temp > Avg Used Size`,
    TEMP_SESSION_LIST: `${MAIN_FRAMES.PA_UNDO_TEMP}/Temp > Session List`,
  },
  PA_TREND_ANALYSIS: {
    ACTIVE_TRANSACTION: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Transaction`,
    ACTIVE_SESSION_SECONDS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Session Sec Times`,
    ACTIVE_SESSION: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Session`,
    ACTIVE_SESSION_SUM: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Session (SUM)`,
    APPLICATION_RATIO: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Application Ratio`,
    ALERT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Alert`,
    BACKENDS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Backends`,
    BLOCKED: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Blocked`,
    DB_METRIC: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/DB Metric`,
    CHECK_POOL_ACTIVE: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Check Pool Active`,
    LOCK_TREE: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Lock Tree`,
    META_LOCK_TREE: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Meta Lock Tree`,
    LONG_ACTIVE_BACKENDS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Long Active Backends`,
    LONG_ACTIVE_THREAD: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Long Active Thread`,
    SESSION: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Session`,
    SQL_SUMMARY: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/SQL Summary`,
    LONG_SESSION_QUERY: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Long Session Query`,
    SQL_STAT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/SQL Stat`,
    TREND: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Trend `,
    TREND_POOL: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Trend Pool`,
    METRIC_LIST: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Metric List `,
    THREADS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Threads`,
    TOP_EVENT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Top Event`,
    SCAN_RATIO: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Scan Ratio`,
    TOP_OBJECT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Top Object`,
    TOP_SQL: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Top SQL`,
    TIME_MODEL: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Time Model`,
    TIME_RANGE_BAR: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Time Range Bar`,
    WAIT_LIST: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait > List`,
    WAIT_CHART: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait > Chart`,
    WAIT_CHAINS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait Chains`,
    WAIT_CLASS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait Class`,
    WAIT_EVENT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait Event`,
    USER_RATIO: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/User Ratio`,
    USER_CONNECTION_INFO: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/User Connection Info`,
    FULL_TEXT: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Full Text`,
    SLOW_QUERY: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Slow Query`,
    VACUUM: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Vacuum`,
  },
  PA_ALERT_HISTORY: {
    ALERT_CHART: `${MAIN_FRAMES.PA_ALERT_HISTORY}/Alert - Chart`,
    ALERT_LIST: `${MAIN_FRAMES.PA_ALERT_HISTORY}/Alert - List`,
    TOP_ALERT: `${MAIN_FRAMES.PA_ALERT_HISTORY}/Top Alert`,
  },
  PA_PARAMETER_HISTORY: {
    PARAMETER_LIST: `${MAIN_FRAMES.PA_PARAMETER_HISTORY}/Parameter List`,
  },
} as const;
