import { computed } from 'vue';
import { MenuInfo } from '@/common/components/organisms/baseMenu/types';
import router from '@/common/router';

export interface Props {
  dashboardMenu: MenuInfo;
  rootMenu: string;
  menuType: 'dashboard' | 'analysisDashboard';
  clicked?: boolean;
}

export interface Emit {
  (e: 'hover', event?: MouseEvent, menuInfo?: MenuInfo): void;
  (e: 'click', event?: MouseEvent, menuInfo?: MenuInfo): void;
}

export const setup = (props: Props, emit: Emit) => {
  const childLength = computed<number>(() => props.dashboardMenu.subMenuList?.length ?? 0);
  const isCurrentPath = computed<boolean>(() => {
    const { rootMenu, menuType } = router.currentRoute.value.query;
    return props.rootMenu === rootMenu && props.menuType === menuType;
  });

  const onHover = (e?: MouseEvent) => {
    emit('hover', e, props.dashboardMenu);
  };

  const onClick = (e?: MouseEvent) => {
    emit('click', e, props.dashboardMenu);
  };

  return {
    childLength,
    isCurrentPath,

    onHover,
    onClick,
  };
};
