const MAIN_FRAMES = {
  CONFIG_MY_INFO: 'Config - My Info',
  CONFIG_USER_GROUP: 'Config - User Group',
  CONFIG_USER_GROUP_WINDOW: 'Config - User Group Setting Window',
  CONFIG_USER_WINDOW: 'Config - User Setting Window',
  CONFIG_POLICY: 'Config - Policy',
  CONFIG_ROLE_PERMISSION: 'Config - Permission > Role',
  CONFIG_TARGET_PERMISSION: 'Config - Target Permission',
} as const;

export const CONFIG_ACCOUNT_FRAME_NAMES = {
  CONFIG_MY_INFO: {
    INFORMATION: `${MAIN_FRAMES.CONFIG_MY_INFO}/Information`,
    PATCH: `${MAIN_FRAMES.CONFIG_MY_INFO}/Patch`,
    SAVE: `${MAIN_FRAMES.CONFIG_MY_INFO}/[Save] information`,
    ACCESS_TOKEN_LIST: `${MAIN_FRAMES.CONFIG_MY_INFO}/Access Token List`,
  },

  // 사용자
  CONFIG_USER_GROUP: {
    USER_GROUP_LIST: `${MAIN_FRAMES.CONFIG_USER_GROUP}/User Group List`,
    USER_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Delete] User Group`,
    USER_LIST: `${MAIN_FRAMES.CONFIG_USER_GROUP}/User List`,
    USER_DELETE: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Delete] User`,
    USER_LOCK: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Lock] User`,
    USER_ALERT_ENABLED: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Enabled] User Alert`,
  },
  CONFIG_USER_GROUP_WINDOW: {
    INFORMATION: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/Information`,
    USER_LIST_ALL: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/User List - All`,
    GROUP_EDIT: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/[Edit] GROUP`,
    GROUP_SAVE: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/[Save] GROUP`,
  },
  CONFIG_USER_WINDOW: {
    PERMISSION: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/Permission`,
    PERMISSION_SET: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/[Set] Permission`,
    INFORMATION_SAVE: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/[Save] Information`,
    USER_SAVE: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/[Save] User`,
  },

  CONFIG_POLICY: {
    PASSWORD_POLICY: `${MAIN_FRAMES.CONFIG_POLICY}/Password Policy`,
    PASSWORD_POLICY_SAVE: `${MAIN_FRAMES.CONFIG_POLICY}/[Save] Password Policy`,
  },

  // 권한
  CONFIG_ROLE_PERMISSION: {
    USER_ROLE_LIST: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/User Role List`,
    USER_ROLE_LIST_DELETE: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/[Delete] User Role List`,
    USER_ROLE_LIST_SAVE: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/[Save] User Role List`,
    PERMISSION_LIST: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/Permission List`,
    PERMISSION_DEFAULT_LIST: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/Permission Default List`,
    GLOBAL_PERMISSION_LIST: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/Global Permission List`,
  },
  CONFIG_TARGET_PERMISSION: {
    GROUP_TARGET: `${MAIN_FRAMES.CONFIG_TARGET_PERMISSION}/GROUP_TARGET`,
    PATCH_PERMISSION: `${MAIN_FRAMES.CONFIG_TARGET_PERMISSION}/PATCH_PERMISSION`,
  },
} as const;
