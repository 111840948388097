import {
  AddedKeyword,
  FilteredKeyword,
  Keyword,
} from '@/common/components/molecules/keywordSettingModal/keywordSettingModal.types';
import { escapeLtGt } from '@/common/utils/string.utils';

export const wrapText = (text: string, keyword: Keyword) =>
  `<mark class="log-highlight-keyword" style="--keyword-bg-color: ${
    keyword.bgColor
  }; --keyword-line-color: ${keyword.lineColor ?? 'transparent'};">${text}</mark>`;

export const highlightLog = (
  log: string,
  {
    configKeywordList,
    userKeywordList,
  }: { configKeywordList?: FilteredKeyword[]; userKeywordList?: AddedKeyword[] },
) => {
  /**
   * 같은 keyword라면, configKeywordList가 우선순위를 가짐
   */
  const keywords = [...(configKeywordList ?? []), ...(userKeywordList ?? [])].filter(
    (item) => item.keyword && ['string', 'regexp'].includes(item.type),
  );
  const result: string[] = [];

  let matchedInfo = {
    length: 0,
    keyword: {} as Keyword,
  };

  let substring = escapeLtGt(log);
  let pos = 0;
  while (pos < substring.length) {
    substring = substring.slice(pos + matchedInfo.length);
    pos = substring.length;
    // eslint-disable-next-line no-loop-func
    keywords.forEach((keyword) => {
      const flags = keyword.type === 'regexp' ? '' : 'i';

      const regExp =
        keyword.type === 'string'
          ? new RegExp(keyword.keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), flags)
          : new RegExp(keyword.keyword, flags);

      const match = regExp.exec(substring);
      if (!match) return;
      if (match.index < pos || (pos === match.index && match[0].length > matchedInfo.length)) {
        pos = match.index;
        matchedInfo = {
          length: match[0].length,
          keyword,
        };
      }
    });

    if (pos < substring.length) {
      result.push(substring.slice(0, pos));
      result.push(wrapText(substring.slice(pos, pos + matchedInfo.length), matchedInfo.keyword));
    } else {
      result.push(substring);
      pos = substring.length;
    }
  }

  return result.join('');
};
