<script setup lang="ts">
import { getVersion } from '@/common/utils/commonUtils';
import { clickoutside as vClickOutside } from '@/common/directives/clickoutside';
import ToggleButtonGroup from '@/common/components/molecules/toggle/ToggleButtonGroup.vue';
import StackSeparator from '@/common/components/layouts/StackSeparator.vue';
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import PopupListElement from '../popupListElement/PopupListElement.vue';
import MyInfoPopupProfileArea from './MyInfoPopupProfileArea.vue';
import MyInfoPopupGroupList from './MyInfoPopupGroupList.vue';
import { type Props, type Emit, setup } from './myInfoPopup.setup';
import PopupLayout from '../PopupLayout.vue';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  t,

  mainMenuRef,
  subMenuRef,
  subMenuPopupStyle,

  productName,
  userProfile,
  languageInfo,
  themeInfo,
  groupItems,
  selectedGroup,

  isGroupListOpened,

  POPUP_LIST_BUTTONS,
  LANGUAGE_OPTIONS,
  THEME_OPTIONS,

  onClickOutside,
  onChangeGroup,
  onChangeLanguage,
  onChangeTheme,
  onLeavePopup,
} = setup(props, emit);
</script>

<template>
  <popup-layout
    ref="mainMenuRef"
    v-click-outside="onClickOutside"
    class="my-info-popup"
    @mouseleave="onLeavePopup"
  >
    <template #header>
      <my-info-popup-profile-area :user-profile="userProfile" />
    </template>
    <template #list>
      <popup-list-element
        v-for="buttonItem in POPUP_LIST_BUTTONS"
        :key="buttonItem.key"
        :icon="buttonItem.icon"
        :text="t(buttonItem.textKey)"
        :selected="buttonItem.key === 'group' && isGroupListOpened"
        :disabled="buttonItem.disabled"
        @click="buttonItem.onClick"
      >
        <template v-if="buttonItem.key === 'language'">
          <toggle-button-group
            :model-value="languageInfo"
            type="round"
            :options="LANGUAGE_OPTIONS"
            @update:model-value="onChangeLanguage"
          />
        </template>
        <template v-if="buttonItem.key === 'theme'">
          <toggle-button-group
            :model-value="themeInfo"
            type="round"
            :options="THEME_OPTIONS"
            @update:model-value="onChangeTheme"
          />
        </template>
        <template v-if="buttonItem.key === 'group'">
          <span class="my-info-popup__group-name">
            {{ selectedGroup?.name ?? '' }}
          </span>
          <ev-icon
            v-if="!buttonItem.disabled"
            icon="icon-arrow-right"
          />
        </template>
      </popup-list-element>
      <stack-separator />
      <popup-list-element
        class="module-version"
        icon="icon-popup-info"
        :text="t('WORD.GNB.HELP.MODULE_VERSION')"
      >
        <badge-item
          size="xsmall"
          html-type="div"
          class="version-badge"
        >
          <span> {{ productName }} {{ getVersion() }} </span>
        </badge-item>
      </popup-list-element>
    </template>
    <template #addition>
      <my-info-popup-group-list
        ref="subMenuRef"
        :class="[{ shown: isGroupListOpened }]"
        :group-list="groupItems"
        :selected-group="selectedGroup"
        :style="subMenuPopupStyle"
        @click-group-item="onChangeGroup"
      />
    </template>
  </popup-layout>
</template>

<style lang="scss">
.my-info-popup {
  --popup-wrapper-padding: calc(4px * 2);
  --popup-width: 256px;

  &__group-name {
    flex: 1;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-blue-05-05);
    text-align: right;

    @include shortening();

    + i {
      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;
    }
  }

  /* stylelint-disable */
  .base-menu-popup__bottom .popup-list-element__inner {
    .left-side {
      flex: none;
    }

    .right-side {
      flex: 1;
    }
  }
  /* stylelint-enable */

  .ev-radio-group {
    width: 72px;
    height: 18px;
  }

  .separator {
    margin: 4px 0;
    border-color: var(--color-gray-05-04);
  }

  .module-version {
    height: 34px;
  }
}
</style>
