import { RouteRecordRaw } from 'vue-router';
import { findPermissionByTranslateId } from '@/common/permission/permission.utils';
import { type Role, type RoleId, RoleIdMap } from './auth.defines';

export const convertToRoleId = (role: Role) => {
  return RoleIdMap[role];
};

export const checkRole = {
  isAdmin: (roleId: RoleId): boolean => roleId === RoleIdMap.Admin,
  isMaster: (roleId: RoleId): boolean => roleId === RoleIdMap.Master,
};

export const isRoleIdIncluded = (allowedRoles: Role[], roleId: RoleId) => {
  return allowedRoles.map(convertToRoleId).includes(roleId);
};

export const extractAccessibleRoutes = (
  routes: RouteRecordRaw[],
  roleId?: RoleId,
): RouteRecordRaw[] => {
  const filteredRoutes: RouteRecordRaw[] = [];

  routes.forEach((route) => {
    // 라우트의 meta.roles의 roleId가 유저의 roleId를 포함하는지 확인
    if (
      (!route.meta?.roles ||
        (roleId != null && route.meta.roles.map(convertToRoleId).includes(roleId))) &&
      ((findPermissionByTranslateId(route.meta?.rolePermission)?.menuOnOff ??
        findPermissionByTranslateId(route.meta?.rolePermission)?.listOnOff) ||
        findPermissionByTranslateId(route.meta?.rolePermission) == null)
    ) {
      const { children, ...restParams } = route;
      // 라우트에 children이 있는 경우 재귀적으로 함수 호출하여 확인
      if (children && children.length > 0) {
        const childAdminRoutes = extractAccessibleRoutes(children, roleId);
        // 추출된 하위 라우트가 있는 경우 filteredRoutes에 추가
        if (childAdminRoutes.length > 0) {
          filteredRoutes.push({
            ...restParams,
            children: childAdminRoutes,
          });
        }
      } else {
        filteredRoutes.push({ ...restParams, children: [] });
      }
    }
  });

  return filteredRoutes;
};
