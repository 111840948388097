import { computed, onBeforeMount, ref } from 'vue';
import { InfoItem } from '@/openapi/systemAlert/model';
import {
  ALERT_NOTIFICATION_TAB_PANELS,
  CHECK_BY_TARGET,
  FIXED_CONTENT,
} from '@/alert/utils/define';
import { useAlertNotificationStore } from '@/config/stores/alert-notification';
import { useInternational } from '@/common/locale';
import { getLocaleKey } from '@/common/utils/commonUtils';
import { RuleCriteria } from '@/alert/components/alertDetail/alertDetail.types';

export interface Props {
  ruleCriteria?: RuleCriteria;
  data?: InfoItem;
}
export const getInitReceiveInfo = ({ ruleCriteria }: { ruleCriteria?: RuleCriteria } = {}) => ({
  ids: [],
  writeContent: {
    title: '',
    content: '',
    fixedContent: FIXED_CONTENT[ruleCriteria || CHECK_BY_TARGET].trim(),
  },
});

export const setup = (props: Props) => {
  const { t } = useInternational();

  const { getUserNamesByIds, getNotificationNamesByIds, fetchAllNotificationInfo } =
    useAlertNotificationStore();
  const selectedTab = ref(ALERT_NOTIFICATION_TAB_PANELS[0].value);

  const LABEL_INFO = {
    email: t('WORD.RECEIVER'),
    slack: t('WORD.NOTIFIED_CHANNEL'),
    telegram: t('WORD.NOTIFIED_CHAT'),
    sms: t('WORD.RECEIVER'),
    webhook: t('WORD.NOTIFIED_WEBHOOK'),
    onSite: t('WORD.RECEIVER'),
  };

  const receiveItems = computed(() => {
    return {
      email: getUserNamesByIds(props.data?.email?.ids ?? []),
      slack: getNotificationNamesByIds('slack', props.data?.slack?.ids ?? []),
      telegram: getNotificationNamesByIds('telegram', props.data?.telegram?.ids ?? []),
      sms: getUserNamesByIds(props.data?.sms?.ids ?? []),
      webhook: getNotificationNamesByIds('webhook', props.data?.webhook?.ids ?? []),
      onSite: getUserNamesByIds(props.data?.onSite?.ids ?? []),
    };
  });
  const title = computed(() =>
    props.data?.groupId
      ? `${t('WORD.GROUP_NOTIFICATION')}: ${props.data.groupName ?? ''}`
      : t('WORD.NOTIFICATION'),
  );
  const tabPanels = ALERT_NOTIFICATION_TAB_PANELS.map(({ text, value, iconClass }) => ({
    text: t(`WORD.${getLocaleKey(text as string)}`),
    value,
    iconClass,
  }));

  // display 형태 서버타입(서버이름)
  const getDisplayServerType = (serverType?: string | null, serverName?: string | null) => {
    if (!serverType) {
      return '';
    }
    if (serverName) {
      return `${serverType} (${serverName})`;
    }
    return serverType;
  };

  onBeforeMount(() => {
    fetchAllNotificationInfo();
  });
  return {
    title,
    tabPanels,
    selectedTab,
    LABEL_INFO,
    receiveItems,
    getDisplayServerType,
    t,
  };
};
