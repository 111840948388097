export const CLOUD = {
  AWS: {
    EC2: {
      GRIDCOLUMNS_cloud_aws_ec2_instanceList: 'GRIDCOLUMNS_cloud_aws_ec2_instanceList',
      FILTER_cloud_aws_ec2: 'FILTER_cloud_aws_ec2',

      GRIDCOLUMNS_cloud_aws_detail_ec2_awsAlarm: 'GRIDCOLUMNS_cloud_aws_detail_ec2_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_ec2_awsAlarm: 'FILTERSEARCH_cloud_aws_detail_ec2_awsAlarm',
    },

    LAMBDA: {
      GRIDCOLUMNS_cloud_aws_lambda_functionList: 'GRIDCOLUMNS_cloud_aws_lambda_functionList',
      FILTER_cloud_aws_lambda: 'FILTER_cloud_aws_lambda',

      GRIDCOLUMNS_cloud_aws_detail_lambda_awsAlarm: 'GRIDCOLUMNS_cloud_aws_detail_lambda_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_lambda_awsAlarm:
        'FILTERSEARCH_cloud_aws_detail_lambda_awsAlarm',
    },

    S3: {
      GRIDCOLUMNS_cloud_aws_s3_bucketList: 'GRIDCOLUMNS_cloud_aws_s3_bucketList',
      FILTER_cloud_aws_s3: 'FILTER_cloud_aws_s3',

      GRIDCOLUMNS_cloud_aws_detail_s3_awsAlarm: 'GRIDCOLUMNS_cloud_aws_detail_s3_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_s3_awsAlarm: 'FILTERSEARCH_cloud_aws_detail_s3_awsAlarm',
    },

    DOCUMENTDB: {
      GRIDCOLUMNS_cloud_aws_documentDb_clusterList: 'GRIDCOLUMNS_cloud_aws_documentDb_clusterList',
      FILTER_cloud_aws_documentDb: 'FILTER_cloud_aws_documentDb',

      GRIDCOLUMNS_cloud_aws_detail_documentDb_awsAlarm:
        'GRIDCOLUMNS_cloud_aws_detail_documentDb_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_documentDb_awsAlarm:
        'FILTERSEARCH_cloud_aws_detail_documentDb_awsAlarm',
    },

    DYNAMODB: {
      GRIDCOLUMNS_cloud_aws_dynamoDb_tableList: 'GRIDCOLUMNS_cloud_aws_dynamoDb_tableList',
      FILTER_cloud_aws_dynamoDb: 'FILTER_cloud_aws_dynamoDb',

      GRIDCOLUMNS_cloud_aws_detail_dynamoDb_awsAlarm:
        'GRIDCOLUMNS_cloud_aws_detail_dynamoDb_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_dynamoDb_awsAlarm:
        'FILTERSEARCH_cloud_aws_detail_dynamoDb_awsAlarm',
    },

    ELASTICACHE: {
      GRIDCOLUMNS_cloud_aws_elasticache_cacheList: 'GRIDCOLUMNS_cloud_aws_elasticache_cacheList',
      FILTER_cloud_aws_elasticache: 'FILTER_cloud_aws_elasticache',

      GRIDCOLUMNS_cloud_aws_detail_elasticache_shard:
        'GRIDCOLUMNS_cloud_aws_detail_elasticache_shard',
      GRIDCOLUMNS_cloud_aws_detail_elasticache_node:
        'GRIDCOLUMNS_cloud_aws_detail_elasticache_node',
      GRIDCOLUMNS_cloud_aws_detail_elasticache_awsAlarm:
        'GRIDCOLUMNS_cloud_aws_detail_elasticache_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_elasticache_shard:
        'FILTERSEARCH_cloud_aws_detail_elasticache_shard',
      FILTERSEARCH_cloud_aws_detail_elasticache_node:
        'FILTERSEARCH_cloud_aws_detail_elasticache_node',
      FILTERSEARCH_cloud_aws_detail_elasticache_awsAlarm:
        'FILTERSEARCH_cloud_aws_detail_elasticache_awsAlarm',
    },

    RDS: {
      GRIDCOLUMNS_cloud_aws_rds_databaseList: 'GRIDCOLUMNS_cloud_aws_rds_databaseList',
      FILTER_cloud_aws_rds: 'FILTER_cloud_aws_rds',

      GRIDCOLUMNS_cloud_aws_detail_rds_awsAlarm: 'GRIDCOLUMNS_cloud_aws_detail_rds_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_rds_awsAlarm: 'FILTERSEARCH_cloud_aws_detail_rds_awsAlarm',
    },

    REDSHIFT: {
      GRIDCOLUMNS_cloud_aws_redshift_clusterList: 'GRIDCOLUMNS_cloud_aws_redshift_clusterList',
      FILTER_cloud_aws_redshift: 'FILTER_cloud_aws_redshift',

      GRIDCOLUMNS_cloud_aws_detail_redshift_awsAlarm:
        'GRIDCOLUMNS_cloud_aws_detail_redshift_awsAlarm',
      FILTERSEARCH_cloud_aws_detail_redshift_awsAlarm:
        'FILTERSEARCH_cloud_aws_detail_redshift_awsAlarm',
    },

    COST: {
      GRIDCOLUMNS_cloud_aws_costExplorer_instanceList:
        'GRIDCOLUMNS_cloud_aws_costExplorer_instanceList',
      FILTERSEARCH_cloud_aws_costExplorer_instanceList:
        'FILTERSEARCH_cloud_aws_costExplorer_instanceList',
      FILTER_cloud_aws_cost: 'FILTER_cloud_aws_cost',
    },
  },
};
