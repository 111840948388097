import { ref } from 'vue';
import { defineStore } from 'pinia';
import {
  getPermissionGroupRolesPermissionControllerAxios,
  getPermissionGroupRolesAuthListPermissionControllerAxios,
  getPermissionGroupRoleSettingPermissionControllerAxios,
} from '@/openapi/metaV6/api/permission-controller-api';
import { RoleGroupRolePermissionItems } from '@/openapi/metaV6/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export const useRolePermissionStore = defineStore('rolePermission', () => {
  const globalPermissionList = ref<RoleGroupRolePermissionItems>();

  const getUserRoleList = async (rolePermissionId?: string) => {
    try {
      const {
        data: { data = [] },
      } = await getPermissionGroupRolesPermissionControllerAxios({
        rolePermissionId,
        frameName: FRAME_NAMES.CONFIG_ROLE_PERMISSION.USER_ROLE_LIST,
      });

      return data;
    } catch (error) {
      console.log(error);
    }

    return [];
  };

  const getPermissionList = async (rolePermissionId: string) => {
    try {
      const {
        data: { data = [] },
      } = await getPermissionGroupRoleSettingPermissionControllerAxios({
        rolePermissionId,
        frameName: FRAME_NAMES.CONFIG_ROLE_PERMISSION.PERMISSION_LIST,
      });

      return data[0] || {};
    } catch (error) {
      console.log(error);
    }

    return {};
  };

  const getGlobalPermissionList = async (rolePermissionId: string) => {
    try {
      const {
        data: { data = [] },
      } = await getPermissionGroupRolesAuthListPermissionControllerAxios({
        rolePermissionId,
        frameName: FRAME_NAMES.CONFIG_ROLE_PERMISSION.GLOBAL_PERMISSION_LIST,
      });

      return data[0] || {};
    } catch (error) {
      console.log(error);
    }

    return {};
  };

  const setGlobalPermissionList = async (rolePermissionId: string) => {
    globalPermissionList.value = await getGlobalPermissionList(rolePermissionId);
  };

  return {
    globalPermissionList,
    getUserRoleList,
    getPermissionList,
    setGlobalPermissionList,
  };
});
