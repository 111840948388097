<script setup lang="ts">
import { type Props, type Emit, setup } from './popupListElement.setup';

const props = withDefaults(defineProps<Props>(), {
  htmlType: 'button',
});
const emit = defineEmits<Emit>();

const {
  isClickable: clickable,
  isHoverable: hoverable,

  onClick,
  onHover,
} = setup(props, emit);
</script>

<template>
  <li class="popup-list-element">
    <component
      :is="props.htmlType"
      :class="['popup-list-element__inner', { selected, clickable, hoverable }]"
      :type="props.htmlType === 'button' ? 'button' : undefined"
      @click="onClick"
      @mouseover="onHover"
    >
      <div class="left-side">
        <slot name="left-side">
          <ev-icon
            v-if="props.icon"
            :icon="props.icon"
          />
          <span>{{ props.text }}</span>
        </slot>
      </div>
      <div class="right-side">
        <slot />
      </div>
    </component>
  </li>
</template>

<style scoped lang="scss">
.popup-list-element {
  width: 100%;
  height: 32px;

  &__inner {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;

    &.selected {
      border-color: var(--color-blue-10-00);
      background-color: var(--color-blue-10-00);
    }

    /* stylelint-disable */
    &.hoverable,
    &.clickable {
      cursor: pointer;

      &:not(.clicked):hover {
        border-color: var(--color-blue-10-00);
        background-color: var(--color-blue-10-00);
      }
    }
    /* stylelint-enable */
  }

  .left-side {
    display: flex;
    flex: 1;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    height: 16px;
    overflow: hidden;

    i {
      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;
      background-color: var(--color-gray-02-10);
      mask-size: 16px;
      mask-repeat: no-repeat;
      mask-position: center;
    }

    span {
      font-size: 11px;
      font-weight: 500;
      line-height: 11px;
      color: var(--color-gray-00-12);
      text-align: start;

      @include shortening();
    }
  }

  .right-side {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }
}
</style>
