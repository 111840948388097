import { RuleCriteria } from '@/alert/components/alertDetail/alertDetail.types';
import { CHECK_BY_COMBINATION } from '@/alert/utils/define';
import AlertTreeGrid from '@/alert/components/alertGrid/AlertTreeGrid.vue';
import AlertGrid from '@/alert/components/alertGrid/AlertGrid.vue';
import { LinkedWindowType } from '@/alert/components/alertGrid/alertGrid.types';

export const getAlertGridComp = ({ ruleCriteria }: { ruleCriteria: RuleCriteria }) =>
  ruleCriteria === CHECK_BY_COMBINATION ? AlertTreeGrid : AlertGrid;

export const getRuleNameIcon = ({ ruleCriteria }: { ruleCriteria: RuleCriteria }) =>
  ruleCriteria === CHECK_BY_COMBINATION
    ? 'icon-alert-check-by-combination'
    : 'icon-alert-check-by-target';

export const checkModalLinked = (
  rowData: Record<string, any>,
  windowType: LinkedWindowType = 'text',
): boolean => {
  const { eventType } = rowData ?? {};

  switch (windowType) {
    case 'logging':
      return ['Logging'].includes(eventType ?? '');
    case 'text':
    default:
      return ['Kubernetes'].includes(eventType ?? '');
  }
};
