import { cloneDeep, isEqual, sortBy } from 'lodash-es';
import {
  FILTER_SEARCH_OPERATORS,
  LOGICAL_OPERATORS,
  MAXIMUM_HISTORY_SIZE,
  TOKEN_STATUS,
} from '@/common/components/molecules/filterSearch/filterSearch.define';
import { ref, nextTick, onMounted, reactive, computed, watch, onBeforeMount } from 'vue';
import { isIncludeTextWithoutWhitespace } from '@/common/utils/commonUtils';
import {
  EditingFilterToken,
  FilterDropdownInfo,
  FilterDropdownInfoCategories,
  FilterDropdownItem,
  FilterHistory,
  FilterItem,
  FilterSearchOperator,
  FilterSelectedValues,
  FilterToken,
  FinishedFilterToken,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { useInternational } from '@/common/locale';
import { useUserEnvStore } from '@/common/stores/user-env';

export interface Props {
  storageKey: string;
  filterItems: FilterItem[];
  selectedValues?: FilterSelectedValues;
  placeholder?: string;
}

export interface Emit {
  (e: 'complete', result: FilterSelectedValues): void;
  (e: 'update:selectedValues', result: FilterSelectedValues): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const userEnvStore = useUserEnvStore();

  const filterItems = ref<FilterItem[]>(cloneDeep(props.filterItems ?? []));
  const tokenList = ref<FilterToken[]>([]);
  const lastToken = computed<FilterToken>(() => tokenList.value[tokenList.value.length - 1]);
  const editingToken = ref<EditingFilterToken | null>(null);
  const selectedLogicalOperator = ref('OR');

  const convertToFilterSelectedValues = (tokens: FilterToken[]): FilterSelectedValues => {
    const finishedTokenList = tokens.filter(
      ({ status }) => status === TOKEN_STATUS.VALUE_DONE,
    ) as FinishedFilterToken[];

    return {
      logicalOperator: selectedLogicalOperator.value,
      tokens: finishedTokenList?.map(({ key, operator, values }) => ({
        key: key.id,
        operator: operator.id,
        value: values?.map(({ id }) => id).join(','),
      })),
    };
  };

  /**
   * layout
   */
  const filterSearchRef = ref();
  const tokensMoreBtnWrapperRef = ref();
  const tokensWrapperRef = ref();
  const moreCount = ref(0);
  const isExpand = ref(false);
  const isFocus = ref(false);
  const mainInputRef = ref();
  const mainInputValue = ref('');

  const tokenRefs = ref<HTMLElement[]>([]);
  const setElementRef = (el, index: number) => {
    if (el instanceof HTMLElement) {
      tokenRefs.value[index] = el;
    }
  };

  const focusMainInput = async () => {
    isFocus.value = true;
    isExpand.value = true;
    await nextTick();
    mainInputRef.value?.focus();
  };

  const setMoreCount = async () => {
    moreCount.value = 0;

    if (
      !tokensMoreBtnWrapperRef.value ||
      !tokensWrapperRef.value ||
      !tokenRefs.value ||
      !tokenList.value.length
    ) {
      return;
    }

    await nextTick();

    const GAP_BETWEEN_TAG_ITEMS = 5;
    const MINIMUM_TEXT_FIELD_WIDTH = 50;
    const wrapperWidth = tokensMoreBtnWrapperRef.value.getBoundingClientRect().width;
    const wrapperWidthShowingMoreBtn = tokensWrapperRef.value.offsetWidth;
    let maximumWidth = wrapperWidth;
    let currentWidth = 0;

    currentWidth += MINIMUM_TEXT_FIELD_WIDTH;

    tokenRefs?.value?.forEach((item: HTMLElement) => {
      item.style.display = 'list-item';

      if (!isExpand.value) {
        const expectedWidth = currentWidth + (item?.offsetWidth ?? 0) + GAP_BETWEEN_TAG_ITEMS;
        if (expectedWidth < maximumWidth && !moreCount.value) {
          currentWidth = expectedWidth;
        } else {
          if (!moreCount.value) {
            maximumWidth = wrapperWidthShowingMoreBtn;
          }
          moreCount.value += 1;
          item.style.display = 'none';
        }
      }
    });
  };

  const expand = async () => {
    isExpand.value = true;
    await setMoreCount();
    await focusMainInput();
  };

  const onClickMoreCount = async () => {
    isExpand.value = !isExpand.value;
    await setMoreCount();
  };

  /**
   * Pin
   */
  const pinnedTokenList = ref<FilterSelectedValues | undefined>(undefined);

  const isPin = ref<boolean>(false);

  const saveFilterSelectedValues = async () => {
    await userEnvStore.setEnvValue([
      {
        key: `${props.storageKey}--tokenList`,
        value: JSON.stringify(convertToFilterSelectedValues(tokenList.value)),
      },
    ]);
  };

  const removeFilterSelectedValues = async () => {
    await userEnvStore.setEnvValue([
      {
        key: `${props.storageKey}--tokenList`,
        value: '',
      },
    ]);
  };

  const onClickPin = () => {
    isPin.value = !isPin.value;

    if (isPin.value) {
      saveFilterSelectedValues();
    } else {
      removeFilterSelectedValues();
    }
  };

  watch(
    tokenList,
    async () => {
      if (isPin.value) {
        await saveFilterSelectedValues();
      }
    },
    { deep: true },
  );

  /**
   * Dropdown List
   */
  const isShowDropdown = ref(false);
  const dropdownListInfo = reactive<FilterDropdownInfo>({
    multi: false,
    category: 'key',
    items: [],
    filteredItems: [],
    isEditing: false,
  });

  const dropdownListRef = ref();
  const filterMainRef = ref();
  const MARGIN_FROM_PARENT = 3;
  const dropdownListWrapperStyle = ref<{
    top: string;
    width: string;
    marginTop: string;
  }>({
    top: '',
    width: 'auto',
    marginTop: `${MARGIN_FROM_PARENT}px`,
  });

  watch(
    () => [isShowDropdown.value, tokenList.value?.length, dropdownListInfo.filteredItems?.length],
    async () => {
      const style = {
        top: '',
        width: 'auto',
        marginTop: `${MARGIN_FROM_PARENT}px`,
      };

      await nextTick();

      const parentEl = filterMainRef.value;
      if (parentEl && isShowDropdown.value) {
        const parentRect = parentEl.getBoundingClientRect();
        const documentEl = document.documentElement;

        style.width = `${parentRect.width - 5}px`;

        const distanceFromBottom =
          documentEl.scrollHeight + documentEl.scrollTop - parentRect.bottom;
        const listHeight = dropdownListRef.value?.clientHeight ?? 0;

        if (listHeight > distanceFromBottom) {
          style.top = `${parentRect.top - listHeight}px`;
          style.marginTop = `${-MARGIN_FROM_PARENT}px`;
        } else {
          style.top = `${parentRect.bottom}px`;
          style.marginTop = `${MARGIN_FROM_PARENT}px`;
        }
      }

      dropdownListWrapperStyle.value = style;
    },
  );

  const hoveredDropdownItemIndex = ref<number>(-1);

  const initHoveredDropdownItemIndex = () => {
    hoveredDropdownItemIndex.value = -1;
  };

  const showDropdownList = () => {
    isShowDropdown.value = true;
  };

  const hideDropdownList = () => {
    isShowDropdown.value = false;
    initHoveredDropdownItemIndex();
  };

  const isSelectedDropdownItem = ({ id }: FilterDropdownItem): boolean => {
    const { category } = dropdownListInfo;

    const targetToken: FilterToken = editingToken.value ?? lastToken.value;
    if (targetToken) {
      switch (category) {
        case 'operator':
          return targetToken.operator?.id === id;

        case 'value':
          return targetToken.values.findIndex((value) => value.id === id) > -1;

        case 'key':
        default:
          break;
      }
    }

    return false;
  };

  const setKeyDropdownList = () => {
    dropdownListInfo.category = 'key';
    dropdownListInfo.multi = false;

    dropdownListInfo.items =
      filterItems.value.map(({ key }) => ({
        id: key.id,
        name: key.name,
      })) ?? [];

    dropdownListInfo.filteredItems = dropdownListInfo.items.map((item) => ({
      ...item,
      checked: false,
    }));

    initHoveredDropdownItemIndex();
  };

  const setOperatorDropdownList = (keyId: string) => {
    dropdownListInfo.category = 'operator';
    dropdownListInfo.multi = false;

    dropdownListInfo.items =
      filterItems.value
        .find((item) => item.key.id === keyId)
        ?.operators?.filter((operator) =>
          FILTER_SEARCH_OPERATORS.includes(operator as FilterSearchOperator),
        )
        ?.map((operator: string) => ({
          id: operator,
          name: operator,
        })) ?? [];

    dropdownListInfo.filteredItems = dropdownListInfo.items.map((item) => ({
      ...item,
      checked: isSelectedDropdownItem(item),
    }));

    initHoveredDropdownItemIndex();
  };

  const setValueDropdownList = (
    keyId: string,
    filterFn?: (item: FilterDropdownItem) => boolean,
    sortFn?: (a: FilterDropdownItem, b: FilterDropdownItem) => number,
  ) => {
    dropdownListInfo.category = 'value';
    dropdownListInfo.multi = !!filterItems.value.find(({ key }) => key.id === keyId)?.values?.multi;

    dropdownListInfo.items =
      filterItems.value
        .find((item) => item.key.id === keyId)
        ?.values?.items?.map(({ id, name }) => ({ id, name })) ?? [];

    if (filterFn) {
      dropdownListInfo.items = dropdownListInfo.items.filter(filterFn);
    }

    dropdownListInfo.filteredItems = dropdownListInfo.items.map((item) => ({
      ...item,
      checked: isSelectedDropdownItem(item),
    }));

    if (sortFn) {
      dropdownListInfo.filteredItems.sort(sortFn);
    }
  };

  const setMultiSelectedValues = (
    targetToken: FilterToken,
    selectedDropdownItem: FilterDropdownItem,
  ) => {
    const index = targetToken.values.findIndex(({ id }) => id === selectedDropdownItem.id);
    if (index !== -1) {
      targetToken.values.splice(index, 1);
    } else {
      targetToken.values.push(selectedDropdownItem);
    }

    dropdownListInfo.filteredItems.forEach((listItem) => {
      listItem.checked = isSelectedDropdownItem(listItem);
    });
  };

  const filterByInput = (searchWord: string) => {
    dropdownListInfo.filteredItems = cloneDeep(dropdownListInfo.items)
      .filter(({ name }) => (searchWord ? isIncludeTextWithoutWhitespace(name, searchWord) : true))
      ?.map((item) => ({
        ...item,
        checked: isSelectedDropdownItem(item),
      }));
  };

  /**
   * History
   */
  const filterHistory = ref<FilterHistory[]>([]);

  const isShowFilterHistoryList = computed<boolean>(() => {
    const lastTokenStatus = lastToken.value?.status;
    return !!(
      filterHistory.value.length &&
      (!lastTokenStatus || lastTokenStatus === TOKEN_STATUS.VALUE_DONE) &&
      !editingToken.value
    );
  });

  const isDuplicatedTokenInHistory = ({ key, operator, values }: FilterToken): boolean => {
    return !!filterHistory.value.find(
      ({ token: historyToken }) =>
        historyToken.key.id === key.id &&
        historyToken?.operator?.id === operator?.id &&
        JSON.stringify(historyToken.values) === JSON.stringify(values),
    );
  };

  const saveHistory = async (targetToken: FilterToken) => {
    if (!targetToken) {
      return;
    }

    if (isDuplicatedTokenInHistory(targetToken)) {
      return;
    }

    if (filterHistory.value.length >= MAXIMUM_HISTORY_SIZE) {
      filterHistory.value.shift();
    }

    filterHistory.value.push({
      token: cloneDeep(targetToken),
      createdTime: Date.now(),
    });

    await userEnvStore.setEnvValue([
      { key: `${props.storageKey}--history`, value: JSON.stringify(filterHistory.value) },
    ]);
  };

  // const removeHistory = async () => {
  //   filterHistory.value = [];
  //   await userEnvStore.setEnvValue([{ key: `${props.storageKey}--history`, value: '' }]);
  // };

  /**
   * User Env
   */
  const loadEnvData = async () => {
    const savedPinnedTokenList = await userEnvStore.getEnvValue(`${props.storageKey}--tokenList`);
    if (savedPinnedTokenList) {
      pinnedTokenList.value = savedPinnedTokenList;
    }
    isPin.value = (pinnedTokenList.value?.tokens?.length ?? -1) > 0;

    const savedFilterHistory = await userEnvStore.getEnvValue(`${props.storageKey}--history`);
    if (savedFilterHistory) {
      filterHistory.value = savedFilterHistory;
    }
  };

  /**
   * emit
   */
  const emitComplete = () => {
    const result = convertToFilterSelectedValues(tokenList.value);
    emit('complete', result);
    emit('update:selectedValues', result);
  };

  watch(isShowDropdown, (showList) => {
    if (!showList && tokenList.value?.length) {
      emitComplete();
    }
  });

  watch(
    () => lastToken?.value?.status,
    async (value) => {
      if (value === TOKEN_STATUS.VALUE_DONE && lastToken?.value?.values?.length) {
        await saveHistory(lastToken!.value!);
        emitComplete();
      }
    },
  );

  /**
   * Logical Operator
   */
  const logicalOperatorListItems = [
    {
      name: t('WORD.SEARCH_BY'),
      value: t('WORD.SEARCH_BY'),
      disabled: true,
    },
    ...LOGICAL_OPERATORS,
  ];

  const onChangeLogicalOperator = async () => {
    if (isPin.value) {
      await saveFilterSelectedValues();
    }

    emitComplete();
  };

  /**
   * TOKEN
   */
  const removeTokens = () => {
    tokenList.value = [];
    tokenRefs.value = [];
    moreCount.value = 0;
  };

  const isActiveAddMultiValueBtn = (targetToken: FilterToken): boolean => {
    const filterItem = filterItems.value.find((item) => item.key.id === targetToken.key.id);
    const selectableItemCount = filterItem?.values?.items?.length;
    const targetTokenValuesCount = targetToken.values?.length;
    const isAllSelected = selectableItemCount === targetTokenValuesCount;

    return !!filterItem?.values?.multi && targetToken.operator?.id !== 'like' && !isAllSelected;
  };

  const setDropdownListByLastToken = () => {
    const lastTokenStatus = lastToken.value?.status ?? TOKEN_STATUS.EMPTY;
    const lastTokenKeyId = lastToken.value?.key?.id;

    switch (lastTokenStatus) {
      case 'EMPTY':
      case 'VALUE_DONE':
        setKeyDropdownList();
        break;

      case 'KEY_DONE':
        setOperatorDropdownList(lastTokenKeyId);
        break;

      case 'VALUE_ING':
      case 'OPERATOR_DONE':
        setValueDropdownList(lastTokenKeyId, () => lastToken.value.operator?.id !== 'like');
        break;

      default:
        break;
    }
  };

  const removeToken = async (targetTokenIndex: number) => {
    if (targetTokenIndex < 0) {
      return;
    }

    tokenList.value.splice(targetTokenIndex, 1);
    tokenRefs.value.splice(targetTokenIndex, 1);

    if (!tokenList.value?.length) {
      setKeyDropdownList();
    }

    await setMoreCount();
    emitComplete();
  };

  const addTokenParts = (selectedDropdownItem: FilterDropdownItem) => {
    const { multi, category, items } = dropdownListInfo;

    if (!multi || !items?.length) {
      initHoveredDropdownItemIndex();
      mainInputValue.value = '';
    }

    switch (category) {
      case 'key': {
        tokenList.value.push({
          id: Date.now(),
          key: selectedDropdownItem,
          operator: undefined,
          values: [],
          status: TOKEN_STATUS.KEY_DONE,
        });
        setOperatorDropdownList(selectedDropdownItem.id);
        break;
      }

      case 'operator': {
        lastToken.value.operator = selectedDropdownItem;
        lastToken.value.status = TOKEN_STATUS.OPERATOR_DONE;
        setValueDropdownList(lastToken.value.key.id, () => selectedDropdownItem?.id !== 'like');
        break;
      }

      case 'value': {
        if (multi && items?.length) {
          setMultiSelectedValues(lastToken.value, selectedDropdownItem);
          setValueDropdownList(
            lastToken.value.key.id,
            () => lastToken.value.operator?.id !== 'like',
          );
          lastToken.value.status = TOKEN_STATUS.VALUE_ING;
        } else {
          lastToken.value.values = [selectedDropdownItem];
          lastToken.value.status = TOKEN_STATUS.VALUE_DONE;
          setKeyDropdownList();
        }
        break;
      }

      default:
        break;
    }
  };

  const updateTargetTokenStatusByEditingToken = () => {
    const targetToken = tokenList.value.find((token) => token.id === editingToken.value?.id);
    if (!targetToken) {
      return;
    }

    if (targetToken.key && !targetToken.operator && !targetToken.values.length) {
      targetToken.status = TOKEN_STATUS.KEY_DONE;
    } else if (targetToken.key && targetToken.operator && !targetToken.values.length) {
      targetToken.status = TOKEN_STATUS.OPERATOR_DONE;
    } else if (targetToken.key && targetToken.operator && targetToken.values.length) {
      targetToken.status = TOKEN_STATUS.VALUE_DONE;
    }

    if (editingToken.value) {
      editingToken.value.status = targetToken.status;
    }
  };

  const quitEditingToken = async () => {
    if (editingToken.value) {
      updateTargetTokenStatusByEditingToken();

      if (editingToken.value.status === TOKEN_STATUS.VALUE_DONE) {
        await saveHistory(editingToken.value);
        await setMoreCount();
      }

      editingToken.value = null;
      dropdownListInfo.isEditing = false;
    }
  };

  const focusPartsInput = (targetTokenID: number) => {
    const targetTokenIndex = tokenList.value?.findIndex((item) => item.id === targetTokenID);
    const targetTokenRef = tokenRefs.value?.[targetTokenIndex] as HTMLElement;
    if (targetTokenRef) {
      const targetInputEl = targetTokenRef?.querySelector('input');
      if (targetInputEl && !dropdownListInfo.items?.length) {
        const inputText = tokenList.value[targetTokenIndex].values[0]?.name;
        if (inputText) {
          targetInputEl.value = inputText;
        }
      }
      targetInputEl?.focus();
    }
  };

  const editTokenParts = async (selectedDropdownItem: FilterDropdownItem | null) => {
    if (!editingToken.value) {
      return;
    }

    const targetToken = tokenList.value.find((token) => token.id === editingToken.value?.id);
    if (!targetToken) {
      return;
    }

    const { multi, category, items } = dropdownListInfo;
    switch (category) {
      case 'operator': {
        if (!selectedDropdownItem) {
          return;
        }

        editingToken.value.operator = selectedDropdownItem;
        targetToken.operator = selectedDropdownItem;
        hideDropdownList();
        await quitEditingToken();
        break;
      }

      case 'value': {
        const isMultiDropdown = selectedDropdownItem && multi && items?.length;
        if (isMultiDropdown && selectedDropdownItem) {
          setMultiSelectedValues(targetToken, selectedDropdownItem);
        } else {
          if (isMultiDropdown && !selectedDropdownItem) {
            editingToken.value.values = [];
            targetToken.values = [];
            targetToken.status = TOKEN_STATUS.OPERATOR_DONE;
          } else if (selectedDropdownItem) {
            editingToken.value.values = [selectedDropdownItem];
            targetToken.values = [selectedDropdownItem];
            targetToken.status = TOKEN_STATUS.VALUE_DONE;
          }
          hideDropdownList();
          await quitEditingToken();
        }
        break;
      }

      case 'key':
      default:
        break;
    }
  };

  const setTokenParts = async (selectedDropdownItem: FilterDropdownItem | null) => {
    if (editingToken.value) {
      await editTokenParts(selectedDropdownItem);
    } else if (selectedDropdownItem && !editingToken.value) {
      addTokenParts(selectedDropdownItem);
    }

    await setMoreCount();
  };

  const onClickDropdownItem = async (selectedDropdownItem: FilterDropdownItem) => {
    await setTokenParts(selectedDropdownItem);

    if (!editingToken.value && (!dropdownListInfo.multi || !dropdownListInfo.items?.length)) {
      await focusMainInput();
    } else if (editingToken.value && dropdownListInfo.multi && dropdownListInfo.items?.length) {
      focusPartsInput(editingToken.value.id);
    }
  };

  const onClickTokenParts = async ({
    targetToken,
    category,
  }: {
    targetToken: FilterToken;
    category: FilterDropdownInfoCategories;
  }) => {
    initHoveredDropdownItemIndex();

    if (editingToken.value) {
      await quitEditingToken();
    }

    editingToken.value = {
      ...targetToken,
      backupValues: cloneDeep(targetToken.values),
    };

    const targetTokenKey = editingToken.value.key.id;
    if (category === 'operator') {
      setOperatorDropdownList(targetTokenKey);

      const targetFilterItemValuesInfo = filterItems.value.find(
        (item) => item.key.id === targetToken.key.id,
      )?.values;
      if (targetFilterItemValuesInfo?.multi && targetToken.values?.length) {
        const hasListValue = targetToken.values?.some(
          (value) => targetFilterItemValuesInfo?.items?.some((item) => item.id === value.id),
        );

        if (hasListValue) {
          dropdownListInfo.filteredItems.map((item) => {
            if (item.id === 'like') {
              item.disabled = true;
            }
            return item;
          });
        } else {
          dropdownListInfo.filteredItems.map((item) => {
            item.disabled = item.id !== 'like';
            return item;
          });
        }
      }
    } else if (category === 'value') {
      setValueDropdownList(
        targetTokenKey,
        () => editingToken.value?.operator?.id !== 'like',
        (a: FilterDropdownItem, b: FilterDropdownItem) => {
          if (a.checked && !b.checked) {
            return -1;
          }
          if (!a.checked && b.checked) {
            return 1;
          }
          return 0;
        },
      );
    }

    dropdownListInfo.isEditing = true;
    showDropdownList();

    await nextTick();
    focusPartsInput(targetToken.id);
  };

  const onClickAddMultiValue = async (targetToken: FilterToken) => {
    if (editingToken.value) {
      await quitEditingToken();
    }

    editingToken.value = {
      ...targetToken,
      backupValues: cloneDeep(targetToken.values),
    };

    setValueDropdownList(targetToken.key.id, (item) =>
      targetToken.operator?.id === 'like' ? false : !isSelectedDropdownItem(item),
    );

    dropdownListInfo.isEditing = true;
    showDropdownList();

    await nextTick();
    focusPartsInput(targetToken.id);
  };

  const onClickRemoveToken = async (targetTokenIndex: number, isEditingToken: boolean) => {
    await removeToken(targetTokenIndex);

    if (isEditingToken) {
      await quitEditingToken();
      await focusMainInput();
    } else if (isFocus.value) {
      setDropdownListByLastToken();
    }

    await setMoreCount();

    if (isPin.value) {
      await saveFilterSelectedValues();
    }

    emitComplete();
  };

  /**
   * User Actions
   */
  const onClickOutside = async () => {
    isExpand.value = false;
    hideDropdownList();

    if (editingToken.value) {
      await quitEditingToken();
    } else if (dropdownListInfo.multi && !editingToken.value && lastToken.value.values?.length) {
      lastToken.value.status = TOKEN_STATUS.VALUE_DONE;
    }

    isFocus.value = false;
    await setMoreCount();
  };

  const onWheelOutside = async () => {
    isExpand.value = false;
    hideDropdownList();
    await setMoreCount();
  };

  const onClickClearAll = async () => {
    removeTokens();

    if (isPin.value) {
      await removeFilterSelectedValues();
    }

    emitComplete();
  };

  const onFocusMainInput = async () => {
    await quitEditingToken();
    setDropdownListByLastToken();
    initHoveredDropdownItemIndex();
    filterByInput(mainInputValue.value);
    showDropdownList();
  };

  const onInput = (e: Event) => {
    const typedEvent = e as InputEvent;
    const target = <HTMLInputElement>typedEvent.target;
    if (target) {
      const inputText = target?.value ?? typedEvent;

      filterByInput(inputText);
      showDropdownList();
      initHoveredDropdownItemIndex();
    }
  };

  const scrollSelectedItemIntoView = () => {
    const selectedItem = dropdownListRef.value?.querySelector('.hovered');
    if (selectedItem) {
      selectedItem.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
    }
  };

  const onKeyUpDown = (e: KeyboardEvent, direction: 'up' | 'down') => {
    e.preventDefault();

    if (!isShowDropdown.value) {
      return;
    }

    const currIndex: number = hoveredDropdownItemIndex.value;

    if (direction === 'up') {
      hoveredDropdownItemIndex.value = Math.max(currIndex - 1, -1);
    } else if (direction === 'down') {
      const maxIndex = isShowFilterHistoryList.value
        ? dropdownListInfo.filteredItems.length + filterHistory.value.length
        : dropdownListInfo.filteredItems.length;
      hoveredDropdownItemIndex.value = Math.min(currIndex + 1, maxIndex);
    }

    if (hoveredDropdownItemIndex.value > -1) {
      scrollSelectedItemIntoView();
    }
  };

  const addTokenByHistory = (selectedHistory: FilterHistory) => {
    const { key, operator, values } = cloneDeep(selectedHistory.token);

    tokenList.value.push({
      id: Date.now(),
      key,
      operator,
      values,
      status: TOKEN_STATUS.VALUE_DONE,
    });
  };

  const onKeyEnter = async (e: KeyboardEvent) => {
    const target = <HTMLInputElement>e.target;

    if (!target || !isShowDropdown.value) {
      return;
    }

    if (isShowFilterHistoryList.value) {
      const historyIndex = hoveredDropdownItemIndex.value - dropdownListInfo.filteredItems.length;
      const reversedHistoryIndex = filterHistory.value.length - 1 - historyIndex;
      const hoveredHistoryItem = filterHistory.value?.[reversedHistoryIndex];
      if (hoveredHistoryItem) {
        addTokenByHistory(hoveredHistoryItem);
        return;
      }
    }

    const hoveredDropdownItem = dropdownListInfo.filteredItems?.[hoveredDropdownItemIndex.value];

    let inputText = target?.value;
    const matchedDropdownItem = dropdownListInfo.filteredItems.find(
      (item) => item.name.toLowerCase().trim() === inputText.toLowerCase().trim(),
    );

    const userInputInfo =
      !dropdownListInfo.items?.length && inputText ? { id: inputText, name: inputText } : null;
    await setTokenParts(hoveredDropdownItem ?? matchedDropdownItem ?? userInputInfo);

    inputText = editingToken.value ? inputText : mainInputValue.value;
    if (dropdownListInfo.multi && inputText) {
      filterByInput(inputText);
    }
  };

  const onKeyCtrlEnter = async () => {
    if (!dropdownListInfo.multi || !dropdownListInfo.items?.length) {
      return;
    }

    if (!editingToken.value) {
      if (lastToken.value.values?.length) {
        lastToken.value.status = TOKEN_STATUS.VALUE_DONE;
        setDropdownListByLastToken();
        filterByInput(mainInputValue.value);
      } else {
        hideDropdownList();
      }
    } else {
      await quitEditingToken();
      await focusMainInput();
    }
  };

  const onKeyBackspace = () => {
    if (!mainInputValue.value) {
      tokenList.value.pop();

      if (dropdownListInfo.category !== 'key') {
        setKeyDropdownList();
      }

      emitComplete();
    }
  };

  const onKeyEsc = async () => {
    if (editingToken.value) {
      if (dropdownListInfo.multi) {
        const targetToken = tokenList.value.find((token) => token.id === editingToken.value?.id);
        if (targetToken) {
          targetToken.values = cloneDeep(editingToken.value.backupValues);
        }
      }

      await quitEditingToken();
    }

    hideDropdownList();

    if (dropdownListInfo.multi && !editingToken.value) {
      lastToken.value.status = TOKEN_STATUS.VALUE_DONE;
    }
  };

  /**
   * Resize
   */
  const ro = new ResizeObserver(async () => {
    await nextTick();
    await setMoreCount();
  });

  /**
   * set Props
   */
  const setTokenListBySelectedResult = (selectedValues: FilterSelectedValues | undefined) => {
    if (!selectedValues || !selectedValues?.tokens?.length) {
      return;
    }

    const { logicalOperator, tokens } = selectedValues;
    selectedLogicalOperator.value = logicalOperator;

    tokens?.forEach(({ key, operator, value }, index) => {
      const filterItem = filterItems.value.find((item) => item.key.id === key);

      const { multi, items } = filterItem?.values ?? {};
      let values: FilterDropdownItem[];
      if (items?.length && multi && operator !== 'like') {
        values = items.filter((item) => value.split(',').includes(item.id));
      } else {
        values = [{ id: value, name: value }];
      }

      if (filterItem) {
        tokenList.value.push({
          id: +`${Date.now()}${index}`,
          key: filterItem.key,
          operator: {
            id: operator,
            name: operator,
          },
          values,
          status: TOKEN_STATUS.VALUE_DONE,
        });
      }
    });

    emitComplete();
  };

  onMounted(async () => {
    ro.observe(filterSearchRef.value);
    await loadEnvData();
    setTokenListBySelectedResult(props.selectedValues ?? pinnedTokenList.value);
  });

  onBeforeMount(() => {
    if (filterSearchRef.value) {
      ro.unobserve(filterSearchRef.value);
    }
  });

  watch(
    () => props.filterItems,
    async (newItems, oldItems) => {
      const newKeys = sortBy(newItems.map((item) => item.key.id));
      const oldKeys = sortBy(oldItems.map((item) => item.key.id));

      if (newKeys.length && !isEqual(newKeys, oldKeys)) {
        filterItems.value = cloneDeep(props.filterItems);
        removeTokens();
        await loadEnvData();
        setTokenListBySelectedResult(pinnedTokenList.value);
      }
    },
  );

  return {
    logicalOperatorListItems,
    selectedLogicalOperator,
    onChangeLogicalOperator,

    isPin,
    onClickPin,

    isFocus,
    filterSearchRef,
    tokensMoreBtnWrapperRef,
    tokensWrapperRef,
    setElementRef,
    moreCount,
    isExpand,
    expand,
    onClickOutside,
    onWheelOutside,

    isShowDropdown,
    dropdownListInfo,
    hoveredDropdownItemIndex,
    dropdownListRef,
    filterMainRef,
    dropdownListWrapperStyle,
    initHoveredDropdownItemIndex,
    onClickDropdownItem,

    mainInputRef,
    mainInputValue,
    onInput,
    onKeyEnter,
    onKeyCtrlEnter,
    onKeyUpDown,
    onKeyBackspace,
    onKeyEsc,
    onFocusMainInput,

    tokenList,
    editingToken,
    lastToken,
    onClickRemoveToken,
    onClickTokenParts,
    isActiveAddMultiValueBtn,
    onClickAddMultiValue,
    onClickClearAll,

    filterHistory,
    isShowFilterHistoryList,
    addTokenByHistory,
    onClickMoreCount,
  };
};
