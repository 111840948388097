import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/common/router';
import { BASE_MENU_VALUE } from '@/common/components/organisms/baseMenu/baseMenu.define';
import { MenuInfo } from '@/common/components/organisms/baseMenu/types';
import { useDashboardFavorite } from '@/common/components/organisms/baseMenu/baseMenu.uses';
import { useInstanceStore } from '@/common/stores/instance';
import { useBaseMenuStore } from '@/common/stores/base-menu';

export interface Props {
  menuInfo: MenuInfo;
  clicked?: boolean;
}

export interface Emit {
  (e: 'hover', event?: MouseEvent, menuInfo?: MenuInfo): void;
  (e: 'click', event?: MouseEvent, menuInfo?: MenuInfo): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { changeGNBInstance } = useInstanceStore();
  const baseMenuStore = useBaseMenuStore();
  const { selectSubPanelMenu } = baseMenuStore;
  const { menuVisitRoute } = storeToRefs(baseMenuStore);
  const { isFavorited, isAnalysisFavorited, updateFavorite } = useDashboardFavorite(props.menuInfo);

  const childLength = computed<number>(() => props.menuInfo.subMenuList?.length ?? 0);
  const isCurrentPath = computed<boolean>(() => {
    if (props.menuInfo.subMenuList) {
      return menuVisitRoute.value.some((route) => route.value === props.menuInfo.value);
    }
    return router.currentRoute.value.fullPath === props.menuInfo.path;
  });

  const isFavoriteIconVisible = computed<boolean>(() => {
    const isDashboard = props.menuInfo.value.includes(`${BASE_MENU_VALUE.DASHBOARD}_`);
    const isDashboardList = props.menuInfo.value.includes(`${BASE_MENU_VALUE.DASHBOARD}_list`);

    return isDashboard && !isDashboardList;
  });

  const favoriteRef = ref<HTMLButtonElement | null>(null);

  const onHover = (e?: MouseEvent) => {
    emit('hover', e, props.menuInfo);
  };

  const onClick = async (e?: MouseEvent) => {
    const favoriteEl = favoriteRef.value;
    const targetEl = e?.target as HTMLElement;

    if (props.menuInfo.subMenuList) {
      emit('click', e, props.menuInfo);
      return;
    }

    if (!props.menuInfo.path) {
      return;
    }
    if (!favoriteEl) {
      const [, rootPath, secondPath] = props.menuInfo.path.split('/');
      if (secondPath === 'multiView' || secondPath === 'singleView') {
        changeGNBInstance(rootPath, secondPath);
        return;
      }
    }
    if (favoriteEl?.contains(targetEl)) {
      updateFavorite();
      return;
    }
    selectSubPanelMenu(props.menuInfo.value);
    router.push(props.menuInfo.path);
  };

  return {
    favoriteRef,

    isCurrentPath,
    isFavoriteIconVisible,
    isFavorited,
    isAnalysisFavorited,

    childLength,

    onHover,
    onClick,
  };
};
