// HexagonChart
import { HexagonChartOption } from '@/common/components/molecules/hexagonChart/hexagonChart.types';
import { DB_TYPE, OS_TYPE } from '@/common/utils/define';

export const DEGREE_60 = Math.PI / 3;
export const COS_60 = Math.cos(DEGREE_60);
export const SIN_60 = Math.sin(DEGREE_60);
export const GROUP_TITLE_IMAGE_SIZE = 20;
export const GROUP_TITLE_IMAGE_GAP = 5;
export const GROUP_BADGE_SIZE = 24;
export const HEXAGON_COLOR_TYPE = {
  widget: {
    running: '#4EFFBB',
    pending: '#FFD759',
    failed: '#FF3D70',
    succeeded: '#45A4FF',
    unknown: '#939393',
    disabled: '#3A3A3A',
  },
  infrastructure: {
    active: '#198FFF',
    inactive: '#A2A2A2',
  },
  instance: {
    total: '#FFFFFF',
    normal: '#12A480',
    warning: '#F9CB3B',
    critical: '#FF1F3F',
    noData: '#777777',
  },
  kubernetes: {
    total: '#FFFFFF',
    running: '#006DD6',
    succeeded: '#12A480',
    pending: '#FFD759',
    failed: '#FF1F3F',
    ready: '#12A480', // succeeded
    notReady: '#FFD759', // pending
    normal: '#12A480',
    warning: '#F9CB3B',
    critical: '#FF1F3F',
  },
  application: {
    total: '#FFFFFF',
    active: '#198FFF',
    inactive: '#A2A2A2',
  },
};
export const HEXAGON_OPTION: HexagonChartOption = {
  gap: 5,
  padding: 40,
  top: 20,
  minSize: 20,
  maxSize: 35,
  hideText: false,
  colorType: 'widget',
};

export const HEXAGON_IMAGE_URL = {
  [`${DB_TYPE.ORACLE}-mono`]: '/hexagon/icon-oracle-mono.svg',
  [`${DB_TYPE.ORACLE}-title`]: '/hexagon/icon-oracle-title.svg',
  [`${DB_TYPE.MYSQL}-mono`]: '/hexagon/icon-mysql-mono.svg',
  [`${DB_TYPE.MYSQL}-title`]: '/hexagon/icon-mysql-title.svg',
  [`${DB_TYPE.POSTGRESQL}-mono`]: '/hexagon/icon-postgresql-mono.svg',
  [`${DB_TYPE.POSTGRESQL}-title`]: '/hexagon/icon-postgresql-title.svg',
  [`${DB_TYPE.SQLSERVER}-mono`]: '/hexagon/icon-sqlserver-mono.svg',
  [`${DB_TYPE.SQLSERVER}-title`]: '/hexagon/icon-sqlserver-title.svg',
  [`${DB_TYPE.REDIS}-mono`]: '/hexagon/icon-redis-mono.svg',
  [`${DB_TYPE.REDIS}-title`]: '/hexagon/icon-redis-title.svg',
  [`${DB_TYPE.CUBRID}-mono`]: '/hexagon/icon-cubrid-mono.svg',
  [`${DB_TYPE.CUBRID}-title`]: '/hexagon/icon-cubrid-title.svg',
  [`${DB_TYPE.MONGODB}-mono`]: '/hexagon/icon-mongodb-mono.svg',
  [`${DB_TYPE.MONGODB}-title`]: '/hexagon/icon-mongodb-title.svg',

  // OS Type Icon (Host View)
  [`${OS_TYPE.WINDOWS}-mono`]: '/hexagon/icon-windows-mono.svg',
  [`${OS_TYPE.WINDOWS}-title`]: '/hexagon/icon-windows-title.svg',
  [`${OS_TYPE.AIX}-mono`]: '/hexagon/icon-aix-mono.svg',
  [`${OS_TYPE.AIX}-title`]: '/hexagon/icon-aix-title.svg',
  [`${OS_TYPE.HP_UX}-mono`]: '/hexagon/icon-hp-mono.svg',
  [`${OS_TYPE.HP_UX}-title`]: '/hexagon/icon-hp-title.svg',
  [`${OS_TYPE.CENTOS}-mono`]: '/hexagon/icon-centos-mono.svg',
  [`${OS_TYPE.REDHAT}-mono`]: '/hexagon/icon-redhat-mono.svg',
  [`${OS_TYPE.ROCKY}-mono`]: '/hexagon/icon-rocky-mono.svg',
  [`${OS_TYPE.UBUNTU}-mono`]: '/hexagon/icon-ubuntu-mono.svg',
  [`${OS_TYPE.DEBIAN}-mono`]: '/hexagon/icon-debian.mono.svg',
  [`${OS_TYPE.SUSE}-mono`]: '/hexagon/icon-suse-mono.svg',
  [`${OS_TYPE.FEDORA}-mono`]: '/hexagon/icon-fedora-mono.svg',
  [`${OS_TYPE.UNKNOWN}-mono`]: '/hexagon/icon-linux-mono.svg',
  [`${OS_TYPE.UNKNOWN}-title`]: '/hexagon/icon-linux-title.svg',
  [`${OS_TYPE.Linux}-title`]: '/hexagon/icon-linux-title.svg',

  // 기본뷰 > ~~ 그룹별로 묶었을 때
  'hostGroup-title': '/hexagon/icon-host-title.svg',
  'wasGroup-title': '/hexagon/icon-was-title.svg',
  'python-title': '/hexagon/icon-python-title.svg',
  'java-title': '/hexagon/icon-java-title.svg',
  'nodejs-title': '/hexagon/icon-nodejs-title.svg',
  'dotnet-title': '/hexagon/icon-dotnet-title.svg',
};

export const DEFAULT_STATUS_LEVEL = {
  pending: 4,
  critical: 4,
  fail: 4,
  failed: 3,
  inactive: 3,
  abnormal: 3,
  terminated: 3,
  warning: 3,
  unknown: 2,
  running: 1,
  normal: 0,
  active: 0,
  succeeded: 0,
} as const;
