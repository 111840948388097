import { store } from '../store';
import { AuthSpec, type PageType, type ActionTypeMap } from './auth.defines';
import { isRoleIdIncluded } from './auth.utils';

const useAuthorize = <P extends PageType>(pageType: P, actionType: ActionTypeMap[P]) => {
  const { role: roleId } = store.getters['myInfo/getAccountInfo'];
  const allowedRoles = AuthSpec[pageType][actionType];

  return isRoleIdIncluded(allowedRoles, roleId);
};

export default useAuthorize;
