const MAIN_FRAMES = {
  ALERT_VIEW: 'Alert View',
  ALERT_DETAIL: 'Alert Detail',
  NOTIFICATION_HISTORY_VIEW: 'Notification History View',
} as const;

export const ALERT_FRAME_NAMES = {
  ALERT_VIEW: {
    FILTERS: `${MAIN_FRAMES.ALERT_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.ALERT_VIEW}/Alert List`,
    TAGS: `${MAIN_FRAMES.ALERT_VIEW}/Tags`,
  },
  ALERT_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.ALERT_DETAIL}/Information`,
    CURRENT: `${MAIN_FRAMES.ALERT_DETAIL}/Current`,
    CURRENT_CLEAR: `${MAIN_FRAMES.ALERT_DETAIL}/[Clear] Current`,
    HISTORY_TREND: `${MAIN_FRAMES.ALERT_DETAIL}/History`,
    HISTORY_SORT_BY_TIME: `${MAIN_FRAMES.ALERT_DETAIL}/History - Sort By Time`,
    PATH: `${MAIN_FRAMES.ALERT_DETAIL}/Information - Path`,
  },
  NOTIFICATION_HISTORY_VIEW: {
    FILTERS: `${MAIN_FRAMES.NOTIFICATION_HISTORY_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.NOTIFICATION_HISTORY_VIEW}/Notification History View`,
    DETAIL: `${MAIN_FRAMES.NOTIFICATION_HISTORY_VIEW}/Notification Status Detail`,
  },
} as const;
