import { floor } from 'lodash-es';
import {
  DecimalOptions,
  DecimalPrecision,
} from '../../widgetSettingsWindow/decimalsPrecisionSelect/decimalsPrecisionSelect.setup';

const DEFAULT_DECIMAL = 3;

export const formatToDecimalPrecision = (
  value: number,
  decimalPrecision: DecimalPrecision,
): number => {
  return floor(value, decimalPrecision);
};

export const formatValueByDecimal = (decimalOptions: DecimalOptions, value: number): string => {
  const decimal = decimalOptions.decimalsPrecision;
  if (decimalOptions?.use) {
    return formatToDecimalPrecision(value, decimal).toLocaleString(undefined, {
      maximumFractionDigits: decimal,
      minimumFractionDigits: decimal,
    });
  }
  return `${formatToDecimalPrecision(value, DEFAULT_DECIMAL).toLocaleString(undefined, {
    maximumFractionDigits: DEFAULT_DECIMAL,
  })}`;
};
