import { ref, computed, onBeforeMount, onMounted, onUnmounted } from 'vue';
import { useViewModeStore } from '@/common/stores/view-mode';
import { storeToRefs } from 'pinia';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { GNB_DB_LISTS } from '@/common/stores/instance';
import { VIEW_MODE } from '@/common/define/common.define';
import { MenuInfo } from '@/common/components/organisms/baseMenu/types';

export interface Props {
  menuInfo: MenuInfo;
  dashboardMenuList?: MenuInfo[];
  analysisBoardMenuList?: MenuInfo[];
  expanded?: boolean;
  selected?: boolean;
}

export interface Emit {
  (e: 'hover', evt: MouseEvent): void;
  (e: 'click'): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { viewMode } = storeToRefs(useViewModeStore());
  const { mainPanelInfo } = storeToRefs(useBaseMenuStore());

  const innerRef = ref();
  const innerHeight = ref<string>('0px');

  const computedDashboardMenu = computed(() => {
    if (!props.menuInfo.dashboardList) return null;
    return {
      ...props.menuInfo.dashboardList,
      subMenuList: props.dashboardMenuList,
    };
  });

  const computedAnalysisBoardMenu = computed(() => {
    if (!props.menuInfo.analysisBoardList) return null;
    return {
      ...props.menuInfo.analysisBoardList,
      subMenuList: props.analysisBoardMenuList,
    };
  });

  const onHoverButton = (e: MouseEvent) => {
    emit('hover', e);
  };

  const onClickButton = () => {
    emit('click');
  };

  const calculateFoldedAreaHeight = (menuInfo: MenuInfo) => {
    const isOverMaxWidth = document.documentElement.clientWidth > 1600;
    const MENU_ELEMENT_HEIGHT = 28 + (isOverMaxWidth ? 4 : 0);
    const MID_MENU_BAR_HEIGHT = 20 + (isOverMaxWidth ? 4 : 0);
    const MID_MENU_TITLE_HEIGHT = 20;
    const MARGIN_BOTTOM_HEIGHT = 2;

    const midMenu = menuInfo.children?.filter((m) => m.children && m.children?.length);

    const numOfMidMenu = midMenu?.length ?? 0;
    const numOfElement =
      (menuInfo.children?.length ?? 0) +
      (midMenu?.reduce((acc, cur) => acc + (cur.children?.length ?? 0), 0) ?? 0);
    const numOfDashboardElement = menuInfo.dashboardList ? 1 : 0;
    const numOfAnalysisBoardElement = menuInfo.analysisBoardList ? 1 : 0;
    let numOfMidMenuBar =
      menuInfo.children?.length || menuInfo.dashboardList || menuInfo.analysisBoardList
        ? numOfMidMenu
        : numOfMidMenu - 1;
    if (numOfMidMenuBar < 0) {
      numOfMidMenuBar = 0;
    }
    const totalHeight =
      numOfMidMenu * MID_MENU_TITLE_HEIGHT +
      numOfMidMenuBar * MID_MENU_BAR_HEIGHT +
      (numOfElement + numOfDashboardElement + numOfAnalysisBoardElement) * MENU_ELEMENT_HEIGHT +
      (numOfMidMenu + numOfElement + numOfDashboardElement + numOfAnalysisBoardElement - 1) *
        MARGIN_BOTTOM_HEIGHT;

    return totalHeight;
  };

  const controller = new AbortController();
  onBeforeMount(() => {
    innerHeight.value = `${calculateFoldedAreaHeight(props.menuInfo)}px`;
    window.addEventListener(
      'resize',
      () => {
        innerHeight.value = `${calculateFoldedAreaHeight(props.menuInfo)}px`;
      },
      { signal: controller.signal },
    );
  });

  const isMaxgaugeLastMenuElement = ref(false);
  onMounted(() => {
    if (viewMode.value === VIEW_MODE.MAXGAUGE) {
      const el = '.menu-element__';
      const elements = document.body.querySelectorAll<HTMLLIElement>(
        el + GNB_DB_LISTS.join(`, ${el}`),
      );
      const lastElement = elements?.[elements.length - 1];

      if (lastElement?.className.includes(props.menuInfo.value)) {
        isMaxgaugeLastMenuElement.value = true;
      }
    }
  });
  onUnmounted(() => {
    controller.abort();
  });

  return {
    innerRef,
    innerHeight,

    mainPanelInfo,
    computedDashboardMenu,
    computedAnalysisBoardMenu,

    onClickButton,
    onHoverButton,

    isMaxgaugeLastMenuElement,
  };
};
