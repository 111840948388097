import { i18n } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { convertChartTypeToWidgetChartType } from '@/dashboard/utils/dashboardUtils';
import { TableRawData } from '@/worker/commands/dashboard/table';
import { getLimitSeriesCountByChartType } from '@/dashboard/components/widgets/widgets.defines';

export const checkEqualizerChartData =
  ({
    notSupportStat,
    notNumberError,
    chartType,
  }: {
    notSupportStat: string;
    notNumberError: string;
    chartType: 'equalizer';
  }) =>
  (
    rawData: TableRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ): WidgetChartDataStatusInfo[] => {
    const { tableData } = rawData ?? {
      tableData: [],
      targets: [],
    };
    const chartWidgetType = convertChartTypeToWidgetChartType(chartType);
    const seriesCountLimit = getLimitSeriesCountByChartType(chartWidgetType[0], chartWidgetType[1]);

    return chartDataStatus.map((item, idx) => {
      if (item.status === 'fail') {
        return item;
      }
      const isExistTargetId = tableData[idx].columns.some(
        (columnInfo) => columnInfo.dataAttribute === 'TargetId',
      );
      const isExistNumberType = tableData[idx].columns.some(
        (columnInfo) => columnInfo.dataType === 'Number',
      );
      if (!isExistTargetId) {
        return {
          status: 'fail',
          type: 'custom',
          chartDataId: item.chartDataId,
          reason: notSupportStat,
        };
      }
      if (!isExistNumberType) {
        return {
          status: 'fail',
          type: 'custom',
          chartDataId: item.chartDataId,
          reason: notNumberError,
        };
      }
      if (seriesCountLimit != null && seriesCountLimit <= idx) {
        return {
          ...item,
          status: 'fail',
          type: 'custom',
          reason: i18n.global.t('ERROR.CHART_DATA.CE002', {
            value: seriesCountLimit,
          }),
          errorCode: 'CE002',
        };
      }
      return item;
    });
  };
