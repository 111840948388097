/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  MultiInstanceRequest,
  PaSessionDetailResponse,
  PaSessionInfoV7Response,
  SessionDetailPostgresV7Response,
  SessionInfoV7Response,
  SessionTypeV7Response,
  TopApplicationRatioV7Response,
  TopUserRatioV7Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const activeBackendListSessionPostgresV7ControllerAxios = ({
  filter,
  instanceId,
  fromTime,
  isFullText,
  period,
  size,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  filter: 'Active' | 'DEFAULT' | 'Idle' | 'Long';
  instanceId: string;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  sort?: 'NONE' | 'elapsedTime';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionInfoV7Response> => {
  const localVarPath = '/api/v7/postgres/{instanceId}/activebackends'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      filter,
      fromTime,
      isFullText,
      period,
      size,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionDetailSessionPostgresV7ControllerAxios = ({
  instanceId,
  pid,
  queryStart,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  pid: number;
  queryStart: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionDetailPostgresV7Response> => {
  const localVarPath = '/api/v7/postgres/{instanceId}/activebackends/{pid}'
    .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)))
    .replace(`{${'pid'}}`, encodeURIComponent(String(pid)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      queryStart,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const paActiveBackendListSessionPostgresV7ControllerAxios = ({
  filter,
  instanceId,
  fromTime,
  isFullText,
  isReport,
  period,
  size,
  sort,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  filter: 'Active' | 'DEFAULT' | 'Idle' | 'Long';
  instanceId: string;
  fromTime?: string;
  isFullText?: boolean;
  isReport?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  sort?: 'NONE' | 'elapsedTime';
  timezone?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PaSessionInfoV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/activebackends'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      filter,
      fromTime,
      isFullText,
      isReport,
      period,
      size,
      sort,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const PaSessionDetailSessionPostgresV7ControllerAxios = ({
  instanceId,
  pid,
  queryStart,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  pid: number;
  queryStart: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PaSessionDetailResponse> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/activebackends/{pid}'
    .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)))
    .replace(`{${'pid'}}`, encodeURIComponent(String(pid)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      queryStart,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionCountByTypeSessionPostgresV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTypeV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/session-count';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topApplicationRatioSessionPostgresV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopApplicationRatioV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/{instanceId}/top-application-ratio'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topUserRatioSessionPostgresV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopUserRatioV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/{instanceId}/top-user-ratio'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionCountByTypePostSessionPostgresV7ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: MultiInstanceRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTypeV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/session-count';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  activeBackendListSessionPostgresV7ControllerAxios,
  sessionDetailSessionPostgresV7ControllerAxios,
  paActiveBackendListSessionPostgresV7ControllerAxios,
  PaSessionDetailSessionPostgresV7ControllerAxios,
  sessionCountByTypeSessionPostgresV7ControllerAxios,
  topApplicationRatioSessionPostgresV7ControllerAxios,
  topUserRatioSessionPostgresV7ControllerAxios,
  sessionCountByTypePostSessionPostgresV7ControllerAxios,
};
