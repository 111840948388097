<script setup lang="ts">
import TextViewer from '@/common/components/molecules/textViewer/TextViewer.vue';
import {
  Props,
  Emit,
  TOGGLE_DATA,
  setup,
} from '@/common/components/organisms/apiTraceWindow/apiTrace.setup';
import SummaryTypeToggle from '@/common/components/molecules/SummaryTypeToggle.vue';

const props = withDefaults(defineProps<Props>(), {
  isPopup: false,
  targetFrameInfo: () => ({
    id: '',
    type: '',
  }),
});
const emit = defineEmits<Emit>();
const {
  subFrameTabsRef,
  tabRenderTrigger,
  currentPath,
  isErrorApi,
  dataSqlToggle,
  targetInfo,
  responseInfos,
  textViewerData,
  onClickSnap,
  onScrollTab,
} = setup(props, emit);
</script>

<template>
  <div class="api-trace">
    <div
      v-if="isPopup"
      class="api-trace__header"
    >
      API Trace {{ currentPath ? `(${currentPath})` : '' }}
    </div>
    <div class="api-trace__toolbar">
      <span> Snap Time </span>
      <ev-select
        v-model="targetInfo.targetSnapId"
        :items="targetInfo.snapTimes"
        class="toolbar-snap-time-selector"
      />
      <span> Frame </span>
      <ev-select
        v-model="targetInfo.targetFrame"
        :items="targetInfo.frames"
        class="toolbar-frame-selector"
      />
      <ev-button
        type="primary"
        @click="onClickSnap"
      >
        Snap
      </ev-button>
    </div>
    <div class="api-trace__contents grid-container">
      <div class="api-trace__contents-targets grid-item1">
        <ev-tabs
          :key="tabRenderTrigger"
          ref="subFrameTabsRef"
          v-model="targetInfo.targetSubFrame"
          v-model:panels="targetInfo.subFrames"
          stretch
          @wheel.prevent.stop="onScrollTab"
        >
          <ev-tab-panel
            v-for="(item, index) in targetInfo.subFrames"
            :key="`${index}-${item.value}`"
            :text="item.text"
            :value="item.value"
          />
        </ev-tabs>
      </div>
      <div class="api-trace__contents-result grid-item2">
        <div class="sql-data-toggle">
          <summary-type-toggle
            v-model:toggle-data-type="dataSqlToggle"
            :active-text="TOGGLE_DATA.SQL"
            :inactive-text="TOGGLE_DATA.DATA"
          />
        </div>
        <text-viewer
          :type="textViewerData.type"
          :text="textViewerData.text"
          :use-border="false"
          use-json-virtual-scroll
        />
      </div>
      <div class="api-trace__contents-params grid-item3">
        <div
          v-for="(data, index) in responseInfos"
          :key="index"
          :class="['contents-prams-item', data.field]"
        >
          <div class="contents-prams-item__name">
            <span
              :class="[
                'contents-prams-item__name-indi',
                {
                  error: isErrorApi,
                },
              ]"
            />
            <span
              :class="[
                'contents-prams-item__name-text',
                {
                  error: isErrorApi,
                },
              ]"
              >{{ data.name }}</span
            >
          </div>
          <div
            v-if="data.field === 'params'"
            class="contents-prams-item__value-params"
          >
            <text-viewer
              :text="data.value"
              :use-border="false"
              type="json"
            />
          </div>
          <div
            v-else
            class="contents-prams-item__value"
          >
            {{ data.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.api-trace {
  --api-info-min-width: 360px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-width: 760px;
  height: 100%;
  overflow: hidden;
  background: var(--window-contents-bg-color);
  font-size: 14px;

  &__header {
    padding: 12px 10px;
    background-color: var(--window-header-bg-color);
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 1;
    color: var(--window-contents-font-color);

    @include shortening();
  }

  &__toolbar {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 12px;
    color: var(--window-contents-font-color);

    .toolbar-snap-time-selector {
      width: 200px !important;
    }
    .toolbar-frame-selector {
      flex: 1;
    }
  }

  &__contents-targets {
    .ev-tabs {
      padding: 0 !important;
    }
    .ev-tabs-body {
      height: 0 !important;
      padding: 0;
    }
  }
  &__contents-result {
    position: relative;

    .sql-data-toggle {
      display: flex;
      position: absolute;
      top: 14px;
      right: 54px;
      z-index: 1;
      gap: 5px;
      align-items: center;
    }
  }

  &__contents-params {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    overflow: hidden;

    .contents-prams-item {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 2px;
      overflow: hidden;

      &.params {
        flex: 1;
      }

      &__name {
        display: flex;
        gap: 5px;
        height: 20px;

        &-indi {
          display: inline-block;
          width: 5px;
          height: 100%;
          background-color: var(--color-blue-06);

          &.error {
            background-color: var(--color-red-06);
          }
        }
        &-text {
          color: var(--color-blue-06);

          &.error {
            color: var(--color-red-06);
          }
        }
      }

      &__value {
        word-wrap: break-word;
      }

      &__value-params {
        overflow: hidden;
      }
    }
  }

  .grid-container {
    display: grid;
    flex: 1;
    grid-template-rows: 31px auto;
    grid-template-columns: auto var(--api-info-min-width);
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-gray-09-00);

    .grid-item1 {
      grid-row: 1;
      grid-column: 1 / span 2;
      min-width: 0;
      max-width: 100%;
    }
    .grid-item2 {
      box-sizing: border-box;
      position: relative;
      grid-row: 2;
      grid-column: 1;
      min-width: 0;
      max-height: 100%;
      padding: 0 0 12px 12px;
      overflow: hidden;
    }
    .grid-item3 {
      grid-row: 2;
      grid-column: 2;
      min-width: var(--api-info-min-width);
      max-height: 100%;
      padding: 12px;
      overflow: hidden;
    }
  }

  .ev-tabs-icon {
    &.icon-fill-warning {
      top: 50%;
      width: 20px;
      height: 20px;
      background-color: var(--color-red-06);
      background-size: 20px;
      color: var(--color-red-06);
      transform: translateY(-50%);
      mask-size: 20px;
    }
  }

  .text-viewer {
    .no-data {
      height: 100%;
      padding-top: 40px;
    }
  }
}
</style>
