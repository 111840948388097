<script setup lang="ts">
interface Props {
  title?: string;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="floating-menu-popup menu-popup">
    <div class="floating-menu-popup__inner menu-popup__inner">
      <p
        v-if="!!props.title"
        class="floating-menu-popup__title"
      >
        {{ props.title }}
      </p>
      <ul class="floating-menu-popup__list">
        <slot name="list" />
      </ul>
    </div>
    <slot name="addition" />
  </div>
</template>

<style scoped lang="scss">
.floating-menu-popup {
  &__inner {
    display: flex;
    flex-direction: column;
    width: var(--gnb-sub-menu-width);
    max-height: calc(100vh - var(--gnb-header-height));
    overflow: hidden;
    background-color: var(--color-gray-08-00) !important;
  }

  &:not(.shown) .floating-menu-popup__inner {
    border: none;
  }

  &__title {
    padding: 12px 12px 3px;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    color: var(--color-gray-02-10);
  }

  &__list {
    padding: 8px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px !important;
    }
  }

  &__list:empty {
    padding: 0;
  }
}
</style>
