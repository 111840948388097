<script setup lang="ts">
import FloatingPopupLayout from '../FloatingPopupLayout.vue';
import PopupListElement from '../popupListElement/PopupListElement.vue';
import { type Props, type Emit, setup } from './myInfoPopupGroupList.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  t,

  onClickGroupItem,
} = setup(emit);
</script>

<template>
  <floating-popup-layout
    class="my-info-group-list"
    :title="t('WORD.MONITORING_GROUP')"
  >
    <template #list>
      <popup-list-element
        v-for="group in props.groupList"
        :key="group.value"
        :text="group.name"
        :selected="group.value === props.selectedGroup?.value"
        @click="() => onClickGroupItem(group)"
      />
    </template>
  </floating-popup-layout>
</template>

<style scoped lang="scss">
.my-info-group-list {
  display: none !important;
  position: absolute;
  left: calc(var(--popup-wrapper-padding) + var(--popup-width)) !important;
  width: var(--gnb-sub-menu-width);
  padding: 0;

  &.shown {
    display: block !important;
  }

  :deep(.button-left) {
    span {
      font-size: 12px;

      @include shortening();
    }
  }
}
</style>
