<script setup lang="ts">
import { setup } from '@/common/components/molecules/statusTag/statusTag.setup';

interface Props {
  type: string;
}

const props = defineProps<Props>();

const { typeToClass } = setup(props);
</script>

<template>
  <span
    class="status-tag"
    :class="typeToClass"
  >
    <slot />
  </span>
</template>

<style lang="scss" scoped>
.status-tag {
  display: inline-block;
  min-width: 72px;
  padding: 2px 10px 1px;
  border: 1px solid var(--color-gray-04-06);
  border-radius: 2px;
  background-color: var(--color-gray-06-01);
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: var(--color-gray-00-12);
  text-align: center;

  & + & {
    margin-left: 4px;
  }

  &.green {
    border-color: var(--color-green-05-05);
    background-color: var(--color-green-10-00);
    color: var(--color-green-02-08);
  }
  &.blue {
    border-color: var(--color-blue-05-05);
    background-color: var(--color-blue-10-00);
    color: var(--color-blue-02-08);
  }
  &.yellow {
    border-color: var(--color-yellow-05-05);
    background-color: var(--color-yellow-10-00);
    color: var(--color-yellow-02-08);
  }
  &.red {
    border-color: var(--color-red-05-05);
    background-color: var(--color-red-10-00);
    color: var(--color-red-02-08);
  }
  &.disabled {
    border-color: var(--color-gray-05-05);
    background-color: var(--color-gray-06-02);
    color: var(--color-gray-03-09);
  }
}
</style>
