const MAIN_FRAMES = {
  CLOUD_AWS_EC2_VIEW: 'AWS EC2 View',
  CLOUD_AWS_LAMBDA_VIEW: 'AWS Lambda View',
  CLOUD_AWS_S3_VIEW: 'AWS S3 View',
  CLOUD_AWS_DOCUMENTDB_VIEW: 'AWS DocumentDB View',
  CLOUD_AWS_DYNAMODB_VIEW: 'AWS DynamoDB View',
  CLOUD_AWS_ELASTICACHE_VIEW: 'AWS Elasticache View',
  CLOUD_AWS_REDSHIFT_VIEW: 'AWS Redshift View',
  AWS_COST_EXPLORER: 'AWS - Cost Explorer',
  CLOUD_AWS_DETAIL: 'AWS Detail',
} as const;

export const CLOUD_FRAME_NAMES = {
  CLOUD: {
    AWS_DOCUMENTDB: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_CARD: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Summary Card`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Summary List`,
      AWS_CLUSTER_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Cluster List`,
    },
    AWS_DYNAMODB: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Recent AWS Alarm List`,
      AWS_CHART: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Chart`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Summary List`,
      AWS_TABLE_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Table List`,
    },
    AWS_ELASTICACHE: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Summary List`,
      AWS_CACHE_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Cache List`,
    },
    AWS_REDSHIFT: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_CARD: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Summary Card`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Summary List`,
      AWS_CLUSTER_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Cluster List`,
    },
    AWS_COST_EXPLORER: {
      FILTERS: `${MAIN_FRAMES.AWS_COST_EXPLORER}/Filter`,
      COST: `${MAIN_FRAMES.AWS_COST_EXPLORER}/Cost`,
    },
    AWS_DETAIL: {
      INFORMATION: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Information`,
      METRIC_COLLECTOR_STATUS: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Metric Collector Status`,
      METRIC: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Metric`,
      LOG: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Log`,
      AWS_ALARM: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Aws Alarm`,
      ELASTICACHE_SHARD: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/ElastiCache Shard and Node`,
      ELASTICACHE_NODE: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/ElastiCache Node `,
      ELASTICACHE_NODE_LIST: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/ElastiCache Nodes List in Cluster`,
    },
  },
} as const;
