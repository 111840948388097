<script setup lang="ts">
import BaseModalWindow from '@/common/components/templates/BaseModalWindow.vue';
import {
  type Props,
  type Emit,
  setup,
} from '@/alert/components/logAlertViewerWindow/logAlertViewerWindow.setup';
import ToggleBox from '@/kubernetes/components/containerLog/ToggleBox.vue';
import LabelItemGroup from '@/common/components/templates/labelItemGroup/LabelItemGroup.vue';
import LabelItem from '@/common/components/molecules/labelItem/LabelItem.vue';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const { t, isShow, isLoading, logAlertInfo, logAlertMessageInfo } = setup(props, emit);
</script>

<template>
  <base-modal-window
    v-model:visible="isShow"
    size-type="CUSTOM"
    :size="{ width: '800px', height: '400px' }"
    :title="t('WORD.UI.MONITORING.LOG.MESSAGE_TITLE')"
  >
    <ev-loading :model-value="isLoading" />

    <toggle-box
      is-expanded
      border
      toggle-size="small"
      class="logging-info"
    >
      <template #title>
        {{ t('WORD.UI.MONITORING.LOG.INFO') }}
      </template>
      <label-item-group>
        <label-item label="Target Type">{{ logAlertInfo.targetKind }}</label-item>
        <label-item label="Target">{{ logAlertInfo.targetName }}</label-item>
        <label-item label="Log Type">{{ logAlertInfo.logType }}</label-item>
        <label-item label="Path">{{ logAlertInfo.path }}</label-item>
        <label-item label="Path Name">{{ logAlertInfo.pathName }}</label-item>
      </label-item-group>
      <label-item-group>
        <label-item label="Filtered Keys">
          <template v-if="!logAlertInfo.filteredKeys?.length">
            {{ '-' }}
          </template>
          <template v-else>
            <mark
              v-for="item in logAlertInfo.filteredKeys"
              :key="item.keyword"
              class="log-highlight-keyword"
              :style="`--keyword-bg-color: ${item.bgColor}; --keyword-line-color: ${
                item.lineColor ?? 'transparent'
              }`"
            >
              {{ item.keyword }}
            </mark>
          </template>
        </label-item>
      </label-item-group>
    </toggle-box>
    <toggle-box
      :show-toggle="false"
      is-expanded
      border
      class="logging-message"
    >
      <template #title>
        {{ t('WORD.LOG_MESSAGE') }}
      </template>
      <pre
        v-if="logAlertMessageInfo"
        class="logging-message__content"
        v-html="logAlertMessageInfo"
      />
    </toggle-box>
  </base-modal-window>
</template>

<style scoped lang="scss">
:deep(.toggle-box__title) {
  padding: 6px 12px;
  font-weight: 700;
}
:deep(.toggle-box__content) {
  padding: 12px;
  color: var(--color-gray-00-12);
}
:deep(.label-item__label) {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-gray-02-10);
}
:deep(.label-item__body) {
  color: var(--color-gray-00-12);
}
.logging-info {
  margin-bottom: 10px;
}
.logging-message {
  display: flex;
  flex: 1;
  flex-direction: column;
  &:deep(.toggle-box_title) {
    cursor: default;
    pointer-events: none;
  }
  &:deep(.toggle-box__content) {
    flex: 1;
    overflow: auto;
    @include typography('body-3');
  }
  &__content {
    width: fit-content;
  }
}
</style>
