import { SlideOutWindowOption } from './slideOutWindow.types';

export const SLIDE_OUT_WIDTH = {
  SMALL: '380px',
  MEDIUM: '550px',
  LARGE: '900px',
};

export const DEFAULT_OPTION: SlideOutWindowOption = {
  direction: 'right',
  sizeType: 'MEDIUM',
  headerActions: ['open', 'close'],
  hasDimLayer: false,
  minWidth: '0',
  minHeight: '0',
  bodyYScroll: 'auto',
  top: '0px',
  translateX: '0px',
  hasExpand: true,
};

export const EXCLUDE_SELECTORS = {
  always: [
    '#ev-message-box-modal',
    '#ev-window-modal',
    '#base-custom-tooltip',
    '#ev-grid-filtering-items-modal',
    '#ev-context-menu-modal',
    '#ev-grid-filter-setting-modal',
    '#ev-grid-column-setting-modal',
    '.slide-out',
    '.application-page-load-avg-rank__container',
    '.text-tooltip',
    '.top-list__name--clickable',
    '.common-tooltip',
    '.on-site-alarm',
    '.service-card',
    '.bullet-chart',
    '.equalizer-chart',
    '.top-bar-chart-widget-content',
    '.time-series-line-widget__content',
    '[data-radix-popper-content-wrapper]',
  ],
  withCursor: [
    '.hexagon-chart',
    '.cluster-map__main',
    '.object-topology',
    '.transaction-view-summary',
    '.table-body table',
    '.vue-grid-item',
  ],
};
