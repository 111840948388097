const MAIN_FRAMES = {
  DASHBOARD: 'Dashboard',
  DASHBOARD_ALERT_DETAIL: 'Alert Detail - Dashboard',
  DASHBOARD_SHARE_WINDOW: 'Share Window - Dashboard',
  SCATTER_SUMMARY_DETAIL: 'Scatter Summary Detail',
} as const;

export const DASHBOARD_FRAME_NAMES = {
  DASHBOARD: {
    ALERT_COUNT: `${MAIN_FRAMES.DASHBOARD}/Alert Count`,
    DASHBOARD_MAPPING_INFO: `${MAIN_FRAMES.DASHBOARD}/Dashboard Mapping Info`,
    DASHBOARD_INFO: `${MAIN_FRAMES.DASHBOARD}/Dashboard Info`,
    DASHBOARD_WIDGET_INFO: `${MAIN_FRAMES.DASHBOARD}/Dashboard Widgets Info`,
    DASHBOARD_USER_GROUP: `${MAIN_FRAMES.DASHBOARD}/Dashboard User Group List`,
    DASHBOARD_USER_GROUP_DELETE: `${MAIN_FRAMES.DASHBOARD}/[Delete] Dashboard User Group List`,
    DASHBOARD_USER_GROUP_MOVE: `${MAIN_FRAMES.DASHBOARD}/[Move] Dashboard User Group List`,
    DASHBOARD_USER_GROUP_SAVE: `${MAIN_FRAMES.DASHBOARD}/[Save] Dashboard User Group List`,
    DASHBOARD_LIST: `${MAIN_FRAMES.DASHBOARD}/Dashboard List`,
    DASHBOARD_LIST_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update] Dashboard List`,
    DASHBOARD_LIST_DELETE: `${MAIN_FRAMES.DASHBOARD}/[Delete] Dashboard List`,
    DASHBOARD_EXPORT: `${MAIN_FRAMES.DASHBOARD}/[Export] Dashboard`,
    DASHBOARD_CLIENT_EXPORT: `${MAIN_FRAMES.DASHBOARD}/[Export] Dashboard - Client`,
    DASHBOARD_IMPORT: `${MAIN_FRAMES.DASHBOARD}/[Import] Dashboard`,
    SET_START_SCREEN_DASHBOARD_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update] Start Screen Dashboard`,
    FAVORITE_DASHBOARD_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update] Favorite Dashboard`,
    REPORT_DASHBOARD_SET: `${MAIN_FRAMES.DASHBOARD}/[Set] Report Dashboard`,
    GLOBAL_VARIABLES: `${MAIN_FRAMES.DASHBOARD}/Global Variables`,
    GLOBAL_VARIABLES_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update]Global Variables`,
    VIEW_PACK_LIST: `${MAIN_FRAMES.DASHBOARD}/View Pack List`,
    VIEW_PACK_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update] View Pack`,
    VIEW_PACK_DELETE: `${MAIN_FRAMES.DASHBOARD}/[Delete] View Pack`,
    VIEW_PACK_IMPORT: `${MAIN_FRAMES.DASHBOARD}/[Import] View Pack`,
    VIEW_PACK_EXPORT: `${MAIN_FRAMES.DASHBOARD}/[Export] View Pack`,
    PRESET_LIST: `${MAIN_FRAMES.DASHBOARD}/Preset List`,
    START_SCREEN_SET: `${MAIN_FRAMES.DASHBOARD}/[Set] Start Screen`,
    WIDGETS: `${MAIN_FRAMES.DASHBOARD} Widgets`,
    CURRENT_DASHBOARD_TARGET_LIST: `${MAIN_FRAMES.DASHBOARD}/Current Dashboard Target List`,
    DUPLICATE_DASHBOARD: `${MAIN_FRAMES.DASHBOARD}/[Create] Duplicate Dashboard`,
    TARGETS_COLUMN_DATA_LIST: `${MAIN_FRAMES.DASHBOARD}/Targets Column Data List`,
    DASHBOARD_SHARE_DELETE: `${MAIN_FRAMES.DASHBOARD}/[Delete] Share Dashboard`,
  },
  DASHBOARD_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear System Alert`,
  },
  DASHBOARD_SHARE_WINDOW: {
    USER_GROUP_LIST: `${MAIN_FRAMES.DASHBOARD_SHARE_WINDOW}/User Group List`,
    USER_LIST: `${MAIN_FRAMES.DASHBOARD_SHARE_WINDOW}/User List`,
    SHARE_DASHBOARD_SAVE: `${MAIN_FRAMES.DASHBOARD_SHARE_WINDOW}/[Save] Share Dashboard`,
    SHARE_DASHBOARD_DELETE: `${MAIN_FRAMES.DASHBOARD_SHARE_WINDOW}/[Delete] Share Dashboard`,
  },
  SCATTER_SUMMARY_DETAIL: {
    SCATTER_CHART: `${MAIN_FRAMES.SCATTER_SUMMARY_DETAIL}/Scatter Chart`,
    LIST: `${MAIN_FRAMES.SCATTER_SUMMARY_DETAIL}/List`,
    WIDGET_MAPPING_INFO: `${MAIN_FRAMES.SCATTER_SUMMARY_DETAIL}/Widget Mapping Info`,
  },
} as const;
