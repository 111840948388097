import { DEFAULT_KEYWORD_BG_COLOR, DEFAULT_KEYWORD_LINE_COLOR } from './keywordSettingModal.define';

export const fixColor = (type: 'line' | 'bg', color?: string) => {
  if (color?.startsWith('var')) {
    return color;
  }

  if (!color || (!color.startsWith('#') && !color.startsWith('rgb'))) {
    return type === 'line' ? DEFAULT_KEYWORD_LINE_COLOR : DEFAULT_KEYWORD_BG_COLOR;
  }

  return color;
};
