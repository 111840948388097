<script setup lang="ts">
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import PopupListElement from '../popupListElement/PopupListElement.vue';
import PopupLayout from '../PopupLayout.vue';
import { setup } from './userSupportPopup.setup';

const {
  t,

  moduleInfoList,
  fileInfoList,
} = setup();
</script>

<template>
  <popup-layout class="user-support-popup">
    <template #header>
      <a
        v-for="fileInfo in fileInfoList"
        :key="fileInfo.label"
        :href="fileInfo.path"
        :download="fileInfo.name"
        class="user-support-popup__download"
        type="button"
      >
        <div class="download-icon">
          <ev-icon
            icon="icon-download"
            size="small"
          />
        </div>
        <p class="download-title">
          {{ fileInfo.label }}
        </p>
      </a>
    </template>
    <template #list>
      <popup-list-element
        icon="icon-popup-info"
        :text="t('WORD.GNB.HELP.MODULE_VERSION')"
        html-type="div"
        class="module-version title"
      />
      <popup-list-element
        v-for="moduleInfo in moduleInfoList"
        :key="moduleInfo.title"
        html-type="div"
        class="module-version element"
      >
        <template #left-side>
          <badge-item
            size="xsmall"
            html-type="div"
            class="version-badge"
          >
            <span class="version">{{ moduleInfo.version }}</span>
          </badge-item>
          <p class="title">{{ moduleInfo.title }}</p>
        </template>
      </popup-list-element>
    </template>
  </popup-layout>
</template>

<style scoped lang="scss">
.user-support-popup {
  width: 256px;
  user-select: none;

  &__download {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 8px 12px;

    .download-icon {
      width: 24px;
      height: 24px;
      padding: 4px;
      border-radius: 4px;
      background-color: var(--color-gray-06-04);

      i {
        width: 16px;
        height: 16px;
        background-color: var(--color-gray-02-10);
      }
    }

    .download-title {
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: var(--color-gray-00-12);
      text-align: center;
    }
  }

  :deep(.base-menu-popup__bottom) {
    padding-top: 4px;
  }

  :deep(.base-menu-popup__list) {
    padding: 8px;
  }

  .module-version {
    height: 16px;

    :deep(> div) {
      padding: 0 !important;
    }

    &.title {
      margin-bottom: 12px;

      :deep(.left-side > span) {
        color: var(--color-gray-03-09) !important;
      }
    }

    &.element {
      font-weight: 500;
      color: var(--color-gray-00-12);

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .version {
        font-size: 10px;
        line-height: 12px;
      }

      .title {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
}
</style>
