<template>
  <div
    class="separator"
    role="separator"
    :aria-orientation="props.orientation"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    orientation?: 'horizontal' | 'vertical';
    mx?: number;
    my?: number;
  }>(),
  {
    orientation: 'horizontal',
    mx: 0,
    my: 0,
  },
);

const mxGap = computed(() => `${props.mx}px`);
const myGap = computed(() => `${props.my}px`);
</script>

<style scoped>
.separator {
  border: none;
  border-top: 1px solid var(--color-gray-05-05);
  border-left: 1px solid var(--color-gray-05-05);
  margin-inline: v-bind('mxGap');
  margin-block: v-bind('myGap');
  &[aria-orientation='horizontal'] {
    width: 100%;
  }
  &[aria-orientation='vertical'] {
    height: 100%;
  }
}
</style>
