import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { sessionCountByTypePostSessionPostgresV7ControllerAxios } from '@/openapi/postgresqlV7/api/session-postgres-v7-controller-api';
import { SessionTypeItem } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface DbSessionTypeState {
  dbSessionTypes: SessionTypeItem[];
  errorStatusText: string;
}

export const dbSessionType: Module<DbSessionTypeState, RootState> = {
  namespaced: true,
  state: {
    dbSessionTypes: [],
    errorStatusText: '',
  },
  mutations: {
    setErrorStatusText: (state: DbSessionTypeState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
    setDbSessionTypes: (state: DbSessionTypeState, data: SessionTypeItem[]) => {
      state.dbSessionTypes = data;
    },
  },
  actions: {
    async fetchDbSessionTypes({ commit }, instanceIds: string[] = []) {
      const frameName = FRAME_NAMES.POSTGRESQL_MULTI_VIEW.DB_SESSION_TYPE;
      try {
        if (!instanceIds.length) {
          return;
        }
        const { data } = await sessionCountByTypePostSessionPostgresV7ControllerAxios({
          frameName,
          request: {
            instanceIds: instanceIds as unknown as Set<string>, // API타입 리스트로 전환시 예전 서비스 로직이라 사이드 이펙트 우려가 된다하여 타입 단언 선언
          },
        });

        commit('setDbSessionTypes', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getErrorStatusText: (state: DbSessionTypeState) => state.errorStatusText,
    getDbSessionTypes: (state: DbSessionTypeState) => state.dbSessionTypes,
  },
};
