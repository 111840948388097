import { computed, ref, watch } from 'vue';
import { BASE_MENU_VALUE } from '@/common/components/organisms/baseMenu/baseMenu.define';
import type { MenuInfo } from '@/common/components/organisms/baseMenu/types';
import { useShowSubPopup } from '../popups.uses';

export interface Props {
  menuInfo: MenuInfo | null;
  dashboardMenuList?: MenuInfo[];
  analysisBoardMenuList?: MenuInfo[];
}

export const setup = (props: Props) => {
  const subMenuInfo = ref<MenuInfo | null>(null);
  const clickedMenu = ref<string | null>(null);

  const { mainMenuRef, subMenuRef, subMenuPopupStyle, setHoveredItemPosition } = useShowSubPopup();

  const computedDashboardMenu = computed<MenuInfo | null>(() => {
    if (!props.menuInfo?.dashboardList) {
      return null;
    }
    return {
      ...props.menuInfo.dashboardList,
      subMenuList: props.dashboardMenuList,
    };
  });
  const computedAnalysisBoardMenu = computed<MenuInfo | null>(() => {
    if (!props.menuInfo?.analysisBoardList) {
      return null;
    }
    return {
      ...props.menuInfo.analysisBoardList,
      subMenuList: props.analysisBoardMenuList,
    };
  });
  const computedSubMenuList = computed<MenuInfo[]>(() => {
    if (!subMenuInfo.value) {
      return [];
    }
    if (subMenuInfo.value.value === BASE_MENU_VALUE.DASHBOARD) {
      return computedDashboardMenu.value?.subMenuList ?? [];
    }
    if (subMenuInfo.value.value === BASE_MENU_VALUE.ANALYSISBOARD) {
      return computedAnalysisBoardMenu.value?.subMenuList ?? [];
    }
    return subMenuInfo.value.subMenuList ?? [];
  });

  const getMidCategoryTitle = (title: string) => {
    return computedSubMenuList.value.length === 1 && title === subMenuInfo.value?.text ? '' : title;
  };

  const clearSubMenu = (forceClear?: boolean) => {
    if (!!clickedMenu.value && !forceClear) {
      return;
    }
    clickedMenu.value = null;
    subMenuInfo.value = null;
  };

  const setSubMenu = (e: MouseEvent, menuInfo: MenuInfo) => {
    setHoveredItemPosition(e);
    subMenuInfo.value = menuInfo;
  };

  const onClickSubMenu = (e?: MouseEvent, menuInfo?: MenuInfo | null) => {
    if (!e || !menuInfo?.subMenuList?.length) {
      clearSubMenu();
      return;
    }
    if (clickedMenu.value === menuInfo.value) {
      clearSubMenu(true);
      return;
    }
    clickedMenu.value = menuInfo.value;
    setSubMenu(e, menuInfo);
  };

  const onHoverSubMenu = (e?: MouseEvent, menuInfo?: MenuInfo | null) => {
    if (clickedMenu.value) {
      // 클릭 된 메뉴가 있을 경우 호버 불가
      return;
    }
    if (!e || !menuInfo?.subMenuList?.length) {
      clearSubMenu();
      return;
    }
    setSubMenu(e, menuInfo);
  };

  watch(
    () => props.menuInfo,
    () => {
      clearSubMenu(true);
    },
  );

  return {
    mainMenuRef,
    subMenuRef,
    subMenuPopupStyle,

    subMenuInfo,
    clickedMenu,
    computedDashboardMenu,
    computedAnalysisBoardMenu,
    computedSubMenuList,

    getMidCategoryTitle,

    onHoverSubMenu,
    onClickSubMenu,
    clearSubMenu,
  };
};
