import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { ThreadTab } from '@/openapi/mysqlV7/model';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { threadTabThreadV7ControllerAxios } from '@/openapi/mysqlV7/api/thread-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface ThreadState {
  thread: ThreadTab[];
  errorStatusText: string;
}

export const thread: Module<ThreadState, RootState> = {
  namespaced: true,
  state: {
    thread: [],
    errorStatusText: '',
  },
  mutations: {
    setThread(state: ThreadState, data: ThreadTab[]) {
      state.thread = data;
    },
    setErrorStatusText(state: ThreadState, errorStatusText: string) {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchThread({ commit }, instanceId) {
      const frameName = 'thread';
      try {
        const param = {
          filter: 'DEFAULT' as 'Active' | 'DEFAULT' | 'Long',
          instanceId,
          frameName: FRAME_NAMES.SESSION_TAB.THREAD,
          isTimeout: true,
          isFullText: false,
        };
        const {
          data: { data = [] },
        } = await threadTabThreadV7ControllerAxios(param);
        commit('setThread', data);
        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getThread: (state: ThreadState) => state.thread,
    getErrorStatusText: (state: ThreadState) => state.errorStatusText,
  },
};
