import { computed, ref, watch, WritableComputedRef } from 'vue';
import { openBrowserPopup } from '@/common/utils/browserPopupUtils';
import { POPUP_MIN_SIZE } from '@/common/components/organisms/alertListDetail/alertListDetail.setup';
import { storeToRefs } from 'pinia';
import { useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { useWidgetDataParams } from '@/dashboard/components/widgets/uses';
import { TagsRequest } from '@/openapi/data/model';
import { webStorageController } from '@/common/utils/webStorage.util';
import { useInternational } from '@/common/locale';
import {
  CurrentRouteInfo,
  InstanceId,
  useInstanceIds,
} from '@/database/components/overviewAlert/overviewAlert.uses';
import {
  useClusterTopologyAlertStore,
  useObjectTopologyAlertStore,
} from '@/config/stores/kubernetes';
import { useBusinessAlertSlideStore } from '@/service/stores/business-alert-slide';
import { useNpmAlertDetails } from '@/infrastructure/views/npm/networkPerformanceMonitoring.context';
import { SlideViewType } from './alertListDetail.types';

export interface Props {
  isVisible: boolean;
  type: SlideViewType;
}
export interface Emit {
  (e: 'update:is-visible', value: boolean): void;
  (e: 'openDetail'): void;
}

const useUserTags = (isShow: WritableComputedRef<boolean>) => {
  const { monitoringDashboard } = storeToRefs(useDashboardViewStore());
  const { getRequestUserTags } = useWidgetDataParams();

  watch(
    (): [number, TagsRequest[]] => [monitoringDashboard.value.dashboardId, getRequestUserTags()],
    ([dashboardId, userTags]) => {
      isShow.value = false;
      webStorageController.setItem({
        type: 'session',
        key: 'alertDetailParams',
        value: JSON.stringify({ dashboard: { dashboardId, userTags } }),
      });
    },
  );
};
const useTargetIds = (isShow: WritableComputedRef<boolean>) => {
  const { currentRouteInfo, getInstanceIds } = useInstanceIds();
  const instanceIds = computed(() => getInstanceIds());
  watch(
    (): [CurrentRouteInfo, InstanceId[]] => [currentRouteInfo.value, instanceIds.value],
    ([currRoute, ids]) => {
      if (ids) {
        isShow.value = false;

        const { view, dbType } = currRoute;
        webStorageController.setItem({
          type: 'session',
          key: 'alertDetailParams',
          value: JSON.stringify({ database: { instanceIds: getInstanceIds(), view, dbType } }),
        });
      }
    },
    { deep: true, immediate: true },
  );
};
const useObjectTopologyTargetIds = (isShow: WritableComputedRef<boolean>) => {
  const objectTopologyAlertStore = useObjectTopologyAlertStore();
  const { apiParams } = storeToRefs(objectTopologyAlertStore);
  watch(
    () => apiParams.value,
    (params) => {
      isShow.value = false;
      webStorageController.setItem({
        type: 'session',
        key: 'alertDetailParams',
        value: JSON.stringify({
          objectTopology: { ...params },
        }),
      });
    },
  );
};
const useClusterTopologyTargetIds = (isShow: WritableComputedRef<boolean>) => {
  const clusterTopologyAlertStore = useClusterTopologyAlertStore();
  const { apiParams } = storeToRefs(clusterTopologyAlertStore);
  watch(
    () => apiParams.value,
    (params) => {
      isShow.value = false;
      webStorageController.setItem({
        type: 'session',
        key: 'alertDetailParams',
        value: JSON.stringify({
          clusterTopology: { ...params },
        }),
      });
    },
  );
};
const useBusinessGroupIds = () => {
  const { targetIds } = storeToRefs(useBusinessAlertSlideStore());
  watch(targetIds, (ids) => {
    webStorageController.setItem({
      type: 'session',
      key: 'alertDetailParams',
      value: JSON.stringify({
        business: { targetIds: ids },
      }),
    });
  });
};

const useNetworkObjectTargetIds = (isShow: WritableComputedRef<boolean>) => {
  const { targetIds } = useNpmAlertDetails();

  watch(targetIds, (_targetIds) => {
    isShow.value = false;
    webStorageController.setItem({
      type: 'session',
      key: 'alertDetailParams',
      value: JSON.stringify({
        networkObject: { targetIds: _targetIds },
      }),
    });
  });
};

const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const isShow = computed({
    get: () => props.isVisible,
    set: (value) => emit('update:is-visible', value),
  });

  useUserTags(isShow);
  useTargetIds(isShow);
  useObjectTopologyTargetIds(isShow);
  useClusterTopologyTargetIds(isShow);
  useBusinessGroupIds();
  useNetworkObjectTargetIds(isShow);

  const slideWidth = ref('900px');
  const onExpandSlide = (isExpand: boolean) => {
    slideWidth.value = isExpand ? '1200px' : '900px';
  };
  const onClickOpenPopup = () => {
    isShow.value = false;
    openBrowserPopup({
      query: {
        viewName: 'alertListDetail',
        isShow: true,
        type: props.type,
      },
      popupStyle: { width: POPUP_MIN_SIZE.WIDTH, height: POPUP_MIN_SIZE.HEIGHT },
      isSingle: true,
      sharedStorageInfo: {
        session: ['alertDetailParams'],
      },
    });
  };

  return {
    isShow,
    slideWidth,
    onExpandSlide,
    onClickOpenPopup,
    t,
  };
};

export { setup };
