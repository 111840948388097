import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { NotificationIdMap } from '@/config/views/alertGroup/alertRuleWindow.types';
import { getNotificationNotificationControllerAxios } from '@/openapi/metaV6/api/notification-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { usePublicUserGroupStore } from '@/config/stores/public-user-group';
import { Notification } from '@/openapi/metaV6/model';

const useUser = () => {
  const userGroupStore = usePublicUserGroupStore();
  const { userGroups } = storeToRefs(userGroupStore);
  const allUsers = computed(() => userGroupStore.getUsersByGroupId(-1));

  const apiResponse = ref(false);
  const fetchUserGroup = async (isInit: boolean) => {
    if (apiResponse.value && !isInit) {
      return;
    }
    apiResponse.value = true;
    try {
      await userGroupStore.dispatchUserGroups({
        frameName: FRAME_NAMES.CONFIG_USER_ALERT_RULE_WINDOW.USER_GROUP,
      });
      await userGroupStore.dispatchUsersByGroupId({
        groupId: -1,
        frameName: FRAME_NAMES.CONFIG_USER_ALERT_RULE_WINDOW.USER_LIST,
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const getUserNamesByIds = (ids: number[]) => {
    const userList = userGroupStore.getUsersByGroupId(-1);
    return ids
      .map((id) => {
        const userInfo = userList.find((item) => item?.userId === id);
        return userInfo?.email ? `${userInfo.name!}(${userInfo.email!})` : userInfo?.name ?? '';
      })
      .filter((v) => !!v);
  };
  return {
    userGroups,
    allUsers,
    fetchUserGroup,
    getUserNamesByIds,
  };
};

type NotificationProvideAsGrid = 'slack' | 'telegram' | 'webhook';
type ApiResponse = (any | Notification)[] | [];

const useNotification = () => {
  const notificationByType = ref<Record<NotificationProvideAsGrid, ApiResponse>>({
    slack: [],
    telegram: [],
    webhook: [],
  });

  const apiResponse = ref<{ [key in string]: boolean }>({});

  const fetchNotificationByType = async (type: NotificationProvideAsGrid, isInit: boolean) => {
    if (apiResponse.value[type] && !isInit) {
      return;
    }
    apiResponse.value[type] = true;
    try {
      const { data } = await getNotificationNotificationControllerAxios({
        type: type.toUpperCase() as Parameters<
          typeof getNotificationNotificationControllerAxios
        >[0]['type'],
        frameName: `${FRAME_NAMES.CONFIG_USER_ALERT_RULE_WINDOW.NOTIFICATION} - ${type}`,
      });
      notificationByType.value[type] = data?.data ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNotificationAllType = async (isInit: boolean) => {
    const notificationTypes: NotificationProvideAsGrid[] = ['slack', 'telegram', 'webhook'];
    await Promise.all(notificationTypes.map((type) => fetchNotificationByType(type, isInit)));
  };

  const getNotificationRowsByType = (type: NotificationProvideAsGrid) => {
    return notificationByType.value[type];
  };
  const idMap = {
    slack: 'slackId',
    telegram: 'telegramId',
    webhook: 'webhookId',
  } as const;
  const getNotificationNamesByIds = (type: keyof typeof idMap, ids: number[]) => {
    const notificationList = notificationByType.value[type];
    const idField = idMap[type];

    return (
      notificationList?.filter((item) => ids.includes(item[idField])).map((item) => item.name) ?? []
    );
  };

  return {
    notificationByType,

    fetchNotificationByType,
    fetchNotificationAllType,

    getNotificationRowsByType,
    getNotificationNamesByIds,
  };
};

export const useAlertNotificationStore = defineStore('alertNotification', () => {
  const storedData = ref<NotificationIdMap>({});

  const { userGroups, allUsers, fetchUserGroup, getUserNamesByIds } = useUser();
  const { notificationByType, fetchNotificationAllType, getNotificationNamesByIds } =
    useNotification();

  const fetchAllNotificationInfo = (isInit = false) => {
    fetchUserGroup(isInit);
    fetchNotificationAllType(isInit);
  };

  const setStoredData = (data: NotificationIdMap) => {
    storedData.value = data;
  };
  const initData = () => {
    storedData.value = {};
  };
  return {
    storedData,

    userGroups,
    allUsers,
    getUserNamesByIds,

    notificationByType,
    getNotificationNamesByIds,

    fetchAllNotificationInfo,
    setStoredData,
    initData,
  };
});
