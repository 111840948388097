import { GridOption, CustomColumn, TreeGridOption } from '@/common/utils/types';
import { treeGridOption } from '@/common/utils/define';
import { i18n } from '@/common/locale';
import { PodElement } from '@/openapi/kubernetes/model';

export const baseGridOption: GridOption = {
  adjust: true,
  useFilter: false,
  rowMinHeight: 32,
  rowHeight: 32,
  scrollWidth: 8,
  useCheckbox: {
    use: false,
  },
  style: {
    border: 'rows',
  },
};

export const listViewGridOption: GridOption = {
  ...baseGridOption,
  rowMinHeight: 32,
  rowHeight: 32,
  useGridSetting: {
    use: true,
  },
  useSelection: {
    use: true,
    disableDeselect: true,
  },
  page: {
    use: true,
    useClient: false,
  },
  maintainScrollOnUpdateRows: true,
};

export const listViewConfigGridOption: GridOption = {
  ...listViewGridOption,
  useCheckbox: {
    use: true,
    mode: 'multi',
    headerCheck: true,
  },
  useGridSetting: {
    use: true,
  },
};

export const listViewSearchBarOption = {
  use: true,
  mode: 'full',
  useClientFilter: true,
} as const;

export const listViewSearchBarOptionWithUseClientFilterFalse = {
  ...listViewSearchBarOption,
  useClientFilter: false,
} as const;

export const listViewPagination = {
  use: true,
  showPageInfo: true,
  showSetting: true,
  useClient: true,
};

export const detailGridOption: GridOption = {
  ...baseGridOption,
  rowMinHeight: 28,
  rowHeight: 28,
  page: {
    use: true,
    useClient: false,
  },
};

export const eventGridColumns: CustomColumn[] = [
  {
    caption: 'Type',
    field: 'type',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Create Time',
    field: 'createTime',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Kind',
    field: 'kind',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Name',
    field: 'name',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Namespace',
    field: 'namespace',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Reason',
    field: 'reason',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Message',
    field: 'message',
    type: 'string',
    searchable: true,
  },
];

export const containersGridColumns: CustomColumn[] = [
  {
    caption: 'Container Name',
    field: 'name',
    type: 'string',
    clickable: true,
    searchable: true,
  },
  {
    caption: 'Status',
    field: 'status',
    type: 'string',
    rendererType: 'label',
    align: 'center',
    searchable: true,
  },
  {
    caption: 'CPU Usage(%)',
    field: 'cpuUsage',
    type: 'number',
    rendererType: 'ratio',
    searchable: true,
  },
  {
    caption: 'Memory Usage(%)',
    field: 'memoryUsage',
    type: 'number',
    rendererType: 'ratio',
    searchable: true,
  },
  {
    caption: 'RX',
    field: 'rx',
    type: 'number',
    searchable: true,
  },
  {
    caption: 'TX',
    field: 'tx',
    type: 'number',
    searchable: true,
  },
  {
    caption: 'Image',
    field: 'image',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Age',
    field: 'age',
    type: 'age',
    searchable: true,
  },
  {
    caption: 'containerId',
    field: 'containerId',
    type: 'string',
    hide: true,
  },
  {
    caption: 'hostId',
    field: 'hostId',
    type: 'string',
    hide: true,
  },
  {
    caption: 'hostGroupId',
    field: 'hostGroupId',
    type: 'string',
    hide: true,
  },
];

export const podsGridColumns: CustomColumn<keyof PodElement>[] = [
  {
    caption: 'Pod Name',
    field: 'name',
    type: 'string',
    clickable: true,
    searchable: true,
  },
  {
    caption: 'Namespace',
    field: 'namespace',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Containers',
    field: 'containers',
    type: 'string',
  },
  {
    caption: 'Alert',
    field: 'lastLevel',
    type: 'string',
    rendererType: 'capitalize-label',
    searchable: true,
  },
  {
    caption: 'Status',
    field: 'status',
    type: 'string',
    rendererType: 'label',
    searchable: true,
  },
  {
    caption: 'Restarts Count',
    field: 'restarts',
    type: 'number',
    searchable: true,
  },
  {
    caption: 'Age',
    field: 'age',
    type: 'age',
    searchable: true,
  },
  {
    caption: 'UID',
    field: 'uid',
    type: 'string',
    hide: true,
  },
];

export const gridSettingOption = {
  useGridSetting: {
    use: true,
    columnMenuText: i18n.global.t('WORD.COLUMN_LIST'),
    searchText: i18n.global.t('WORD.SEARCH'),
    emptyText: i18n.global.t('MESSAGE.NO_DATA'),
    okBtnText: i18n.global.t('WORD.OK'),
  },
  columnMenuText: {
    hide: i18n.global.t('WORD.HIDE'),
  },
  emptyText: i18n.global.t('MESSAGE.NO_DATA'),
};

export const paTreeGridOption: TreeGridOption = {
  adjust: true,
  showHeader: true,
  rowHeight: 32,
  parentIcon: 'none',
  childIcon: 'none',
  style: {
    border: 'none' as 'rows' | 'none',
    highlight: -1,
  },
  useCheckbox: {
    use: false,
  },
  expandIcon: 'ev-icon-square-plus',
  collapseIcon: 'ev-icon-square-minus',
  useGridSetting: {
    use: true,
    columnMenuText: i18n.global.t('WORD.COLUMN_LIST'),
    searchText: i18n.global.t('WORD.SEARCH'),
    emptyText: i18n.global.t('MESSAGE.NO_DATA'),
    okBtnText: i18n.global.t('WORD.OK'),
  },
  columnMenuText: {
    hide: i18n.global.t('WORD.HIDE'),
  },
  emptyText: i18n.global.t('MESSAGE.NO_DATA'),
};

export const statChangeTreeGridOptions = {
  showHeader: false,
  useFilter: true,
  rowHeight: 36,
  style: {
    border: 'none',
    highlight: -1,
  },
  parentIcon: 'none',
  childIcon: 'none',
};

export const listViewTreeGridOption = {
  ...treeGridOption,
  rowHeight: 32,
  useGridSetting: {
    use: true,
  },
  useSelection: {
    use: true,
    disableDeselect: true,
  },
};

export const listViewConfigTreeGridOption: GridOption = {
  ...listViewTreeGridOption,
  useCheckbox: {
    use: true,
    headerCheck: true,
  },
};

export const ROW_DATA_INDEX = 2;
export const ROW_DISABLED_INDEX = 6;
