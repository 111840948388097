import { computed, onMounted, ref } from 'vue';
import { isNil } from 'lodash-es';
import { useStore } from '@/common/store';
import router from '@/common/router';
import { USER_GENERAL_SETTING_ITEMS } from '@/common/utils';
import type { MyInfo } from '@/common/api/resTypes/userInfoRes';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { RoleIdMap as ROLE_ID_MAP } from '@/common/auth/auth.defines';
import type { UpdateUserV7Request } from '@/openapi/metaV6/model';
import { patchMyInfoUserV7ControllerAxios } from '@/openapi/metaV6/api/user-v7-controller-api';
import type { UserProfile, GroupItem, UserTheme } from './myInfoPopup.types';
import { THEME_MAPPER, THEME_START_CASE } from './myInfoPopup.define';

export const useMyInfoData = () => {
  const store = useStore();
  const userInfo = computed<MyInfo>(() => store.getters['myInfo/getAccountInfo']);

  const getLanguage = (field: keyof (typeof USER_GENERAL_SETTING_ITEMS.lang)[0], value: string) =>
    USER_GENERAL_SETTING_ITEMS.lang.find((item) => item[field] === value) ??
    USER_GENERAL_SETTING_ITEMS.lang[0];

  const getTheme = (value?: string) =>
    USER_GENERAL_SETTING_ITEMS.theme.find((item) => item.value === value) ??
    USER_GENERAL_SETTING_ITEMS.theme[0];

  const userProfile = computed<UserProfile>(() => ({
    email: userInfo.value.email,
    name: userInfo.value.name,
    profileImage: userInfo.value.profileImage,
    role: userInfo.value.role,
  }));
  const userTheme = computed<UserTheme>(() => getTheme(userInfo.value.theme).name as UserTheme);
  const userLanguage = computed<string>(() => getLanguage('value', userInfo.value.language).name);
  const groupItems = computed<GroupItem[]>(() =>
    userInfo.value.groups.map((item) => ({
      value: item.groupId,
      name: item.name ?? `Undefined Group ${item.groupId}`,
    })),
  );

  const languageInfo = ref<string>(userLanguage.value);
  const themeInfo = ref<UserTheme>(THEME_START_CASE[0]);
  const selectedGroup = ref<GroupItem | null>(null);
  const isGroupListOpened = ref<boolean>(false);

  const fetchMyInfo = async () => {
    try {
      const language = getLanguage('name', languageInfo.value).value;
      const theme = THEME_MAPPER[themeInfo.value];
      const request: UpdateUserV7Request = {
        ...userInfo.value,
        email: userInfo.value.email,
        name: userInfo.value.name,
        theme,
        language,
      };

      if (userInfo.value.role !== ROLE_ID_MAP.Master) {
        request.groups = userInfo.value.groups.map((item) => {
          item.fixed = item.groupId === selectedGroup.value?.value;
          return item;
        });
      }
      await patchMyInfoUserV7ControllerAxios({
        request,
        frameName: FRAME_NAMES.CONFIG_MY_INFO.PATCH,
      });
      router.go(0);
    } catch (e) {
      console.log(e);
    }
  };

  const changeGroup = async (group: GroupItem) => {
    if (group.value === selectedGroup.value?.value) {
      return;
    }
    selectedGroup.value = group;
    await fetchMyInfo();
  };

  const changeLanguage = async (language?: string) => {
    if (!language || language === languageInfo.value) {
      return;
    }
    languageInfo.value = language;
    await fetchMyInfo();
  };

  const changeTheme = async (theme?: string) => {
    if (!theme || theme === themeInfo.value) {
      return;
    }
    themeInfo.value = theme as UserTheme;
    await fetchMyInfo();
  };

  onMounted(() => {
    languageInfo.value = userLanguage.value;
    themeInfo.value = userTheme.value;
    const currentGroup = userInfo.value.groups.find((item) => item.fixed);
    if (currentGroup && currentGroup.name && !isNil(currentGroup.groupId)) {
      selectedGroup.value = { name: currentGroup.name, value: currentGroup.groupId };
    } else {
      selectedGroup.value = null;
    }
  });

  return {
    userProfile,
    languageInfo,
    themeInfo,
    groupItems,

    selectedGroup,
    isGroupListOpened,

    changeGroup,
    changeLanguage,
    changeTheme,
  };
};
