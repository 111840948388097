import { computed, getCurrentInstance } from 'vue';
import type { IconType } from '@/types/icon.types';

export interface Props {
  htmlType?: 'button' | 'div';
  text?: string;
  icon?: IconType;
  selected?: boolean;
  disabled?: boolean;
}

export interface Emit {
  (e: 'click', event?: MouseEvent): void;
  (e: 'hover', event?: MouseEvent): void;
}

export const setup = (
  props: Omit<Props, 'htmlType'> & { htmlType: 'button' | 'div' },
  emit: Emit,
) => {
  const instance = getCurrentInstance()!;

  const isClickable = computed<boolean>(
    () => props.htmlType === 'button' && !!instance.vnode.props?.onClick && !props.disabled,
  );
  const isHoverable = computed<boolean>(() => !!instance.vnode.props?.onHover && !props.disabled);

  const onClick = (e: MouseEvent) => {
    if (!isClickable.value) {
      return;
    }
    emit('click', e);
  };

  const onHover = (e: MouseEvent) => {
    if (!isHoverable.value) {
      return;
    }
    emit('hover', e);
  };

  return {
    isClickable,
    isHoverable,

    onClick,
    onHover,
  };
};
