import {
  CustomFilterOption,
  FilterItem,
  FilterSelectedValues,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { ComputedRef, ref } from 'vue';
import { filterFunctionByOperator } from '@/common/components/molecules/filterSearch/filterSearch.utils';
import { CustomColumn } from '@/common/utils';

export const useEvGridFilterSearch = <Row extends unknown>({
  columns,
  filterItems,
}: {
  columns: ComputedRef<CustomColumn[] | Readonly<CustomColumn[]>>;
  filterItems: FilterItem[];
}) => {
  const filterSearchResultMV = ref<FilterSelectedValues>();

  const commonFilterCondition = (
    row: Row,
    token: {
      key: string;
      operator: string;
      value: string;
    },
    isTreeGrid: boolean,
  ): boolean => {
    const targetColumnIndex = columns?.value?.findIndex((column) => column.field === token.key);
    if (targetColumnIndex < 0) {
      return false;
    }

    const { type } = columns?.value?.[targetColumnIndex];
    const multi = filterItems?.find((item) => item.key.id === token.key)?.values?.multi;
    const searchValues: string[] = multi ? token.value.split(',') : [token.value];
    const targetValue = isTreeGrid ? row[token.key] : row[targetColumnIndex];

    return filterFunctionByOperator({
      targetValue,
      operator: token.operator,
      searchValues,
      type,
    });
  };

  const filterGridByFilterSearch = (
    rows,
    result: FilterSelectedValues | undefined,
    customOption?: CustomFilterOption[],
  ) => {
    let filterResult: any[] = rows;

    if (result?.tokens.length) {
      filterResult = filterResult.filter((row) => {
        return result?.logicalOperator === 'AND'
          ? result.tokens.every((token) => {
              const custom = customOption && customOption.find((option) => option.id === token.key);
              if (custom) {
                return custom.filterFn(row, token);
              }

              return commonFilterCondition(row, token, false);
            })
          : result.tokens.some((token) => {
              const custom = customOption && customOption.find((option) => option.id === token.key);
              if (custom) {
                return custom.filterFn(row, token);
              }

              return commonFilterCondition(row, token, false);
            });
      });
    }

    return filterResult;
  };

  const filterTree = (
    rows,
    filterValues: FilterSelectedValues,
    parentExpand = true,
    customOption?: CustomFilterOption[],
  ): void => {
    rows.forEach((node) => {
      const isMatchedNode =
        filterValues?.logicalOperator === 'AND'
          ? filterValues.tokens.every((token) => {
              const custom = customOption && customOption.find((option) => option.id === token.key);
              if (custom) {
                return custom.filterFn(node, token);
              }

              return commonFilterCondition(node, token, true);
            })
          : filterValues.tokens.some((token) => {
              const custom = customOption && customOption.find((option) => option.id === token.key);
              if (custom) {
                return custom.filterFn(node, token);
              }

              return commonFilterCondition(node, token, true);
            });

      if (node.children) {
        filterTree(node.children, filterValues, parentExpand && node.expand);
      }

      // 자식 노드 중 하나라도 조건에 맞는 node가 있을 경우 부모 노드 show 처리
      const isIncludeNotFilteredChild = !!(node.children ?? []).some((child) => child.show);
      node.show = isMatchedNode || isIncludeNotFilteredChild;
      node.isFilter = !node.show;

      if (!node.children?.length) return;

      if (isMatchedNode) {
        // 부모 노드가 show인 경우, 자식 모두 show 처리
        const isExpand = parentExpand && node.expand;
        node.children.forEach((child) => {
          child.show = isExpand;
          child.isFilter = false;
        });
      } else {
        // 부모 노드가 show가 아닌 경우, 조건에 맞는 자식만 show 처리
        const isExpand = parentExpand && node.expand;
        node.children.forEach((child) => {
          child.show = isExpand && !child.isFilter;
        });
      }
    });
  };

  const initTree = (rows) => {
    rows.forEach((node) => {
      node.show = node.parent ? node.parent.expand && node.parent.show : true;
      node.isFilter = false;

      if (node.children?.length) {
        initTree(node.children);
      }
    });
  };

  const filterTreeGridByFilterSearch = (rows, result: FilterSelectedValues | undefined) => {
    initTree(rows);
    if (result?.tokens.length) {
      filterTree(rows, result);
    }

    return rows;
  };

  const changeStateObjectRowsByFilterSearch = (rows, result: FilterSelectedValues | undefined) => {
    if (!result?.tokens.length) {
      return rows.map((node) => ({ ...node, searched: false }));
    }

    return rows.map((node) => {
      const searched =
        result?.logicalOperator === 'AND'
          ? result.tokens.every((token) => commonFilterCondition(node, token, true))
          : result?.tokens.some((token) => commonFilterCondition(node, token, true));

      return {
        ...node,
        searched,
      };
    });
  };

  return {
    filterSearchResultMV,
    filterGridByFilterSearch,
    filterTreeGridByFilterSearch,
    changeStateObjectRowsByFilterSearch,
  };
};
