import { nextTick, ref, watch, type CSSProperties } from 'vue';
import { isNil } from 'lodash-es';
import PopupLayout from './PopupLayout.vue';
import FloatingPopupLayout from './FloatingPopupLayout.vue';
import { BASE_MENU_VALUE } from '../baseMenu.define';

interface HoveredItemInformation {
  top: number;
  bottom: number;
  menuValue: string | null;
}

interface HoveredItemPosition {
  top: number;
  height: number;
}

interface PopupMenuStyleParams {
  baseMenuElement: HTMLElement;
  popupMenuElement: HTMLElement;
  hoveredItemInformation: HoveredItemInformation;
  // hoveredItemInformation: 호버 이벤트가 발생한 요소의 top, 메뉴명
}

interface SubMenuStyleParams {
  mainMenuElement: HTMLElement;
  subMenuElement: HTMLElement;
  hoveredPosition: HoveredItemPosition;
  // hoveredItemPosition: 호버 이벤트가 발생한 요소의 top, height 정보
}

export const useShowMainPopup = () => {
  const baseMenuRef = ref<HTMLDivElement>();
  const popupMenuRef = ref<InstanceType<any>>();

  const hoveredItemInfo = ref<HoveredItemInformation>({
    top: 0,
    bottom: 0,
    menuValue: null,
  });
  const popupMenuStyle = ref<CSSProperties>({});

  const setHoveredItemInfo = (e?: MouseEvent): string | null => {
    const el = e?.currentTarget as HTMLElement;
    const {
      dataset: { menu = null },
    } = el;
    const { top, bottom } = el?.getBoundingClientRect();
    const { clientHeight } = document.documentElement;

    if (isNil(top)) {
      return menu;
    }

    hoveredItemInfo.value.top = top;
    hoveredItemInfo.value.bottom = clientHeight - bottom;
    hoveredItemInfo.value.menuValue = menu;
    return menu;
  };

  const calculatePopupPosition = ({
    baseMenuElement,
    popupMenuElement,
    hoveredItemInformation,
  }: PopupMenuStyleParams): CSSProperties => {
    const { top: hoveredItemTop, bottom: hoveredItemBottom, menuValue } = hoveredItemInformation;

    if (menuValue === BASE_MENU_VALUE.SETTINGS || menuValue === BASE_MENU_VALUE.HELP) {
      return { bottom: `${hoveredItemBottom}px` };
    }

    const baseMenuRect = baseMenuElement.getBoundingClientRect();
    const popupMenuRect = popupMenuElement.getBoundingClientRect();
    const { clientWidth } = document.documentElement;
    const headerHeight = clientWidth > 1600 ? 48 : 40;

    if (popupMenuRect.height >= baseMenuRect.height) {
      return { top: `${headerHeight}px` };
    }
    if (popupMenuRect.height + hoveredItemTop > baseMenuRect.height) {
      return { top: `${baseMenuRect.height - popupMenuRect.height - 4}px` };
      // 4px padding
    }
    return { top: `${hoveredItemTop}px` };
  };

  watch(
    hoveredItemInfo,
    async (hoveredInfo) => {
      await nextTick();

      const baseMenuElement = baseMenuRef.value;
      const popupMenuElement = popupMenuRef.value?.$el ?? popupMenuRef.value;

      if (!hoveredInfo.menuValue || !baseMenuElement || !popupMenuElement) {
        return;
      }

      popupMenuStyle.value = calculatePopupPosition({
        baseMenuElement,
        popupMenuElement,
        hoveredItemInformation: hoveredInfo,
      });
    },
    { deep: true },
  );

  return {
    baseMenuRef,
    popupMenuRef,
    popupMenuStyle,

    setHoveredItemInfo,
  };
};

export const useShowSubPopup = () => {
  const mainMenuRef = ref<InstanceType<typeof PopupLayout | typeof FloatingPopupLayout>>();
  const subMenuRef = ref<InstanceType<any>>();

  const hoveredItemPosition = ref<HoveredItemPosition>({ top: 0, height: 0 });
  const subMenuPopupStyle = ref<CSSProperties>({ overflowY: 'visible' });

  const setHoveredItemPosition = (e?: MouseEvent) => {
    const el = e?.currentTarget as HTMLElement;
    if (!el) {
      return;
    }

    const { offsetTop: top, offsetHeight: height } = el;

    subMenuPopupStyle.value = { overflowY: 'visible' };
    hoveredItemPosition.value = { top, height };
  };

  const calculateSubMenuStyle = ({
    mainMenuElement,
    subMenuElement,
    hoveredPosition,
  }: SubMenuStyleParams): CSSProperties => {
    const { top, height } = hoveredPosition;

    const floatingMenuRect = mainMenuElement.getBoundingClientRect();
    const floatingSubMenuRect = subMenuElement.getBoundingClientRect();
    const { clientHeight } = document.documentElement;

    // 윈도우 화면에서, 메뉴의 위치까지
    const menuItemTop = top + floatingMenuRect.top;

    // 메뉴 아래 부분 잘림 여부
    const isSliceMenuBottom: boolean = menuItemTop + floatingSubMenuRect.height > clientHeight;
    // 메뉴 상단 부분 잘림 여부
    const isSliceMenuTop: boolean = floatingSubMenuRect.height > menuItemTop;
    // 메뉴가 윈도우 화면 보다 큼 여부
    const isSubMenuBigger: boolean = floatingSubMenuRect.height > clientHeight;

    if (isSliceMenuBottom) {
      return isSliceMenuTop
        ? {
            top: `${-floatingMenuRect.top}px`,
            height: isSubMenuBigger ? `${clientHeight}px` : `${floatingSubMenuRect.height}px`,
            overflowY: isSubMenuBigger ? 'scroll' : 'visible',
          }
        : {
            top: `${top + height - floatingSubMenuRect.height}px`,
            overflowY: 'visible',
          };
    }
    return {
      top: `${top}px`,
      overflowY: 'visible',
    };
  };

  watch(hoveredItemPosition, async (hoveredPosition) => {
    await nextTick();

    const mainMenuElement = mainMenuRef.value?.$el ?? mainMenuRef.value;
    const subMenuElement = subMenuRef.value?.$el ?? subMenuRef.value;

    if (!mainMenuElement || !subMenuElement) {
      return;
    }

    subMenuPopupStyle.value = calculateSubMenuStyle({
      mainMenuElement,
      subMenuElement,
      hoveredPosition,
    });
  });

  return {
    mainMenuRef,
    subMenuRef,
    subMenuPopupStyle,

    setHoveredItemPosition,
  };
};
