const MAIN_FRAMES = {
  WAS_VIEW: 'WAS View',
  WAS_DETAIL: 'WAS Detail',
  ACTIVE_TRANSACTION_LIST: 'Active Transaction List',

  TRANSACTION_VIEW: 'Transaction View',
  TRANSACTION_DETAIL: 'Transaction Detail',

  URL_MONITORING_VIEW: 'URL Monitoring View',
  URL_MONITORING_DETAIL: 'URL Monitoring Detail',

  PAGE_PERFORMANCE_MONITORING_VIEW: 'Page Performance Monitoring View',
  PAGE_PERFORMANCE_MONITORING_DETAIL: 'Page Performance Monitoring Detail',
  PAGE_PERFORMANCE_ANALYSIS_DETAIL: 'Page Performance Analysis Detail',

  RUM_BROWSER_ERROR_TRACKING_VIEW: 'RUM Browser Error Tracking View',
  RUM_BROWSER_ERROR_TRACKING_DETAIL: 'RUM Browser Error Tracking Detail',

  TRACES_VIEW: 'Traces View',
} as const;

export const APPLICATION_FRAME_NAMES = {
  WAS_VIEW: {
    FILTERS: `${MAIN_FRAMES.WAS_VIEW}/Filters`,
    WAS_LIST: `${MAIN_FRAMES.WAS_VIEW}/WAS List`,
  },
  WAS_DETAIL: {
    ACTIVES: `${MAIN_FRAMES.WAS_DETAIL}/Actives`,
    ACTIVES_DETAIL: `${MAIN_FRAMES.WAS_DETAIL}/Actives Detail`,
    ACTIVES_DETAIL_KILL_THREAD: `${MAIN_FRAMES.WAS_DETAIL}/Actives Detail - Kill Thread`,
    CONFIG: `${MAIN_FRAMES.WAS_DETAIL}/Config`,
    DATA_SOURCE: `${MAIN_FRAMES.WAS_DETAIL}/Data Source`,
    HEAP_DUMP: `${MAIN_FRAMES.WAS_DETAIL}/Heap Dump`,
    HEAD_DUMP_EXEC: `${MAIN_FRAMES.WAS_DETAIL}/[Exec] Heap Dump`,
    GARBAGE_COLLECTION: `${MAIN_FRAMES.WAS_DETAIL}/Garbage Collection`,
    GARBAGE_COLLECTION_JVM_GC_STAT: `${MAIN_FRAMES.WAS_DETAIL}/Garbage Collection - JVM/GC Stat`,
    METRIC: `${MAIN_FRAMES.WAS_DETAIL}/Metric`,
    INFORMATION: `${MAIN_FRAMES.WAS_DETAIL}/Information`,
    INFORMATION_WAS_GROUP: `${MAIN_FRAMES.WAS_DETAIL}/Information - WAS Group`,
    TRANSACTIONS: `${MAIN_FRAMES.WAS_DETAIL}/Transactions`,
    THREAD_DUMP: `${MAIN_FRAMES.WAS_DETAIL}/Thread Dump`,
    THREAD_DUMP_DETAIL: `${MAIN_FRAMES.WAS_DETAIL}/Thread Dump Detail`,
    LIBRARIES: `${MAIN_FRAMES.WAS_DETAIL}/Libraries`,
    LOADED_CLASS: `${MAIN_FRAMES.WAS_DETAIL}/Loaded Class`,
  },
  ACTIVE_TRANSACTION_LIST: {
    DETAIL: `${MAIN_FRAMES.ACTIVE_TRANSACTION_LIST}/Detail`,
  },

  TRANSACTION_VIEW: {
    FILTERS: `${MAIN_FRAMES.TRANSACTION_VIEW}/Filters`,
    CURRENT_STAT_TREND: `${MAIN_FRAMES.TRANSACTION_VIEW}/Current Stat Trend`,
    TOP_5_CARD: `${MAIN_FRAMES.TRANSACTION_VIEW}/Top 5 Card`,
    TRANSACTION_TREND: `${MAIN_FRAMES.TRANSACTION_VIEW}/Transaction Trend`,
    TRANSACTION_COUNT: `${MAIN_FRAMES.TRANSACTION_VIEW}/Transaction Count`,
  },
  TRANSACTION_DETAIL: {
    ETOE_TARGET: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Target`,
    ETOE_PATH: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Path,List`,
    ETOE_INFORMATION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Information`,
    ETOE_METRIC: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Metric`,
    ETOE_CALL_TREE_DETAIL: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Call Tree - Detail`,
    ETOE_CALL_TREE_SUMMARY: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Call Tree - Summary`,
    ETOE_SQL_TEXT: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/SQL Text`,
    ETOE_EXCEPTION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Exception`,
    ETOE_ACTIVE_HISTORY: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Active History`,
    SPAN_PATH: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Path`,
    SPAN_LIST: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/List`,
    SPAN_INFORMATION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Information`,
    SPAN_TAGS: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Tags`,
    SPAN_LOGS: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Logs`,
    SPAN_ERROR: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Error`,
  },

  URL_MONITORING_VIEW: {
    FILTERS: `${MAIN_FRAMES.URL_MONITORING_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.URL_MONITORING_VIEW}/URL List`,
  },
  URL_MONITORING_DETAIL: {
    DETAIL: `${MAIN_FRAMES.URL_MONITORING_DETAIL}/Detail`,
    LIST: `${MAIN_FRAMES.URL_MONITORING_DETAIL}/Response List`,
  },

  PAGE_PERFORMANCE_MONITORING_VIEW: {
    FILTERS: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Filters`,
    APPLICATION_LIST: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Application List`,
    TOP_APPLICATIONS: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Top Applications`,
    METRIC: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Metric`,
  },
  PAGE_PERFORMANCE_MONITORING_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_DETAIL}/Information`,
    ANALYSIS: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_DETAIL}/Analysis`,
    SCATTER: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_DETAIL}/Scatter`,
    USER_META_INFO: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_DETAIL}/User Meta Info`,
    METRIC: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_DETAIL}/Metric`,
    EVENT: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_DETAIL}/Event`,
  },
  PAGE_PERFORMANCE_ANALYSIS_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.PAGE_PERFORMANCE_ANALYSIS_DETAIL}/Information`,
    LOAD_TIMELINE: `${MAIN_FRAMES.PAGE_PERFORMANCE_ANALYSIS_DETAIL}/Load Timeline`,
    RESOURCE_TIMELINE: `${MAIN_FRAMES.PAGE_PERFORMANCE_ANALYSIS_DETAIL}/Resource Timeline`,
    METRIC: `${MAIN_FRAMES.PAGE_PERFORMANCE_ANALYSIS_DETAIL}/Metric`,
  },

  RUM_BROWSER_ERROR_TRACKING_VIEW: {
    FILTERS: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Browser Error Log List`,
    COUNT_CHART: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Browser Error Count Chart`,
    OCCURRENCE_CHART: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Browser Error Occurrence Chart`,
    KEYWORD: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Log Keyword`,
    KEYWORD_SAVE: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/[Save] Log Keyword`,
    KEYWORD_DELETE: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/[Delete] Log Keyword`,
  },
  RUM_BROWSER_ERROR_TRACKING_DETAIL: {
    ANALYSIS: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_DETAIL}/Browser Error Analysis`,
  },

  TRACES_VIEW: {
    FILTERS: `${MAIN_FRAMES.TRACES_VIEW}/Filters`,
    TRACES_LIST: `${MAIN_FRAMES.TRACES_VIEW}/Traces List`,
  },
} as const;
