import { computed, ComputedRef, getCurrentInstance, onMounted, Ref, ref, watch } from 'vue';
import dayjs, { ManipulateType } from 'dayjs';
import { TimePeriodData } from '@/types/common.types';
import {
  PeriodControlButtonData,
  TimeFormat,
  TimePeriodInfo,
  TimePeriodOptions,
  TimeRangeParams,
  UseCurrentAndPeriodParams,
  UseCurrentParams,
  UsePeriodParams,
} from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import {
  formatTimeRangeToUtc,
  getBackTimeRange,
  getCurrentTimeRange,
  getFormalPeriod,
  parsePeriod,
  stringifyPeriod,
  getForwardTimeRange,
  isExceededCurrentTime,
  parseInterval,
} from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { useRepeat } from '@/worker/composables/useRepeat';
import { useInternational } from '@/common/locale';
import { getLocaleKey, promiseConfirmMsg } from '@/common/utils/commonUtils';
import { TimePeriodType } from '@/common/utils';
import { GLOBAL_TIME_LIVE_FORMAT, GLOBAL_TIME_RANGE_FORMAT } from './timePeriodIndicator.define';

export interface Props {
  periodInfo: TimePeriodInfo;
  periodItems?: Readonly<TimePeriodData[]>;
  options?: TimePeriodOptions;
  disabled?: boolean;
}

export interface Emit {
  (e: 'update-period-info', value: TimePeriodInfo): void;
}

// 기획서: 이전 시점으로 이동 불가능 (1번)
export const GLOBAL_TIME_CURRENT: TimePeriodOptions = {
  useCurrent: true,
  usePeriod: false,
} as const;
// 기획서: 현재 시점에 대해 확인하는 리스트 (2번)
export const GLOBAL_TIME_CURRENT_AND_PERIOD: TimePeriodOptions = {
  useCurrent: true,
  usePeriod: true,
} as const;
// 기획서: 조회기간을 설정하여 확인하는 리스트 (3번)
export const GLOBAL_TIME_PERIOD: TimePeriodOptions = {
  useCurrent: false,
  usePeriod: true,
} as const;
// 기획서: 실시간 + 기간(period) 변경 (4번)
export const GLOBAL_TIME_ONLY_CURRENT_AND_PERIOD: TimePeriodOptions = {
  useCurrent: true,
  usePeriod: true,
  useOnlyCurrent: true,
} as const;
// 기획서: 실시간 + 기간 + 캘린더 (2번 + 캘린더)
export const GLOBAL_DATE_TIME_CURRENT_AND_PERIOD: TimePeriodOptions = {
  useCurrent: true,
  usePeriod: true,
  useCalendar: true,
} as const;
// 기획서: 기간 + 캘린더 (3번 + 캘린더)
export const GLOBAL_DATE_TIME_PERIOD_CALENDAR: TimePeriodOptions = {
  useCurrent: false,
  usePeriod: true,
  useCalendar: true,
  useInput: true,
} as const;

export const BUTTON_TYPE = {
  BACK: 'back',
  PAUSE: 'pause',
  FORWARD: 'forward',
  REFRESH: 'refresh',
} as const;

export const MODE = {
  CURRENT: 'CURRENT',
  CURRENT_AND_PERIOD: 'CURRENT_AND_PERIOD',
  PERIOD: 'PERIOD',
  CURRENT_PERIOD_CALENDAR: 'CURRENT_PERIOD_CALENDAR',
  PERIOD_CALENDAR: 'PERIOD_CALENDAR',
} as const;

export const CUSTOM_PERIOD_PREFIX = 'custom';

export const getInitPeriodInfo = ({
  isPaused,
  timePeriod,
  timeFormat,
}: Partial<TimePeriodInfo> & { timeFormat?: TimeFormat } = {}): TimePeriodInfo => {
  const DEFAULT_TIME_PERIOD = timePeriod ?? 'p10m';
  const { fromTime, toTime } = getCurrentTimeRange({ period: DEFAULT_TIME_PERIOD, timeFormat });

  return {
    timePeriod: DEFAULT_TIME_PERIOD,
    fromTime,
    toTime,
    ...formatTimeRangeToUtc({ fromTime, toTime }),
    isPaused: isPaused ?? false,
  };
};

const useCurrent = ({ interval, timePeriodInfo }: UseCurrentParams) => {
  const updateTimePeriodInfo = ({ isPaused = false }: { isPaused: boolean }) => {
    const time = isPaused
      ? timePeriodInfo.value.fromTime
      : dayjs.tz().format(GLOBAL_TIME_LIVE_FORMAT);

    timePeriodInfo.value = {
      timePeriod: 'current',
      fromTime: time,
      toTime: time,
      ...formatTimeRangeToUtc({
        fromTime: time,
        toTime: time,
      }),
      isPaused,
    };
  };

  // live 상태
  const setCurrentTimeRepeat = () => {
    updateTimePeriodInfo({ isPaused: false });
  };
  const getTimeout = () =>
    dayjs.duration(interval.value.offset, interval.value.unit).asMilliseconds();

  const { resetFetch, clearFetch } = useRepeat(setCurrentTimeRepeat, getTimeout(), {
    isImmediate: false,
    isSetup: true,
  });

  const togglePause = (isPaused: boolean) => {
    // 일시 정지 클릭
    if (isPaused) {
      clearFetch();
      updateTimePeriodInfo({ isPaused: true });
    } else {
      // 재생 클릭
      resetFetch({
        timeout: getTimeout(),
      });
    }
  };

  let alreadyChanged = false;
  const buttonController = {
    [BUTTON_TYPE.PAUSE]: () => {
      alreadyChanged = true;

      const prevIsPaused = timePeriodInfo.value.isPaused;
      togglePause(!prevIsPaused);
    },
  };

  onMounted(() => {
    if (!timePeriodInfo.value.isPaused) {
      resetFetch({ timeout: getTimeout() });
    }
  });

  watch(
    () => timePeriodInfo.value.isPaused,
    (curIsPaused) => {
      // pause 버튼 클릭으로 인한 실행 방지
      if (alreadyChanged) {
        alreadyChanged = false;
        return;
      }

      togglePause(curIsPaused);
    },
  );

  return {
    buttonController,
  };
};

const useCurrentAndPeriod = ({
  interval,
  periodItems,
  selectedPeriod,
  timePeriodInfo,
}: UseCurrentAndPeriodParams) => {
  // 현재 선택된 period가 periodItem에 있는지 체크
  const isPeriodItem = () => {
    const period = getFormalPeriod(selectedPeriod.value);
    const periodValues = periodItems.value.map(({ value }) => value);
    return periodValues.includes(period);
  };

  const lastLiveTimePeriod = ref<string>(isPeriodItem() ? selectedPeriod.value : 'p10m');
  const getLiveDisplayTime = ({ toTime }: { toTime: string }) =>
    dayjs(toTime, GLOBAL_TIME_RANGE_FORMAT).format(GLOBAL_TIME_LIVE_FORMAT);

  const getRangeDisplayTime = ({ fromTime, toTime }: { fromTime: string; toTime: string }) =>
    `${fromTime} ~ ${toTime}`;

  const updateCustomPeriodItem = ({
    period,
    displayTime,
  }: {
    period: string;
    displayTime: string;
  }) => {
    const periodItem = {
      name: displayTime,
      value: `${CUSTOM_PERIOD_PREFIX}${period}`,
      shortName: period.slice(1),
    };

    const customPeriodIdx = periodItems.value.findIndex(({ value }) =>
      value.includes(CUSTOM_PERIOD_PREFIX),
    );
    if (customPeriodIdx > -1) {
      periodItems.value[customPeriodIdx] = periodItem;
    } else {
      periodItems.value.unshift(periodItem);
    }

    selectedPeriod.value = periodItems.value[0].value;
  };

  // live 상태
  const getTimeout = () =>
    dayjs.duration(interval.value.offset, interval.value.unit).asMilliseconds();

  const setCurrentTimeRepeat = () => {
    const period = getFormalPeriod(selectedPeriod.value);
    const { fromTime, toTime } = getCurrentTimeRange({ period });

    timePeriodInfo.value = {
      timePeriod: period,
      fromTime,
      toTime,
      ...formatTimeRangeToUtc({ fromTime, toTime }),
      isPaused: false,
    };

    updateCustomPeriodItem({
      period,
      displayTime: getLiveDisplayTime({ toTime }),
    });
  };
  const { resetFetch, clearFetch } = useRepeat(setCurrentTimeRepeat, getTimeout(), {
    isImmediate: false,
    isSetup: true,
  });

  const togglePause = (isPaused: boolean) => {
    // 일시 정지 클릭
    if (isPaused) {
      clearFetch();

      const period = getFormalPeriod(selectedPeriod.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      timePeriodInfo.value = {
        timePeriod: period,
        fromTime,
        toTime,
        ...formatTimeRangeToUtc({ fromTime, toTime }),
        isPaused: true,
      };

      updateCustomPeriodItem({
        period,
        displayTime: getRangeDisplayTime({ fromTime, toTime }),
      });
    } else {
      if (selectedPeriod.value.includes(CUSTOM_PERIOD_PREFIX) && !isPeriodItem()) {
        selectedPeriod.value = lastLiveTimePeriod.value;
      }
      // 재생 클릭
      resetFetch({ timeout: getTimeout() });
    }
  };

  let alreadyChanged = false;
  const buttonController = {
    [BUTTON_TYPE.BACK]: () => {
      clearFetch();
      alreadyChanged = true;

      const period = getFormalPeriod(selectedPeriod.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      const { fromTime: backFromTime, toTime: backToTime } = getBackTimeRange({
        fromTime,
        toTime,
      });

      timePeriodInfo.value = {
        timePeriod: period,
        fromTime: backFromTime,
        toTime: backToTime,
        ...formatTimeRangeToUtc({ fromTime: backFromTime, toTime: backToTime }),
        isPaused: true,
      };

      updateCustomPeriodItem({
        period,
        displayTime: getRangeDisplayTime({ fromTime: backFromTime, toTime: backToTime }),
      });
    },
    [BUTTON_TYPE.PAUSE]: () => {
      alreadyChanged = true;

      const prevIsPaused = timePeriodInfo.value.isPaused;
      togglePause(!prevIsPaused);
    },
    [BUTTON_TYPE.FORWARD]: () => {
      clearFetch();
      alreadyChanged = true;

      const period = getFormalPeriod(selectedPeriod.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      const { fromTime: forwardFromTime, toTime: forwardToTime } = getForwardTimeRange({
        fromTime,
        toTime,
      });

      if (isExceededCurrentTime({ time: forwardToTime })) {
        if (!isPeriodItem()) {
          selectedPeriod.value = lastLiveTimePeriod.value;
        }
        setCurrentTimeRepeat();
        return;
      }

      timePeriodInfo.value = {
        timePeriod: period,
        fromTime: forwardFromTime,
        toTime: forwardToTime,
        ...formatTimeRangeToUtc({ fromTime: forwardFromTime, toTime: forwardToTime }),
        isPaused: true,
      };

      updateCustomPeriodItem({
        period,
        displayTime: getRangeDisplayTime({ fromTime: forwardFromTime, toTime: forwardToTime }),
      });
    },
  };

  const onChangePeriod = () => {
    // 동일 기간 선택한 경우에는 무시
    if (selectedPeriod.value.includes(CUSTOM_PERIOD_PREFIX)) {
      return;
    }

    setCurrentTimeRepeat();
  };

  onMounted(() => {
    const { isPaused, timePeriod, fromTime, toTime } = timePeriodInfo.value;

    updateCustomPeriodItem({
      period: getFormalPeriod(timePeriod),
      displayTime:
        isPaused || timePeriod === 'pa'
          ? getRangeDisplayTime({ fromTime, toTime })
          : getLiveDisplayTime({ toTime }),
    });
    if (!isPaused && timePeriod !== 'pa') {
      resetFetch({ timeout: getTimeout() });
    }
  });

  watch(
    timePeriodInfo,
    (curInfo, prevInfo) => {
      // pause 버튼 클릭으로 인한 실행 방지
      if (alreadyChanged) {
        alreadyChanged = false;
        return;
      }

      if (curInfo.isPaused !== prevInfo.isPaused) {
        togglePause(curInfo.isPaused);
        return;
      }

      if (
        curInfo.timePeriod !== prevInfo.timePeriod ||
        curInfo.fromTime !== prevInfo.fromTime ||
        curInfo.toTime !== prevInfo.toTime
      ) {
        if (isPeriodItem()) {
          lastLiveTimePeriod.value = selectedPeriod.value;
        }

        updateCustomPeriodItem({
          period: getFormalPeriod(curInfo.timePeriod),
          displayTime:
            curInfo.isPaused || curInfo.timePeriod === 'pa'
              ? getRangeDisplayTime({ fromTime: curInfo.fromTime, toTime: curInfo.toTime })
              : getLiveDisplayTime({ toTime: curInfo.toTime }),
        });

        if (prevInfo.timePeriod === 'pa' && curInfo.timePeriod !== 'pa') {
          resetFetch({ timeout: getTimeout() });
        } else if (curInfo.timePeriod === 'pa') {
          clearFetch();
        }
      }
    },
    { deep: true },
  );

  return {
    buttonController,
    onChangePeriod,
  };
};

const usePeriod = ({
  periodItems,
  selectedPeriod,
  timePeriodInfo,
  isInitializedOutside,
}: UsePeriodParams) => {
  const updateCustomPeriodItem = ({ period, fromTime, toTime }: TimeRangeParams) => {
    const periodItem = {
      name: `${fromTime} ~ ${toTime}`,
      value: `${CUSTOM_PERIOD_PREFIX}${period}`,
      shortName: period.slice(1),
    };

    const customPeriodIdx = periodItems.value.findIndex(({ value }) =>
      value.includes(CUSTOM_PERIOD_PREFIX),
    );
    if (customPeriodIdx > -1) {
      periodItems.value[customPeriodIdx] = periodItem;
    } else {
      periodItems.value.unshift(periodItem);
    }
    selectedPeriod.value = periodItems.value[0].value;
  };

  const setTimePeriodInfo = ({ period, fromTime, toTime }: TimeRangeParams) => {
    timePeriodInfo.value = {
      timePeriod: period,
      fromTime,
      toTime,
      ...formatTimeRangeToUtc({ fromTime, toTime }),
      isPaused: true,
    };

    updateCustomPeriodItem({ period, fromTime, toTime });
  };

  // 현재 시간으로 fromTime, toTime을 설정
  const setCurrentTime = () => {
    const period = getFormalPeriod(selectedPeriod.value);
    const { fromTime, toTime } = getCurrentTimeRange({ period });

    setTimePeriodInfo({ period, fromTime, toTime });
  };

  // 과거 시간으로 fromTime, toTime을 설정
  const setCurrentTimeInit = () => {
    const period = getFormalPeriod(selectedPeriod.value);

    let { fromTime, toTime } = timePeriodInfo.value;
    if (timePeriodInfo.value.timePeriod !== 'pa' && !isInitializedOutside) {
      const { fromTime: fromTimeByCurrentTimeRange, toTime: toTimeByCurrentTimeRange } =
        getCurrentTimeRange({ period });
      fromTime = fromTimeByCurrentTimeRange;
      toTime = toTimeByCurrentTimeRange;
    }
    setTimePeriodInfo({ period, fromTime, toTime });
  };

  const buttonController = {
    [BUTTON_TYPE.BACK]: () => {
      const period = getFormalPeriod(selectedPeriod.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      const { fromTime: backFromTime, toTime: backToTime } = getBackTimeRange({
        fromTime,
        toTime,
      });

      setTimePeriodInfo({ period, fromTime: backFromTime, toTime: backToTime });
    },
    [BUTTON_TYPE.PAUSE]: () => {},
    [BUTTON_TYPE.FORWARD]: () => {
      const period = getFormalPeriod(selectedPeriod.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      const { fromTime: forwardFromTime, toTime: forwardToTime } = getForwardTimeRange({
        fromTime,
        toTime,
      });

      if (isExceededCurrentTime({ time: forwardToTime })) {
        setCurrentTime();
        return;
      }

      setTimePeriodInfo({ period, fromTime: forwardFromTime, toTime: forwardToTime });
    },
    [BUTTON_TYPE.REFRESH]: () => {
      setCurrentTime();
    },
  };

  const onChangePeriod = () => {
    // 동일 기간 선택한 경우에는 무시
    if (selectedPeriod.value.includes(CUSTOM_PERIOD_PREFIX)) {
      return;
    }

    setCurrentTime();
  };

  onMounted(() => {
    setCurrentTimeInit();
  });

  watch(
    () => timePeriodInfo.value,
    ({ timePeriod, fromTime, toTime }) => {
      if (timePeriod === 'pa' || isInitializedOutside) {
        updateCustomPeriodItem({ period: timePeriod, fromTime, toTime });
      }
    },
  );

  return {
    buttonController,
    onChangePeriod,
  };
};

interface UseCalendarParams {
  interval: Ref<{ offset: number; unit: ManipulateType }>;
  periodItems: Ref<TimePeriodData[]>;
  selectedPeriod: Ref<string>;
  timePeriodInfo: Ref<TimePeriodInfo>;
  useLive?: boolean;
  useLiveSecLimit?: number;
  maxTimeRangeSecLimit?: ComputedRef<number | undefined>;
}
const useCalendar = ({
  interval,
  periodItems,
  selectedPeriod,
  timePeriodInfo,
  useLive = true,
  useLiveSecLimit,
  maxTimeRangeSecLimit,
}: UseCalendarParams) => {
  const playLive = computed(() => {
    const period = getFormalPeriod(selectedPeriod.value);

    return useLiveSecLimit && useLive
      ? parsePeriod(period as TimePeriodType).offset <= useLiveSecLimit
      : useLive;
  });

  const getLiveDisplayTime = ({ toTime }: { toTime: string }) =>
    dayjs(toTime, GLOBAL_TIME_RANGE_FORMAT).format(GLOBAL_TIME_LIVE_FORMAT);

  const getRangeDisplayTime = ({ fromTime, toTime }: { fromTime: string; toTime: string }) =>
    `${fromTime} ~ ${toTime}`;

  const updateCustomPeriodItem = ({
    period,
    displayTime,
  }: {
    period: string;
    displayTime: string;
  }) => {
    const periodItem = {
      name: displayTime,
      value: `${CUSTOM_PERIOD_PREFIX}${period}`,
      shortName: period.split(' ')[0].slice(1),
    };

    const customPeriodIdx = periodItems.value.findIndex(({ value }) =>
      value.includes(CUSTOM_PERIOD_PREFIX),
    );
    if (customPeriodIdx > -1) {
      periodItems.value[customPeriodIdx] = periodItem;
    } else {
      periodItems.value.unshift(periodItem);
    }

    selectedPeriod.value = periodItems.value[0].value;
  };

  const setCurrentTimeRepeat = () => {
    const period = getFormalPeriod(selectedPeriod.value);
    const { fromTime, toTime } = getCurrentTimeRange({ period });

    timePeriodInfo.value = {
      timePeriod: period,
      fromTime,
      toTime,
      ...formatTimeRangeToUtc({ fromTime, toTime }),
      isPaused: !playLive.value,
    };

    updateCustomPeriodItem({
      period,
      displayTime: playLive.value
        ? getLiveDisplayTime({ toTime })
        : getRangeDisplayTime({ fromTime, toTime }),
    });
  };

  const getTimeout = () =>
    dayjs.duration(interval.value.offset, interval.value.unit).asMilliseconds();

  const { resetFetch, clearFetch } = useRepeat(setCurrentTimeRepeat, getTimeout(), {
    isImmediate: false,
    isSetup: true,
  });

  const isPeriodItem = () => {
    const period = getFormalPeriod(selectedPeriod.value);
    const periodValues = periodItems.value.map(({ value }) => value);
    return periodValues.includes(period);
  };

  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const lastLiveTimePeriod = ref<string>(isPeriodItem() ? selectedPeriod.value : 'p10m');
  const togglePause = async (isPaused: boolean) => {
    if (isPaused) {
      clearFetch();

      const period = getFormalPeriod(selectedPeriod.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      timePeriodInfo.value = {
        timePeriod: period,
        fromTime,
        toTime,
        ...formatTimeRangeToUtc({ fromTime, toTime }),
        isPaused: true,
      };

      updateCustomPeriodItem({
        period,
        displayTime: getRangeDisplayTime({ fromTime, toTime }),
      });
    } else {
      /** NOTE
       * logging 페이지 실시간 조회 시간 제한 동작 추가
       */
      if (useLiveSecLimit) {
        if (!playLive.value) {
          const ok = await promiseConfirmMsg(ctx, {
            msgStr: t('MESSAGE.UI.LOGS.REAL_TIME'),
            okCallback: async () => {
              selectedPeriod.value = 'p10m';
            },
          });
          if (!ok) {
            return;
          }
        }
      } else if (selectedPeriod.value.includes(CUSTOM_PERIOD_PREFIX) && !isPeriodItem()) {
        selectedPeriod.value = lastLiveTimePeriod.value;
      }

      if (
        maxTimeRangeSecLimit?.value &&
        parsePeriod(selectedPeriod.value).offset > maxTimeRangeSecLimit.value
      ) {
        selectedPeriod.value = stringifyPeriod({ totalSeconds: maxTimeRangeSecLimit.value });
      }

      resetFetch({ timeout: getTimeout() });
    }
  };

  const buttonController = {
    [BUTTON_TYPE.BACK]: () => {
      clearFetch();

      const period = getFormalPeriod(selectedPeriod.value, maxTimeRangeSecLimit?.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      const { fromTime: backFromTime, toTime: backToTime } = getBackTimeRange({
        fromTime,
        toTime,
        maxTimeRangeSecLimit: maxTimeRangeSecLimit?.value,
      });

      timePeriodInfo.value = {
        timePeriod: period,
        fromTime: backFromTime,
        toTime: backToTime,
        ...formatTimeRangeToUtc({ fromTime: backFromTime, toTime: backToTime }),
        isPaused: true,
      };

      updateCustomPeriodItem({
        period,
        displayTime: getRangeDisplayTime({ fromTime: backFromTime, toTime: backToTime }),
      });
    },
    [BUTTON_TYPE.PAUSE]: () => {
      const prevIsPaused = timePeriodInfo.value.isPaused;
      togglePause(!prevIsPaused);
    },
    [BUTTON_TYPE.FORWARD]: () => {
      clearFetch();
      const period = getFormalPeriod(selectedPeriod.value, maxTimeRangeSecLimit?.value);
      const { fromTime, toTime } = timePeriodInfo.value;

      const { fromTime: forwardFromTime, toTime: forwardToTime } = getForwardTimeRange({
        fromTime,
        toTime,
        maxTimeRangeSecLimit: maxTimeRangeSecLimit?.value,
      });

      if (isExceededCurrentTime({ time: forwardToTime })) {
        if (!isPeriodItem()) {
          selectedPeriod.value = lastLiveTimePeriod.value;
        }
        setCurrentTimeRepeat();
        return;
      }

      timePeriodInfo.value = {
        timePeriod: period,
        fromTime: forwardFromTime,
        toTime: forwardToTime,
        ...formatTimeRangeToUtc({ fromTime: forwardFromTime, toTime: forwardToTime }),
        isPaused: true,
      };

      updateCustomPeriodItem({
        period,
        displayTime: getRangeDisplayTime({ fromTime: forwardFromTime, toTime: forwardToTime }),
      });
    },
    [BUTTON_TYPE.REFRESH]: () => {
      setCurrentTimeRepeat();
    },
  };

  const onChangePeriodByCalendar = ({ fromTime, toTime, period }) => {
    clearFetch();

    timePeriodInfo.value = {
      timePeriod: period,
      fromTime,
      toTime,
      ...formatTimeRangeToUtc({ fromTime, toTime }),
      isPaused: true,
    };

    updateCustomPeriodItem({
      period,
      displayTime: getRangeDisplayTime({ fromTime, toTime }),
    });
  };

  const onChangePeriod = () => {
    setCurrentTimeRepeat();
    if (playLive.value) {
      resetFetch({ timeout: getTimeout() });
    }
  };

  onMounted(() => {
    const { isPaused, timePeriod, fromTime, toTime } = timePeriodInfo.value;

    updateCustomPeriodItem({
      period: getFormalPeriod(timePeriod),
      displayTime: isPaused
        ? getRangeDisplayTime({ fromTime, toTime })
        : getLiveDisplayTime({ toTime }),
    });

    if (!isPaused && timePeriod !== 'pa') {
      resetFetch({ timeout: getTimeout() });
    }
  });

  watch(
    timePeriodInfo,
    (curInfo, prevInfo) => {
      if (curInfo && prevInfo && curInfo.isPaused !== prevInfo.isPaused) {
        selectedPeriod.value = curInfo.timePeriod;
        togglePause(curInfo.isPaused);
        return;
      }

      if (
        curInfo.timePeriod !== prevInfo.timePeriod ||
        curInfo.fromTime !== prevInfo.fromTime ||
        curInfo.toTime !== prevInfo.toTime
      ) {
        if (isPeriodItem()) {
          lastLiveTimePeriod.value = selectedPeriod.value;
        }

        updateCustomPeriodItem({
          period: getFormalPeriod(curInfo.timePeriod),
          displayTime:
            curInfo.isPaused || curInfo.timePeriod === 'pa'
              ? getRangeDisplayTime({ fromTime: curInfo.fromTime, toTime: curInfo.toTime })
              : getLiveDisplayTime({ toTime: curInfo.toTime }),
        });

        if (prevInfo.timePeriod === 'pa' && curInfo.timePeriod !== 'pa') {
          resetFetch({ timeout: getTimeout() });
        } else if (curInfo.timePeriod === 'pa') {
          clearFetch();
        }
      }
    },
    { deep: true },
  );

  return { buttonController, onChangePeriod, onChangePeriodByCalendar };
};

export const setup = (props: Props, emit: Emit) => {
  const timePeriodInfo = computed<TimePeriodInfo>({
    get: () => props.periodInfo,
    set: (info) => {
      emit('update-period-info', info);
    },
  });

  const { t } = useInternational();
  const periodItems = ref<TimePeriodData[]>(
    props.periodItems!.map<TimePeriodData>(({ name, value, shortName }) => ({
      name: `${t(`WORD.TIME_PERIOD_ITEMS.${getLocaleKey(name)}`)}`,
      value,
      shortName,
    })),
  );
  const selectedPeriod = ref<string>(timePeriodInfo.value.timePeriod || 'p10m');

  const interval = computed(() => {
    return parseInterval(props.options?.interval || 'I5s');
  });

  const mode = (() => {
    const {
      useCurrent: useCurrentOption,
      usePeriod: usePeriodOption,
      useCalendar: useCalendarOption,
    } = props.options || {};

    if (useCalendarOption) {
      if (!useCurrentOption && usePeriodOption) {
        return MODE.PERIOD_CALENDAR;
      }
      return MODE.CURRENT_PERIOD_CALENDAR;
    }

    if (useCurrentOption && !usePeriodOption) {
      return MODE.CURRENT;
    }

    if (useCurrentOption && usePeriodOption) {
      return MODE.CURRENT_AND_PERIOD;
    }

    if (!useCurrentOption && usePeriodOption) {
      return MODE.PERIOD;
    }

    return '';
  })();

  const useByMode = (() => {
    switch (mode) {
      case MODE.CURRENT:
        return {
          ...useCurrent({
            timePeriodInfo,
            interval,
          }),
          onChangePeriod: () => {},
        };
      case MODE.CURRENT_AND_PERIOD:
        return {
          ...useCurrentAndPeriod({
            timePeriodInfo,
            selectedPeriod,
            periodItems,
            interval,
          }),
          onChangePeriodByCalendar: () => {},
        };
      case MODE.PERIOD:
        return {
          ...usePeriod({
            selectedPeriod,
            timePeriodInfo,
            periodItems,
            isInitializedOutside: props.options?.isInitializedOutside ?? false,
          }),
          onChangePeriodByCalendar: () => {},
        };
      case MODE.CURRENT_PERIOD_CALENDAR:
        return useCalendar({
          timePeriodInfo,
          selectedPeriod,
          periodItems,
          interval,
          useLiveSecLimit: props.options?.useLiveSecLimit,
          maxTimeRangeSecLimit: computed(() => props.options?.timeRangeSecLimit),
        });
      case MODE.PERIOD_CALENDAR:
        return useCalendar({
          timePeriodInfo,
          selectedPeriod,
          periodItems,
          interval,
          useLive: false,
          maxTimeRangeSecLimit: computed(() => props.options?.timeRangeSecLimit),
        });
      default:
        return {
          buttonController: {},
          onChangePeriod: () => {},
          onChangePeriodByCalendar: () => {},
        };
    }
  })();

  const periodControlButtons = computed<PeriodControlButtonData[]>(() => {
    const buttons: PeriodControlButtonData[] = [];

    if (props.options?.usePeriod && props.options?.useOnlyCurrent === undefined) {
      buttons.push({
        key: BUTTON_TYPE.BACK,
        icon: 'icon-fill-2arrow-left',
        click: useByMode.buttonController[BUTTON_TYPE.BACK],
      });
    }

    buttons.push({
      key: BUTTON_TYPE.PAUSE,
      icon:
        mode === MODE.PERIOD ||
        timePeriodInfo.value.isPaused ||
        timePeriodInfo.value.timePeriod === 'pa'
          ? 'icon-fill-arrow-right'
          : 'icon-pause',
      disabled: mode === MODE.PERIOD || mode === MODE.PERIOD_CALENDAR,
      click: useByMode.buttonController[BUTTON_TYPE.PAUSE],
    });

    if (props.options?.usePeriod && props.options?.useOnlyCurrent === undefined) {
      buttons.push({
        key: BUTTON_TYPE.FORWARD,
        icon: 'icon-fill-2arrow-right',
        click: useByMode.buttonController[BUTTON_TYPE.FORWARD],
      });
    }

    if (mode === MODE.PERIOD) {
      buttons.push({
        key: BUTTON_TYPE.REFRESH,
        icon: 'icon-refresh',
        click: useByMode.buttonController[BUTTON_TYPE.REFRESH],
      });
    }

    const hasRefreshButton = Object.values(buttons)
      .map((btn) => btn.key)
      .includes(BUTTON_TYPE.REFRESH);
    if (mode === MODE.PERIOD_CALENDAR && !hasRefreshButton) {
      buttons.push({
        key: BUTTON_TYPE.REFRESH,
        icon: 'icon-refresh',
        click: useByMode.buttonController[BUTTON_TYPE.REFRESH],
      });
    }

    return buttons;
  });
  const onChangePeriod = useByMode.onChangePeriod || (() => {});
  const onChangePeriodByCalendar = useByMode.onChangePeriodByCalendar || (() => {});

  return {
    mode,
    periodItems,
    selectedPeriod,
    periodControlButtons,
    onChangePeriod,

    timePeriodInfo,
    onChangePeriodByCalendar,
  };
};
