import { ref } from 'vue';
import { ListItem } from '@/config/components/reasonSelect/reasonList/reasonList.types';
import { isNil } from 'lodash-es';
import { getK8sReasonByKindMetaAlertRuleControllerAxios } from '@/openapi/metaV6/api/meta-alert-rule-controller-api';

export const useReasonData = () => {
  const reasonDataMap = ref(new Map<number, string>());

  const setReasonMap = (items: ListItem[]) => {
    items.forEach((item) => {
      if (!isNil(item.value) && !isNil(item.name)) {
        reasonDataMap.value.set(item.value, item.name);
      }
    });
  };

  const fetchReason = async (kind: string) => {
    if (!kind) {
      return;
    }
    try {
      const { data } = await getK8sReasonByKindMetaAlertRuleControllerAxios({
        kind: kind as Parameters<typeof getK8sReasonByKindMetaAlertRuleControllerAxios>[0]['kind'],
      });
      (data?.data ?? []).forEach((item) => {
        if (!isNil(item.eventId) && !isNil(item.reason)) {
          reasonDataMap.value.set(item.eventId, item.reason);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getReasons = (eventIds: number[]) => {
    return eventIds
      .map((id) => {
        return reasonDataMap.value.get(id);
      })
      .filter((reason) => reason) as string[];
  };

  return {
    reasonDataMap,
    setReasonMap,
    fetchReason,
    getReasons,
  };
};
