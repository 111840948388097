import { computed, onMounted, ref, watch } from 'vue';
import { type RouteRecordRaw } from 'vue-router';
import { useViewModeStore } from '@/common/stores/view-mode';
import { useInstanceStore } from '@/common/stores/instance';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { VIEW_MODE } from '@/common/define/common.define';
import { storeToRefs } from 'pinia';
import { getDashboardNaviMenu } from '@/dashboard/router/routes';
import { getApplicationNaviMenu, getRumNaviMenu } from '@/application/router/routes';
import { getInfrastructureNaviMenu } from '@/infrastructure/router/routes';
import { getCloudNaviMenu } from '@/cloud/router/routers';
import { getKubernetesNaviMenu } from '@/kubernetes/router/routes';
import { getPgNaviMenu } from '@/postgresql/router/routes';
import { getOracleNaviMenu } from '@/oracle/router/routes';
import { getMysqlNaviMenu } from '@/mysql/router/routes';
import { getReportNaviMenu } from '@/report/router/routes';
import { getConfigNaviMenu } from '@/config/router/routes';
import { getBusinessNaviMenu } from '@/service/router/router';
import { getLogsNaviMenu } from '@/logs/router/routes';
import { getToolMenu } from '@/tool/router/routes';
import { getDatabaseNaviMenu } from '@/database/router/routes';
import { faultAnalysisMenu } from '@/analysisTools/router/routers';
import { getAlertNaviMenu } from '@/alert/router/routes';
import { getSqlServerNaviMenu } from '@/sqlServer/router/routes';
import { getHelpNaviMenu } from '@/help/router/routes';
import { convertCamelToCapital, getLocaleKey } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import {
  DashboardId,
  PlatformDashboardValue,
  usePlatformDashboardStore,
} from '@/common/stores/platform-dashboard';
import { store } from '@/common/store';
import { findPermissionByTranslateId } from '@/common/permission/permission.utils';
import { DASHBOARD_MENU_TYPE } from '@/common/components/molecules/breadCrumbs/breadCrumbs.define';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { getKafkaMenu } from '@/kafka/router/routes';
import type { MenuInfo } from './types';
import { BASE_MENU_VALUE, SUBTREE_ARRAY } from './baseMenu.define';

export const useDashboardMenu = (menuInfo: MenuInfo[]) => {
  const dashboardMenuInfo = ref<Record<string, MenuInfo[]>>({
    [BASE_MENU_VALUE.APPLICATION]: [],
    [BASE_MENU_VALUE.KUBERNETES]: [],
    [BASE_MENU_VALUE.DATABASE]: [],
    [BASE_MENU_VALUE.INFRASTRUCTURE]: [],
    [BASE_MENU_VALUE.CLOUD]: [],
    [BASE_MENU_VALUE.BUSINESS]: [],
    [BASE_MENU_VALUE.LOGS]: [],
    [BASE_MENU_VALUE.ALERT]: [],
  });
  const analysisBoardMenuInfo = ref<Record<string, MenuInfo[]>>({
    [BASE_MENU_VALUE.APPLICATION]: [],
    [BASE_MENU_VALUE.KUBERNETES]: [],
    [BASE_MENU_VALUE.DATABASE]: [],
    [BASE_MENU_VALUE.INFRASTRUCTURE]: [],
    [BASE_MENU_VALUE.BUSINESS]: [],
    [BASE_MENU_VALUE.LOGS]: [],
    [BASE_MENU_VALUE.ALERT]: [],
  });

  const platformDashboardStore = usePlatformDashboardStore();
  const { getDashboardByPlatformList } = platformDashboardStore;
  const { menuByPlatform, menuByAnalysisPlatform } = storeToRefs(platformDashboardStore);

  onMounted(getDashboardByPlatformList);

  const dashboardSortFn = (infoA: MenuInfo, infoB: MenuInfo) => {
    if (infoA.favoriteId && !infoB.favoriteId) {
      return -1;
    }
    if (infoB.favoriteId && !infoA.favoriteId) {
      return 1;
    }
    return 0;
  };

  watch(
    menuByPlatform,
    (platformList) => {
      menuInfo.forEach((menu) => {
        const menuValue = menu.value === BASE_MENU_VALUE.KUBERNETES ? 'k8s' : menu.value;
        const platform: Record<DashboardId, PlatformDashboardValue> = platformList![menuValue];
        if (!platform) return;

        const dashboards = Object.entries(platform);
        if (dashboards.length) {
          const menuInfoIdx = menuInfo.findIndex((info) => info.value === menu.value);
          if (menuInfoIdx === undefined) {
            return;
          }
          const children = dashboards.map(([dashboardId, contents]) => ({
            text: contents.name,
            value: `dashboard_Dashboard View_${dashboardId}`,
            path: `/dashboard/view/${dashboardId}?rootMenu=${menu.value}&menuType=${DASHBOARD_MENU_TYPE.DASHBOARD}`,
            favoriteId: contents.favoriteId,
            featuredIcon: contents.iconId,
          }));
          dashboardMenuInfo.value[menu.value] = children.sort(dashboardSortFn);
        } else {
          dashboardMenuInfo.value[menu.value] = [];
        }
      });
    },
    { deep: true, immediate: true },
  );
  watch(
    menuByAnalysisPlatform,
    (platformList) => {
      menuInfo.forEach((menu) => {
        const menuValue = menu.value === BASE_MENU_VALUE.KUBERNETES ? 'k8s' : menu.value;
        const platform: Record<DashboardId, PlatformDashboardValue> = platformList![menuValue];
        if (!platform) return;

        const dashboards = Object.entries(platform);
        if (dashboards.length) {
          const menuInfoIdx = menuInfo.findIndex((info) => info.value === menu.value);
          if (menuInfoIdx === undefined) {
            return;
          }
          const children = dashboards.map(([dashboardId, contents]) => ({
            text: contents.name,
            value: `dashboard_Dashboard View_${dashboardId}`,
            path: `/dashboard/view/${dashboardId}?rootMenu=${menu.value}&menuType=${DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD}`,
            favoriteId: contents.favoriteId,
            featuredIcon: contents.iconId,
          }));
          analysisBoardMenuInfo.value[menu.value] = children.sort(dashboardSortFn);
        } else {
          analysisBoardMenuInfo.value[menu.value] = [];
        }
      });
    },
    { deep: true, immediate: true },
  );

  return { dashboardMenuInfo, analysisBoardMenuInfo };
};

export const useMenu = () => {
  const { t } = useInternational();
  const { viewMode } = useViewModeStore();
  const { role: roleId } = store.getters['myInfo/getAccountInfo'];

  // DFS 사용
  const findMenuItem = (
    menuTree: MenuInfo[] = [],
    predicate: (node: MenuInfo) => boolean,
    menuVisitRoute: MenuInfo[] = [],
  ): MenuInfo | null => {
    if (!menuTree.length) {
      return null;
    }

    for (let i = 0; i < menuTree.length; i++) {
      const menuNode = menuTree[i];
      if (predicate(menuNode)) {
        menuVisitRoute.unshift(menuNode);
        return menuNode;
      }

      for (let j = 0; j < SUBTREE_ARRAY.length; j++) {
        if (menuNode[SUBTREE_ARRAY[j]]) {
          const foundNode = findMenuItem(menuNode[SUBTREE_ARRAY[j]], predicate, menuVisitRoute);
          if (foundNode) {
            menuVisitRoute.unshift(menuNode);
            return foundNode;
          }
        }
      }
    }

    return null;
  };

  const getMenuName = (routeName: string): string => {
    const menuName = routeName ? routeName.slice(routeName.indexOf('_') + 1) : '';
    const localeKey = getLocaleKey(menuName);
    if (routeName === 'kubernetes_Service') {
      // 쿠버네티스 예외처리
      // NOTE: 쿠버 서비스는 서비스, 그 외 서비스는 비즈니스로 변경해야함
      return t('WORD.GNB.KUBE_SERVICE');
    }
    if (routeName === '') {
      return '';
      // NOTE: 고객지원 탭의 특정 메뉴는 카테고리명이 존재하지 않음
    }
    return t(`WORD.GNB.${localeKey}`);
  };

  const getChildrenList = (item, subItem, parentPath: string) =>
    subItem.children.map((sub) => {
      const subPath =
        parentPath.substring(-1) === '/' ? `${parentPath}${sub.path}` : `${parentPath}/${sub.path}`;
      return {
        text: getMenuName(sub.name),
        value: `${item.path.split('/')[1]}_${sub.path}`,
        path: subPath,
        disabled: sub.props?.disabled,
        children: sub.children ? getChildrenList(item, sub, subPath) : [],
      };
    });

  // 메뉴 목록
  const getMenuList = (routerInfo: RouteRecordRaw[]) =>
    routerInfo
      .filter((item) => !item.meta?.invisibleViewMode?.includes(viewMode))
      .flatMap((item) => {
        const itemName = item.name as string;
        if (!item.children) {
          return {
            text: getMenuName(itemName),
            value: itemName,
            path: item.path,
            disabled: (item.props as Record<string, any>)?.disabled,
            meta: item.meta,
          };
        }
        const childList = item
          .children!.filter((child) => !child.meta?.invisibleViewMode?.includes(viewMode))
          .flatMap((subItem) => {
            const subItemName = subItem.name as string;
            return {
              text: getMenuName(subItemName) || convertCamelToCapital(subItem.path),
              value: subItemName.split('_')[0]
                ? `${subItemName.split('_')[0]}_${subItem.path}`
                : subItem.path,
              path: `${item.path}${subItem.path}`,
              disabled: (subItem.props as Record<string, any>)?.disabled,
              children: subItem.children
                ? getChildrenList(item, subItem, `${item.path}${subItem.path}`)
                : [],
              meta: subItem.meta,
            };
          });

        return {
          text: getMenuName(itemName),
          value: itemName,
          path: '',
          children: childList,
          meta: item.meta,
        };
      });

  const checkAllSubMenuPermissionDenied = (menuPermissionKeyList: string[]) => {
    return menuPermissionKeyList.every((menuPermissionKey) => {
      return !findPermissionByTranslateId(menuPermissionKey)?.menuOnOff;
    });
  };

  // children: 최하위 leaf node (예시: Kubernetes > Pod, Infrastructure > Host)
  // subMenuList: 클릭하면 우측 메뉴가 펼쳐지는 하위 메뉴 (예시: Settings)

  // 대시보드 메뉴 목록
  const dashboardMenuInfo = {
    text: t('WORD.GNB.DASHBOARD'),
    value: BASE_MENU_VALUE.DASHBOARD,
    children: getMenuList(getDashboardNaviMenu())[0]?.children,
  };

  // 하위 대시보드 메뉴 목록
  const subDashboardMenuInfo = {
    text: t('WORD.GNB.DASHBOARD'),
    value: BASE_MENU_VALUE.DASHBOARD,
    subMenuList: [],
  };

  // 하위 분석보드 메뉴 목록
  const subAnalysisBoardMenuInfo = {
    text: t('WORD.GNB.ANALYSISBOARD'),
    value: BASE_MENU_VALUE.ANALYSISBOARD,
    subMenuList: [],
  };

  // 인프라 > 대시보드 메뉴
  const infrastructureDashboardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subDashboardMenuInfo
      : undefined;

  const infrastructureAnalysisBoardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subAnalysisBoardMenuInfo
      : undefined;

  // 인프라 메뉴 목록
  const infrastructureMenuInfo: MenuInfo[] = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.INFRASTRUCTURE'),
          value: BASE_MENU_VALUE.INFRASTRUCTURE,
          children: getMenuList(getInfrastructureNaviMenu())[0]?.children,
          dashboardList: infrastructureDashboardList,
          analysisBoardList: infrastructureAnalysisBoardList,
        },
      ]
    : [];

  // 카프카 > 대시보드
  const kafkaDashboardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_KAFKA_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subDashboardMenuInfo
      : undefined;

  const kafkaAnalysisBoardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_KAFKA_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subAnalysisBoardMenuInfo
      : undefined;

  // 카프카 메뉴
  const kafkaMenuInfo: MenuInfo[] = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KAFKA,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.KAFKA'),
          value: BASE_MENU_VALUE.KAFKA,
          children: getMenuList(getKafkaMenu())[0]?.children,
          dashboardList: kafkaDashboardList,
          analysisBoardList: kafkaAnalysisBoardList,
        },
      ]
    : [];

  // 클라우드 > AWS 메뉴
  const subMenuCategories: ('computing' | 'storage' | 'database' | 'management')[] = [
    'computing',
    'storage',
    'database',
    'management',
  ];

  const cloudAwsMenuInfo: MenuInfo[] = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.AWS'),
          value: `${BASE_MENU_VALUE.CLOUD}_aws`,
          subMenuList: subMenuCategories
            .map((category) => {
              return {
                text: t(`WORD.${category.toUpperCase()}`),
                value: '',
                children: getMenuList(getCloudNaviMenu('aws', category))[0]?.children,
              };
            })
            .filter(({ children }) => children && children?.length > 0),
        },
      ]
    : [];

  // 클라우드 > 대시보드 메뉴
  const cloudDashboardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subDashboardMenuInfo
      : undefined;

  const cloudAnalysisBoardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subAnalysisBoardMenuInfo
      : undefined;

  // 클라우드 메뉴 목록
  const cloudMenuInfo: MenuInfo[] = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.CLOUD'),
          value: BASE_MENU_VALUE.CLOUD,
          children: [
            ...(getMenuList(getCloudNaviMenu())[0]?.children || []),
            {
              text: t('WORD.CLOUD_SERVICE'),
              value: '',
              children: [...cloudAwsMenuInfo],
            },
          ],
          dashboardList: cloudDashboardList,
          analysisBoardList: cloudAnalysisBoardList,
        },
      ]
    : [];

  // 쿠버네티스 > 대시보드 메뉴
  const kubernetesDashboardList = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_DASHBOARD,
  )?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;

  const kubernetesAnalysisBoardList = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_DASHBOARD,
  )?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;

  // 쿠버네티스 > 워크로드 메뉴
  const kubernetesWorkloadMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.WORKLOAD'),
          value: `${BASE_MENU_VALUE.KUBERNETES}_workloads`,
          children: getMenuList(getKubernetesNaviMenu('workloads'))[0]?.children,
        },
      ]
    : [];
  // 쿠버네티스 > 네트워크 메뉴
  const kubernetesNetworkMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NETWORK,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.NETWORK'),
          value: `${BASE_MENU_VALUE.KUBERNETES}_network`,
          subMenuList: getMenuList(getKubernetesNaviMenu('network')),
        },
      ]
    : [];
  // 쿠버네티스 > 스토리지 메뉴
  const kubernetesStorageMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.CONFIG_STORAGE'),
          value: `${BASE_MENU_VALUE.KUBERNETES}_ConfigStorage`,
          subMenuList: getMenuList(getKubernetesNaviMenu('configStorage')),
        },
      ]
    : [];
  // 쿠버네티스 > 접근 제어 메뉴
  const kubernetesAccessControlMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_ACCESS_CONTROL,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.ACCESS_CONTROL'),
          value: `${BASE_MENU_VALUE.KUBERNETES}_accessControl`,
          subMenuList: getMenuList(getKubernetesNaviMenu('accessControl')),
        },
      ]
    : [];
  // 쿠버네티스 메뉴 목록
  const kubernetesMenuInfo: MenuInfo[] = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.KUBERNETES'),
          value: BASE_MENU_VALUE.KUBERNETES,
          children: [
            ...(getMenuList(getKubernetesNaviMenu())[0]?.children || []),
            ...kubernetesWorkloadMenuInfo,
            {
              text: '',
              value: '',
              children: [
                ...kubernetesNetworkMenuInfo,
                ...kubernetesStorageMenuInfo,
                ...kubernetesAccessControlMenuInfo,
              ],
            },
          ],
          dashboardList: kubernetesDashboardList,
          analysisBoardList: kubernetesAnalysisBoardList,
        },
      ]
    : [];

  // 어플리케이션 > 대시보드 메뉴
  const applicationDashboardList = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_DASHBOARD,
  )?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const applicationAnalysisBoardList = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_DASHBOARD,
  )?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;
  const rumMenuInfo = checkAllSubMenuPermissionDenied([
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_PAGE_PERFORMANCE_MONITORING,
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_BROWSER_ERROR_TRACKING,
    // NOTE: 사용자 모니터링 추가될 경우 role permission key 추가
  ])
    ? undefined
    : {
        text: t('WORD.GNB.RUM'),
        value: `${BASE_MENU_VALUE.APPLICATION}_rum`,
        children: getMenuList(getRumNaviMenu())[0]?.children,
      };
  // 어플리케이션 메뉴 목록
  const applicationMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.APPLICATION'),
          value: BASE_MENU_VALUE.APPLICATION,
          children: [
            ...(getMenuList(getApplicationNaviMenu())[0]?.children ?? []),
            ...(rumMenuInfo ? [rumMenuInfo] : []),
          ],
          dashboardList: applicationDashboardList,
          analysisBoardList: applicationAnalysisBoardList,
        },
      ]
    : [];

  // 데이터베이스 메뉴 목록
  const databaseDashboardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subDashboardMenuInfo
      : undefined;
  const databaseAnalysisBoardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subAnalysisBoardMenuInfo
      : undefined;
  const databaseMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE,
  )?.menuOnOff
    ? [
        {
          text: viewMode !== VIEW_MODE.MAXGAUGE ? t('WORD.GNB.DATABASE') : t('WORD.GNB.OVERVIEW'),
          value: BASE_MENU_VALUE.DATABASE,
          children: getMenuList(getDatabaseNaviMenu())[0]?.children,
          dashboardList: databaseDashboardList,
          analysisBoardList: databaseAnalysisBoardList,
        },
      ]
    : [];

  // 서비스 > 대시보드 메뉴
  const serviceDashboardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subDashboardMenuInfo
      : undefined;
  const serviceAnalysisBoardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subAnalysisBoardMenuInfo
      : undefined;
  // 서비스 메뉴 목록
  const serviceMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.BUSINESS'),
          value: BASE_MENU_VALUE.BUSINESS,
          children: getMenuList(getBusinessNaviMenu())[0]?.children,
          dashboardList: serviceDashboardList,
          analysisBoardList: serviceAnalysisBoardList,
        },
      ]
    : [];

  // 로그 > 대시보드 메뉴
  const logDashboardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_LOGS_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subDashboardMenuInfo
      : undefined;
  const logAnalysisBoardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_LOGS_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subAnalysisBoardMenuInfo
      : undefined;
  // 로그 메뉴 목록
  const logMenuInfo = findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_LOGS)
    ?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.LOGS'),
          value: BASE_MENU_VALUE.LOGS,
          children: getMenuList(getLogsNaviMenu())[0]?.children,
          dashboardList: logDashboardList,
          analysisBoardList: logAnalysisBoardList,
        },
      ]
    : [];

  // 알림 > 대시보드 메뉴
  const alertDashboardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subDashboardMenuInfo
      : undefined;
  const alertAnalysisBoardList =
    findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_DASHBOARD)
      ?.menuOnOff && viewMode !== VIEW_MODE.MAXGAUGE
      ? subAnalysisBoardMenuInfo
      : undefined;
  // 알림 메뉴 목록
  const alertMenuInfo = findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT)
    ?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.ALERT'),
          value: BASE_MENU_VALUE.ALERT,
          children: getMenuList(getAlertNaviMenu(roleId))[0]?.children,
          dashboardList: alertDashboardList,
          analysisBoardList: alertAnalysisBoardList,
        },
      ]
    : [];

  const toolMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_TOOL,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.TOOL'),
          value: BASE_MENU_VALUE.TOOL,
          children: getMenuList(getToolMenu())[0]?.children,
        },
      ]
    : [];

  // PA Application 메뉴 목록
  const paAppMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_APPLICATION,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.APPLICATION'),
          value: `${BASE_MENU_VALUE.PA}_faultAnalysis`,
          children: getMenuList(faultAnalysisMenu)[0]?.children,
        },
      ]
    : [];

  // PA DB 메뉴 목록
  const paDBMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.DATABASE'),
          value: `${BASE_MENU_VALUE.PA}_performanceTuning`,
          children: [
            {
              text: t('WORD.GNB.ORACLE'),
              value: BASE_MENU_VALUE.ORACLE,
              subMenuList: getMenuList(getOracleNaviMenu()),
            },
            {
              text: t('WORD.GNB.POSTGRESQL'),
              value: BASE_MENU_VALUE.PG,
              subMenuList: getMenuList(getPgNaviMenu()),
            },
            {
              text: t('WORD.GNB.MYSQL'),
              value: BASE_MENU_VALUE.MYSQL,
              subMenuList: getMenuList(getMysqlNaviMenu()),
            },
            {
              text: t('WORD.GNB.SQL_SERVER'),
              value: BASE_MENU_VALUE.SQLSERVER,
              subMenuList: getMenuList(getSqlServerNaviMenu()),
            },
          ],
        },
      ]
    : [];

  // PA 메뉴 목록
  const paMenuInfo = findPermissionByTranslateId(ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS)
    ?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.PERFORMANCE_ANALYSIS'),
          value: BASE_MENU_VALUE.PA,
          children: [...toolMenuInfo, ...paAppMenuInfo, ...paDBMenuInfo],
        },
      ]
    : [];

  // 리포트 메뉴 목록
  const reportMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_REPORT,
  )?.menuOnOff
    ? [
        {
          text: t('WORD.GNB.REPORT'),
          value: BASE_MENU_VALUE.REPORT,
          children: getMenuList(getReportNaviMenu())[0]?.children,
        },
      ]
    : [];

  // 고객지원 메뉴 목록
  const helpMenuInfo = {
    text: t('WORD.GNB.HELP.TITLE'),
    value: BASE_MENU_VALUE.HELP,
    subMenuList: getMenuList(getHelpNaviMenu()),
  };

  // 설정 메뉴 목록
  const myInfoMenuInfo = {
    text: t('WORD.GNB.SETTING'),
    value: BASE_MENU_VALUE.SETTINGS,
    subMenuList: getMenuList(getConfigNaviMenu(roleId)),
  };

  // instance에 따라 maxgauge 메뉴 목록이 달라짐
  const instanceStore = useInstanceStore();
  const { instanceCount } = storeToRefs(instanceStore);

  // maxgauge oracle
  const maxgaugeOracleDashboardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_DASHBOARD,
  })?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const maxgaugeOracleAnalysisBoardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_DASHBOARD,
  })?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;
  const maxgaugeOracleMenuInfo =
    instanceCount.value?.oracle &&
    findPermissionByTranslateId({
      [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE,
    })?.menuOnOff
      ? [
          {
            text: t('WORD.GNB.ORACLE'),
            value: BASE_MENU_VALUE.ORACLE,
            dashboardList: maxgaugeOracleDashboardList,
            analysisBoardList: maxgaugeOracleAnalysisBoardList,
            children: [
              ...(getMenuList(getOracleNaviMenu('instanceView'))[0]?.children ?? []),
              {
                text: t('WORD.GNB.SQL_ANALYSIS'),
                value: `${BASE_MENU_VALUE.ORACLE}_sqlAnalysis`,
                children: getMenuList(getOracleNaviMenu('sqlAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.EVENT_ANALYSIS'),
                value: `${BASE_MENU_VALUE.ORACLE}_eventAnalysis`,
                children: getMenuList(getOracleNaviMenu('eventAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.SESSION_ANALYSIS'),
                value: `${BASE_MENU_VALUE.ORACLE}_sessionAnalysis`,
                children: getMenuList(getOracleNaviMenu('sessionAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.PERFORMANCE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.ORACLE}_performanceAnalysis`,
                children: getMenuList(getOracleNaviMenu('performanceAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.DATASPACE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.ORACLE}_dataSpaceAnalysis`,
                children: getMenuList(getOracleNaviMenu('dataSpaceAnalysis'))[0]?.children,
              },
            ],
          },
        ]
      : [];

  // maxgauge pg
  const maxgaugePgDashboardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL_DASHBOARD,
  })?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const maxgaugePgAnalysisBoardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL_DASHBOARD,
  })?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;
  const maxgaugePgMenuInfo =
    instanceCount.value?.postgresql &&
    findPermissionByTranslateId({
      [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL,
    })?.menuOnOff
      ? [
          {
            text: t('WORD.GNB.POSTGRESQL'),
            value: BASE_MENU_VALUE.PG,
            dashboardList: maxgaugePgDashboardList,
            analysisBoardList: maxgaugePgAnalysisBoardList,
            children: [
              ...(getMenuList(getPgNaviMenu('instanceView'))[0]?.children ?? []),
              {
                text: t('WORD.GNB.SQL_ANALYSIS'),
                value: `${BASE_MENU_VALUE.PG}_sqlAnalysis`,
                children: getMenuList(getPgNaviMenu('sqlAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.SESSION_ANALYSIS'),
                value: `${BASE_MENU_VALUE.PG}_sessionAnalysis`,
                children: getMenuList(getPgNaviMenu('sessionAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.PERFORMANCE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.PG}_performanceAnalysis`,
                children: getMenuList(getPgNaviMenu('performanceAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.DATASPACE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.PG}_dataSpaceAnalysis`,
                children: getMenuList(getPgNaviMenu('dataSpaceAnalysis'))[0]?.children,
              },
            ],
          },
        ]
      : [];

  // maxgauge mysql
  const maxgaugeMysqlDashboardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL_DASHBOARD,
  })?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const maxgaugeMysqlAnalysisBoardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL_DASHBOARD,
  })?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;
  const maxgaugeMysqlMenuInfo =
    instanceCount.value?.mysql &&
    findPermissionByTranslateId({
      [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL,
    })?.menuOnOff
      ? [
          {
            text: t('WORD.GNB.MYSQL'),
            value: BASE_MENU_VALUE.MYSQL,
            dashboardList: maxgaugeMysqlDashboardList,
            analysisBoardList: maxgaugeMysqlAnalysisBoardList,
            children: [
              ...(getMenuList(getMysqlNaviMenu('instanceView'))[0]?.children ?? []),
              {
                text: t('WORD.GNB.SQL_ANALYSIS'),
                value: `${BASE_MENU_VALUE.MYSQL}_sqlAnalysis`,
                children: getMenuList(getMysqlNaviMenu('sqlAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.SESSION_ANALYSIS'),
                value: `${BASE_MENU_VALUE.MYSQL}_sessionAnalysis`,
                children: getMenuList(getMysqlNaviMenu('sessionAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.PERFORMANCE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.MYSQL}_performanceAnalysis`,
                children: getMenuList(getMysqlNaviMenu('performanceAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.DATASPACE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.MYSQL}_dataSpaceAnalysis`,
                children: getMenuList(getMysqlNaviMenu('dataSpaceAnalysis'))[0]?.children,
              },
            ],
          },
        ]
      : [];

  // maxgauge SQL Server
  const maxgaugeSqlServerDashboardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER_DASHBOARD,
  })?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const maxgaugeSqlServerAnalysisBoardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER_DASHBOARD,
  })?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;
  const maxgaugeSqlServerMenuInfo =
    instanceCount.value?.sqlserver &&
    findPermissionByTranslateId({
      [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER,
    })?.menuOnOff
      ? [
          {
            text: t('WORD.GNB.SQL_SERVER'),
            value: BASE_MENU_VALUE.SQLSERVER,
            dashboardList: maxgaugeSqlServerDashboardList,
            analysisBoardList: maxgaugeSqlServerAnalysisBoardList,
            children: [
              ...(getMenuList(getSqlServerNaviMenu('instanceView'))[0]?.children ?? []),
              {
                text: t('WORD.GNB.SQL_ANALYSIS'),
                value: `${BASE_MENU_VALUE.SQLSERVER}_sqlAnalysis`,
                children: getMenuList(getSqlServerNaviMenu('sqlAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.SESSION_ANALYSIS'),
                value: `${BASE_MENU_VALUE.SQLSERVER}_sessionAnalysis`,
                children: getMenuList(getSqlServerNaviMenu('sessionAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.PERFORMANCE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.SQLSERVER}_performanceAnalysis`,
                children: getMenuList(getSqlServerNaviMenu('performanceAnalysis'))[0]?.children,
              },
              {
                text: t('WORD.GNB.DATASPACE_ANALYSIS'),
                value: `${BASE_MENU_VALUE.SQLSERVER}_dataSpaceAnalysis`,
                children: getMenuList(getSqlServerNaviMenu('dataSpaceAnalysis'))[0]?.children,
              },
            ],
          },
        ]
      : [];

  // maxgauge Redis
  const maxgaugeRedisDashboardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_REDIS_DASHBOARD,
  })?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const maxgaugeRedisAnalysisBoardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_REDIS_DASHBOARD,
  })?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;
  const maxgaugeRedisMenuInfo =
    instanceCount.value?.redis &&
    findPermissionByTranslateId({
      [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_REDIS,
    })?.menuOnOff
      ? [
          {
            text: t('WORD.GNB.REDIS'),
            value: BASE_MENU_VALUE.REDIS,
            dashboardList: maxgaugeRedisDashboardList,
            analysisBoardList: maxgaugeRedisAnalysisBoardList,
          },
        ]
      : [];

  // maxgauge Cubrid
  const maxgaugeCubridDashboardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CUBRID_DASHBOARD,
  })?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const maxgaugeCubridAnalysisBoardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CUBRID_DASHBOARD,
  })?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;

  const maxgaugeCubridMenuInfo =
    instanceCount.value?.cubrid &&
    findPermissionByTranslateId({
      [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CUBRID,
    })?.menuOnOff
      ? [
          {
            text: t('WORD.GNB.CUBRID'),
            value: BASE_MENU_VALUE.CUBRID,
            dashboardList: maxgaugeCubridDashboardList,
            analysisBoardList: maxgaugeCubridAnalysisBoardList,
          },
        ]
      : [];

  // maxgauge Mongo
  const maxgaugeMongoDashboardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MONGO_DASHBOARD,
  })?.menuOnOff
    ? subDashboardMenuInfo
    : undefined;
  const maxgaugeMongoAnalysisBoardList = findPermissionByTranslateId({
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MONGO_DASHBOARD,
  })?.menuOnOff
    ? subAnalysisBoardMenuInfo
    : undefined;
  const maxgaugeMongoMenuInfo =
    instanceCount.value?.mongodb &&
    findPermissionByTranslateId({
      [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MONGO,
    })?.menuOnOff
      ? [
          {
            text: t('WORD.GNB.MONGODB'),
            value: BASE_MENU_VALUE.MONGODB,
            dashboardList: maxgaugeMongoDashboardList,
            analysisBoardList: maxgaugeMongoAnalysisBoardList,
          },
        ]
      : [];

  // maxgauge PA > DB 메뉴 목록
  const maxgaugePaDbMenuInfo = findPermissionByTranslateId(
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
  )?.menuOnOff
    ? [
        ...maxgaugeOracleMenuInfo,
        ...maxgaugePgMenuInfo,
        ...maxgaugeMysqlMenuInfo,
        ...maxgaugeSqlServerMenuInfo,
        ...maxgaugeRedisMenuInfo,
        ...maxgaugeCubridMenuInfo,
        ...maxgaugeMongoMenuInfo,
      ]
    : [];

  // exemONE 메뉴 목록
  const exemONEMenuInfo: MenuInfo[] = [
    dashboardMenuInfo,
    ...infrastructureMenuInfo,
    ...kafkaMenuInfo,
    ...cloudMenuInfo,
    ...kubernetesMenuInfo,
    ...applicationMenuInfo,
    ...databaseMenuInfo,
    ...serviceMenuInfo,
    ...logMenuInfo,
    ...alertMenuInfo,
    ...paMenuInfo,
    ...reportMenuInfo,
    helpMenuInfo,
    myInfoMenuInfo,
  ];

  // maxgauge 메뉴 목록
  const maxgaugeMenuInfo: MenuInfo[] = [
    dashboardMenuInfo,
    ...databaseMenuInfo,
    ...maxgaugePaDbMenuInfo,
    ...toolMenuInfo,
    ...infrastructureMenuInfo,
    ...serviceMenuInfo,
    ...logMenuInfo,
    ...alertMenuInfo,
    ...reportMenuInfo,
    helpMenuInfo,
    myInfoMenuInfo,
  ];

  const menuInfo = computed<MenuInfo[]>(() => {
    switch (viewMode) {
      case VIEW_MODE.EXEMONE:
        return exemONEMenuInfo;
      case VIEW_MODE.MAXGAUGE:
        return maxgaugeMenuInfo;
      default:
        return exemONEMenuInfo;
    }
  });

  return { findMenuItem, getMenuList, menuInfo, helpMenuInfo, myInfoMenuInfo };
};

export const useDashboardFavorite = (dashboardInfo: MenuInfo) => {
  const platformDashboardStore = usePlatformDashboardStore();
  const { updateFavoriteDashboardList } = useDashboardListViewStore();
  const { getDashboardByPlatformList } = platformDashboardStore;
  const { menuByPlatform, menuByAnalysisPlatform } = storeToRefs(platformDashboardStore);

  const getQueryParam = (search: string, path: string) => {
    const params = new URL(`http://${path}`).searchParams.get(search);
    return params === 'kubernetes' ? 'k8s' : params;
  };

  const getDashboardId = (path: string) => {
    const url = new URL(`http://${path}`);
    const pathSegments = url.pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  };

  const isFavorited = computed(() => {
    const { path } = dashboardInfo;
    if (!path) return false;

    const platform = getQueryParam('rootMenu', path);
    const dashboardId = getDashboardId(path);
    if (!platform || !dashboardId || !menuByPlatform.value[platform]) {
      return false;
    }
    return !!menuByPlatform.value[platform][dashboardId]?.favoriteId;
  });

  const isAnalysisFavorited = computed(() => {
    const { path } = dashboardInfo;
    if (!path) return false;

    const platform = getQueryParam('rootMenu', path);
    const dashboardId = getDashboardId(path);
    if (!platform || !dashboardId || !menuByAnalysisPlatform.value[platform]) {
      return false;
    }
    return !!menuByAnalysisPlatform.value[platform][dashboardId]?.favoriteId;
  });

  const updateFavorite = async () => {
    try {
      const dashboardId = dashboardInfo.path?.split('/')[3].split('?')[0];
      if (!dashboardId) return;
      await updateFavoriteDashboardList([Number(dashboardId)]);
      await getDashboardByPlatformList();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    isFavorited,
    isAnalysisFavorited,
    updateFavorite,
  };
};
