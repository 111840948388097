import { computed } from 'vue';
import {
  convertStandardStatus,
  statusToColor,
} from '@/common/components/molecules/statusTag/statusTag.utils';

export const setup = (props) => {
  const typeToClass = computed(() => {
    const standardStatus = convertStandardStatus(props.type);
    return statusToColor(standardStatus);
  });

  return {
    typeToClass,
  };
};
