import { i18n } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { convertChartTypeToWidgetChartType } from '@/dashboard/utils/dashboardUtils';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import { getLimitSeriesCountByChartType } from '@/dashboard/components/widgets/widgets.defines';

export const checkScoreboardStyle2ChartData =
  ({ diffUnitError, chartType }: { diffUnitError: string; chartType: 'scoreboard-style2' }) =>
  (
    rawData: CurrentRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ): WidgetChartDataStatusInfo[] => {
    let firstStatUnit = '';

    const { currentData } = rawData;
    const chartWidgetType = convertChartTypeToWidgetChartType(chartType);
    const seriesCountLimit = getLimitSeriesCountByChartType(chartWidgetType[0], chartWidgetType[1]);

    return chartDataStatus.reduce((dataStatusList, apiChartDataStatus, index) => {
      if (index === 0) {
        firstStatUnit = currentData.at(index)?.at(0)?.unit ?? '';
        if (apiChartDataStatus.status === 'fail') {
          dataStatusList.push(apiChartDataStatus);
        }
      } else if (index === 1) {
        const secondStatUnit = currentData[index][0]?.unit ?? '';
        if (firstStatUnit != null && secondStatUnit != null && firstStatUnit !== secondStatUnit) {
          dataStatusList.push({
            status: 'fail',
            type: 'custom',
            chartDataId: apiChartDataStatus.chartDataId,
            reason: diffUnitError,
          });
        }
      } else {
        dataStatusList.push({
          ...apiChartDataStatus,
          status: 'fail',
          type: 'custom',
          reason: i18n.global.t('ERROR.CHART_DATA.CE002', {
            value: seriesCountLimit,
          }),
          errorCode: 'CE002',
        });
      }
      return dataStatusList;
    }, [] as WidgetChartDataStatusInfo[]);
  };
