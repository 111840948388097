import { MyInfo } from '@/common/api/resTypes/userInfoRes';
import { store } from '@/common/store';
import { DatePickerMarker } from '@vuepic/vue-datepicker';
import dayjs from 'dayjs';
import { computed, ref } from 'vue';

export const useCalendar = (disabledSelectedBeforeDay?: string) => {
  const userLanguage = computed<string>(() => {
    const { language }: MyInfo = store.getters['myInfo/getAccountInfo'];

    switch (language) {
      default:
      case 'KO':
        return 'ko-KR';
      case 'EN':
        return 'en-US';
    }
  });

  const monthYearButtonDirection = computed(() => {
    return userLanguage.value === 'ko-KR' ? 'row-reverse' : 'row';
  });

  const today = dayjs().tz();

  const makers = ref<DatePickerMarker[]>([
    {
      date: today.toDate(),
      type: 'dot',
    },
  ]);

  const checkDisabledDate = (date: Date): boolean => {
    const isBeforeDisabledDate = disabledSelectedBeforeDay
      ? dayjs(date).startOf('day').isBefore(dayjs(disabledSelectedBeforeDay))
      : false;
    return isBeforeDisabledDate || dayjs(date).startOf('day').isAfter(today.startOf('day'));
  };

  const isToday = (date: Date): boolean => {
    const givenDate = dayjs(date).tz();

    return givenDate.isSame(today, 'day');
  };

  const isSunday = (date: Date): boolean => {
    const day = dayjs(date).tz().day();
    const SUNDAY = 0;

    return day === SUNDAY;
  };

  const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const getFormattedDay = (index: number) => DAYS[index];

  return {
    userLanguage,
    monthYearButtonDirection,
    makers,
    checkDisabledDate,
    today,
    isToday,
    isSunday,
    getFormattedDay,
  };
};
