<script setup lang="ts">
import OneButton from '@/common/components/molecules/button/OneButton.vue';
import { Size } from '@/types/common.types';
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    isExpanded?: boolean;
    showToggle?: boolean;
    border?: boolean;
    radius?: boolean;
    toggleSize?: Size;
  }>(),
  {
    isExpanded: false,
    showToggle: true,
    border: false,
    radius: false,
  },
);

const emit = defineEmits<{
  'update:isExpanded': [value: boolean];
}>();

const internalIsExpanded = ref(props.isExpanded ?? false);

const onClickExpand = () => {
  internalIsExpanded.value = !internalIsExpanded.value;
  emit('update:isExpanded', internalIsExpanded.value);
};

watch(
  () => props.isExpanded,
  (value) => {
    internalIsExpanded.value = value ?? false;
  },
);

defineSlots<{
  title: void;
  actions: void;
  default: void;
}>();
</script>

<template>
  <div
    :class="[
      'toggle-box',
      {
        'toggle-box--border': props.border,
        'toggle-box--expanded': internalIsExpanded,
        'toggle-box--radius': props.radius,
      },
    ]"
  >
    <h3 class="toggle-box__title">
      <div
        class="toggle-box__title-text"
        @click="onClickExpand"
      >
        <one-button
          v-if="props.showToggle"
          type="icon"
          :size="props.toggleSize"
          :left-icon="internalIsExpanded ? 'ev-icon-s-arrow-up' : 'ev-icon-s-arrow-down'"
        />
        <slot name="title" />
      </div>
      <div class="toggle-box__title-actions">
        <slot name="actions" />
      </div>
    </h3>
    <div
      v-show="internalIsExpanded"
      class="toggle-box__content"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.toggle-box {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin: 0;
  }
  &__title-actions {
    flex-shrink: 0;
  }
  &__title-text {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__content {
    padding: 8px;
  }
}
.toggle-box--border {
  border: 1px solid var(--color-gray-05-05);
  &.toggle-box--expanded > .toggle-box__content {
    border-top: 1px solid var(--color-gray-05-05);
  }
}
.toggle-box--radius {
  border-radius: 2px;
}
</style>
