import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import {
  DashboardData,
  DashboardUserGroupData,
  UpdateDashboardFavoriteRequest,
} from '@/dashboard/types/dashboard';
import {
  getGroupsIntegratedDashboardControllerAxios,
  patchBoardIntegratedDashboardControllerAxios,
  patchFavoriteBoardIntegratedDashboardControllerAxios,
  setCustomReportIntegratedDashboardControllerAxios,
} from '@/openapi/metaV6/api/integrated-dashboard-controller-api';
import {
  moveBoardIntegratedDashboardControllerAxios,
  patchGroupIntegratedDashboardControllerAxios,
} from '@/openapi/dashboard/api/integrated-dashboard-controller-api';
import {
  IntegrateDashboardComponentExtends,
  IntegrateDashboardPatchResponse,
} from '@/openapi/metaV6/model';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { useViewModeStore } from '@/common/stores/view-mode';
import { PLATFORM_MENU } from '@/common/stores/platform-dashboard';
import { DashboardGroupMoveRequest } from '@/openapi/dashboard/model';
import { getBoardsIntegratedDashboardV8ControllerAxios } from '@/openapi/dashboard/api/integrated-dashboard-v8-controller-api';
import {
  convertToUpdateDashboardGroupRequestDto,
  convertToDashboardData,
  convertToDashboardGroup,
  convertToDeleteDashboardsDto,
  convertToUpdateDashboardFavoriteRequestDto,
} from '@/dashboard/adaptors';
import { UpdateDashboardGroupRequest } from '../types/dashboard/types';

export const useDashboardListViewStore = defineStore('dashboardDashboardListView', () => {
  const dashboardUserGroupList = ref<DashboardUserGroupData[]>([]);
  const dashboardList = ref<DashboardData[]>([]); // 이미지 값 없는 데이터. 목록뷰에서 사용.
  const { isMaxgaugeMode } = storeToRefs(useViewModeStore());

  const fetchDashboardUserGroupList = async () => {
    try {
      const { data } = await getGroupsIntegratedDashboardControllerAxios({
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_USER_GROUP,
      });
      dashboardUserGroupList.value = data?.data?.map(convertToDashboardGroup) ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const isExemOnePlatform = (platform: string): boolean =>
    Object.values<string>(PLATFORM_MENU).includes(platform);

  const filterMenuPaths = (menuPaths: string[]): string[] => {
    let filteredValues: string[];
    if (isMaxgaugeMode.value) {
      filteredValues = menuPaths.filter((item) => item !== 'database');
    } else {
      const exemOnePlatform = menuPaths.filter((item) => isExemOnePlatform(item));
      const isExistMaxGaugePlatform = menuPaths.some((item) => !isExemOnePlatform(item));
      if (isExistMaxGaugePlatform && !menuPaths.includes('database')) {
        filteredValues = [...exemOnePlatform, 'database'];
      } else {
        filteredValues = exemOnePlatform;
      }
    }
    return filteredValues;
  };

  const fetchDashboardList = async (isImage = false) => {
    try {
      const { data } = await getBoardsIntegratedDashboardV8ControllerAxios({
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_LIST,
        includeImage: isImage,
      });
      const dashboardInfos = data.data ?? [];
      const dashboardListData = dashboardInfos.map(convertToDashboardData).map((info) => ({
        ...info,
        menuPaths: filterMenuPaths(info.menuPaths ?? []),
        analysisMenuPaths: filterMenuPaths(info.analysisMenuPaths ?? []),
      }));
      dashboardList.value = dashboardListData;
    } catch (e) {
      console.log(e);
    }
  };

  const updateDashboardUserGroup = async (
    { dashboardGroupId, dashboardGroupName }: DashboardUserGroupData,
    deleteWithDashboard = false,
    deleted = false,
    frameName = '',
  ) => {
    try {
      await patchGroupIntegratedDashboardControllerAxios({
        request: {
          dashboardGroupId,
          dashboardGroupName,
          deleteWithDashboard,
          deleted,
        },
        frameName,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const addDashboardList = async (
    dashboards: IntegrateDashboardComponentExtends[],
  ): PromiseAxiosResponse<IntegrateDashboardPatchResponse> => {
    return patchBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
    });
  };

  const updateDashboardList = async (
    dashboards: IntegrateDashboardComponentExtends[],
    isImage = false,
    frameName?: string,
  ) => {
    if (!dashboards.length) {
      return;
    }

    await patchBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
      frameName,
    });

    await fetchDashboardList(isImage);
  };

  const deleteDashboardList = async (dashboardIds: number[], isImage = false) => {
    const deletedDashboards: DashboardData[] = dashboardList.value.reduce(
      (acc: DashboardData[], dashboard) => {
        if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
          acc.push({
            ...dashboard,
            dashboardGroupId: -1,
          });
        }
        return acc;
      },
      [],
    );

    await updateDashboardList(
      deletedDashboards.map(convertToDeleteDashboardsDto),
      isImage,
      FRAME_NAMES.DASHBOARD.DASHBOARD_LIST_UPDATE,
    );
  };

  const updateDashboardListGroup = async (
    dashboards: DashboardGroupMoveRequest[],
    isImage = false,
    frameName?: string,
  ) => {
    await moveBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
      frameName,
    });

    await fetchDashboardList(isImage);
  };

  const moveGroupForDashboardList = async (dashboardIds: number[], newGroupId: string | null) => {
    const movedDashboards: UpdateDashboardGroupRequest[] = [];
    dashboardList.value.forEach((dashboard) => {
      if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
        movedDashboards.push({
          dashboardId: dashboard.dashboardId,
          dashboardGroupId: newGroupId ? +newGroupId : undefined,
        });
      }
    });

    await updateDashboardListGroup(
      movedDashboards.map(convertToUpdateDashboardGroupRequestDto),
      false,
      FRAME_NAMES.DASHBOARD.DASHBOARD_USER_GROUP_MOVE,
    );
  };

  const updateFavoriteDashboardList = async (dashboardIds: number[]) => {
    if (!dashboardIds.length) {
      return;
    }

    const dashboards: UpdateDashboardFavoriteRequest[] = [];
    dashboardList.value.forEach((dashboard) => {
      if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
        dashboards.push({
          dashboardId: dashboard.dashboardId,
          favoriteId: dashboard.favoriteId,
          deleted: !!dashboard.favoriteId,
        });
      }
    });

    try {
      await patchFavoriteBoardIntegratedDashboardControllerAxios({
        request: {
          dashboards: dashboards.map(convertToUpdateDashboardFavoriteRequestDto),
        },
        frameName: FRAME_NAMES.DASHBOARD.FAVORITE_DASHBOARD_UPDATE,
      });
      await fetchDashboardList();
    } catch (e) {
      console.log(e);
    }
  };

  const setReportDashboard = async (dashboardId: number, customReport: boolean) => {
    try {
      await setCustomReportIntegratedDashboardControllerAxios({
        request: {
          dashboardId,
          customReport,
        },
        frameName: FRAME_NAMES.DASHBOARD.REPORT_DASHBOARD_SET,
      });

      await fetchDashboardList();
    } catch (e) {
      console.log(e);
    }
  };

  return {
    dashboardUserGroupList,
    dashboardList,
    fetchDashboardList,
    fetchDashboardUserGroupList,
    updateDashboardUserGroup,
    addDashboardList,
    updateDashboardList,
    deleteDashboardList,
    moveGroupForDashboardList,
    updateFavoriteDashboardList,
    setReportDashboard,
  };
});
