<template>
  <ev-window
    v-model:visible="isVisible"
    :title="props.title"
    :window-class="windowClass"
    :width="windowSize?.width"
    :height="windowSize?.height"
    :min-width="windowSize?.minWidth"
    :min-height="windowSize?.minHeight"
    :is-modal="false"
    :hide-scroll="false"
    draggable
    resizable
    :maximizable="props.maximizable"
    :esc-close="true"
    focusable
    @mousedown="onMouseDownWindow"
  >
    <template
      v-if="slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <div class="draggable-window__body">
      <slot />
    </div>
  </ev-window>
</template>

<script setup lang="ts">
import { type Props, type Emit, type Slots, setup } from './draggableWindow.setup';

const props = withDefaults(defineProps<Props>(), {
  visible: false,
  title: '',
  sizeType: 'MEDIUM',
  className: '',
  size: () => ({
    width: '',
    height: '',
    minWidth: '',
    minHeight: '',
  }),
  maximizable: false,
});

const emit = defineEmits<Emit>();

const slots = defineSlots<Slots>();

const {
  isVisible,
  windowClass,
  windowSize,

  onMouseDownWindow,
} = setup(props, emit);
</script>

<style lang="scss">
$ev-tabs-title-height: 32px;
$draggable-window-row-height: 28px;
$draggable-window-row-dt-width: 185px;
.draggable-window {
  padding: 0;
  font-size: $font-size-base !important;

  .ev-window-header {
    $header-content-height: 28px;

    display: flex;
    justify-content: space-between;
    .ev-window-title {
      font-size: 15px;
      color: var(--window-title-font-color);
    }
    .draggable-window__condition {
      display: inline-flex;
      position: absolute;
      top: 7px;
      right: 45px;
      gap: 8px;
      align-items: center;
      font-size: 12px;

      /* stylelint-disable */
      .ev-select {
        width: 200px !important;
        .ev-input {
          height: $header-content-height !important;
        }
      }
      /* stylelint-enable */
    }
  }
  .ev-window-header__header-button {
    position: absolute;
    top: 6px;
    right: 45px;
    width: auto;

    &--xsmall {
      top: 10px;
      right: 38px;
    }
  }

  &__body {
    height: 100%;

    &--has-tail {
      height: calc(100% - 35px - 10px);
      margin-bottom: 10px;
      overflow-y: auto;
    }

    dl {
      display: flex;
      height: $draggable-window-row-height;
      border: 1px solid var(--draggable-window-table-border-color);
      line-height: $draggable-window-row-height;
      &:not(:last-child) {
        border-bottom: none;
      }
    }
    dt {
      width: $draggable-window-row-dt-width;
      padding: 0 16px;
      border-right: 1px solid var(--draggable-window-table-border-color);
      background-color: var(--draggable-window-key-bg-color);
    }
    dd {
      flex: 1;
      padding: 0 16px;
      background-color: var(--draggable-window-val-bg-color);
      color: var(--draggable-window-val-font-color);

      @include shortening();

      &:not(:last-child) {
        border-right: 1px solid var(--draggable-window-table-border-color);
      }
    }

    .ev-tabs {
      height: 100%;
      padding-top: 6px;
    }
    .ev-tabs-body {
      height: calc(100% - #{$ev-tabs-title-height});
      padding-top: 0 !important;
      border-radius: 0 0 2px 2px;
      background-color: var(--tabs-header-active-bg-color);
    }
    .ev-tab-panel {
      height: 100%;
    }
    .table {
      background-color: #262626;
    }
  }
  .detail-window {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__tab-field {
      flex: 1;
      overflow-y: hidden;
    }
    &__table {
      padding-bottom: 6px;
    }
    &__table--flex {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &__table-only-dt {
      width: 100%;
    }
    &__table-last-row {
      flex: 1;
      dd {
        overflow-y: auto;
        white-space: pre-wrap;
      }
    }
    &__table--hide {
      height: 0;
      padding-bottom: 0;
      opacity: 0;
      transition: height $animate-fast;
      transition-delay: 0.3s;
    }
    .ev-tabs {
      padding-top: 0 !important;
    }
  }
}
</style>
