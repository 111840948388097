<script setup lang="ts">
import { setup } from '@/common/components/organisms/baseMenu/baseMenu.setup';
import BaseMenuLogo from '@/common/components/organisms/baseMenu/baseMenuLogo/BaseMenuLogo.vue';
import { clickoutside as vClickOutside } from '@/common/directives/clickoutside';
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import TutorialGuide from '@/help/components/tutorialGuide/TutorialGuide.vue';
import MyInfoPopup from './popups/myInfoPopup/MyInfoPopup.vue';
import UserSupportPopup from './popups/userSupportPopup/UserSupportPopup.vue';
import FloatingMenuPopup from './popups/floatingMenuPopup/FloatingMenuPopup.vue';
import MenuElement from './menuElement/MenuElement.vue';
import UserMenuElement from './menuElement/UserMenuElement.vue';
import SupportMenuElement from './menuElement/SupportMenuElement.vue';
import RightPanelMenu from './rightPanelMenu/RightPanelMenu.vue';
import { BASE_MENU_VALUE } from './baseMenu.define';

const {
  t,

  menuInfo,
  dashboardMenuInfo,
  analysisBoardMenuInfo,
  myInfoMenuInfo,
  helpMenuInfo,

  productName,

  mainPanelInfo,
  hoveredMenuInfo,
  isMyInfoMenuClicked,

  baseMenuRef,
  popupMenuRef,
  popupMenuStyle,
  tooltipMouseEvent,
  isBaseMenuHidden,

  onMouseEventInTarget,
  onHoverMainCategory,
  onHoverHelpCategory,
  onClickMainCategory,
  onClickMyInfoCategory,
  onClickSupportCategory,
  onShrinkMainPanel,
  onHideFloatingMenu,
  onHideMyInfoPopup,
  onClickTitle,
  onClickSetting,
  onClickOutsideMainPanel,
} = setup();
</script>

<template>
  <aside
    v-click-outside="onClickOutsideMainPanel"
    class="base-menu-wrapper"
    @mouseleave="onHideFloatingMenu"
  >
    <nav
      ref="baseMenuRef"
      :class="[
        'base-menu',
        { expanded: mainPanelInfo.isMainPanelExpanded, hidden: isBaseMenuHidden },
      ]"
    >
      <div class="base-menu-header">
        <base-menu-logo
          :product-name="productName"
          :is-expand="mainPanelInfo.isMainPanelExpanded"
          @click-title="onClickTitle"
        />
        <button
          class="base-menu-header__slide-button"
          @click="onShrinkMainPanel"
          @mouseenter="onMouseEventInTarget"
          @mouseleave="onMouseEventInTarget"
        >
          <ev-icon icon="icon-left-panel" />
        </button>
        <simple-text-tooltip
          :mouse-event="tooltipMouseEvent"
          base-pos="bottom"
          :text="`${t('WORD.HIDE_MENU')}`"
        />
      </div>
      <ul class="base-menu__top">
        <template
          v-for="(info, idx) in menuInfo"
          :key="`${info.text}__${idx}`"
        >
          <menu-element
            v-if="info.value !== BASE_MENU_VALUE.SETTINGS && info.value !== BASE_MENU_VALUE.HELP"
            :menu-info="info"
            :dashboard-menu-list="dashboardMenuInfo[info.value]"
            :analysis-board-menu-list="analysisBoardMenuInfo[info.value]"
            :selected="mainPanelInfo.selectedCategory === info.value"
            @click="() => onClickMainCategory(info)"
            @hover="onHoverMainCategory"
          />
        </template>
      </ul>
      <ul class="base-menu__bottom">
        <support-menu-element
          :info="helpMenuInfo"
          :selected="mainPanelInfo.selectedCategory === helpMenuInfo.value"
          @hover="onHoverHelpCategory"
          @click="onClickSupportCategory"
        />
        <user-menu-element
          v-if="!!myInfoMenuInfo"
          :info="myInfoMenuInfo"
          :selected="mainPanelInfo.selectedCategory === myInfoMenuInfo.value"
          @hover="onHoverHelpCategory"
          @click="onClickMyInfoCategory"
        />
      </ul>
      <user-support-popup
        :class="{
          expanded: mainPanelInfo.isMainPanelExpanded,
          shown: hoveredMenuInfo?.value === BASE_MENU_VALUE.HELP,
        }"
        :style="popupMenuStyle"
        @mouseleave="onHideFloatingMenu"
      />
      <my-info-popup
        v-click-outside="onHideMyInfoPopup"
        :class="{
          expanded: mainPanelInfo.isMainPanelExpanded,
          shown: hoveredMenuInfo?.value === BASE_MENU_VALUE.SETTINGS || isMyInfoMenuClicked,
        }"
        :is-fixed="isMyInfoMenuClicked"
        :style="popupMenuStyle"
        @click-setting="onClickSetting"
        @mouseleave="onHideFloatingMenu"
      />
      <floating-menu-popup
        ref="popupMenuRef"
        :class="{
          shown:
            hoveredMenuInfo?.value &&
            hoveredMenuInfo.value !== BASE_MENU_VALUE.HELP &&
            hoveredMenuInfo.value !== BASE_MENU_VALUE.SETTINGS &&
            !mainPanelInfo.isMainPanelExpanded,
        }"
        :style="popupMenuStyle"
        :menu-info="hoveredMenuInfo"
        :dashboard-menu-list="dashboardMenuInfo[hoveredMenuInfo?.value ?? '']"
        :analysis-board-menu-list="analysisBoardMenuInfo[hoveredMenuInfo?.value ?? '']"
        @mouseleave="onHideFloatingMenu"
      />
    </nav>
    <right-panel-menu />
    <tutorial-guide />
  </aside>
</template>

<style lang="scss">
.base-menu-wrapper {
  display: flex;
  z-index: $on-base-sub-menu;
  flex-direction: row;
  height: 100%;
  background-color: var(--gnb-bg-color);
}
.base-menu {
  --app-list-padding-top: 6px;
  --shadow-color: rgba(0, 0, 0, 0.14);
  --tooltip-bg-color: #393939;

  display: flex;
  position: relative;
  flex-direction: column;
  width: var(--gnb-menu-width);
  height: 100%;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.2);
  transition: width $animate-gnb;

  &__top {
    flex: 1;
    padding: var(--app-list-padding-top) 1.5px 0 5.5px;
    overflow: scroll;
    border-right: 1px solid var(--gnb-border-color);

    &::-webkit-scrollbar {
      width: 4px !important;
      background-color: var(--gnb-bg-color) !important;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--gnb-bg-color) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gnb-scroll-thumb-color) !important;
    }

    &:not(:hover)::-webkit-scrollbar-thumb {
      background-color: var(--gnb-bg-color) !important;
    }
  }

  &__bottom {
    position: relative;
    width: 100%;
    padding-bottom: 10px;

    &::before {
      position: absolute;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: var(--gnb-border-color);
      content: '';
      @include place-at-center('v');
    }
  }

  > div:not(.menu-popup),
  > ul {
    z-index: $on-base-sub-menu;
    background-color: var(--gnb-bg-color);
  }

  &.expanded {
    width: var(--gnb-menu-width-expanded);

    .base-menu__bottom {
      border-top: 1px solid var(--gnb-border-color);
    }
  }

  &.hidden {
    width: 0;
    overflow: hidden;
  }

  .base-menu-header {
    border-right: 1px solid var(--gnb-border-color);
  }
}
</style>
