import { RSAKey } from '@/common/utils/rsa/rsa';
import { getRsaKeyRsaControllerAxios } from '@/openapi/secure/api/rsa-controller-api';

/*
  rsaKey 사용하여 암호화한 후 api 호출하는 경우, useApiRsa > handleApiRsaId 사용하여 api 호출해야 합니다.

  ex. useApiRsa().handleApiRsaId({ fn, params, rsaId });

  @returns rsaKey {string} 암호화 하는데 사용
  @returns rsaId {string} api 호출 시 header에 붙여 서버에서 검증하는데 사용
 */
const getPublicRsaKey = async () => {
  let rsaKey;
  let rsaId;
  try {
    const {
      data: { publicKeyModulus, publicKeyExponent, rsaId: _rsaId },
    } = await getRsaKeyRsaControllerAxios({});
    rsaKey = new RSAKey();
    rsaKey.setPublic(publicKeyModulus, publicKeyExponent);
    rsaId = _rsaId;
  } catch (e) {
    console.log(e);
  }
  return { rsaKey, rsaId };
};

const getCroppedArr = (str, size = 39) => {
  const numCroppedItem = Math.ceil(str.length / size);
  const croppedItems = new Array(numCroppedItem);

  for (let i = 0; i < numCroppedItem; i++) {
    const startIndex = i * size;
    croppedItems[i] = str.slice(startIndex, startIndex + size);
  }
  return croppedItems;
};
const getEncryptString = <T extends string | string[]>(str: T, publicRsaKey) => {
  if (!str) {
    return '';
  }

  /** RSA 분할 암호화 문자열 길이 기준 - 39
   *  (1024 / 8) - 11 = 117byte
   *  api key size: 1024bit
   *  padding and header data: 11byte
   *  utf-8 기준 한글 한글자당 3byte로 계산: 117 / 3 = 39
   */
  const MAX_STR_LENGTH = 39;

  const getRsaCroppedString = (fullString) =>
    getCroppedArr(fullString, MAX_STR_LENGTH)
      .map((item) => publicRsaKey.encrypt(item))
      .join(' ');

  if (Array.isArray(str)) {
    return str.map((item) => getRsaCroppedString(item));
  }
  return getRsaCroppedString(str);
};

export { getPublicRsaKey, getEncryptString };
