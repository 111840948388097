<template>
  <div class="base-menu-popup menu-popup">
    <div class="base-menu-popup__inner menu-popup__inner">
      <slot name="header" />
      <div class="base-menu-popup__bottom">
        <ul class="base-menu-popup__list">
          <slot name="list" />
        </ul>
      </div>
    </div>
    <slot name="addition" />
  </div>
</template>

<style scoped lang="scss">
.base-menu-popup {
  --popup-menu-inner-width: calc(256px + 2px);

  display: none;
  left: var(--gnb-menu-width) !important;
  transition: left $animate-gnb;

  &.shown {
    display: block !important;
  }

  &.shown.expanded {
    left: var(--gnb-menu-width-expanded) !important;
  }

  &:not(.expanded):hover {
    display: block !important;
  }

  &__inner {
    width: var(--popup-menu-inner-width);
  }

  &__bottom {
    padding: 8px;
  }

  &__list {
    padding: 4px;
    border-radius: 4px;
    background-color: var(--color-gray-08-00);
  }
}
</style>
