<template>
  <div
    ref="slideOutRef"
    :class="[
      'slide-out',
      { 'slide-out--show': isVisible },
      { 'slide-out--expand': isExpand },
      { [`slide-out--gnb-${gnbCondition}`]: gnbCondition },
    ]"
  >
    <section
      :class="[
        'slide-out-window',
        `slide-out-window--${options!.direction}`,
        options!.layout ? `slide-out-window--${options!.layout}` : '',
        { 'slide-out-window--popup': props.isPopup },
      ]"
      @mouseenter="() => emitMouseHover('enter')"
      @mouseover="() => emitMouseHover('over')"
      @mouseleave="() => emitMouseHover('leave')"
      @click="onClickSlideWindow"
    >
      <ev-button
        v-show="isVisible && options?.hasExpand"
        type="primary"
        class="slide-out-window__tip slide-out-window__tip--expand"
        :disabled="isExpand"
        @click="onClickExpand"
      >
        <i class="icon-arrow-up" />
        <span class="text">Expand</span>
      </ev-button>
      <ev-button
        v-show="isVisible && options?.hasExpand"
        type="primary"
        class="slide-out-window__tip slide-out-window__tip--collapse"
        @click="onClickCollapse"
      >
        <i class="icon-arrow-down" />
        <span class="text">Collapse</span>
      </ev-button>

      <slot name="headerTop" />
      <div
        v-if="props.title"
        class="slide-out-window__header"
      >
        <div class="header-content-wrapper left-wrapper">
          <slot name="titleLeft" />
          <p
            :title="props.title"
            class="slide-out-window__title"
          >
            {{ props.title }}
          </p>
          <slot name="headerLeft" />
        </div>
        <div class="header-content-wrapper right-wrapper">
          <slot name="headerRight" />
          <div class="slide-out-window__header-actions">
            <span
              v-for="item in headerActions"
              :key="item.type"
              :class="['header-action', `header-action--${item.type}`]"
              @click="() => onClickAction(item.type)"
            >
              <ev-icon :icon="item.icon" />
            </span>
          </div>
        </div>
      </div>
      <div class="slide-out-window__body">
        <ev-loading :model-value="props.isLoading" />
        <slot />
      </div>
    </section>
    <div
      v-if="options?.hasDimLayer"
      class="slide-out-dim-layer"
    />
  </div>
</template>

<script lang="ts" setup>
import { type Props, type Emit, setup } from './slideOutWindow.setup';

const props = withDefaults(defineProps<Props>(), {
  isVisible: false,
  isPopup: false,
  isLoading: false,
  isHeightFixed: true,
  title: '',
  options: () => ({
    direction: 'right',
    sizeType: 'MEDIUM',
    headerActions: ['open', 'close'],
    hasDimLayer: false,
    minWidth: '0',
    minHeight: '0',
    bodyYScroll: 'auto',
    top: '0px',
    translateX: '0px',
    hasExpand: true,
  }),
});
const emit = defineEmits<Emit>();

const {
  isVisible,
  isExpand,
  gnbCondition,

  slideStyleObj,
  headerActions,
  options,

  slideOutRef,

  onClickAction,
  emitMouseHover,
  onClickExpand,
  onClickCollapse,
  onClickSlideWindow,
} = setup(props, emit);
</script>

<style lang="scss">
.slide-out-dim-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $on-slide-out-window - 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.slide-out-window {
  $slide-top: v-bind('slideStyleObj.top');
  $slide-width: v-bind('slideStyleObj.width');
  $slide-height: v-bind('slideStyleObj.height');
  $slide-min-width: v-bind('slideStyleObj.minWidth');
  $slide-min-height: v-bind('slideStyleObj.minHeight');
  $header-height: v-bind('slideStyleObj.headerHeight');
  $body-height: v-bind('slideStyleObj.bodyHeight');
  $body-max-height: v-bind('slideStyleObj.bodyMaxHeight');
  $horizontal-padding: 9px 12px;

  position: fixed;
  top: $slide-top;
  left: 100%;
  z-index: $on-slide-out-window;
  width: $slide-width;
  max-width: 100%;
  height: $slide-height;
  background-color: var(--slide-detail-content-bg-color);
  transition:
    transform $animate-fast,
    width $animate-fast,
    height $animate-fast,
    left $animate-fast;

  &__tip {
    display: inline-flex;
    position: absolute;
    left: -64px;
    z-index: $on-app-header + 5;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 24px;
    padding: 4px 12px 4px 10px;
    cursor: pointer;
    background-color: #515151;
    color: #e9e9e9;
    transform: rotate(-90deg);

    &--expand {
      top: calc(50% - 50.5px);
      border-radius: 0 4px 0 0 !important;
    }

    &--collapse {
      top: calc(50% + 50.5px);
      border-radius: 4px 0 0 !important;

      ::before {
        position: absolute;
        top: 50%;
        right: -2px;
        width: 1px;
        height: calc(100% + 2px);
        background-color: var(--slide-out-window-button-middle-line-color);
        transform: translateY(-50%);
        content: '';
      }
    }

    i {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    .text {
      font-size: 12px;
    }
  }

  &--popup {
    width: 100%;
    overflow: auto;
  }

  &--left {
    left: 0;
    transform: translateX(-100%);
  }

  &__header {
    display: flex;
    z-index: 2;
    gap: 6px;
    justify-content: space-between;
    height: $header-height;
    padding: $horizontal-padding;
    border-bottom: 1px solid var(--slide-header-border-color);
    background-color: var(--slide-header-bg-color);
    color: var(--slide-out-window-font-color);

    .header-content-wrapper {
      display: flex;
      gap: 6px;
      align-items: center;
      height: 100%;
      overflow: hidden;

      &.left-wrapper {
        flex: 1;
      }
    }
  }

  &__title {
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;

    @include shortening();
  }

  &__header-actions {
    display: flex;
    gap: 10px;
    margin-left: 4px;
    font-size: 18px;
    color: var(--color-d-gray-02);

    .header-action {
      cursor: pointer;
      transition: opacity $animate-fast;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__body {
    position: relative;
    min-width: $slide-min-width;
    height: $body-height;
    min-height: $slide-min-height;
    max-height: $body-max-height;
    padding: $horizontal-padding;
    overflow-y: v-bind('slideStyleObj.bodyYScroll');

    > .ev-tabs:first-child {
      .ev-tabs-header {
        margin-top: -1px;
      }
      .ev-tabs-title {
        &::before {
          top: 1px;
        }
      }
    }
  }
}
.slide-out {
  $slide-translate-x: v-bind('slideStyleObj.translateX');

  overflow: hidden;

  &--show {
    .slide-out-window {
      $slide-translate-x: v-bind('slideStyleObj.translateX');

      box-shadow: var(--slide-out-shadow);
      transform: translateX(-100%);

      &--left {
        transform: translateX(calc(var(--gnb-menu-width) + $slide-translate-x));
      }
    }
  }

  &--expand {
    .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width) + 28px));

      &__tip--expand i {
        background-color: var(--slide-out-window-arrow-icon-color) !important;
      }
    }
  }

  /* stylelint-disable */
  &--gnb-expanded {
    &.slide-out--show .slide-out-window {
      &--left {
        transform: translateX(calc(var(--gnb-menu-width-expanded) + $slide-translate-x));
      }
    }

    &.slide-out--expand .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width-expanded) + 28px));
    }
  }

  &--gnb-opened {
    &.slide-out--show .slide-out-window {
      &--left {
        transform: translateX(calc(var(--gnb-menu-width-opened) + $slide-translate-x));
      }
    }

    &.slide-out--expand .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width-opened) + 28px));
    }
  }

  &--gnb-expanded-and-opened {
    &.slide-out--show .slide-out-window {
      &--left {
        transform: translateX(calc(var(--gnb-menu-width-expanded-and-opened) + $slide-translate-x));
      }
    }

    &.slide-out--expand .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width-expanded-and-opened) + 28px));
    }
  }
  /* stylelint-enable */
}
.slide-out--detail {
  .ev-tabs-body {
    padding: 0;
    background-color: var(--slide-detail-content-bg-color);
  }
}
</style>
