import { computed } from 'vue';
import { Store } from 'vuex';
import { useInternational } from '@/common/locale';
import { RootState, useStore } from '@/common/store';
import { MenuInfo } from '../types';

export interface Props {
  info: MenuInfo;
  selected?: boolean;
}

export interface Emit {
  (e: 'hover', evt: MouseEvent): void;
  (e: 'click', evt: MouseEvent): void;
}

export const setup = (emit: Emit) => {
  const { t } = useInternational();
  const store: Store<RootState> = useStore();

  const userInfo = computed(() => store.getters['myInfo/getAccountInfo']);

  const onHoverButton = (e: MouseEvent) => {
    emit('hover', e);
  };

  const onClickButton = (e: MouseEvent) => {
    emit('click', e);
  };

  return {
    t,
    userInfo,

    onHoverButton,
    onClickButton,
  };
};
