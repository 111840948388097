import { computed, ref } from 'vue';
import { useInternational } from '@/common/locale';
import { capitalize } from 'lodash-es';
import { STATUS } from '@/common/components/molecules/statusTooltipTag/statusTooltipTag.define';
import { useSimpleTextTooltip } from '../simpleTextTooltip/simpleTextTooltip.uses';

interface StatusInfoType {
  status: string;
  type?: string; // NOTE: instance Type
  service: string; // NOTE: 기존 router 기준말고 직접 처리
}

export interface Props {
  statusInfo: StatusInfoType;
  teleportTo?: string;
}

export const setup = (props: Props) => {
  const { t } = useInternational();
  const servcie = props.statusInfo.service.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
  const type = props.statusInfo.type ? props.statusInfo.type.toUpperCase() : '';

  const statusTooltipMouseEvent = ref<MouseEvent | null>(null);
  const { onMouseEventInTarget } = useSimpleTextTooltip(statusTooltipMouseEvent);
  const statusTooltipInfo = ref<{ name: string; description: string }>({
    name: '',
    description: '',
  });

  const formattedStatus = computed(() => {
    const { status } = props.statusInfo;
    if (!status) return '';
    return status.replace(/[,\s()-]+/g, '_').toUpperCase();
  });

  const statusToClass = computed(() => {
    if (type) {
      if (STATUS?.[servcie]?.[type]?.GREEN?.includes(formattedStatus.value)) return 'green';
      if (STATUS?.[servcie]?.[type]?.BLUE?.includes(formattedStatus.value)) return 'blue';
      if (STATUS?.[servcie]?.[type]?.RED?.includes(formattedStatus.value)) return 'red';
      if (STATUS?.[servcie]?.[type]?.YELLOW?.includes(formattedStatus.value)) return 'yellow';
    } else {
      if (STATUS?.[servcie]?.GREEN?.includes(formattedStatus.value)) return 'green';
      if (STATUS?.[servcie]?.BLUE?.includes(formattedStatus.value)) return 'blue';
      if (STATUS?.[servcie]?.RED?.includes(formattedStatus.value)) return 'red';
      if (STATUS?.[servcie]?.YELLOW?.includes(formattedStatus.value)) return 'yellow';
    }
    return 'gray';
  });

  const getTranslationKey = () => {
    const key = `DESC.TAG.${servcie}${type ? `.${type}` : ''}.`;
    return `${key.toUpperCase()}${formattedStatus.value}`;
  };

  const onMouseStatus = ({ e }: { e: MouseEvent }) => {
    const { status } = props.statusInfo;
    if (status !== '') {
      const translationKey = getTranslationKey();
      const translation = t(translationKey);

      statusTooltipInfo.value.name = capitalize(status);

      if (translation === translationKey) {
        return;
      }

      statusTooltipInfo.value.description = translation;
    }

    onMouseEventInTarget(e);
  };

  return {
    statusToClass,
    statusTooltipMouseEvent,
    onMouseStatus,
    statusTooltipInfo,
  };
};
