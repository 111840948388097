<script setup lang="ts">
import SlideOutWindow from '@/common/components/templates/slideOutWindow/SlideOutWindow.vue';
import CustomConfirmWindow from '@/common/components/organisms/customConfirmWindow/CustomConfirmWindow.vue';
import {
  type Emit,
  type Props,
  setup,
} from '@/common/components/templates/slideDetail/slideDetail.setup';
import SlideDetailProvider from '@/common/components/templates/slideDetail/SlideDetailProvider.vue';
import { useSplitSlideDetailStore } from '@/common/stores/slide-detail';

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const {
  isShow,
  isOpenNav,
  isDetailLoading,
  detailComp,
  selectedSlideTabPanel,
  slideTabPanel,
  slideDetailHistory,
  onSlideTabChange,
  onNavToggle,
  onHistoryBack,
  localizedTitle,
  t,
  closeSlide,
  getNavTitle,
  deleteSlideTabPanel,
  isExcess,
  onClickExcess,

  isShowSplit,
  splitDetailComp,
  isSplitLoading,
  splitTabPanel,
  closeSplit,
} = setup(props, emit);
</script>

<template>
  <slide-out-window
    v-model:is-visible="isShow"
    :title="localizedTitle"
    :options="{
      width: isShowSplit ? '1500px' : '900px',
      headerActions: ['close'],
    }"
    class="slide-detail"
    :is-loading="false"
  >
    <template #titleLeft>
      <ev-icon
        v-if="slideDetailHistory.length > 1"
        icon="icon-arrow-left"
        class="slide-detail__back"
        size="large"
        @click="onHistoryBack"
      />
    </template>
    <template #headerRight>
      <ev-button
        class="slide-detail__toggle"
        :class="isOpenNav ? 'selected' : ''"
        size="small"
        @click="onNavToggle"
      >
        <ev-icon
          icon="icon-time"
          size="small"
        />
        {{ t('WORD.SLIDE_HISTORY') }}
      </ev-button>
      <nav
        v-if="isOpenNav"
        class="slide-detail__nav"
      >
        <button
          v-if="slideTabPanel.length > 0"
          type="button"
          class="slide-detail__nav-button initialized"
          :class="{
            selected: slideTabPanel[0].value === selectedSlideTabPanel?.value,
          }"
          @click="() => onSlideTabChange(slideTabPanel[0])"
        >
          <span class="slide-detail__nav-name">{{
            getNavTitle({
              type: slideTabPanel[0].type,
              name: slideTabPanel[0].name,
              title: slideTabPanel[0].title,
            })
          }}</span>
          <span class="slide-detail__nav-date">{{ slideTabPanel[0].date }}</span>
        </button>
        <div
          v-if="slideTabPanel.length > 1"
          class="slide-detail__nav-container"
        >
          <button
            v-for="(item, index) in slideTabPanel"
            :key="index"
            type="button"
            class="slide-detail__nav-button"
            :class="{
              selected: item.value === selectedSlideTabPanel?.value,
              hidden: item.init,
            }"
            @click="() => onSlideTabChange(item)"
          >
            <span class="slide-detail__nav-name">{{
              getNavTitle({ type: item.type, name: item.name, title: item.title })
            }}</span>
            <span class="slide-detail__nav-date">{{ item.date }}</span>
            <ev-button
              type="icon"
              @click.stop="() => deleteSlideTabPanel(item)"
            >
              <ev-icon icon="icon-close" />
            </ev-button>
          </button>
        </div>
      </nav>
    </template>
    <div
      v-if="isShow"
      class="slide-detail__body"
    >
      <section class="slide-detail-panel">
        <ev-loading :model-value="isDetailLoading || props.isLoading" />
        <slide-detail-provider>
          <component
            :is="detailComp"
            @close-slide="closeSlide"
          />
        </slide-detail-provider>
      </section>
      <section
        v-if="isShowSplit"
        class="slide-detail-panel slide-detail-panel--split"
      >
        <div class="slide-detail-panel__header">
          <ev-button
            type="icon"
            @click.stop="closeSplit"
          >
            <ev-icon icon="icon-right-panel" />
          </ev-button>
          <span class="slide-detail__nav-name">{{
            getNavTitle({
              type: splitTabPanel?.type,
              name: splitTabPanel?.name,
              title: splitTabPanel?.title,
            }) || t('WORD.DETAIL')
          }}</span>
        </div>
        <div class="slide-detail-panel__body">
          <ev-loading :model-value="isSplitLoading" />
          <slide-detail-provider :store="useSplitSlideDetailStore">
            <component
              :is="splitDetailComp"
              v-if="splitDetailComp"
              v-bind="splitTabPanel?.props"
            />
          </slide-detail-provider>
        </div>
      </section>
    </div>
    <custom-confirm-window
      v-model:is-show="isExcess"
      use-confirm
      @confirm="onClickExcess"
    >
      <p class="excess-text">
        {{ t('MESSAGE.SAVE_TAB_PANEL') }}
      </p>
    </custom-confirm-window>
  </slide-out-window>
</template>

<style lang="scss">
.slide-detail {
  .ev-tabs {
    overflow: hidden;
  }
  .ev-tabs-body {
    padding: 16px 10px;
    overflow: auto;
    background-color: var(--slide-detail-content-bg-color);
  }
  .ev-tab-panel {
    height: 100%;
  }

  &__back {
    cursor: pointer;
  }

  &__toggle {
    display: inline-flex;
    gap: 4px;
    align-items: center;
  }

  &__nav {
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 10;
    width: 380px;
    overflow: hidden;
    border-radius: 2px;
    background: var(--slide-detail-nav-bg-color);
    box-shadow: var(--dropdown-shadow);

    &-container {
      max-height: 320px;
      overflow-y: auto;
      border-top: 1px solid var(--slide-detail-nav-line-color);
    }

    &-button {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      padding: 8px 44px 8px 16px;
      overflow: hidden;
      cursor: pointer;
      background-color: transparent;
      color: var(--slide-detail-nav-button-color);
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background-color: var(--slide-detail-content-hover-bg-color);
      }

      span {
        display: block;
        width: 100%;
      }

      .ev-button {
        position: absolute;
        top: 50%;
        right: 12px;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        .icon-close {
          background-color: var(--slide-detail-nav-close-color);
        }
      }

      &.selected {
        background-color: var(--slide-detail-nav-button-selected-bg-color);
      }

      &.hidden {
        display: none;
      }

      &.initialized {
        position: relative;
        padding: 8px 12px 8px 48px;

        &::after {
          position: absolute;
          top: 50%;
          left: 16px;
          width: 20px;
          height: 20px;
          background-color: var(--slide-detail-nav-pin-color);
          transform: translateY(-50%);
          content: '';
          mask: url('@/common/assets/sparrow/icon-sparrow-pin.svg') no-repeat center;
        }
      }
    }

    &-name {
      overflow: hidden;
      font-size: 14px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-date {
      margin-top: 4px;
      font-size: 11px;
      color: var(--slide-detail-nav-button-date-color);
      text-align: left;
    }
  }

  &__body {
    display: flex;
    height: 100%;
  }

  // TODO: MFC PA 화면 보고 오면 padding 생겨 임시 조치
  .slide-out-window__body {
    padding: 0;
  }

  .text-viewer {
    height: 100%;
  }

  // ## Comment: 디테일 내부 차트의 툴팁, 로딩창 보다 slide-detail-history 의 z-index 가 높아야함
  .slide-out-window__header {
    z-index: 860;
  }
}
.slide-detail-panel {
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;

  &__header {
    display: flex;
    gap: 4px;
    align-items: center;
    height: 32px;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-gray-00-12);
  }

  &__body {
    position: relative;
    height: calc(100% - 32px);
  }

  &--split {
    border-left: 1px solid var(--color-gray-05-05);
  }
}
.excess-text {
  white-space: pre-wrap;
}
</style>
