<script setup lang="ts">
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import { type Props, setup } from './myInfoPopupProfileArea.setup';

const props = defineProps<Props>();
const { userRoleName } = setup(props);
</script>

<template>
  <div class="my-info-profile">
    <div
      class="my-info-profile__image"
      :class="props.userProfile.profileImage"
    />
    <div class="my-info-profile__meta">
      <badge-item
        v-if="!!userRoleName"
        class="profile-role"
        size="xsmall"
        html-type="span"
      >
        {{ userRoleName }}
      </badge-item>
      <p class="profile-name">{{ props.userProfile.name }}</p>
      <p class="profile-email">{{ props.userProfile.email }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.my-info-profile {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: 100%;
  padding: 16px 16px 8px;

  &__image {
    width: 48px;
    height: 48px;
    border-radius: 30px;
  }

  &__meta {
    flex: 1;
    overflow: hidden;
    color: var(--color-gray-00-12);

    .profile-role {
      height: 17px;
      padding: 2px 6px;
      margin-bottom: 8px;
      background-color: var(--color-gray-00-12);
      font-size: 9px;
      font-weight: 500;
      color: var(--color-gray-12-00);
    }
    .profile-name {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      @include shortening(block);
    }
    .profile-email {
      font-size: 13px;
      line-height: 15px;
      @include shortening(block);
    }
  }
}
</style>
