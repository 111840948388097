import { computed, onUnmounted } from 'vue';
import { WINDOW_SIZE } from '@/common/utils/define';
import { WindowSizeType } from '@/common/utils/types';
import { useDraggableWindowIndexStore } from '@/common/stores/draggable-window';

export interface Props {
  visible?: boolean;
  title?: string;
  sizeType?: WindowSizeType | 'CUSTOM';
  size?: {
    width?: string;
    height?: string;
    minWidth: string;
    minHeight: string;
  };
  className?: string;
  maximizable?: boolean;
}

export interface Emit {
  (e: 'update:visible', v: boolean): void;
  (e: 'click-save'): void;
}

export interface Slots {
  header?: () => any;
}

export const setup = (props: Props, emit: Emit) => {
  let currentWindowSequence = '';
  const isVisible = computed<boolean>({
    get: () => !!props.visible,
    set: () => emit('update:visible', false),
  });

  const {
    checkIsBehindWindowMinIndex,

    addDraggableWindowSequence,
    resetDraggableWindowZIndexBySequence,
    removeDraggableWindowSequence,
  } = useDraggableWindowIndexStore();

  const windowClass = computed(
    () =>
      `draggable-window window--type-${props.sizeType?.toLowerCase().replace('_', '-')} ${
        props.className
      }`,
  );
  const windowSize = computed(() => {
    if (props.sizeType === 'CUSTOM') {
      const { minWidth, minHeight, height, width } = props.size ?? {};
      return {
        minWidth,
        minHeight,
        height: height ?? minHeight,
        width: width ?? minWidth,
      };
    }
    return WINDOW_SIZE[props.sizeType ?? ''] ?? 'MEDIUM';
  });

  const onMouseDownWindow = (clickInfo: { ref: HTMLDivElement }) => {
    if (!clickInfo.ref) {
      return;
    }

    const sequence = addDraggableWindowSequence(clickInfo.ref);
    if (!sequence) {
      return;
    }

    if (!currentWindowSequence) {
      currentWindowSequence = sequence;
    }

    if (checkIsBehindWindowMinIndex(clickInfo.ref)) {
      resetDraggableWindowZIndexBySequence(currentWindowSequence);
    }
  };

  onUnmounted(() => {
    removeDraggableWindowSequence(currentWindowSequence);
  });

  return {
    isVisible,
    windowClass,
    windowSize,

    onMouseDownWindow,
  };
};
