import { computed } from 'vue';
import { USER_ROLE } from '@/common/utils';
import type { UserProfile } from './myInfoPopup.types';

export interface Props {
  userProfile: UserProfile;
}

export const setup = (props: Props) => {
  const userRoleName = computed<string | null>(
    () => USER_ROLE[props.userProfile.role ?? 2]?.name ?? null,
  );

  return { userRoleName };
};
