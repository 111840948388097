<script setup lang="ts">
import {
  setup,
  Props,
  Emit,
} from '@/common/components/molecules/timePeriod/timePeriodV2Calendar.setup';
import RangeCalendar from '@/common/components/molecules/rangeCalendar/RangeCalendar.vue';
import { stringifyDateString } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import OneTimePicker from '../input/oneTimePicker/OneTimePicker.vue';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  t,
  rangeCalendarRef,
  startDate,
  endDate,
  startDateString,
  endDateString,
  timePickerValues,
  timeValidationInfo,
  isRangeInToday,
  onClickResetDateTimePicker,
  onClickCancel,
  onClickOk,
} = setup(props, emit);
</script>

<template>
  <div class="time-period-v2-calendar">
    <range-calendar
      ref="rangeCalendarRef"
      v-model:start-date="startDate"
      v-model:end-date="endDate"
      :disabled-selected-before-day="props.disabledSelectedBeforeDay"
    />
    <div
      v-if="props.maxRangeInSec"
      class="time-period-v2-calendar__notice"
    >
      <ev-icon icon="icon-calendar" />
      <p>
        {{ t('WORD.AVAILABLE_RANGE') }}
        <strong>{{ stringifyDateString(props.maxRangeInSec) }}</strong>
      </p>
    </div>
    <div class="time-period-v2-calendar__time-picker-wrapper">
      <div class="time-period-v2-calendar__time-picker">
        <span class="time-period-v2-calendar__time-picker-label">{{ t('WORD.START') }}</span>
        <span class="time-period-v2-calendar__time-picker-date">{{ startDateString }}</span>
        <one-time-picker
          v-model="timePickerValues[0]"
          :error-msg="timeValidationInfo.fromTime"
          :readonly-times="props.readonlyTimes"
        />
      </div>
      <div class="time-period-v2-calendar__time-picker">
        <span class="time-period-v2-calendar__time-picker-label">{{ t('WORD.END') }}</span>
        <span class="time-period-v2-calendar__time-picker-date">{{ endDateString }}</span>
        <one-time-picker
          v-model="timePickerValues[1]"
          :error-msg="timeValidationInfo.toTime"
          :readonly-times="props.readonlyTimes"
        />
      </div>
    </div>
    <div class="time-period-v2-calendar__button-wrapper">
      <button
        type="button"
        :class="['time-period-v2-calendar__reset-button', { disabled: isRangeInToday }]"
        :disabled="isRangeInToday"
        @click="onClickResetDateTimePicker"
      >
        {{ t('WORD.RESET_TO_TODAY') }}
      </button>
      <div>
        <ev-button
          type="default"
          size="small"
          @click.stop="onClickCancel"
        >
          {{ t('WORD.CANCEL') }}
        </ev-button>
        <ev-button
          type="primary"
          size="small"
          :disabled="!!timeValidationInfo.fromTime || !!timeValidationInfo.toTime"
          @click.stop="onClickOk"
        >
          {{ t('WORD.OK') }}
        </ev-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.time-period-v2-calendar {
  display: flex;
  position: absolute;
  top: 100%;
  right: var(--time-period-select-width);
  z-index: $on-base-sub-menu;
  flex-direction: column;
  width: 528px;
  padding: 16px;
  margin: 4px 2px 0 0;
  border-radius: 2px;
  background-color: var(--color-gray-07-00);
  box-shadow: var(--dropdown-shadow);

  .ev-calendar-date-area:not(:first-child) {
    border: none !important;
  }

  &__time-picker-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid var(--color-gray-05-05);

    .time-period-v2-calendar__time-picker {
      display: flex;
      flex: auto;
      flex-direction: row;
      gap: 10px;
      align-items: flex-start;
      width: auto;

      &:nth-last-child(1) {
        justify-content: flex-end;
      }
    }

    .time-period-v2-calendar__time-picker-label {
      color: var(--color-gray-02-10);
      @include typography('body-2');
    }

    .time-period-v2-calendar__time-picker-date {
      line-height: 1.5 !important;
      color: var(--color-gray-00-12);
      @include typography('body-2');
    }
  }

  .ev-input {
    height: 20px !important;
    color: var(--color-gray-02-10) !important;

    &.error {
      border-color: var(--color-red-05-05) !important;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;

    .time-period-v2-calendar__reset-button {
      cursor: pointer;
      background-color: transparent;
      font-size: 10px;
      color: var(--color-blue-05-05);

      &.disabled {
        cursor: not-allowed;
        color: var(--color-gray-04-08);
      }
    }

    > div {
      display: flex;
      gap: 8px;
    }

    .ev-button {
      width: 60px !important;
    }
  }

  &__notice {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 12px;
    margin-bottom: 12px;
    border-radius: 4px;
    background-color: var(--color-gray-09-02);
  }
}
</style>
