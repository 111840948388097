<script setup lang="ts">
interface Props {
  title?: string;
}
const props = defineProps<Props>();
</script>

<template>
  <li :class="['mid-category', { 'no-title': !props.title }]">
    <p
      v-if="!!props.title"
      class="mid-category__title"
    >
      {{ props.title }}
    </p>
    <ul class="mid-category__list">
      <slot />
    </ul>
  </li>
</template>

<style scoped lang="scss">
.mid-category {
  padding: 8px 0;

  &__title {
    width: 100%;
    padding: 3px 8px;
    font-size: 10px;
    line-height: 14px;
    color: var(--color-gray-02-10);

    @include shortening();
  }

  &:first-child,
  &.no-title {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
</style>
